import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { addYears, subDays } from "date-fns";
import * as React from "react";
import "../../Component/LeftSidebar/LeftSidebar.scss";
import { EMotorCompCode } from "../../Enum/EMotorCompCode";
import { useAppSelector } from "../../Store/hooks";
import {
  CAL_NEW_NCB,
  FORMAT_DATE_DD_MM_YYYY,
  GET_CURRENT_DATE,
  addDaysToDate,
  addYearsToDate,
  calculateAgeInDays,
  formatAmountToCurrency,
  formatLicensePlate,
  formatNumberWithCurrencySymbol,
} from "../../SupportingFiles/HelpingFunction";
import { TWPremiumCal } from "../../SupportingFiles/TWHelper";

const TWLeftSidebar = () => {
  const {
    ADD_FORM,
    SELECTED_QUOTE_DATA,
    ADDON_STATUS,
    QUOTE_LOADER,
    PLAN_TYPE,
  } = useAppSelector((state) => state.TW);
  const [premium, setPremium] = React.useState<number>(0);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  React.useEffect(() => {
    const premium_details = SELECTED_QUOTE_DATA.quotationDetail;

    if (premium_details) {
      const premium_with_tax = TWPremiumCal(
        premium_details,
        ADDON_STATUS,
        VERIFY_KYC_FORM.customer_type.value
      );
      setPremium(premium_with_tax);
    }
  }, [SELECTED_QUOTE_DATA, ADDON_STATUS]);
  const [policyPeriod, setPolicyPeriod] = React.useState("");

  React.useEffect(() => {
    if (PLAN_TYPE === "Bundle Policy") {
      const value = `${GET_CURRENT_DATE()} To ${FORMAT_DATE_DD_MM_YYYY(
        subDays(addYears(new Date(), 5), 1)
      )}`;
      setPolicyPeriod(value);
    } else {
      if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
        "G002"
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            2
          )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 1), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
        EMotorCompCode.RSA
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            2
          )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 1), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
        EMotorCompCode.Kotak
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            3
          )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 2), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            1
          )} To ${addYearsToDate(GET_CURRENT_DATE(), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      }
    }
  }, []);

  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"bikeIcon"}></Box>
      <h5 className="vehicle_number">
        {PLAN_TYPE === "Bundle Policy"
          ? ADD_FORM.rto.value
          : formatLicensePlate(ADD_FORM.reg_no.value)}
      </h5>
      <h5>{ADD_FORM.make_model.value}</h5>
      <h6 className="mb-6">{ADD_FORM.variant_cc.value}</h6>

      <img
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.logo}
      />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_name}
      </h6>

      {PLAN_TYPE === "Third Party" ? null : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          marginBottom="20px">
          <p className="mb-1">IDV</p>
          <h5>
            {formatAmountToCurrency(
              Number(SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV)
            )}
          </h5>
        </Box>
      )}
      <h5>{policyPeriod}</h5>
      <p className="mb-8">Policy Period</p>
      {PLAN_TYPE === "Third Party" ? null : (
        <Box className="mb-6">
          <h5 className="ncb">
            {PLAN_TYPE === "Bundle Policy"
              ? "0"
              : CAL_NEW_NCB(
                  ADD_FORM.prev_ncb,
                  ADD_FORM.claimed,
                  ADD_FORM.previous_insurer.value,
                  ADD_FORM.policy_expiry_date.value
                )}
            %
          </h5>
          <p>New NCB</p>
        </Box>
      )}
      <h5 className="premium">
        {QUOTE_LOADER ? (
          <img src="../../images/loader.svg" alt="" height="12px" />
        ) : (
          <>{formatNumberWithCurrencySymbol(premium)}</>
        )}
      </h5>
      <p className="mb-6">Premium (Incl. GST)</p>

      {/* <Accordion
        sx={{
          boxShadow: "none",
          marginBottom: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px!important",
          ":before": { display: "none" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ fontWeight: "600" }}
        >
          Addon Covers
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px 12px 12px",
            li: { listStyleType: "disc" },
          }}
        >
          <ul
            style={{ fontSize: "13px", textAlign: "left", marginLeft: "12px" }}
          >
            <li>Zero Depreciation</li>
            <li>24x7 Roadside Assistance</li>
          </ul>
        </AccordionDetails>
      </Accordion> */}
    </Grid>
  );
};

export default TWLeftSidebar;
