import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TNRIDetails } from "../../../../types/TermFullJourney/TNRIDetails";

const setNRIDetails = (
  state: TTermFullJourneySlice,
  action: PayloadAction<TNRIDetails>
) => {
  const data: TTermFullJourneySlice = { ...state, NRIDetails: action.payload };
  return data;
};

export const TERM_NRI_DETAILS = {
  setNRIDetails,
};
