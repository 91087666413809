import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";
import MTermRiderQuestion from "../../../../Component/Term/MTermRiderQuestion/MTermRiderQuestion";
import "../../../../SCSS/CommonSCSS/MProposalForms.scss";
import { TermSlice } from "../../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatNumberToLakhOrCrores,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { TDropdown } from "../../../../types/Common/TDropdown";

const MRidersBenefits = ({
  loaderTf001,
  loaderTf002,
  loaderTf003,
  loaderTf004,
  loaderTf005,
  tf001Data,
  tf002Data,
  tf003Data,
  tf004Data,
  tf005Data,
  loader,
}: {
  loaderTf001: boolean;
  loaderTf002: boolean;
  loaderTf003: boolean;
  loaderTf004: boolean;
  loaderTf005: boolean;
  tf001Data: TDropdown[];
  tf002Data: TDropdown[];
  tf003Data: TDropdown[];
  tf004Data: TDropdown[];
  tf005Data: TDropdown[];
  loader: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { RIDERS_BENEFITS, SELECTED_QUOTE_DATA, PROPOSER_DATA } =
    useAppSelector((state) => state.Term);

  return (
    <Box className="proposalPreview pb-16">
      <h5 className="sectionTitle">Addon Details</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={6}>
            <img src={SELECTED_QUOTE_DATA.companyDetails.logo} alt="logo" />
            <p className="mt-3 product_name">
              {SELECTED_QUOTE_DATA.productDetails.product_name}
            </p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium mb-2">
              Premium:{" "}
              <span>
                {formatAmountToCurrency(
                  RIDERS_BENEFITS.riders_benefits_response.finalPremium
                )}
              </span>
            </p>
            <p className=" mb-2">
              Sum Assured:{" "}
              <span>
                {!isEmpty(PROPOSER_DATA.sum_assured.value)
                  ? formatNumberToLakhOrCrores(
                      Number(PROPOSER_DATA.sum_assured.value)
                    )
                  : formatNumberToLakhOrCrores(
                      Number(
                        `${SELECTED_QUOTE_DATA?.premiumDetails?.sum_assured}`
                      )
                    )}
              </span>
            </p>
            <p className="mb-2">
              Payment Mode:{" "}
              <span>
                {" "}
                {SELECTED_QUOTE_DATA?.productDetails?.frequency === "12"
                  ? "Monthly"
                  : SELECTED_QUOTE_DATA?.productDetails?.frequency === "2"
                  ? "Half-Yearly"
                  : SELECTED_QUOTE_DATA?.productDetails?.frequency === "4"
                  ? "Quarterly"
                  : SELECTED_QUOTE_DATA?.productDetails?.frequency === "1"
                  ? "Yearly"
                  : "Single Pay"}
              </span>
            </p>
            <p>
              Policy Tenure:{" "}
              <span>{`${SELECTED_QUOTE_DATA.premiumDetails.term} Years`}</span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={12}>
          {SELECTED_QUOTE_DATA.productDetails.product_code ===
          "LTH101" ? null : (
            <MTermRiderQuestion
              icon_class="bonus"
              loader={loaderTf003}
              addon_amount={`${RIDERS_BENEFITS.riders_benefits_response?.riders?.TF003?.premium}`}
              question_name="Personal Accident Cover"
              toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf003Status",
                        value: v,
                      },
                      {
                        key: "tf005Status",
                        value: false,
                      },
                    ],
                  })
                );
              }}
              sub_details={
                <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                  <SelectDropdown
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf003Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf003Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf003Data}
                    warn_status={false}
                  />
                </Box>
              }
            />
          )}
          <MTermRiderQuestion
            icon_class="bonus"
            loader={loaderTf001}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
            question_name="36 Critical Illness Cover"
            toggle_status={RIDERS_BENEFITS.local_value.tf001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: v,
                    },
                    {
                      key: "tf004Status",
                      value: false,
                    },
                  ],
                })
              );
            }}
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf001Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf001Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf001Data}
                  warn_status={false}
                />
              </Box>
            }
          />
          <MTermRiderQuestion
            icon_class="bonus"
            loader={loaderTf002}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response.riders?.TF002?.premium}`}
            question_name="Accidental Disability Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf002Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf002Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf002Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf002Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <MTermRiderQuestion
            icon_class="bonus"
            loader={loaderTf004}
            addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF001?.premium}`}
            question_name="Cancer Cover"
            sub_details={
              <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                <SelectDropdown
                  attrName={""}
                  title="Select Value"
                  value={RIDERS_BENEFITS.local_value.tf004Value}
                  value_update={(a: any, v: any) => {
                    dispatch(
                      TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                        updates: [
                          {
                            key: "tf004Value",
                            value: v,
                          },
                        ],
                      })
                    );
                  }}
                  data={tf004Data}
                  warn_status={false}
                />
              </Box>
            }
            toggle_status={RIDERS_BENEFITS.local_value.tf004Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "tf001Status",
                      value: false,
                    },
                    {
                      key: "tf004Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          {SELECTED_QUOTE_DATA.productDetails.product_code ===
          "LTH101" ? null : (
            <MTermRiderQuestion
              loader={loaderTf005}
              addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response.riders?.TF003?.premium}`}
              question_name="Accidental Death Cover"
              sub_details={
                <Box sx={{ maxWidth: "250px", paddingBottom: "16px" }}>
                  <SelectDropdown
                    attrName={""}
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf005Value}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf005Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf005Data}
                    warn_status={false}
                  />
                </Box>
              }
              toggle_status={RIDERS_BENEFITS.local_value.tf005Status}
              value_update={(a: any, v: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf003Status",
                        value: false,
                      },
                      {
                        key: "tf005Status",
                        value: v,
                      },
                    ],
                  })
                );
              }}
            />
          )}
          <h5 className="mt-10 mb-4">Benefits:</h5>
          <MTermRiderQuestion
            addon_amount="Free"
            question_name="Death Benefits as Instalment Option"
            toggle_status={RIDERS_BENEFITS.local_value.BNF001Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "BNF001Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <MTermRiderQuestion
            addon_amount="Free"
            question_name="Return of Premium"
            toggle_status={RIDERS_BENEFITS.local_value.BNF002Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "BNF002Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
          <MTermRiderQuestion
            addon_amount="Free"
            question_name="Waiver of Premium on 60 Critical Illness"
            toggle_status={RIDERS_BENEFITS.local_value.BNF003Status}
            value_update={(a: any, v: any) => {
              dispatch(
                TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                  updates: [
                    {
                      key: "BNF003Status",
                      value: v,
                    },
                  ],
                })
              );
            }}
          />
        </Grid>
      </Grid>
      <MTermFooter
        forward={() => {
          window.location.href =
            RIDERS_BENEFITS.riders_benefits_response.redirectionUrl;
        }}
        loader={loader}
        disableButton={isEmpty(
          RIDERS_BENEFITS?.riders_benefits_response?.finalPremium
        )}
      />
    </Box>
  );
};

export default MRidersBenefits;
