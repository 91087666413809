import React from "react";
import { TNivaBupaMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import { Box } from "@mui/material";
import MNivaBupaSenMedicalQuestion from "../../../../../Component/MMedicalDetailQuestion/MNivaBupaSenMedicalQuestion";
import MFooter from "../../../../../Component/MFooter/MFooter";

const MMedicalDetailNivaBupaSenior = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: TNivaBupaMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) => {
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      {Object.values(medicalQuestionData).map(
        (data, indexMainQuestion: number) => (
          <div key={indexMainQuestion}>
            <MNivaBupaSenMedicalQuestion
              value_update={updateMasterState}
              data={data.main_question}
            />
          </div>
        )
      )}
      {/* <HealthFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      /> */}
      <MFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MMedicalDetailNivaBupaSenior;
