import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CKYCDetailsPopup from "../../../../../Component/CKYCDetailsPopup/CKYCDetailsPopup";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Footer from "../../../../../Component/Footer/Footer";
import MemberRadioButton from "../../../../../Component/MemberRadioButton/MemberRadioButton";
import ToggleSwitch from "../../../../../Component/ToggleSwitch/ToggleSwitch";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { GET_DATE_VALIDATION } from "../../../../../SupportingFiles/HealthHelper";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TAdultOneDetails } from "../../../../../types/Health/ProposalDeatail/TProposalDetail";
import { THealthNomineeDetails } from "../../../../../types/Health/THealthSlice";

export default function AdultOneDetails({
  proposerFields,
  nomineeDetails,
  fieldsUpdateState,
  validateForm,
  handleClick,
  adultOneDetails,
  kycOpen,
  setKycOpen,
  loader,
  toggle_status,
  disbaledButton,
  setDisbaledButton,
}: {
  proposerFields: TAdultOneDetails;
  adultOneDetails: TAdultOneDetails;
  nomineeDetails: THealthNomineeDetails;
  fieldsUpdateState: Function;
  validateForm: Function;
  handleClick: Function;
  kycOpen: boolean;
  setKycOpen: Function;
  loader: boolean;
  toggle_status: any;
  disbaledButton: boolean;
  setDisbaledButton: Function;
}) {
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    DROPDOWN_DATA,
    PAGE_STATUS,
    NOMINEE_RELATIONSHIP_STATUS,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Health);
  const { GENDER, HEIGHT_FEET, HEIGHT_INCH, NIVA_BUPA_OCCUPATION } =
    useAppSelector((state) => state.CommonDropdownData);

  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  let ANNUAL_INCOME = [
    {
      label: "1 Lakh",
      value: "100000",
    },
    {
      label: "2 Lakh",
      value: "200000",
    },
    {
      label: "3 Lakh",
      value: "300000",
    },
    {
      label: "4 Lakh",
      value: "400000",
    },
    {
      label: "5 Lakh",
      value: "500000",
    },
    {
      label: "6 Lakh",
      value: "600000",
    },
    {
      label: "7 Lakh",
      value: "700000",
    },
    {
      label: "8 Lakh",
      value: "800000",
    },
    {
      label: "9 Lakh",
      value: "900000",
    },
    {
      label: "10 Lakh",
      value: "1000000",
    },
  ];

  const hdfcProductIds = [
    EProductId.HDFC_OPTIMA_RESTORE,
    EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN,
    EProductId.HDFC_OPTIMA_SECURE,
    EProductId.HDFC_OPTIMA_SECURE_Global,
    EProductId.HDFC_OPTIMA_SECURE_Plan,
  ];

  const annual_income_niva_bupa = [];
  for (
    let incomeValue = 100000;
    incomeValue <= 10000000;
    incomeValue += 100000
  ) {
    let label;
    if (incomeValue === 10000000) {
      label = "1 Crore";
    } else {
      label = `${incomeValue / 100000} Lakh`;
    }
    annual_income_niva_bupa.push({ label: label, value: `${incomeValue}` });
  }

  const EDUCATION = [
    { label: "Matric", value: "Matric" },
    { label: "Non-Matric", value: "Non-Matric" },
    { label: "Graduate", value: "Graduate" },
    { label: "Post-Graduate", value: "Post-Graduate" },
    { label: "Professional Course", value: "Professional Course" },
  ];

  useEffect(() => {
    if (toggle_status) {
      toast.error("Plan not allowed for NRI");
      setDisbaledButton(true);
    } else {
      setDisbaledButton(false);
    }
  }, [toggle_status]);
  return (
    <Box>
      <CKYCDetailsPopup open={kycOpen} setOpen={setKycOpen} loader={loader} />
      {proposerFields.IS_PROPOSER_INSURED ? (
        <h5 className="sectionTitle">
          1<sup>st</sup> Adult Details
        </h5>
      ) : (
        <h5 className="sectionTitle">Propser Details</h5>
      )}

      <>
        {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.CARE &&
          ADD_FORM.spouse_status &&
          ADD_FORM.children !== 0) ||
        (SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.STAR &&
          ADD_FORM.spouse_status &&
          ADD_FORM.children !== 0) ? null : (
          <Grid container spacing={3}>
            <Grid xs={12}>
              <FormControlLabel
                className="proposalCheckBoxWrapper"
                control={
                  <Checkbox
                    disabled={PAGE_STATUS}
                    defaultChecked={proposerFields.IS_PROPOSER_INSURED}
                    value={proposerFields.IS_PROPOSER_INSURED}
                    onClick={() => handleClick()}
                  />
                }
                label="Proposer is also an Insured Member"
              />
            </Grid>
          </Grid>
        )}
      </>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.NIVA_BUPA ? (
        <Grid container spacing={3}>
          <Grid xs={6}>
            <p style={{ fontWeight: 600, fontSize: "16px", color: "#FCAF17" }}>
              Are you an NRI?
            </p>
          </Grid>
          <Grid xs={6} textAlign={"right"}>
            <ToggleSwitch
              toggle_status={toggle_status}
              value_update={fieldsUpdateState}
              attrName={"toggle_switch"}
            />
          </Grid>
        </Grid>
      ) : null}
      {!proposerFields.IS_PROPOSER_INSURED ? (
        <>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={
                  proposerFields.name.value
                    ? proposerFields.name.value
                    : CKYC_DETAILS.kycCustomerName
                }
                attrName={"name"}
                value_update={fieldsUpdateState}
                warn_status={false}
                error_message={
                  proposerFields.name.value === "" ? "Enter Full Name" : ""
                }
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.dob.value}
                attrName={"dob"}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={proposerFields.dob.warning}
                date_validation_type={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).validation_type
                }
                min_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).min_date
                }
                max_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).max_date
                }
                default_date={
                  SELECTED_QUOTE_DATA.productDetails.id === 2843
                    ? subYears(new Date(), 56)
                    : subYears(new Date(), 18)
                }
                disabled={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.HDFC_OPTIMA_RESTORE ||
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.STAR
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={proposerFields.gender.value}
                attrName={"gender"}
                value_update={fieldsUpdateState}
                data={GENDER}
                warn_status={proposerFields.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Mobile"}
                value={ADD_FORM.mobile.value}
                attrName={"mobile"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.mobile.warning}
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.email.value}
                attrName={"email"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.email.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={12}
                class_name="inputField"
                title={"Aadhar"}
                value={proposerFields.aadhar.value}
                attrName={"aadhar"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.aadhar.warning}
                error_message={
                  isEmpty(proposerFields.aadhar.value)
                    ? "Enter Aadhar"
                    : "Enter Valid Aadhar"
                }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Emergency Phone"}
                value={proposerFields.emergencyPhone.value}
                attrName={"emergencyPhone"}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.emergencyPhone.warning}
                // error_message={
                //   proposerFields.emergencyPhone.value.toString().length < 11
                //     ? "Enter Valid Emergency Phone Number"
                //     : ""
                // }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="ALPHANUMERIC"
                class_name="inputField"
                title={"PAN"}
                value={proposerFields.pan_card.value}
                attrName={["pan_card"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.pan_card.warning}
                error_message={
                  isEmpty(proposerFields.pan_card.value)
                    ? "Enter PAN"
                    : "Enter Valid Pan"
                }
                max_length={10}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={
                  adultOneDetails.name.value
                    ? adultOneDetails.name.value
                    : CKYC_DETAILS.kycCustomerName
                }
                attrName={["name", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.name.warning}
                error_message={"Enter Full Name"}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={adultOneDetails.dob.value}
                attrName={["dob", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={adultOneDetails.dob.warning}
                date_validation_type={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).validation_type
                }
                min_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).min_date
                }
                max_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).max_date
                }
                default_date={subYears(new Date(), 18)}
                disabled={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.HDFC_OPTIMA_RESTORE ||
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.STAR
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={adultOneDetails.gender.value}
                attrName={["gender", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={GENDER}
                warn_status={adultOneDetails.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Mobile"}
                value={ADD_FORM.mobile.value}
                attrName={["mobile", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.mobile.warning}
                error_message={
                  isEmpty(adultOneDetails.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={adultOneDetails.email.value}
                attrName={["email", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.email.warning}
                error_message={
                  isEmpty(adultOneDetails.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={12}
                class_name="inputField"
                title={"Aadhar"}
                value={adultOneDetails.aadhar.value}
                attrName={["aadhar", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.aadhar.warning}
                error_message={
                  isEmpty(adultOneDetails.aadhar.value)
                    ? "Enter Aadhar"
                    : "Enter Valid Aadhar"
                }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Emergency Phone"}
                value={adultOneDetails.emergencyPhone.value}
                attrName={["emergencyPhone", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.emergencyPhone.warning}
                error_message={
                  adultOneDetails.emergencyPhone.value.toString().length < 11
                    ? "Enter Valid Mobile No."
                    : ""
                }
              />
            </Grid>
            <Grid xs={4}>
              <RKTextField
                validation_type="ALPHANUMERIC"
                class_name="inputField"
                title={"PAN"}
                value={adultOneDetails.pan_card.value}
                attrName={["pan_card", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.pan_card.warning}
                error_message={
                  isEmpty(adultOneDetails.pan_card.value)
                    ? "Enter PAN"
                    : "Enter Valid Pan "
                }
                alphabet_only={true}
                max_length={10}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6} md={4} lg={3} className="weightField">
              <span className="weightIcon"></span>
              <RKTextField
                validation_type="NUMBER"
                max_length={3}
                class_name="inputField"
                title={"Weight (Kg)"}
                value={adultOneDetails.weight.value}
                attrName={["weight", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.weight.warning}
                error_message="Enter Weight"
              />
            </Grid>
            <Grid xs={6} md={6} lg={5} className="heightField">
              <span className="heightIcon"></span>
              <SelectDropdown
                class_name="inputField mr-2"
                title="Feet"
                value={adultOneDetails.heightFeet.value}
                attrName={["heightFeet", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_FEET}
                warn_status={adultOneDetails.heightFeet.warning}
                error_message="Select Height Feet"
              />
              <SelectDropdown
                class_name="inputField"
                title="Inches"
                value={adultOneDetails.heightInches.value}
                attrName={["heightInches", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_INCH}
                warn_status={adultOneDetails.heightInches.warning}
                error_message="Select Height Inches"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.STAR ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.BAJAJ ? (
              <></>
            ) : (
              <Grid xs={6} md={5} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Marital Status"
                  value={adultOneDetails.maritalStatus.value}
                  attrName={["maritalStatus", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={DROPDOWN_DATA.MARTIAL}
                  warn_status={adultOneDetails.maritalStatus.warning}
                  error_message="Select Marital Status"
                />
              </Grid>
            )}

            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC ||
            SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ||
            hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id) ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.STAR ||
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.GO_DIGIT ? (
              <>
                <Grid xs={6} md={5} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Occupation"
                    value={adultOneDetails.occupation.value}
                    attrName={["occupation", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={DROPDOWN_DATA.OCCUPATION}
                    warn_status={adultOneDetails.occupation.warning}
                    error_message="Select Occupation Status"
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC ||
            SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ||
            hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id) ? (
              <>
                <Grid xs={6} md={5} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Annual Income"
                    value={adultOneDetails?.annual_income?.value}
                    attrName={["annual_income", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={ANNUAL_INCOME}
                    warn_status={adultOneDetails?.annual_income?.warning}
                    error_message="Select Annual Income"
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA ? (
              <>
                <Grid xs={6} md={5} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Occupation"
                    value={adultOneDetails.occupation.value}
                    attrName={["occupation", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={NIVA_BUPA_OCCUPATION}
                    warn_status={adultOneDetails.occupation.warning}
                    error_message="Select Occupation Status"
                  />
                </Grid>
                <Grid xs={6} md={5} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Qualification"
                    value={adultOneDetails.qualification.value}
                    attrName={["qualification", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={EDUCATION}
                    warn_status={adultOneDetails.qualification.warning}
                    error_message="Select Qualification Status"
                  />
                </Grid>
                <Grid xs={6} md={5} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Annual Income"
                    value={adultOneDetails.annual_income.value}
                    attrName={["annual_income", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={annual_income_niva_bupa}
                    warn_status={adultOneDetails.annual_income.warning}
                    error_message="Select Annual Income Status"
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}

            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA && (
              <Grid xs={6} md={5} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Education"
                  value={adultOneDetails?.qualification?.value}
                  attrName={["qualification", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={[
                    {
                      label: "Class X",
                      value: "SSC",
                    },
                    {
                      label: "Professional Degree",
                      value: "PROFESSIONALDEGREE",
                    },
                    {
                      label: "Post Graduate",
                      value: "POSTGRADUATE",
                    },
                    {
                      label: "Professional Degree",
                      value: "PROFESSIONALDEGREE",
                    },
                    {
                      label: "Less than Class X",
                      value: "LESSCLASS10",
                    },
                    {
                      label: "Graduate",
                      value: "GRAD",
                    },
                    {
                      label: "Class XII",
                      value: "HSC",
                    },
                  ]}
                  warn_status={adultOneDetails?.qualification?.warning}
                  error_message="Select Education Status"
                />
              </Grid>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA && (
              <Grid xs={6} md={4} lg={4} className="weightField">
                <RKTextField
                  validation_type="NUMBER"
                  class_name="inputField"
                  title="Annual Income"
                  value={adultOneDetails?.member_income?.value}
                  attrName={["member_income", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  max_length={2}
                  warn_status={adultOneDetails?.member_income?.warning}
                  error_message="Enter income"
                />
                <SelectDropdown
                  class_name="inputField"
                  title="Notation"
                  value={adultOneDetails?.grainful_income?.value}
                  attrName={["grainful_income", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={[
                    {
                      label: "Lakh",
                      value: "lakhs",
                    },
                    {
                      label: "Crore",
                      value: "crore",
                    },
                  ]}
                  warn_status={adultOneDetails?.grainful_income?.warning}
                  error_message="Select Grainful Income"
                />
              </Grid>
            )}
            {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.ICICI_HEALTH_ADVANTAGE ? (
              <Grid xs={6} md={5} lg={4}>
                <SelectDropdown
                  class_name="inputField"
                  title="Annual Income"
                  value={adultOneDetails?.annual_income?.value}
                  attrName={["annual_income", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  data={ANNUAL_INCOME}
                  warn_status={adultOneDetails?.annual_income?.warning}
                  error_message="Select Annual Income"
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <hr />
              <h6>Nominee Details</h6>
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent="center">
            {ADD_FORM.spouse_status ? (
              <Grid xs={4}>
                <MemberRadioButton
                  checked={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                  class_name="adult"
                  input_name="ksdgkj"
                  input_id={"ADULT_2"}
                  label_name="Adult 2"
                  input_type="radio"
                  attrName={"nomineDetail"}
                  value={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                  value_update={() => {
                    dispatch(
                      HealthSlice.actions.CHANGE_NOMINEE_STATUS("ADULT_2")
                    );
                  }}
                />
              </Grid>
            ) : null}
            {!ADD_FORM.spouse_status ? null : (
              <Grid xs={4}>
                <MemberRadioButton
                  checked={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                  class_name="adult"
                  input_name="ksdgkj"
                  input_id="OTHER"
                  label_name="Other"
                  value={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                  input_type="radio"
                  attrName={"nomineDetail"}
                  value_update={() => {
                    dispatch(
                      HealthSlice.actions.CHANGE_NOMINEE_STATUS("OTHER")
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>
          {/* Nominee Member Details */}
          {NOMINEE_RELATIONSHIP_STATUS === "OTHER" ? (
            <Box>
              <Grid container spacing={3}>
                <Grid xs={6}>
                  <RKTextField
                    validation_type="NAME"
                    class_name="inputField"
                    title={"Full Name"}
                    value={nomineeDetails.full_name.value}
                    attrName={["nomineeDetails", "full_name"]}
                    value_update={fieldsUpdateState}
                    warn_status={nomineeDetails.full_name.warning}
                    error_message="Enter Nominee Full Name"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid xs={4}>
                  <DatePicker
                    class_name="inputField"
                    title={"DOB"}
                    value={nomineeDetails.dob.value}
                    attrName={["nomineeDetails", "dob"]}
                    date_validation_type="YEARS"
                    min_date={100}
                    max_date={18}
                    default_date={subYears(new Date(), 18)}
                    value_update={fieldsUpdateState}
                    error_message="Enter Nominee Date Of Birth"
                    warn_status={nomineeDetails.dob.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relationship"
                    value={nomineeDetails?.relationship?.value}
                    attrName={["nomineeDetails", "relationship"]}
                    value_update={fieldsUpdateState}
                    data={DROPDOWN_DATA.NOMINEE_RELATIONSHIP}
                    warn_status={nomineeDetails?.relationship?.warning}
                    error_message="Enter Nominee RelationShip"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      )}

      {/* Footer */}

      <Footer
        attrName={"pageStatus"}
        textName={PAGE_STATUS ? "Update" : "Continue"}
        value={1}
        value_update={() => {}}
        forward={() => {
          validateForm();
        }}
        disableButton={disbaledButton}
      />
    </Box>
  );
}
