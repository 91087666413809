import { TCarAddonStatus } from "../types/Car/TCarAddonStatus";
import {
  TCarPremiumDetails,
  TCarSingleQuoteDetail,
} from "../types/Car/TCarSingleQuoteDetail";
import { calculate_passenger_cover, isEmpty } from "./HelpingFunction";

export type TTataPlanName =
  | ""
  | "Sapphire ++"
  | "Sapphire +"
  | "Pearl +"
  | "Sapphire"
  | "Coral"
  | "Pearl";

export const TATA_ADDON_PREMIUM_KEY = {
  "Sapphire ++": "tata_sapphirepp_plan_amount",
  "Sapphire +": "tata_sapphirep_plan_amount",
  "Pearl +": "tata_perlplus_plan_amount",
  Sapphire: "tata_sapphire_plan_amount",
  Coral: "tata_coral_plan_amount",
  Pearl: "tata_perl_plan_amount",
};

function parse_value(value: any): number {
  if (isNaN(parseFloat(value))) {
    return 0;
  } else {
    return parseFloat(value);
  }
}

export const DETERMINE_PLAN_TYPE_TATA = (
  ADDON_STATUS: TCarAddonStatus
): TTataPlanName => {
  let plan_name: TTataPlanName = "";

  if (
    ADDON_STATUS.depreciation_cover.value &&
    !ADDON_STATUS.invoice_cover.value &&
    !ADDON_STATUS.engine_cover.value &&
    !ADDON_STATUS.tyre_cover.value &&
    !ADDON_STATUS.key_replacement.value &&
    !ADDON_STATUS.consumable_cover.value
  ) {
    plan_name = "Pearl";
  }

  if (
    ADDON_STATUS.key_replacement.value &&
    !ADDON_STATUS.invoice_cover.value &&
    !ADDON_STATUS.engine_cover.value &&
    !ADDON_STATUS.tyre_cover.value
  ) {
    plan_name = "Coral";
  }

  if (
    ADDON_STATUS.tyre_cover.value &&
    !ADDON_STATUS.invoice_cover.value &&
    !ADDON_STATUS.engine_cover.value
  ) {
    plan_name = "Sapphire";
  }

  if (
    ADDON_STATUS.consumable_cover.value ||
    (ADDON_STATUS.engine_cover.value && !ADDON_STATUS.invoice_cover.value)
  ) {
    plan_name = "Pearl +";
  }

  if (
    ADDON_STATUS.tyre_cover.value &&
    ADDON_STATUS.engine_cover.value &&
    !ADDON_STATUS.invoice_cover.value
  ) {
    plan_name = "Sapphire +";
  }

  if (ADDON_STATUS.invoice_cover.value) {
    plan_name = "Sapphire ++";
  }

  return plan_name;
};

export const GET_TATA_PLAN_ADDON_LIST = (
  ADDON_STATUS: TCarAddonStatus
): Array<string> => {
  switch (DETERMINE_PLAN_TYPE_TATA(ADDON_STATUS)) {
    case "Coral":
      return [
        ADDON_STATUS.consumable_cover.label,
        ADDON_STATUS.depreciation_cover.label,
        ADDON_STATUS.personalBelonging.label,
        "Emergency Transport",
        ADDON_STATUS.key_replacement.label,
        ADDON_STATUS.rsa.label,
        "Repair of Glass",
      ];

    case "Pearl":
      return [
        ADDON_STATUS.depreciation_cover.label,
        ADDON_STATUS.personalBelonging.label,
        "Emergency Transport",
        ADDON_STATUS.key_replacement.label,
        ADDON_STATUS.rsa.label,
        "Repair of Glass",
      ];

    case "Pearl +":
      return [
        ADDON_STATUS.consumable_cover.label,
        ADDON_STATUS.depreciation_cover.label,
        ADDON_STATUS.personalBelonging.label,
        "Emergency Transport",
        ADDON_STATUS.key_replacement.label,
        ADDON_STATUS.rsa.label,
        "Repair of Glass",
        ADDON_STATUS.engine_cover.label,
      ];

    case "Sapphire":
      return [
        ADDON_STATUS.consumable_cover.label,
        ADDON_STATUS.depreciation_cover.label,
        ADDON_STATUS.personalBelonging.label,
        "Emergency Transport",
        ADDON_STATUS.key_replacement.label,
        ADDON_STATUS.rsa.label,
        "Repair of Glass",
        ADDON_STATUS.tyre_cover.label,
      ];

    case "Sapphire +":
      return [
        ADDON_STATUS.engine_cover.label,
        ADDON_STATUS.consumable_cover.label,
        ADDON_STATUS.depreciation_cover.label,
        ADDON_STATUS.personalBelonging.label,
        "Emergency Transport",
        ADDON_STATUS.key_replacement.label,
        ADDON_STATUS.rsa.label,
        "Repair of Glass",
        ADDON_STATUS.tyre_cover.label,
      ];

    case "Sapphire ++":
      return [
        ADDON_STATUS.invoice_cover.label,
        ADDON_STATUS.engine_cover.label,
        ADDON_STATUS.consumable_cover.label,
        ADDON_STATUS.depreciation_cover.label,
        ADDON_STATUS.personalBelonging.label,
        "Emergency Transport",
        ADDON_STATUS.key_replacement.label,
        ADDON_STATUS.rsa.label,
        "Repair of Glass",
        ADDON_STATUS.tyre_cover.label,
      ];
    default:
      break;
  }
  return [];
};

export function calculate_premium_for_car_tata(
  quote_details: TCarSingleQuoteDetail | undefined,
  addon_status: TCarAddonStatus,
  passenger_cover_user_value: string,
  PLAN_TYPE: string,
  reg_date: string
) {
  let premium: number = 0;

  if (quote_details === undefined) return 0;

  const basicOD = parse_value(
    isEmpty(DETERMINE_PLAN_TYPE_TATA(addon_status))
      ? `${quote_details.premiumDetails.withOutBundlebBasicOd}`
      : `${quote_details.premiumDetails.withBundleBasicOD}`
  );
  const basicTP = parse_value(quote_details.premiumDetails.basicTP);
  const cngKitOD = parse_value(quote_details.premiumDetails.cngKitOD);
  const cngKitTP = parse_value(quote_details.premiumDetails.cngKitTP);
  const accessoryCoverCharge = isEmpty(DETERMINE_PLAN_TYPE_TATA(addon_status))
    ? parse_value(`${quote_details.premiumDetails.withOutBundleAccessory}`)
    : parse_value(`${quote_details.premiumDetails.withBundleAccessory}`);
  const ncbDiscount = parse_value(
    isEmpty(DETERMINE_PLAN_TYPE_TATA(addon_status))
      ? `${quote_details.premiumDetails.withOutNcbDiscount}`
      : `${quote_details.premiumDetails.withBundleNcbDiscount}`
  );
  const paOwnerDriver = parse_value(quote_details.premiumDetails.paOwnerDriver);
  const driverCover = parse_value(quote_details.premiumDetails.driverCover);
  const noClaimBonusSameSlab = parse_value(
    quote_details.premiumDetails.noClaimBonusSameSlab
  );

  premium =
    basicOD +
    basicTP -
    ncbDiscount +
    cngKitOD +
    cngKitTP +
    accessoryCoverCharge;

  let selectedPackage = DETERMINE_PLAN_TYPE_TATA(addon_status);

  if (selectedPackage !== "") {
    premium =
      premium +
      parseFloat(
        `${
          quote_details?.premiumDetails[
            TATA_ADDON_PREMIUM_KEY[
              selectedPackage as keyof typeof TATA_ADDON_PREMIUM_KEY
            ] as keyof TCarPremiumDetails
          ]
        }`
      );
  }

  // Add addon premiums
  if (addon_status.pa_owner_cover.value) {
    premium = premium + paOwnerDriver;
  }
  if (addon_status.driver_cover.value) {
    premium = premium + driverCover;
  }
  if (addon_status.ncb_cover.value) {
    premium = premium + noClaimBonusSameSlab;
  }
  if (addon_status.passenger_cover.value) {
    premium =
      premium +
      calculate_passenger_cover(
        Number(`${passenger_cover_user_value}`),
        quote_details,
        PLAN_TYPE
      );
  }

  // Calculate tax amount and final premium
  let tax_amount = (premium / 100) * 18;
  let final_premium = premium + tax_amount;

  return final_premium;
}
