import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useAppSelector } from "../../Store/hooks";
import {
  calculateAgeInDays,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./MedicalQuestion.scss";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import { subDays } from "date-fns";
import CustomCheckbox from "../FieldTypes/CustomCheckbox/CustomCheckbox";
import { EProductId } from "../../Services/Enum/EHome";

interface Props {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
  any_question_or_sub_question?: any;
  show_toggle_status?: boolean;
}
const StarMedicalQuestion: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
  any_question_or_sub_question,
  show_toggle_status = true,
}) => {
  const {
    INSURED_MEMBER_DETAILS,
    ADD_FORM,
    KYC_DETAILS,
    past_health_insurance_value,
    MEDICAL_QUESTION_DATA_STAR,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Health);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };
  const LifeOfManagement_data: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "MEDICAL";
    } else if (i === 2) {
      status = "SURGICAL";
    }
    LifeOfManagement_data.push({ value: status, label: `${status}` });
  }

  const CurrentStatus_data: any = [];

  for (let i = 1; i <= 3; i++) {
    let status;
    if (i === 1) {
      status = "CURED";
    } else if (i === 2) {
      status = "NOT TREATED";
    } else if (i === 3) {
      status = "ONGOING";
    }

    CurrentStatus_data.push({ value: status, label: `${status}` });
  }
  const Details_of_treatment: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "Yes";
    } else if (i === 2) {
      status = "NO";
    }

    Details_of_treatment.push({ value: status, label: `${status}` });
  }

  const checkQuation = Object.keys(MEDICAL_QUESTION_DATA_STAR).map(
    (data) => data
  );
  console.log("dataSubQuestion", checkQuation);

  console.log(sub_details);

  return (
    <Box className={main_class}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          {show_toggle_status ? (
            <ToggleSwitch
              toggle_status={toggle_button_status}
              value_update={update_status}
              attrName={attrName}
            />
          ) : (
            <RKTextField
              class_name=" inputField mb-5"
              title={"Past Health Insurance"}
              attrName={"past_health_insurance"}
              value={past_health_insurance_value}
              value_update={updateMasterState}
              warn_status={false}
              error_message={"Enter description"}
            />
          )}
        </Grid>
      </Grid>
      {any_question_or_sub_question}
      <Grid container spacing={2}>
        {data?.main_question?.member_data &&
          (data?.main_question?.id === "MQ03" ||
            data?.main_question?.id === "MQ04" ||
            data?.main_question?.id === "MQ05") &&
          data?.main_question?.status &&
          Object.values(data?.main_question?.member_data)?.map(
            (memberDataValue: any, memberIndex: number) => (
              <React.Fragment key={memberIndex}>
                {memberDataValue.name !== "" && (
                  <Grid xs={4}>
                    <MemberRadioButtonNew
                      class_name={
                        memberIndex === 0 || memberIndex === 1
                          ? "adult"
                          : "child"
                      }
                      checked={memberDataValue?.selection_status}
                      label_name={memberDataValue.name}
                      attrName={[
                        "mainMemberData",
                        data?.main_question?.id,
                        memberDataValue?.keyName,
                      ]}
                      value_update={updateMasterState}
                    />
                    {memberDataValue?.selection_status &&
                      memberDataValue.keyName && (
                        <>
                          {Object.values(memberDataValue.field_data).map(
                            (field_data_data: any) => {
                              let datePickerRendered = false; // Flag to track DatePicker rendering
                              return (
                                <React.Fragment key={field_data_data.id}>
                                  {field_data_data.field_type === "DROPDOWN" ? (
                                    <SelectDropdown
                                      class_name=" inputField mb-5 mt-3"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      data={
                                        field_data_data?.title ===
                                          "CurrentStatus"
                                          ? CurrentStatus_data
                                          : field_data_data?.title ===
                                            "IsMedicalQuestionOpted"
                                            ? Details_of_treatment
                                            : LifeOfManagement_data
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type === "CHECKBOX" ? (
                                    <CustomCheckbox
                                      label={field_data_data.title}
                                      value_update={updateMasterState}
                                      value={field_data_data.value?.value}
                                      defaultChecked={false}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type ===
                                    "DISEASES_TEXTFIELD" &&
                                    memberDataValue.field_data["MQ1-SQ1-F3"]
                                      ?.value?.value === true ? (
                                    <RKTextField
                                      class_name=" inputField mb-5 mt-3"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type ===
                                    "TEXTFIELD" ? (
                                    <RKTextField
                                      class_name=" inputField mb-5 mt-3"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type ===
                                    "DATEPICKER" &&
                                    !datePickerRendered && (
                                      <DatePicker
                                        class_name="inputField mb-5 mt-3"
                                        title={field_data_data?.title}
                                        value={field_data_data?.value?.value}
                                        attrName={[
                                          "mainMemberDataField",
                                          data?.main_question?.id,
                                          memberDataValue.keyName,
                                          field_data_data.id,
                                        ]}
                                        value_update={updateMasterState}
                                        min_date={
                                          memberDataValue.keyName ===
                                            "adult_one"
                                            ? INSURED_MEMBER_DETAILS
                                              .ADULT_ONE_DETAILS.dob.value
                                              ? calculateAgeInDays(
                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                              )
                                              : calculateAgeInDays(
                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                              )
                                            : memberDataValue.keyName ===
                                              "adult_two"
                                              ? calculateAgeInDays(
                                                `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                              )
                                              : memberDataValue.keyName ===
                                                "child_one"
                                                ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                )
                                                : memberDataValue.keyName ===
                                                  "child_two"
                                                  ? calculateAgeInDays(
                                                    `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                  )
                                                  : memberDataValue.keyName ===
                                                    "child_three"
                                                    ? calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                    )
                                                    : memberDataValue.keyName ===
                                                      "child_four"
                                                      ? calculateAgeInDays(
                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                      )
                                                      : 25
                                        }
                                        max_date={0}
                                        default_date={subDays(
                                          new Date(),
                                          memberDataValue.keyName ===
                                            "adult_one"
                                            ? INSURED_MEMBER_DETAILS
                                              .ADULT_ONE_DETAILS.dob.value
                                              ? calculateAgeInDays(
                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                              )
                                              : calculateAgeInDays(
                                                `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                              )
                                            : memberDataValue.keyName ===
                                              "adult_two"
                                              ? calculateAgeInDays(
                                                `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                              )
                                              : memberDataValue.keyName ===
                                                "child_one"
                                                ? calculateAgeInDays(
                                                  `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                )
                                                : memberDataValue.keyName ===
                                                  "child_two"
                                                  ? calculateAgeInDays(
                                                    `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                  )
                                                  : memberDataValue.keyName ===
                                                    "child_three"
                                                    ? calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                    )
                                                    : memberDataValue.keyName ===
                                                      "child_four"
                                                      ? calculateAgeInDays(
                                                        `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                      )
                                                      : 25
                                        )}
                                        warn_status={
                                          field_data_data?.value?.warning
                                        }
                                        date_validation_type="DAYS"
                                        error_message={
                                          "Select existing since"
                                        }
                                      />
                                    )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      )}
                  </Grid>
                )}
              </React.Fragment>
            )
          )}
      </Grid>
    </Box>
  );
};

export default StarMedicalQuestion;
