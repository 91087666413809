import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TInsuredMemberDetails } from "../../../../types/TermFullJourney/TInsuredMemberDetails";

function setInsuredDetails(
  state: TTermFullJourneySlice,
  action: PayloadAction<TInsuredMemberDetails>
) {
  const data: TTermFullJourneySlice = {
    ...state,
    insuredDetails: action.payload,
  };
  return data;
}

export const TERM_INSURED_REDUCERS = {
  setInsuredDetails,
};
