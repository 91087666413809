import { Box, Collapse, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import React from "react";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import NivaBupaMedicalQuestion from "../../../../../Component/MedicalQuestion/NivabupaMedicalQuestion";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import { useAppSelector } from "../../../../../Store/hooks";
import { calculateAgeInDays } from "../../../../../SupportingFiles/HelpingFunction";
import { TNivaBupaMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import HealthFooter from "../../../../Desktop/Health/HealthFooter/HealthFooter";
import MNivaBupaMedicalQuestion from "../../../../../Component/MMedicalDetailQuestion/MNivaBupaMedicalQuestion";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { Link } from "react-router-dom";
// import HealthFooter from "../../HealthFooter/HealthFooter";

const MMedicalDetailNivaBupa = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
  dailyDrinker,
  setDailyDrinker,
  disable,
}: {
  medicalQuestionData: TNivaBupaMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
  setDailyDrinker: Function;
  dailyDrinker: boolean;
  disable: boolean;
}) => {
  const { INSURED_MEMBER_DETAILS, DROPDOWN_DATA, isDeduction_value } =
    useAppSelector((state) => state.Health);

  return (
    <Box>
      <Modal open={dailyDrinker} className="modalWrapperMobile mwMobile-kyc">
        <Box className="modalContent">
          <Grid container spacing={2}>
            <Grid xs={12}>
              <h4 className="popup-heading mb-2">Sorry</h4>
              <Link
                className="close-button"
                onClick={() => {
                  setDailyDrinker(false);
                }}
                to={""}
              />
              <hr />
            </Grid>
          </Grid>
          <Grid container spacing={2} marginBottom={"8px"}>
            <Grid xs={12} className="ctaBtn">
              <p className="mb-0">
                If you are a daily drinker, you can't proceed with this insurer.
                Select "No" or check with other insurer.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => {
            console.log("mneva", data);
            return (
              <div key={indexMainQuestion}>
                <MNivaBupaMedicalQuestion
                  key={indexMainQuestion}
                  main_class="medicalQuestion_m borderBottom"
                  icon_class={data?.main_question?.icon_class}
                  question_name={data?.main_question?.title}
                  sub_details={data?.main_question?.description}
                  input_id_first={data?.main_question?.id}
                  input_id_second={data?.main_question?.id}
                  input_name="diagnosed"
                  toggle_button_status={data?.main_question?.status}
                  attrName={["question", data?.main_question?.id]}
                  value_update={updateMasterState}
                  data={data}
                  updateMasterState={updateMasterState}
                  select_disease={
                    <Collapse
                      in={data?.main_question?.status}
                      className="medicalQuestion_m-SubSection"
                    >
                      <Grid container columnSpacing={3} className="pr-2 mb-3">
                        {data?.main_question?.sub_question && (
                          <Grid xs={12}>
                            {/* <h5>High or low blood pressure</h5> */}
                            {data?.main_question?.sub_question &&
                              Object.values(
                                data?.main_question?.sub_question
                              )?.map(
                                (
                                  dataSubQuestion: any,
                                  indexSubQuestion: number
                                ) => (
                                  <>
                                    <MNivaBupaMedicalQuestion
                                      key={indexSubQuestion}
                                      main_class="medicalQuestion borderBottom"
                                      icon_class={"tobaco"}
                                      question_name={dataSubQuestion?.title}
                                      sub_details={
                                        <p>{dataSubQuestion?.description}</p>
                                      }
                                      input_id_first={dataSubQuestion?.id}
                                      input_id_second={dataSubQuestion?.id}
                                      input_name="blood pressure"
                                      toggle_button_status={
                                        dataSubQuestion?.status
                                      }
                                      attrName={[
                                        "subQuestion",
                                        data?.main_question?.id,
                                        dataSubQuestion?.id,
                                      ]}
                                      value_update={updateMasterState}
                                      data={data}
                                      dataSubQuestion={dataSubQuestion}
                                      updateMasterState={updateMasterState}
                                      select_disease={
                                        <Collapse
                                          in={dataSubQuestion?.status}
                                          className="medicalQuestion-SubSection"
                                        >
                                          <Grid container columnSpacing={2}>
                                            {dataSubQuestion?.member_data &&
                                              dataSubQuestion?.status &&
                                              Object.values(
                                                dataSubQuestion?.member_data
                                              )?.map(
                                                (
                                                  memberDataValue: any,
                                                  memberIndex: number
                                                ) => (
                                                  <React.Fragment
                                                    key={memberIndex}
                                                  >
                                                    {memberDataValue.name !==
                                                      "" && (
                                                        <Grid xs={12}>
                                                          <MemberRadioButtonNew
                                                            class_name={`mb-3
                                                          ${memberIndex === 0 ||
                                                                memberIndex === 1
                                                                ? "adult"
                                                                : "child"
                                                              }
                                                        `}
                                                            checked={
                                                              memberDataValue?.selection_status
                                                            }
                                                            label_name={
                                                              memberDataValue.name
                                                            }
                                                            attrName={[
                                                              "mainMember",
                                                              data?.main_question
                                                                ?.id,
                                                              dataSubQuestion.id,
                                                              memberDataValue?.keyName,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                          />
                                                          {memberDataValue?.selection_status &&
                                                            memberDataValue.keyName && (
                                                              <>
                                                                {Object.values(
                                                                  memberDataValue.field_data
                                                                ).map(
                                                                  (
                                                                    field_data_data: any
                                                                  ) => {
                                                                    let datePickerRendered =
                                                                      false; // Flag to track DatePicker rendering
                                                                    return (
                                                                      <React.Fragment
                                                                        key={
                                                                          field_data_data.id
                                                                        }
                                                                      >
                                                                        {field_data_data.field_type ===
                                                                          "DROPDOWN" ? (
                                                                          <SelectDropdown
                                                                            class_name=" inputField mb-5"
                                                                            title={
                                                                              field_data_data.title
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              memberDataValue.keyName,
                                                                              field_data_data.id,
                                                                              dataSubQuestion.id,
                                                                            ]}
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                            data={[
                                                                              {
                                                                                label:
                                                                                  "Yes",
                                                                                value:
                                                                                  "Yes",
                                                                              },
                                                                              {
                                                                                label:
                                                                                  "No",
                                                                                value:
                                                                                  "No",
                                                                              },
                                                                            ]}
                                                                            error_message={
                                                                              "Enter description"
                                                                            }
                                                                          />
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {field_data_data.field_type ===
                                                                          "TEXTFIELD" ? (
                                                                          <RKTextField
                                                                            class_name=" inputField mb-5"
                                                                            title={
                                                                              field_data_data.title
                                                                            }
                                                                            attrName={[
                                                                              "mainMemberDataField",
                                                                              data
                                                                                ?.main_question
                                                                                ?.id,
                                                                              memberDataValue.keyName,
                                                                              field_data_data.id,
                                                                              dataSubQuestion.id,
                                                                            ]}
                                                                            value={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.value
                                                                            }
                                                                            value_update={
                                                                              updateMasterState
                                                                            }
                                                                            warn_status={
                                                                              field_data_data
                                                                                ?.value
                                                                                ?.warning
                                                                            }
                                                                            error_message={
                                                                              "Enter description"
                                                                            }
                                                                            validation_type="NUMBER"
                                                                          />
                                                                        ) : (
                                                                          ""
                                                                        )}
                                                                        {field_data_data.field_type ===
                                                                          "DATEPICKER" &&
                                                                          !datePickerRendered && (
                                                                            <DatePicker
                                                                              class_name="inputField mb-5"
                                                                              title={
                                                                                field_data_data?.title
                                                                              }
                                                                              value={
                                                                                field_data_data
                                                                                  ?.value
                                                                                  ?.value
                                                                              }
                                                                              attrName={[
                                                                                "mainMemberDataField",
                                                                                data
                                                                                  ?.main_question
                                                                                  ?.id,
                                                                                memberDataValue.keyName,
                                                                                field_data_data.id,
                                                                                dataSubQuestion.id,
                                                                              ]}
                                                                              value_update={
                                                                                updateMasterState
                                                                              }
                                                                              min_date={
                                                                                memberDataValue.keyName ===
                                                                                  "adult_one"
                                                                                  ? INSURED_MEMBER_DETAILS
                                                                                    .ADULT_ONE_DETAILS
                                                                                    .dob
                                                                                    .value
                                                                                    ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                    )
                                                                                    : calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "adult_two"
                                                                                    ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                    )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_one"
                                                                                      ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_two"
                                                                                        ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                        : memberDataValue.keyName ===
                                                                                          "child_three"
                                                                                          ? calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                          )
                                                                                          : memberDataValue.keyName ===
                                                                                            "child_four"
                                                                                            ? calculateAgeInDays(
                                                                                              `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                            )
                                                                                            : 25
                                                                              }
                                                                              max_date={
                                                                                0
                                                                              }
                                                                              default_date={subDays(
                                                                                new Date(),
                                                                                memberDataValue.keyName ===
                                                                                  "adult_one"
                                                                                  ? INSURED_MEMBER_DETAILS
                                                                                    .ADULT_ONE_DETAILS
                                                                                    .dob
                                                                                    .value
                                                                                    ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                    )
                                                                                    : calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                                                    )
                                                                                  : memberDataValue.keyName ===
                                                                                    "adult_two"
                                                                                    ? calculateAgeInDays(
                                                                                      `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                                                    )
                                                                                    : memberDataValue.keyName ===
                                                                                      "child_one"
                                                                                      ? calculateAgeInDays(
                                                                                        `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                                                      )
                                                                                      : memberDataValue.keyName ===
                                                                                        "child_two"
                                                                                        ? calculateAgeInDays(
                                                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                                                        )
                                                                                        : memberDataValue.keyName ===
                                                                                          "child_three"
                                                                                          ? calculateAgeInDays(
                                                                                            `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                                                          )
                                                                                          : memberDataValue.keyName ===
                                                                                            "child_four"
                                                                                            ? calculateAgeInDays(
                                                                                              `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                                                            )
                                                                                            : 25
                                                                              )}
                                                                              warn_status={
                                                                                field_data_data
                                                                                  ?.value
                                                                                  ?.warning
                                                                              }
                                                                              date_validation_type="DAYS"
                                                                              error_message={
                                                                                "Select existing since"
                                                                              }
                                                                            />
                                                                          )}
                                                                      </React.Fragment>
                                                                    );
                                                                  }
                                                                )}
                                                              </>
                                                            )}
                                                        </Grid>
                                                      )}
                                                  </React.Fragment>
                                                )
                                              )}
                                          </Grid>
                                        </Collapse>
                                      }
                                    />
                                  </>
                                )
                              )}
                          </Grid>
                        )}
                      </Grid>
                    </Collapse>
                  }
                />
              </div>
            );
          }
        )}
      </>
      {/* <HealthFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      /> */}
      <MFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
        disableButton={
          medicalQuestionData?.MQ08?.main_question?.status || disable
        }
      />
    </Box>
  );
};

export default MMedicalDetailNivaBupa;
