import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { TCareAddon } from "../../../../types/Health/TCareAddon";
import { useAppSelector } from "../../../../Store/hooks";
import Loader from "../../../../Component/LoaderblackBg/Loader";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import Footer from "../../../../Component/Footer/Footer";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import { TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE } from "../../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";

function MAddonDetails({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();
  const { QUOTE_LOADER, DROPDOWN_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  console.log(addonStatus, "addonStatus");

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Outpatient"
            toggle_status={addonStatus.outpatient}
            value_update={updateMasterState}
            attrName={"outpatient"}
            desc=""
            sub_details={
              <Box maxWidth={"200px"}>
                <SelectDropdown
                  title="Sum Insured"
                  value={addonStatus.healthAddSumInsured}
                  value_update={updateMasterState}
                  attrName={"healthAddSumInsured"}
                  data={[
                    { value: "20000", label: "20000" },
                    { value: "30000", label: "30000" },
                    { value: "50000", label: "50000" },
                  ]}
                />
              </Box>
            }
            disable={true}
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Critical Illness"
            toggle_status={addonStatus.critical_illness}
            value_update={updateMasterState}
            attrName={"critical_illness"}
            desc="Critical illness give a lump sum amount equal to Sum Insured in case of first diagnosis of the covered critical illnesses. It protect the insured against financial loss in the event of a terminal illness."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Pro Health-Cumulative Bonus Booster"
            toggle_status={addonStatus.cumulative}
            value_update={updateMasterState}
            attrName={"cumulative"}
            desc="Additional Sum Insured of 25% will be added as cumulative bonus at the time of renewal in case there is no claim in the expiring policy"
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Non-Medical Items"
            toggle_status={addonStatus.non_medical}
            value_update={updateMasterState}
            attrName={"non_medical"}
            desc="All the Non-Medical Items (as per List I of Annexure III) shall be covered upto sum insured which in normal course are not covered."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Personal Accident Cover"
            toggle_status={addonStatus.personal_accident}
            value_update={updateMasterState}
            attrName={"personal_accident"}
            desc="Lump sum benefit equal to 2 times of the base sum insured subject to a maximum of Rs50 lacs, payable in case of Accidental Death or Permanent Total Disablement."
          />
        </Grid>

        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
}

export default MAddonDetails;
