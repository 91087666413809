import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "./../../../../../SCSS/CommonSCSS/ProposalForms.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import { TTermProposerData } from "../../../../../types/TTermSlice";
import CustomCheckbox from "../../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";

const ProposerDetails = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  loader,
  isProposerSameInsured,
  setIsProposerSameInsured,
  isChecked,
  setChecked,
}: {
  proposerFields: TTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  loader: boolean;
  isProposerSameInsured: boolean;
  setIsProposerSameInsured: Function;
  isChecked: boolean;
  setChecked: Function;
}) => {
  const navigate = useNavigate();
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Term);
  const handleCheckboxChange = (attrName: string, isChecked: boolean) => {
    setChecked(isChecked);
    console.log(isChecked, "isChecked");
  };

  const handleClick = () => {
    setIsProposerSameInsured((prevState: boolean) => !prevState);
  };

  console.log(isChecked, "isChecked");

  return (
    <>
      {isProposerSameInsured ? (
        <Box>
          <h5 className="sectionTitle">Proposer Details</h5>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <h6>
                <CustomCheckbox
                  label={"Proposer is also an insured member."}
                  attrName="validPuc"
                  value_update={handleCheckboxChange}
                  value={isChecked}
                  defaultChecked={false}
                  disabled={false}
                />
              </h6>
            </Grid>
            {/* <Grid xs={12}>
              <h6>Proposer / Insured Details</h6>
            </Grid> */}
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.full_name.value}
                attrName={["full_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.full_name?.warning}
                error_message={
                  isEmpty(proposerFields.full_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.dob.value}
                attrName={["dob"]}
                disabled={true}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={proposerFields?.dob?.warning}
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                disabled
                class_name="inputField"
                title="Gender"
                value={proposerFields.gender.value}
                attrName={["gender"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.GENDER}
                warn_status={proposerFields?.gender?.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                disabled
                class_name="inputField"
                title={"Mobile"}
                value={proposerFields.mobile.value}
                attrName={["mobile"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.mobile?.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile number"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.email.value}
                attrName={["email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.email?.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={proposerFields.pincode.value}
                attrName={["pincode"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.pincode?.warning}
                validation_type="NUMBER"
                max_length={6}
                disabled={true}
                error_message={
                  isEmpty(proposerFields.pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Emergency Phone"}
                value={proposerFields.emergency_number.value}
                attrName={["emergency_number"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.emergency_number?.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.emergency_number.value)
                    ? "Enter Emergency Phone Number "
                    : "Enter Valid Emergency Phone Number"
                }
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={proposerFields?.occupation?.value}
                attrName={["occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Qualification"
                value={proposerFields?.qualification?.value}
                attrName={["qualification"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.QUALIFICATION_DATA}
                warn_status={proposerFields?.qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>

          <TermFooter
            loader={loader}
            forward={isChecked ? validateForm : handleClick}
          />
        </Box>
      ) : (
        <Box>
          <h5 className="sectionTitle">Insured Details</h5>
          <Grid container spacing={3}>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                validation_type="NAME"
                value={proposerFields.insured_name.value}
                attrName={["insured_name"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_name?.warning}
                error_message={
                  isEmpty(proposerFields.insured_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.insured_dob.value}
                attrName={["insured_dob"]}
                value_update={fieldsUpdateState}
                error_message="Enter DOB"
                warn_status={proposerFields?.insured_dob?.warning}
                min_date={100}
                max_date={18}
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={proposerFields.insured_gender.value}
                attrName={["insured_gender"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.GENDER}
                warn_status={proposerFields?.insured_gender?.warning}
                error_message="Select Gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Mobile"}
                value={proposerFields?.insured_mobile?.value}
                attrName={["insured_mobile"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_mobile?.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile number"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={proposerFields.insured_email.value}
                attrName={["insured_email"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_email?.warning}
                error_message={
                  isEmpty(proposerFields.insured_email.value)
                    ? "Enter Email"
                    : "Enter Valid Email"
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={proposerFields?.insured_pincode?.value}
                attrName={["insured_pincode"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields?.insured_pincode?.warning}
                validation_type="NUMBER"
                max_length={6}
                error_message={
                  isEmpty(proposerFields.insured_pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={proposerFields?.insured_occupation?.value}
                attrName={["insured_occupation"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.OCCUPATION_DATA}
                warn_status={proposerFields?.insured_occupation?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Qualification"
                value={proposerFields?.insured_qualification?.value}
                attrName={["insured_qualification"]}
                value_update={fieldsUpdateState}
                data={DROPDOWN_DATA.QUALIFICATION_DATA}
                warn_status={proposerFields?.insured_qualification?.warning}
                error_message="Select Proposer Occupation"
              />
            </Grid>
          </Grid>

          <TermFooter loader={loader} forward={validateForm} />
        </Box>
      )}
    </>
  );
};

export default ProposerDetails;
