import { Box, Radio, RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import { TProposerDetails } from "../../../../../../types/TermFullJourney/TProposerDetails";
import { TInsuredMemberDetails } from "../../../../../../types/TermFullJourney/TInsuredMemberDetails";
import { useAppSelector } from "../../../../../../Store/hooks";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import SearchDropdown from "../../../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

const ProposerDetails = ({
  updateMasterState,
  proposerDetails,
  setProposerDetails,
  insuredMemberDetails,
  setInsuredMemberDetails,
  validateForm,
  loader,
}: {
  updateMasterState: Function;
  proposerDetails: TProposerDetails;
  setProposerDetails: Function;
  insuredMemberDetails: TInsuredMemberDetails;
  setInsuredMemberDetails: Function;
  validateForm: Function;
  loader: boolean;
}) => {
  const dropdownData = useAppSelector(
    (state) => state.TermFullJourney.dropdownData
  );
  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={3}>
        {/* <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                value={proposerDetails.is_proposer_is_insured_member}
                defaultChecked={proposerDetails.is_proposer_is_insured_member}
                onClick={() =>
                  updateMasterState(
                    ["is_proposer_is_insured_member", setProposerDetails],
                    !proposerDetails.is_proposer_is_insured_member
                  )
                }
              />
            }
            label="Proposer is also an insured member?"
          />
        </Grid> */}
        <Grid xs={12}>
          <h6>
            {proposerDetails.is_proposer_is_insured_member
              ? "Proposer / Insured Details"
              : "Proposer Details"}
          </h6>
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            value={proposerDetails.full_name.value}
            attrName={["full_name", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.full_name.warning}
            error_message="Enter full name"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={proposerDetails.dob.value}
            attrName={["dob", setProposerDetails, true]}
            value_update={updateMasterState}
            error_message="Select DOB"
            warn_status={proposerDetails.dob.warning}
            min_date={60}
            disabled={true}
            tooltip="You cannot change DOB on proposal page. If you would like to edit this . Please go back to the quote page "
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={proposerDetails.gender.value}
            attrName={["gender", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.genderData}
            warn_status={proposerDetails.gender.warning}
            error_message="Select gender"
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Mobile"}
            value={proposerDetails.mobile.value}
            attrName={["mobile", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.mobile.warning}
            error_message="Enter mobile no."
            validation_type="NUMBER"
            max_length={10}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={proposerDetails.email.value}
            attrName={["email", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.email.warning}
            error_message="Enter E-mail"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Aadhar"}
            value={proposerDetails.aadhar_number.value}
            attrName={["aadhar_number", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.aadhar_number.warning}
            error_message="Eneter aadhar no."
            validation_type="NUMBER"
            max_length={12}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Emergency Phone"}
            value={proposerDetails.emergency_number.value}
            attrName={["emergency_number", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.emergency_number.warning}
            error_message="Enter emergency no."
            validation_type="NUMBER"
            max_length={10}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Marital Status"
            value={`${proposerDetails.marital_status.value}`}
            attrName={["marital_status", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.maritalStatusData}
            warn_status={proposerDetails.marital_status.warning}
            error_message="Select martital status"
          />
        </Grid>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Annual Income"
            value={proposerDetails.annual_income.value}
            attrName={["annual_income", setProposerDetails, false]}
            value_update={updateMasterState}
            data={dropdownData.annualIncomeData}
            warn_status={proposerDetails.annual_income.warning}
            error_message="Select annual income"
          />
        </Grid>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Education"
            value={proposerDetails.education.value}
            attrName={["education", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.EDUCATION_MASTER}
            warn_status={proposerDetails.education.warning}
            error_message="Select education"
          />
        </Grid>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Occupation"
            value={proposerDetails.occupation.value}
            attrName={["occupation", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.occupationData}
            warn_status={proposerDetails.occupation.warning}
            error_message="Select occupation"
          />
        </Grid>

        {proposerDetails.occupation.value.code === "SPRO" ? (
          <Grid xs={4}>
            <RKTextField
              class_name="inputField"
              title={"Occupation Description"}
              value={proposerDetails.occupation_desc.value}
              attrName={["occupation_desc", setProposerDetails, true]}
              value_update={updateMasterState}
              warn_status={proposerDetails.occupation_desc.warning}
              error_message="Enter Organization description"
            />
          </Grid>
        ) : null}
        {proposerDetails.occupation.value.code === "SPVT" ||
          proposerDetails.occupation.value.code === "BSEM" ||
          proposerDetails.occupation.value.code === "SPRO" ? (
          <>
            <Grid xs={4}>
              <SearchDropdown
                class_name="inputField"
                title="Organization"
                value={proposerDetails.org_name.value}
                attrName={["org_name", setProposerDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.insurance_company}
                warn_status={proposerDetails.org_name.warning}
                error_message="Select Organization"
                disabled={proposerDetails.occupation.value.code === "BSEM"}
              />
            </Grid>
            {proposerDetails.org_name.value.code === "NOT ANSWERED" ||
              proposerDetails.org_name.value.code === "Others" ? (
              <Grid xs={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Organization Description"}
                  value={proposerDetails.org_description.value}
                  attrName={["org_description", setProposerDetails, true]}
                  value_update={updateMasterState}
                  warn_status={proposerDetails.org_description.warning}
                  error_message="Enter Organization description"
                />
              </Grid>
            ) : null}
            <Grid xs={4}>
              <SearchDropdown
                class_name="inputField"
                title="Organization Type"
                value={proposerDetails.org_type.value}
                attrName={["org_type", setProposerDetails, true]}
                value_update={updateMasterState}
                data={
                  proposerDetails.occupation.value.code === "BSEM"
                    ? [
                      { code: "NOT ANSWERED", label: "Others" },
                      { code: "PROPREITOR", label: "Proprietor" },
                      { code: "PARTNER", label: "Partnership" },
                    ]
                    : [
                      { code: "NOT ANSWERED", label: "Others" },
                      { code: "GOVT", label: "Govt" },
                      { code: "PRIVATE LTD", label: "Pvt Ltd" },
                      { code: "PUBLIC LTD", label: "Public Ltd" },
                      { code: "TRST", label: "Trust" },
                      { code: "SOCIETY", label: "Society" },
                      { code: "PROPREITOR", label: "Proprietor" },
                      { code: "PARTNER", label: "Partnership" },
                    ]
                }
                warn_status={proposerDetails.org_type.warning}
                error_message="Select Organization type"
              />
            </Grid>
            {proposerDetails.org_type.value.code === "NOT ANSWERED" ? (
              <Grid xs={4}>
                <RKTextField
                  class_name="inputField"
                  title={"Organization Type Description"}
                  value={proposerDetails.org_type_desc.value}
                  attrName={["org_type_desc", setProposerDetails, true]}
                  value_update={updateMasterState}
                  warn_status={proposerDetails.org_type_desc.warning}
                  error_message="Enter Organization type description"
                />
              </Grid>
            ) : null}
          </>
        ) : null}
        {proposerDetails.occupation.value.code === "PROF" ? (
          <Grid xs={4}>
            <SearchDropdown
              class_name="inputField"
              title="My Profession"
              value={proposerDetails.my_profession.value}
              attrName={["my_profession", setProposerDetails, true]}
              value_update={updateMasterState}
              data={dropdownData.myProf}
              warn_status={proposerDetails.my_profession.warning}
              error_message="Select my profession"
            />
          </Grid>
        ) : null}
        <Grid xs={6}>
          <SearchDropdown
            class_name="inputField"
            title="Objective of buying policy"
            value={proposerDetails.obj_of_buying_policy.value}
            attrName={["obj_of_buying_policy", setProposerDetails, true]}
            value_update={updateMasterState}
            data={dropdownData.buyingPolicy}
            warn_status={proposerDetails.obj_of_buying_policy.warning}
            error_message="Select Objective of buying policy"
          />
        </Grid>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"PAN"}
            value={proposerDetails.pan_no.value}
            attrName={["pan_no", setProposerDetails, true]}
            value_update={updateMasterState}
            warn_status={proposerDetails.pan_no.warning}
            error_message="Enter PAN no."
          />
        </Grid>
        <Grid xs={12}>
          <h6 className="mr-3">NRI Details</h6>
        </Grid>
        <Grid xs={12}>
          <RadioGroup
            value={proposerDetails.nationality}
            onChange={(e) => {
              updateMasterState(
                ["nationality", setProposerDetails, true],
                e.target.value
              );
            }}
            style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              value="indian"
              control={<Radio />}
              label="Indian"
            />
            {/* <FormControlLabel value="nri" control={<Radio />} label="NRI" /> */}
          </RadioGroup>
        </Grid>
      </Grid>

      {/* Insured Member Details */}
      {!proposerDetails.is_proposer_is_insured_member ? (
        <Box>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <hr />
              <h6>Insured Member Details</h6>
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={insuredMemberDetails.full_name.value}
                attrName={["full_name", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                warn_status={insuredMemberDetails.full_name.warning}
                error_message="Enter full name"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={insuredMemberDetails.dob.value}
                attrName={["dob", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                error_message="Select DOB"
                warn_status={insuredMemberDetails.dob.warning}
                min_date={60}
              />
            </Grid>
            <Grid xs={4}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={insuredMemberDetails.gender.value}
                attrName={["gender", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.genderData}
                warn_status={insuredMemberDetails.gender.warning}
                error_message="Select gender"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Aadhar Number"}
                value={insuredMemberDetails.aadhar_number.value}
                attrName={["aadhar_number", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                warn_status={insuredMemberDetails.aadhar_number.warning}
                error_message="Enter aadhar no."
                validation_type="NUMBER"
                max_length={12}
              />
            </Grid>
            <Grid xs={4}>
              <SearchDropdown
                class_name="inputField"
                title="Marital Status"
                value={insuredMemberDetails.marital_status.value}
                attrName={["marital_status", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.maritalStatusData}
                warn_status={insuredMemberDetails.marital_status.warning}
                error_message="Select marital status"
              />
            </Grid>
            <Grid xs={4}>
              <SearchDropdown
                class_name="inputField"
                title="Education"
                value={insuredMemberDetails.education.value}
                attrName={["education", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.EDUCATION_MASTER}
                warn_status={insuredMemberDetails.education.warning}
                error_message="Select education"
              />
            </Grid>
            <Grid xs={4}>
              <SearchDropdown
                class_name="inputField"
                title="Occupation"
                value={insuredMemberDetails.occupation.value}
                attrName={["occupation", setInsuredMemberDetails, true]}
                value_update={updateMasterState}
                data={dropdownData.occupationData}
                warn_status={insuredMemberDetails.occupation.warning}
                error_message="Select occupation"
              />
            </Grid>
            {insuredMemberDetails.occupation.value.code === "SPVT" ||
              insuredMemberDetails.occupation.value.code === "BSEM" ||
              insuredMemberDetails.occupation.value.code === "SPRO" ? (
              <>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Organization"
                    value={insuredMemberDetails.org_name.value}
                    attrName={["org_name", setInsuredMemberDetails, true]}
                    value_update={updateMasterState}
                    data={dropdownData.insurance_company}
                    warn_status={insuredMemberDetails.org_name.warning}
                    error_message="Select Organization"
                  />
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Organization Description"}
                    value={insuredMemberDetails.org_desc.value}
                    attrName={["org_desc", setInsuredMemberDetails, true]}
                    value_update={updateMasterState}
                    warn_status={insuredMemberDetails.org_desc.warning}
                    error_message="Enter Organization description"
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
      ) : null}
      <Footer
        continueFunction={validateForm}
        backText="Back to Quotes"
        loader={loader}
      />
    </Box>
  );
};

export default ProposerDetails;
