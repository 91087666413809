import { createSlice } from "@reduxjs/toolkit";
import { TCKYC } from "../../../types/CKYC/TCKYC";
import { CKYC_REDUCERS } from "./CKYCReducer";

const intitialState: TCKYC = {
  MANUAL_KYC_THIRD_PARTY_URL: "",
  MANUAL_KYC_STATUS: false,
  VERIFY_KYC_FORM: {
    section: "premium_details",
    ckyc_option: 1,
    customer_type: { value: "Individual", warning: false },
    dob: { value: "", warning: false },
    doc_id: { value: "", warning: false },
    doc_type: { value: "", warning: false },
    full_name: { value: "", warning: false },
    gender: { value: "", warning: false },
    form_sixety: { value: "", warning: false },
    pincode: { value: "", warning: false },
    city: { value: "", warning: false },
    houseNo: { value: "", warning: false },
    area_town: { value: "", warning: false },
    income_source: { value: "", warning: false },
    Family_relationship: { value: "", warning: false },
    Occupation: { value: "", warning: false },
    imageFormSixetyName: { value: "", warning: false },
    passport: { value: "", warning: false },
    identity_doc: { value: "", warning: false },
    family_full_name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    toggle_switch: false,
    pep_remark: "",
  },
  CKYC_DETAILS: {
    transactionId: "",
    CkycPTransaction: "",
    kycTranNumber: "",
    kycNo: "",
    kycCustomerName: "",
    kycFirstName: "",
    kycMiddleName: "",
    kycLastName: "",
    kycDOB: "",
    kycMobile: "",
    kycEmail: "",
    kycPAN: "",
    kycAadhaar: "",
    kycAddress1: "",
    kycAddress2: "",
    kycAddress3: "",
    kycCity: "",
    kycDistrict: "",
    kycState: "",
    kycPincode: "",
    zone: "",
    poaStatus: "",
    poiStatus: "",
    kycStatus: "",
    kycManualURL: "",
    ncb_tag: "",
    correspondencePincode: "",
  },
};

export const CKYCSlice = createSlice({
  name: "CKYC",
  initialState: intitialState,
  reducers: { ...CKYC_REDUCERS },
});
