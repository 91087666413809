import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { ECompanyCode } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../Store/hooks";
import { GET_DATE_VALIDATION } from "../../../../../SupportingFiles/HealthHelper";
import { TTwoAdultMemberDetails } from "../../../../../types/Health/ProposalDeatail/TProposalDetail";

const MAdultTwoDetails = ({
  fieldsUpdateState,
  validateForm,
  memberFields,
}: {
  fieldsUpdateState: Function;
  validateForm: Function;
  memberFields: TTwoAdultMemberDetails;
}) => {
  const navigate = useNavigate();
  const {
    PAGE_STATUS,
    DROPDOWN_DATA,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Health);

  const DATA = useAppSelector((state) => state.CommonDropdownData);
  const { OCCUPATION, ANNUAL_INCOME_NIVA_BUPA, NIVA_BUPA_OCCUPATION } =
    useAppSelector((state) => state.CommonDropdownData);

  const EDUCATION = [
    { label: "Matric", value: "Matric" },
    { label: "Non-Matric", value: "Non-Matric" },
    { label: "Graduate", value: "Graduate" },
    { label: "Post-Graduate", value: "Post-Graduate" },
    { label: "Professional Course", value: "Professional Course" },
  ];

  return (
    <Box>
      <h5 className="sectionTitle">
        2<sup>nd</sup> Adult Details
      </h5>
      <Grid container columnSpacing={2} rowSpacing={3} marginTop={"8px"}>
        <Grid xs={12}>
          <SelectDropdown
            disabled={PAGE_STATUS}
            class_name="inputField"
            title="Relationship with Proposer"
            value={memberFields.relationship.value}
            attrName={"relationship"}
            value_update={fieldsUpdateState}
            data={
              INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED ||
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value ===
                "Self"
                ? [{ value: "Spouse", label: "Spouse" }]
                : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship
                    .value === "Father"
                ? [{ value: "Mother", label: "Mother" }]
                : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship
                    .value === "Mother"
                ? [{ value: "Father", label: "Father" }]
                : DROPDOWN_DATA?.ADULT2_RELATIONSHIP
            }
            warn_status={memberFields.relationship.warning}
            error_message="Select Relationship"
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            validation_type="NAME"
            class_name="inputField"
            title={"Full Name"}
            value={memberFields.name.value}
            attrName={"name"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.name.warning}
            error_message="Enter Full Name"
          />
        </Grid>
        <Grid xs={6}>
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.HDFC ? (
            <DatePicker
              class_name="inputField"
              title={"DOB"}
              value={memberFields.dob.value}
              attrName={"dob"}
              value_update={fieldsUpdateState}
              error_message="Select DOB"
              warn_status={memberFields.dob.warning}
              date_validation_type={
                SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC
                  ? "DAYS"
                  : "YEARS"
              }
              min_date={
                SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC
                  ? 16801
                  : 100
              }
              max_date={
                SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC
                  ? 6575
                  : 18
              }
              default_date={subYears(new Date(), 18)}
            />
          ) : (
            <DatePicker
              class_name="inputField"
              title={"DOB"}
              value={memberFields.dob.value}
              attrName={"dob"}
              value_update={fieldsUpdateState}
              error_message="Select DOB"
              warn_status={memberFields.dob.warning}
              date_validation_type={
                GET_DATE_VALIDATION(
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                  `${SELECTED_QUOTE_DATA.productDetails.id}`
                ).validation_type
              }
              min_date={
                GET_DATE_VALIDATION(
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                  `${SELECTED_QUOTE_DATA.productDetails.id}`
                ).min_date
              }
              max_date={
                GET_DATE_VALIDATION(
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                  `${SELECTED_QUOTE_DATA.productDetails.id}`
                ).max_date
              }
              default_date={
                SELECTED_QUOTE_DATA.productDetails.id === 2843
                  ? subYears(new Date(), 56)
                  : subYears(new Date(), 18)
              }
            />
          )}
        </Grid>
        {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
        ECompanyCode.NIVA_BUPA ? (
          <>
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={memberFields?.occupation.value}
                attrName={["occupation"]}
                value_update={fieldsUpdateState}
                data={NIVA_BUPA_OCCUPATION}
                warn_status={memberFields?.occupation.warning}
                error_message="Select Occupation Status"
              />
            </Grid>
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Qualification"
                value={memberFields?.qualification.value}
                attrName={["qualification"]}
                value_update={fieldsUpdateState}
                data={EDUCATION}
                warn_status={memberFields?.qualification.warning}
                error_message="Select Qualification Status"
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid xs={6} className="weightField">
          <span className="weightIcon"></span>
          <RKTextField
            class_name="inputField"
            validation_type="NUMBER"
            max_length={3}
            title={"Weight (Kg)"}
            value={memberFields.weight.value}
            attrName={"weight"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.weight.warning}
            error_message={"Enter Weight"}
          />
        </Grid>
        <Grid xs={12} className="heightField">
          <span className="heightIcon"></span>
          <SelectDropdown
            class_name="inputField mr-2"
            title="Feet"
            value={memberFields.heightFeet.value}
            attrName={"heightFeet"}
            error_message="Select Height Feet"
            value_update={fieldsUpdateState}
            data={DATA.HEIGHT_FEET}
            warn_status={memberFields.heightFeet.warning}
          />
          <SelectDropdown
            class_name="inputField"
            title="Inches"
            error_message="Select Height Inches"
            value={memberFields.heightInches.value}
            attrName={"heightInches"}
            value_update={fieldsUpdateState}
            data={DATA.HEIGHT_INCH}
            warn_status={memberFields.heightInches.warning}
          />
        </Grid>
      </Grid>
      <MFooter
        attrName={"pageStatus"}
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        value={1}
        forward={() => validateForm()}
        backward={() => navigate(-1)}
      />
    </Box>
  );
};

export default MAdultTwoDetails;
