import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "../MNavbar/MNavbar.scss";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useAppSelector } from "../../Store/hooks";

const MNavbar = () => {
  const navigate = useNavigate();
  const { TW, Car, Health, Term } = useAppSelector((state) => state);

  return (
    <Box className="mProposalNavbar">
      <Grid container columnSpacing={0} rowSpacing={3}>
        <Grid xs={12}>
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}>
            {TW.REDIRECTED ||
            Car.REDIRECTED ||
            Health.REDIRECTED ||
            Term.REDIRECTED ? (
              <ArrowBackIosNewRoundedIcon />
            ) : (
              <ArrowBackIcon />
            )}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MNavbar;
