import { Box, Button, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../Store/hooks";
import RKTextField from "../../../FieldTypes/RKTextField/RKTextField";

const MVerifyOTP = ({
  open,
  setOpen,
  otpField,
  updateMasterState,
  RESEND_OTP,
  validate_otp,
}: {
  open: boolean;
  setOpen: any;
  otpField: { value: string; warning: boolean };
  updateMasterState: Function;
  RESEND_OTP: Function;
  validate_otp: Function;
}) => {
  const { proposerDetails } = useAppSelector((state) => state.TermFullJourney);

  return (
    <Modal open={open} className="modalWrapper">
      <Box className="modalInner" marginLeft={"20px"} marginRight={"20px"}>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <h4 className="popup-heading mb-2">Verify OTP</h4>
            <Link
              href="#"
              className="close-button"
              onClick={() => {
                setOpen(false);
              }}
            />
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent={"center"}>
          <Grid xs={12} textAlign="center">
            <h5 className="claimed">OTP has sent successfully on</h5>
            <h5
              style={{
                fontSize: "16px",
                fontWeight: "600",
                marginTop: "4px",
                marginBottom: "16px",
              }}>
              {`+91-${proposerDetails.mobile.value}`}
            </h5>
          </Grid>
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Enter OTP"}
              value={otpField.value}
              attrName={""}
              value_update={updateMasterState}
              warn_status={otpField.warning}
              error_message={"Enter OTP"}
              max_length={6}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={12} textAlign={"center"} marginTop={"20px"}>
            <Link onClick={() => RESEND_OTP()} sx={{ cursor: "pointer" }}>
              Resend OTP
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid xs={12} className="ctaBtn mt-5">
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={12} className="ctaBtn" textAlign={"center"}>
            <Button
              variant="contained"
              className="regularSecondaryBtn"
              onClick={() => {
                validate_otp();
              }}>
              Verify Otp
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default MVerifyOTP;
