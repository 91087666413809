import { Box, Button } from "@mui/material";
import * as React from "react";
import { CLIENTS } from "../../URLCollection/Clients";
import "./CustomButton.scss";

interface Props {
  class_name?: string;
  text_name: string | any;
  loading?: boolean;
  onClickFunction?: Function;
  disabled?: boolean;
}

const CustomButton: React.FC<Props> = ({
  class_name = "regularPrimaryBtn",
  text_name,
  loading = false,
  onClickFunction = () => {},
  disabled,
}) => {
  React.useEffect(() => {
    // Get the hostname
    var hostname = window.location.hostname;
    // Get the navbar element
    var navbar = document.querySelector(".mediumPrimaryBtn");

    if (navbar !== null) {
      // Set background color based on hostname
      if (hostname === CLIENTS.AMAZE) {
        (navbar as HTMLElement).style.backgroundColor = "#00785a"; // Replace with your desired color
      } else if (hostname === CLIENTS.JIO) {
        (navbar as HTMLElement).style.backgroundColor = "#DF9F39"; // Replace with your desired color
      } else if (hostname === CLIENTS.MOTILAL) {
        (navbar as HTMLElement).style.backgroundColor = "#ff881c"; // Replace with your desired color
      }
      // Add more conditions for additional hostnames as needed
    }
  }, [window.location.hostname]);
  return (
    <div className="ctaBtn">
      <Button
        id={text_name}
        variant="contained"
        className={class_name}
        onClick={() => {
          if (loading === false) {
            onClickFunction();
          }
        }}
        disabled={disabled}
      >
        {loading === true ? (
          <>
            <Box className="loader mr-2" />
            Loading
          </>
        ) : (
          text_name
        )}
      </Button>
    </div>
  );
};

export default CustomButton;
