import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import TermRiderQuestion from "../../../../../Component/Term/TermRiderQuestion/TermRiderQuestion";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { TermSlice } from "../../../../../Store/Slice_Reducer/Term/TermSlice";
import { TDropdown } from "../../../../../types/Common/TDropdown";
import "./../../../../../SCSS/CommonSCSS/ProposalForms.scss";

function RidersBenefits({
  forward,
  tf003Data,
  loaderTf003,
}: {
  forward: Function;
  tf003Data: TDropdown[];
  loaderTf003: boolean;
}) {
  const { RIDERS_BENEFITS, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  return (
    <Box>
      <h5 className="sectionTitle">Addon Detail</h5>
      {QUOTE_FIELDS_DATA.payMode === "5" ? null : (
        <Grid container spacing={3}>
          <Grid xs={12}>
            <TermRiderQuestion
              show_toggle_button={false}
              disable={true}
              loader={loaderTf003}
              addon_amount={`${RIDERS_BENEFITS?.riders_benefits_response?.riders?.TF003?.premium}`}
              main_class="addonQuestion borderBottom"
              icon_class={"hospital"}
              question_name={`Accidental death`}
              policy_term=""
              desc={
                "Accidental death rider provides additional protection in case of accidental death during the policy term. The accidental death sum assured is payable over and above the base sum assured in case of accidental death."
              }
              sub_details={
                <Box sx={{ maxWidth: "250px" }}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Select Value"
                    value={RIDERS_BENEFITS.local_value.tf003Value}
                    attrName={"tf003Value"}
                    value_update={(a: any, v: any) => {
                      dispatch(
                        TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT(
                          {
                            updates: [
                              {
                                key: "tf003Value",
                                value: v,
                              },
                            ],
                          }
                        )
                      );
                    }}
                    data={tf003Data}
                    warn_status={false}
                  />
                </Box>
              }
              attrName={"tf003Value"}
              // toggle_status={RIDERS_BENEFITS.local_value.tf003Status}
              toggle_status={true}
              value_update={(attrName: any, value: any) => {
                dispatch(
                  TermSlice.actions.UPDATE_LOCAL_VALUE_HDFC_RIDERS_BENEFIT({
                    updates: [
                      {
                        key: "tf003Status",
                        value: value,
                      },
                    ],
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <TermFooter
        forward={() => {
          forward();
        }}
      />
    </Box>
  );
}

export default RidersBenefits;
