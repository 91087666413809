import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OwnerDetails from "../../../Page/Desktop/TW/Acko/OwnerDetails";
import MOwnerDetails from "../../../Page/Mobile/TW/Acko/MOwnerDetails";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { TW_SERVICES } from "../../../Services/TW/TWServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  isEmpty,
  validateEmail,
  validateFullName,
  validateGST,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TOwnerDetails } from "../../../types/TTWSlice";
import { CLIENTS } from "../../../URLCollection/Clients";
import { pushDataLayer, pushMotorDataLayer, sendEventToNativeApp } from "../../../utils/analytics";

const OwnerDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    OWNER_DETAILS,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    SELECTED_QUOTE_DATA,
    REDIRECTED
  } = useAppSelector((state) => state.TW);
  const { TW } = useAppSelector((state) => state);
  const [pageStatus, setPageStatus] = useState<number>(1);
  const [loader, setLoader] = useState<boolean>(false);
  const [kycOpen, setKycOpen] = useState<boolean>(false);
  const [ownerFields, setOwnerFields] = useState<TOwnerDetails>(OWNER_DETAILS);

  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "pageStatus") {
      validateForm();
      setPageStatus(value);
    } else if (attrName === "backbutton") {
      navigate(-1);
    } else if (attrName === "includePAOwner") {
      setOwnerFields((prev) => ({
        ...prev,
        includePAOwner: !prev.includePAOwner,
      }));
    }
  };

  const validateForm = () => {
    let data: TOwnerDetails = { ...ownerFields };

    if (!data.includePAOwner) {
      data = {
        ...data,
        pacover: {
          ...data.pacover,
          warning: isEmpty(data.pacover.value),
        },
      };
    }
    if (data.customer_type.value === "Organization") {
      data = {
        ...data,
        name: {
          ...data.name,
          warning: isEmpty(data.name.value),
        },
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
        dob: {
          ...data.dob,
          warning: isEmpty(data.dob.value),
        },
        email: {
          ...data.email,
          warning: !validateEmail(data.email.value),
        },
        GST: {
          ...data.GST,
          warning: !validateGST(data.GST.value),
        },
      };
    } else {
      data = {
        ...data,
        name: {
          ...data.name,
          warning: data.name.value
            ? !validateFullName(data.name.value)
            : isEmpty(data.name.value),
        },
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
        dob: {
          ...data.dob,
          warning: isEmpty(data.dob.value),
        },
        gender: {
          ...data.gender,
          warning: isEmpty(data.gender.value),
        },
        maritalStatus: {
          ...data.maritalStatus,
          warning: isEmpty(data.maritalStatus.value),
        },
        email: {
          ...data.email,
          warning: !validateEmail(data.email.value),
        },
      };
    }
    let hasError: boolean = false;

    if (data.customer_type.value !== "Organization") {
      hasError =
        data.name.warning ||
        data.mobile.warning ||
        data.email.warning ||
        data.gender.warning ||
        data.maritalStatus.warning ||
        data.dob.warning;
    } else {
      hasError = hasError || data.GST.warning || data.email.warning;
    }

    setOwnerFields(data);

    if (!hasError) {
      if(window.location.hostname ===CLIENTS.JIO){
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "owner",
          "proceed",
          "two wheeler",
          REDIRECTED ?"JFS app": "Insure website"
        );
        const eventProps = {
          action: "owner",
          click:"continue",
          type: "two wheeler",
          EntrySource:REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      } 
      dispatch(TWSlice.actions.OWNER_DETAILS(data));
      updateRecords();
      if (PAGE_STATUS) {
        navigate(`${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}`);
      } else {
        navigate(`${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.VEHICLE_DETAILS}`);
      }
    }
  };

  const validateFun = (attrName: string, value: any) => {
    switch (attrName[0]) {
      case "name":
        return attrName[0] === "name"
          ? !validateFullName(value)
          : isEmpty(value);
      case "mobile":
        return attrName[0] === "mobile" ? !validateMobileNumber(value) : false;
      case "email":
        return attrName[0] === "email" ? !validateEmail(value) : false;
      case "GST":
        return attrName[0] === "GST" ? !validateGST(value) : false;

      default:
        return isEmpty(value);
    }
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: {
        ...[attrName[0]],
        [attrName[1]]: value,
        warning: validateFun(attrName, value),
      },
    });
  };

  const handleClick = (key: any, value: any) => {
    setOwnerFields({
      ...ownerFields,
      [key]: value,
      includePAOwner: key === "vehicleRegistered" && false,
      ...(!ownerFields.vehicleRegistered
        ? {
            dob: { ...ownerFields.dob, warning: false },
            maritalStatus: { ...ownerFields.maritalStatus, warning: false },
            gender: { ...ownerFields.gender, warning: false },
            pacover: { ...ownerFields.pacover, warning: false },
          }
        : {}),
    });
  };

  useEffect(() => {
    setOwnerFields((prev) => ({
      ...prev,
      pacover: { ...prev.pacover, warning: false },
      GST: { ...prev.GST, warning: false },
    }));
  }, []);
  const updateRecords = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        const response = results.response;
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      customerName: ownerFields.name.value,
      mobile: ownerFields.mobile.value,
      customerGender: ownerFields.gender.value,
      customerEmail: ownerFields.email.value,
      customerDOB: ownerFields.dob.value,
      vehicleOwned: ownerFields.customer_type.value,
      customerGSTNo: ownerFields.GST.value,
      section: "customer_details",
      insurance_type: "TW",
    };

    TW_SERVICES.CAPTURE_RECORD_ON_EVERY_STEP(onSuccess, onError, param);
  };

  useEffect(() => {
    const Params: {
      quote_no: string;
      type: string;
      addon: string;
      value: "0" | "1" | any;
    } = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      type: "TW",
      addon: "pa_owner_driver",
      value:
        TW.OWNER_DETAILS.customer_type.value === "Organization"
          ? "0"
          : ADDON_STATUS.pa_owner_cover.value
          ? "1"
          : "0",
    };

    TW_SERVICES.UPDATE_ADDON_STATUS(
      () => {},
      () => {},
      Params
    );
  }, [ADDON_STATUS]);

  useEffect(() => {
    if (ownerFields.customer_type.value === "Organization") {
      dispatch(
        TWSlice.actions.UPDATE_ADDON_STATUS({
          key: "pa_owner_cover",
          value: false,
        })
      );
    }
  }, [ownerFields.customer_type]);

  return (
    <>
      {isMobile ? (
        <MOwnerDetails
          ownerFields={ownerFields}
          pageStatus={pageStatus}
          updateMasterState={updateMasterState}
          handleClick={handleClick}
          setOwnerFields={setOwnerFields}
          fieldsUpdateState={fieldsUpdateState}
          kycOpen={kycOpen}
          loader={loader}
          setKycOpen={setKycOpen}
        />
      ) : (
        <OwnerDetails
          ownerFields={ownerFields}
          pageStatus={pageStatus}
          updateMasterState={updateMasterState}
          handleClick={handleClick}
          setOwnerFields={setOwnerFields}
          fieldsUpdateState={fieldsUpdateState}
          kycOpen={kycOpen}
          loader={loader}
          setKycOpen={setKycOpen}
        />
      )}
    </>
  );
};

export default OwnerDetailsContainer;
