import React from "react";
import { Routes, Route } from "react-router-dom";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import STARCKYCContainer from "../../../../../Container/CKYC/Star/STARCKYCContainer";
import HealthProposalContainer from "../../HealthProposalContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import STARManualKYCContainer from "../../../../../Container/CKYC/Star/STARManualKycContainer";
import MedicalDetailsContainerComp from "../../../../../Container/Health/STAR/MedicalDetailsContainer/MedicalDetailsContainerComp";
import MedicalDetailsContainerFHO from "../../../../../Container/Health/STAR/MedicalDetailsContainer/MedicalDetailsContainerFHO";
import { useAppSelector } from "../../../../../Store/hooks";
import { EProductId } from "../../../../../Services/Enum/EHome";
import MedicalDetailsContainerRedCarpet from "../../../../../Container/Health/STAR/MedicalDetailsContainer/MedicalDetailsContainerRedCarpet";

const STAR_ROUTES = () => {
 
  const {SELECTED_QUOTE_DATA} = useAppSelector((state)=>state.Health);


  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<STARCKYCContainer />} />
        <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<STARManualKYCContainer />}
        />
        
       {SELECTED_QUOTE_DATA.productDetails.id === EProductId.STAR_COMP_PRODUCT_ID ? 
       <Route
       path={HEALTH_ROUTE.MEDICAL_DETAILS}
       element={<MedicalDetailsContainerComp />}
     />
       :
       (<></>) 
       }
       {SELECTED_QUOTE_DATA.productDetails.id === EProductId.STAR_FHO_PRODUCT_ID ? 
       <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainerFHO />}
        />:(<></>) }
        {SELECTED_QUOTE_DATA.productDetails.id === EProductId.START_RED_CARPET ? 
       <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainerRedCarpet />}
        />:(<></>) }
      </Route>
    </Routes>
  );
};

export default STAR_ROUTES;
