import { Route, Routes } from "react-router";
import RSACKYCContainer from "../../../../../Container/CKYC/RSA/RSACKYCContainer";
import NomineeDetailsContainer from "../../../../../Container/Car/Common/NomineeDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/Car/Common/ReviewDetailsContainer";
import VehicleDetailsContainer from "../../../../../Container/Car/Common/VehicleDetailsContainer";
import AddonDetailsCOntainerRSA from "../../../../../Container/Health/RSA/AddonDetailsCOntainerRSA";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { CAR_ROUTES } from "../../../../Path/CarRoutes";
import AddressDetailsContainer from "../../../../../Container/Car/Common/AddressDetailsContainer";
import HealthProposalContainer from "../../HealthProposalContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import MedicalDetailsContainerRSA from "../../../../../Container/Health/RSA/MedicalDetailsContainerRSA/MedicalDetailsContainerRSA";

export const RSA_ROUTES = () => {
  return (
  <>
  <Routes>
    <Route path={HEALTH_ROUTE.PROPOSAL_PAGE} element={<HealthProposalContainer />}>
      <Route path={CKYC_ROUTES.CKYC} element={<RSACKYCContainer />} />
      <Route
        path={HEALTH_ROUTE.ADDON_DETAILS}
        element={<AddonDetailsCOntainerRSA />}
      />
      <Route
       path={HEALTH_ROUTE.MEDICAL_DETAILS}
       element={<MedicalDetailsContainerRSA />}
     />
    </Route>
  </Routes>

  </>
  )
  };
