import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import DatePicker from "../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MTermFooter from "../../../../Component/Term/MTermFooter/MTermFooter";
import { useAppSelector } from "../../../../Store/hooks";
import { isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { TTermProposerData } from "../../../../types/TTermSlice";

const MProposerDetails = ({
  proposerFields,
  fieldsUpdateState,
  validateForm,
  forward,
  backward,
}: {
  proposerFields: TTermProposerData;
  fieldsUpdateState: Function;
  validateForm: Function;
  forward?: Function;
  backward?: Function;
}) => {
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Term);

  return (
    <Box>
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <h6>Prposer / Insured Details</h6>
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Full Name"}
            validation_type="NAME"
            value={proposerFields.full_name.value}
            attrName={["full_name"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.full_name.warning}
            error_message={
              isEmpty(proposerFields.full_name.value)
                ? "Enter Full Name"
                : "Enter Valid Full Name"
            }
          />
        </Grid>
        <Grid xs={6}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={proposerFields.dob.value}
            attrName={["dob"]}
            disabled={true}
            value_update={fieldsUpdateState}
            error_message="Enter DOB"
            warn_status={proposerFields.dob.warning}
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            disabled
            class_name="inputField mb-2"
            title="Gender"
            value={proposerFields.gender.value}
            attrName={["gender"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.GENDER}
            warn_status={proposerFields.gender.warning}
            error_message="Select Gender"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            disabled
            class_name="inputField mb-2"
            title={"Mobile"}
            value={proposerFields.mobile.value}
            attrName={["mobile"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.mobile.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={
              isEmpty(proposerFields.mobile.value)
                ? "Enter Mobile Number"
                : "Enter Valid Mobile number"
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Email"}
            value={proposerFields.email.value}
            attrName={["email"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.email.warning}
            error_message={
              isEmpty(proposerFields.email.value)
                ? "Enter Email"
                : "Enter Valid Email"
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Pincode"}
            value={proposerFields.pincode.value}
            attrName={["pincode"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.pincode.warning}
            validation_type="NUMBER"
            max_length={12}
            disabled={true}
            error_message={
              isEmpty(proposerFields.pincode.value)
                ? "Enter Pincode"
                : "Enter Valid Pincode"
            }
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField mb-2"
            title={"Emergency Phone"}
            value={proposerFields.emergency_number.value}
            attrName={["emergency_number"]}
            value_update={fieldsUpdateState}
            warn_status={proposerFields.emergency_number.warning}
            max_length={10}
            validation_type="NUMBER"
            error_message={
              isEmpty(proposerFields.emergency_number.value)
                ? "Enter Emg Phone num "
                : "Enter Valid Emg Phone num"
            }
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="ProposerOccupation"
            value={proposerFields.occupation.value}
            attrName={["occupation"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.OCCUPATION_DATA}
            warn_status={proposerFields.occupation.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField mb-2"
            title="ProposerQualification"
            value={proposerFields?.qualification?.value}
            attrName={["qualification"]}
            value_update={fieldsUpdateState}
            data={DROPDOWN_DATA.QUALIFICATION_DATA}
            warn_status={proposerFields.qualification.warning}
            error_message="Select Proposer Occupation"
          />
        </Grid>
      </Grid>
      <MTermFooter forward={validateForm} />
    </Box>
  );
};

export default MProposerDetails;
