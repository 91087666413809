import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddonDetailsNIVABUPA from "../../../Page/Desktop/Health/NIVA_BUPA/AddonDetails/AddonDetailsNIVABUPA";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { EProductId } from "../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { TNivaBupaAddonQuestion } from "../../../types/Health/HealthQuotation/TNivaBupaAddonQuestion";
import { TNIVABUPAAddons } from "../../../types/Health/ProposalDeatail/TNIVABUPA/TNIVABUPAAddon";
import { toast } from "react-toastify";
import MAddonDetailsNIVABUPA from "../../../Page/Mobile/Health/NIVA_BUPA/MAddonDetails/MAddonDetailsNIVABUPA";
import AddonDetailsRSA from "../../../Page/Desktop/Health/RSA/AddonDetails/AddonDetailsRSA";
import { TRSAAddon } from "../../../types/Health/HealthQuotation/TAddonRSA";
import { Box } from "@mui/material";
import MAddonDetailsRSA from "../../../Page/Mobile/Health/RSA/MAddonDetails/MAddonDetailsRSA";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";

const AddonDetailsCOntainerRSA = () => {
  const isMobile = useIsMobile();
  const { Health } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS_RSA,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TRSAAddon>(ADDON_DETAILS_RSA);
  console.log("ADD_FORM_RESPONSE", ADD_FORM_RESPONSE);
  console.log("SELECTED_QUOTE_DATA", SELECTED_QUOTE_DATA);

  useEffect(() => {
    updatePreiumApi();

    // dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_ICIC(addonStatus));
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA_RSA(addonStatus));
  }, [addonStatus]);
  useEffect(() => {
    const sumInsured = ADD_FORM_RESPONSE.sum_insured; // Assuming sum_insured is the correct property to access the sum insured amount

    // Check if sum insured is 15 lacs or above and set AddOn84 as mandatory
    if (sumInsured >= 1500000) {
      setAddonStatus((prev) => ({
        ...prev,
        Claim_Protector: true,
      }));
    }

    // Check if sum insured is 1 crore or above and set AddOn85 and AddOn86 as mandatory
    if (sumInsured >= 10000000) {
      setAddonStatus((prev) => ({
        ...prev,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
  }, []);

  useEffect(() => {
    if (Number(SELECTED_QUOTE_DATA.sumInsured) >= 10000000) {
      setAddonStatus((prevState) => ({
        ...prevState,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
  }, []);
  const updateMasterState = (attrName: any, value: any) => {
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]: value,
    }));
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        console.log("quoteData");

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_RSA: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    console.log(addonStatus, "addonStatus");

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        hospitalCashbenefitOpted:
          addonStatus?.hospitalCashbenefitOpted === true ? "on" : "off",
        isVoluntaryCoPaymentSelected:
          addonStatus?.isVoluntaryCoPaymentSelected === true ? "Yes" : "No",
        opttopupOpted: addonStatus?.opttopupOpted === true ? "on" : "off",
        internationalTreatmentOpted:
          addonStatus?.internationalTreatmentOpted === true ? "on" : "off",
        supremePlusCoverSelected:
          addonStatus?.supremePlusCoverSelected === true ? "Yes" : "No",
        elitePlusCoverSelected:
          addonStatus?.elitePlusCoverSelected === true ? "Yes" : "No",
        consumableItemsCoverageOpted:
          addonStatus?.consumableItemsCoverageOpted === true ? "Yes" : "No",
        deductibleAmount: addonStatus?.deductibleAmount,
        topUpOptedCount: addonStatus?.topUpOptedCount,
        voluntaryCoPaymentPercentage: addonStatus?.voluntaryCoPaymentPercentage,
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonRsa(onSuccess, onError, param);
  };

  console.log("addonStatus", addonStatus);
  const validate = () => {
    if (Number(SELECTED_QUOTE_DATA.sumInsured) >= 20000000) {
      setAddonStatus((prevState) => ({
        ...prevState,
        Claim_Protector: true,
        Compassionate_Visit: true,
        Nursing_at_Home: true,
      }));
    }
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      section: "add_ons_details",
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      hospitalCashbenefitOpted:
        addonStatus?.hospitalCashbenefitOpted === true ? 1 : 0,
      isVoluntaryCoPaymentSelected:
        addonStatus?.isVoluntaryCoPaymentSelected === true ? 1 : 0,
      opttopupOpted: addonStatus?.opttopupOpted === true ? 1 : 0,
      internationalTreatmentOpted:
        addonStatus?.internationalTreatmentOpted === true ? 1 : 0,
      supremePlusCoverSelected:
        addonStatus?.supremePlusCoverSelected === true ? 1 : 0,
      elitePlusCoverSelected:
        addonStatus?.elitePlusCoverSelected === true ? 1 : 0,
      consumableItemsCoverageOpted:
        addonStatus?.consumableItemsCoverageOpted === true ? 1 : 0,
      deductibleAmount: addonStatus?.deductibleAmount,
      topUpOptedCount: addonStatus?.topUpOptedCount,
    };

    if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
        // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
      );
    }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };
  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetailsRSA
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <AddonDetailsRSA
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default AddonDetailsCOntainerRSA;
