import { Box, Grid } from "@mui/material";
import React from "react";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import { TCareAddon } from "../../../../types/Health/TCareAddon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../Store/hooks";
import Loader from "../../../../Component/LoaderblackBg/Loader";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MFooter from "../../../../Component/MFooter/MFooter";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";

const MAddonDetailsOptimaSecure = ({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TCareAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) => {
  const navigate = useNavigate();
  const { QUOTE_LOADER, DROPDOWN_DATA, ADD_FORM,isDeduction_value } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            left: 0,
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {/* <Loader size="small" /> */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}>
              Please wait...
            </h5>
          </Box>
        </Box>
      ) : (
        <Box>
          <h5 className="sectionTitle">Addon Details</h5>
          <Grid xs={12}>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="overseas travel"
              toggle_status={addonStatus.overseas_travel}
              value_update={updateMasterState}
              attrName={"overseas_travel"}
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="IPA Rider"
              toggle_status={addonStatus.ipa}
              value_update={updateMasterState}
              attrName={"ipa"}
            />
            {CKYC_DETAILS.kycCity.toLowerCase() === "mumbai".toLowerCase() && (
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="optima well"
                toggle_status={addonStatus.optima_well}
                value_update={updateMasterState}
                attrName={"optima_well"}
              />
            )}
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="IsUnlimited Restore"
              toggle_status={addonStatus.IsUnlimitedRestore}
              value_update={updateMasterState}
              attrName={"IsUnlimitedRestore"}
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Critical Illness"
              toggle_status={addonStatus.Iscritical_Illness}
              value_update={updateMasterState}
              attrName={"Iscritical_Illness"}
              sub_details={
                <Box maxWidth={"200px"}>
                  <SelectDropdown
                    title="Sum Insured"
                    value={addonStatus.Iscritical_Illness_sum_insured}
                    value_update={updateMasterState}
                    attrName={"Iscritical_Illness_sum_insured"}
                    data={DROPDOWN_DATA.CRITICAL_SUMINSURED}
                  />
                </Box>
              }
            />
            {ADD_FORM.spouse_status || ADD_FORM.children > 0 ? null : (
              <>
                {" "}
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="health Hospital Cash Benefit "
                  toggle_status={addonStatus.health_hospital_cash_benefit}
                  value_update={updateMasterState}
                  attrName={"health_hospital_cash_benefit"}
                  sub_details={
                    <Box maxWidth={"200px"}>
                      <SelectDropdown
                        title="Sum Insured"
                        value={
                          addonStatus.health_hospital_cash_benefit_sum_insured
                        }
                        value_update={updateMasterState}
                        attrName={"sub_health_hospital_cash_benefit"}
                        data={[
                          { value: "500", label: "500" },
                          { value: "1000", label: "1000" },
                          { value: "1500", label: "1500" },
                          { value: "2000", label: "2000" },
                        ]}
                      />
                    </Box>
                  }
                />
                <AddonQuestion
                  addon_amount=""
                  main_class="addonQuestion borderBottom"
                  icon_class="bonus"
                  question_name="Global Rider"
                  toggle_status={
                    !addonStatus.health_hospital_cash_benefit
                      ? addonStatus.health_hospital_cash_benefit
                      : addonStatus.isGlobalRider
                  }
                  value_update={updateMasterState}
                  attrName={"isGlobalRider"}
                  disable={!addonStatus.health_hospital_cash_benefit}
                />
              </>
            )}
          </Grid>
          <Grid xs={6}>
          <SelectDropdown
            class_name=" inputField mb-5"
            title={"Deductibles"}
            attrName={"deduction"}
            value={isDeduction_value}
            value_update={updateMasterState}
            data={DROPDOWN_DATA.DEDUCTION_DATA}
            warn_status={false}
            error_message={"Enter description"}
          />
        </Grid>
          <MFooter
            textName={PAGE_STATUS === true ? "Update" : "Continue"}
            attrName={"pageStatus"}
            forward={() => {
              validate();
            }}
            value={0}
            backward={() => {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
              );
            }}
          />
        </Box>
      )}
    </>
  );
};

export default MAddonDetailsOptimaSecure;
