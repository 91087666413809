import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Masonry } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../../../Component/CustomButton/CustomButton";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import MFooter from "../../../../../Component/MFooter/MFooter";
import MHealthPreviewDetailBox from "../../../../../Component/MHealthPreviewDetailBox/MHealthPreviewDetailBox";
import MHealthRedirectPopup from "../../../../../Component/MHealthRedirectPopup/MHealthRedirectPopup";
import { EMotorCompCode } from "../../../../../Enum/EMotorCompCode";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../Store/hooks";
import { COLORS } from "../../../../../SupportingFiles/colors";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../../../../SupportingFiles/HelpingFunction";
import MMedicalDetailPreviewBoxBajaj from "../../BAJAJ/MMedicalDetailPreviewBoxBajaj";
import MMedicalDetailPreviewBoxGoDigit from "../../GODIGIT/MMedicalDetailPreviewBoxGODigit/MMedicalDetailPreviewBoxGODigit";
import MMedicalDetailPreviewBoxOptimaSecure from "../../HDFC/MMedicalDetailPreviewBoxOptimaSecure";
import MMedicalDetailPreviewBoxOptimaRestore from "../../HDFC/MMedicalDetails/MMedicalDetailPreviewBoxOptimaRestore";
import MMedicalDetailPreviewBoxIcic from "../../ICIC/MMedicalDetailPreviewBoxIcic/MMedicalDetailPreviewBoxIcic";
import MMedicalDetailPreviewBoxKotak from "../../Kotak/MMedicalDetailKotak/MMedicalDetailPreviewBoxKotak/MMedicalDetailPreviewBoxKotak";
import MMedicalDetailPreviewBoxNivabupa from "../../NIVA_BUPA/medicalDetails/MMedicalDetailPreviewBoxNivabupa/MMedicalDetailPreviewBoxNivabupa";
import MMedicalDetailPreviewBoxNivabupaSen from "../../NIVA_BUPA/medicalDetails/MMedicalDetailPreviewBoxNivabupa/MMedicalDetailPreviewBoxNivabupaSen";
import MMedicalDetailPreviewBoxRSA from "../../RSA/MMedicalDetailPreviewBoxRSA";
import MMedicalDetailPreviewBoxStar from "../../STAR/MedicalDetailPreviewBox/MMedicalDetailPreviewBoxStar";
import MMedicalDetailPreviewBox from "../MMedicalDetailPreviewBox/MMedicalDetailPreviewBox";
import "./../../../../../SCSS/CommonSCSS/MProposalForms.scss";
import MMedicalDetailPreviewBoxAdityaBirla from "../../ADITYA_BIRLA/MMedicalDetails/MMedicalDetailPreviewBoxAdityaBirla";
const MReviewDetails = ({
  PreviewDetails,
  navigate,
  VERIFY_OTP,
  CREATE_PROPOSAL,
  loader,
  handleContinue,
  openRedirectPopup,
  setOpenRedirectPopup,
  paymentForm,
  paymentFormData,
}: {
  PreviewDetails: any;
  navigate: Function;
  VERIFY_OTP: Function;
  CREATE_PROPOSAL: Function;
  loader: boolean;
  handleContinue: Function;
  openRedirectPopup: boolean;
  setOpenRedirectPopup: Function;
  paymentForm: any;
  paymentFormData: any;
}) => {
  const { ADD_FORM, SELECTED_QUOTE_DATA, ADDRESS_DETAILS, QUOTE_LOADER } =
    useAppSelector((state) => state.Health);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const handleUpdateOTP = (attrName: any, value: any) => {
    setOtpValue(value);
  };
  const [quote_data, setquote_data] = useState<number>(0);
  const [totalChild, setTotalChild] = useState<number>(0);
  useEffect(() => {
    setquote_data(SELECTED_QUOTE_DATA?.premiumDetails.finalPremium);
  }, [SELECTED_QUOTE_DATA]);
  useEffect(() => {
    const total_child: number = ADD_FORM.children;

    setTotalChild(total_child);
  }, [ADD_FORM]);
  return (
    <Box className="proposalPreview">
      {/* OTP Modal start */}
      <Modal open={showOTPPopup} className="modalWrapperMobile mwMobile-kyc">
        <Box className="modalContent">
          <Grid container spacing={2}>
            <Grid xs={12} textAlign="center">
              <img
                alt=""
                src="../images/enter-otp.svg"
                width="140px"
                className="mt-6"
              />
              <h4 className="popup-heading mb-2 mt-6">Enter OTP</h4>
              <p className="grey_p">Enter the OTP to proceed</p>
              <Link
                to={""}
                className="close-button"
                onClick={() => {
                  setShowOTPPopup(false);
                  setOtpValue("");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={8} xsOffset={2}>
              <RKTextField
                class_name="inputField"
                title={"Enter OTP"}
                value={otpValue}
                attrName={"otpValue"}
                value_update={handleUpdateOTP}
                error_message={"Enter OTP"}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3}>
            <Grid xs={12} style={{ textAlign: "center" }}>
              <CustomButton
                text_name="Create Proposal"
                class_name="regularPrimaryBtn mb-3 mt-6"
                onClickFunction={VERIFY_OTP}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* OTP Modal End */}

      <Grid container className="row" spacing={0}>
        <Grid xs={12}>
          <h5 className="sectionTitle">Review Details</h5>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <Accordion
            className="policyDetails mb-3"
            sx={{
              boxShadow: "none",
              "&:before": {
                display: "none",
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreRoundedIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ padding: "0px" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "JioType",
                }}>
                <Box display="flex" gap="16px" marginTop="8px">
                  <img
                    src={SELECTED_QUOTE_DATA?.CompanyDetails?.logo}
                    alt=""
                    height="50px"
                  />
                  <Box>
                    {/* <p style={{ fontWeight: "700" }}>Care Health Insurance</p> */}
                    <p style={{ fontWeight: "700" }}>
                      {SELECTED_QUOTE_DATA.CompanyDetails.short_desc}
                    </p>
                    <p style={{ color: COLORS.lightgrey, fontSize: "12px" }}>
                      {/* Silver Smart */}
                      {SELECTED_QUOTE_DATA.productDetails?.product_name}
                    </p>
                  </Box>
                </Box>
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0px",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}>
              <Box>
                <h6 className="mb-2">
                  {ADD_FORM.spouse_status ? "2 Adults" : "1 Adult"}
                  <span className="p-1">
                    {totalChild === 0
                      ? null
                      : totalChild === 1
                        ? `,${totalChild} Child`
                        : `,${totalChild} Children`}
                  </span>
                </h6>

                <p className="small_p">
                  Cover Amount :
                  <span>
                    {" "}
                    {formatToCurrency(
                      Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured)
                    )}
                  </span>
                </p>
                <p className="small_p">
                  Policy Tenure :
                  <span>
                    {" "}
                    {SELECTED_QUOTE_DATA.premiumDetails.term === 1
                      ? "1 Year"
                      : `${SELECTED_QUOTE_DATA.premiumDetails.term} Year's`}
                  </span>
                </p>
                <p className="small_p">
                  Final Premium :
                  <span>
                    {" "}
                    {QUOTE_LOADER ? (
                      <img src="../../images/loader.svg" alt="" height="12px" />
                    ) : (
                      <>{formatAmountToCurrency(Number(quote_data))}</>
                    )}
                  </span>
                </p>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid xs={12}>
          <Masonry columns={1} spacing={2} sx={{ margin: "auto" }}>
            {PreviewDetails.map((data: any, index: any) => {
              return (
                <MHealthPreviewDetailBox
                  detailBoxName={data.title}
                  detailBoxData={data.detailView}
                  url={data.url === undefined ? "" : data.url}
                  isMailingAddresssame={ADDRESS_DETAILS.isMailingAddressSame}
                  mailing_detail_view={data.mailing_detail_view}
                />
              );
            })}
          </Masonry>

          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.CARE ? (
            <MMedicalDetailPreviewBox />
          ) : null}
          {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA &&
            SELECTED_QUOTE_DATA.productDetails.id === 2592) ||
            SELECTED_QUOTE_DATA.productDetails.id === 2593 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2594 ? (
            <MMedicalDetailPreviewBoxNivabupa />
          ) : null}
          {(ECompanyCode.NIVA_BUPA &&
            SELECTED_QUOTE_DATA.productDetails.id === 2890) ||
            SELECTED_QUOTE_DATA.productDetails.id === 2891 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2892 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2893 ? (
            <MMedicalDetailPreviewBoxNivabupaSen />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.KOTAK ? (
            <MMedicalDetailPreviewBoxKotak />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.ICIC ? (
            <MMedicalDetailPreviewBoxIcic />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.BAJAJ ? (
            <MMedicalDetailPreviewBoxBajaj />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.ADITY_BIRLA ? (
            <MMedicalDetailPreviewBoxAdityaBirla />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_SECURE ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_SECURE_Global ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_SECURE_Plan ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
            <MMedicalDetailPreviewBoxOptimaSecure />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_RESTORE ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
            <MMedicalDetailPreviewBoxOptimaRestore />
          ) : null}
          {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.RSA &&
            SELECTED_QUOTE_DATA.productDetails.id === 98) ||
            SELECTED_QUOTE_DATA.productDetails.id === 99 ||
            SELECTED_QUOTE_DATA.productDetails.id === 100 ? (
            <MMedicalDetailPreviewBoxRSA />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.GO_DIGIT ? (
            <MMedicalDetailPreviewBoxGoDigit />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.STAR_COMP_PRODUCT_ID ? (
            <MMedicalDetailPreviewBoxStar />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.STAR_FHO_PRODUCT_ID ? (
            // <MMedicalDetailPreviewBoxStarFHO />
            <MMedicalDetailPreviewBoxStar />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.START_RED_CARPET ? (
            // <MMedicalDetailPreviewBoxStarFHO />
            <MMedicalDetailPreviewBoxStar />
          ) : null}
          <MFooter
            loader={loader}
            attrName={"pageStatus"}
            textName={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G009"
                ? "Continue"
                : "Make Payment"
            }
            value={1}
            // forward={() => {
            //   CREATE_PROPOSAL();
            // }}

            forward={
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
                EMotorCompCode.RSA
                ? handleContinue
                : CREATE_PROPOSAL
            }
            backward={() => {
              navigate(-1);
            }}
          />
          <MHealthRedirectPopup
            open={openRedirectPopup}
            setOpen={setOpenRedirectPopup}
            form={paymentForm}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MReviewDetails;
