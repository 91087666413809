import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import NRIDetails from "../../../../../Page/Desktop/TermFullJourney/ICICI/Proposal/NRIDetails/NRIDetails";
import MNRIDetails from "../../../../../Page/Mobile/TermFullJourney/ICICI/Proposal/NRIDetails/MNRIDetails";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { NRIDetailsAPIDTO } from "../../../../../Services/DTO/TermFullJourney/NRIDetailsAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
  minLength2,
  minLength4,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import { TNRIDetails } from "../../../../../types/TermFullJourney/TNRIDetails";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../types/TermFullJourney/TProposerDetails";

function NRIDetailsContainer() {
  const isMobile = useIsMobile();
  const { proposerPage, proposerDetails } = useAppSelector(
    (state) => state.TermFullJourney
  );
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const NRI_Details = useAppSelector(
    (state) => state.TermFullJourney.NRIDetails
  );
  const [NRIDetail, setNRIDetail] = useState<TNRIDetails>(NRI_Details);

  const updateMasterState = (attrName: any, value: any) => {
    if (Array.isArray(attrName)) {
      if (attrName[1] === "status") {
        setNRIDetail((prev: any) => ({
          ...prev,
          [attrName[0]]: { ...prev[attrName[0]], [attrName[1]]: value },
        }));
      } else {
        setNRIDetail((prev: any) => ({
          ...prev,
          [attrName[0]]: {
            ...prev[attrName[0]],
            [attrName[1]]: { value: value, warning: isEmpty(value) },
          },
        }));
      }
    } else {
      if (attrName === "nationality_status") {
        setNRIDetail((prev) => ({
          ...prev,
          [attrName]: value,
          resident_status: value === "Indian" ? "Resident Indian" : "PIO",
        }));
      } else if (
        attrName === "resident_only_india" ||
        attrName === "birth_country_india" ||
        attrName === "resident_status" ||
        attrName === "FATCA_CRS_STATUS" ||
        attrName === "disclaimer_two"
      ) {
        setNRIDetail((prev) => ({
          ...prev,
          [attrName]: value,
        }));
      } else {
        setNRIDetail((prev) => ({
          ...prev,
          [attrName]: { value: value, warning: isEmpty(value) },
        }));
      }
    }
  };

  useEffect(() => {
    getCountryMaster();

    updateMasterState("tin_pan_issuing_country", "India");
    updateMasterState("tin_pan_other_country", proposerDetails.pan_no.value);
  }, []);

  const getCountryMaster = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        let data: TDropdown[] = response.map((obj: any) => {
          return {
            key: obj.name,
            value: obj.name,
          };
        });

        dispatch(TermFullJourneySlice.actions.setCountryMaster(data));
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getCountryMaster(onSuccess, onError);
  };

  const validate_form = () => {
    var hasError = false;
    var nri_details: TNRIDetails = { ...NRIDetail };

    nri_details = {
      ...nri_details,
      tin_pan_issuing_country: {
        ...nri_details.tin_pan_issuing_country,
        warning: isEmpty(nri_details.tin_pan_issuing_country.value),
      },
    };
    nri_details = {
      ...nri_details,
      tin_pan_other_country: {
        ...nri_details.tin_pan_other_country,
        warning: isEmpty(nri_details.tin_pan_other_country.value),
      },
    };
    nri_details = {
      ...nri_details,
      tin_pan_issuing_country_2: {
        ...nri_details.tin_pan_issuing_country_2,
        warning: isEmpty(nri_details.tin_pan_issuing_country_2.value),
      },
    };
    nri_details = {
      ...nri_details,
      tin_pan_other_country_2: {
        ...nri_details.tin_pan_other_country_2,
        warning: isEmpty(nri_details.tin_pan_other_country_2.value),
      },
    };
    nri_details = {
      ...nri_details,
      tin_pan_issuing_country_3: {
        ...nri_details.tin_pan_issuing_country_3,
        warning: isEmpty(nri_details.tin_pan_issuing_country_3.value),
      },
    };
    nri_details = {
      ...nri_details,
      tin_pan_other_country_3: {
        ...nri_details.tin_pan_other_country_3,
        warning: isEmpty(nri_details.tin_pan_other_country_3.value),
      },
    };

    if (!nri_details.birth_country_india) {
      nri_details = {
        ...nri_details,
        country_of_birth: {
          ...nri_details.country_of_birth,
          warning: isEmpty(nri_details.country_of_birth.value),
        },
      };
      nri_details = {
        ...nri_details,
        place_of_birth: {
          ...nri_details.place_of_birth,
          warning: isEmpty(nri_details.place_of_birth.value),
        },
      };
    }

    if (!nri_details.FATCA_CRS_STATUS) {
      toast.error("Please accept FATCA and CRS disclaimer");
      return;
    }

    if (
      nri_details.resident_status === "NRI" ||
      nri_details.resident_status === "PIO" ||
      nri_details.resident_status === "Foreign National"
    ) {
      var nriDetails = nri_details.nri_details;

      nriDetails = {
        ...nriDetails,
        country_of_nationality: {
          ...nriDetails.country_of_nationality,
          warning: isEmpty(nriDetails.country_of_nationality.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        country_of_residence: {
          ...nriDetails.country_of_residence,
          warning: isEmpty(nriDetails.country_of_residence.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        passport_number: {
          ...nriDetails.passport_number,
          warning: !minLength4(nriDetails.passport_number.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        purpose_of_stay_abroad: {
          ...nriDetails.purpose_of_stay_abroad,
          warning: !minLength2(nriDetails.purpose_of_stay_abroad.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        employer_university_details: {
          ...nriDetails.employer_university_details,
          warning: !minLength2(nriDetails.employer_university_details.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        date_of_arriving_india: {
          ...nriDetails.date_of_arriving_india,
          warning: isEmpty(nriDetails.date_of_arriving_india.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        date_of_leaving_india: {
          ...nriDetails.date_of_leaving_india,
          warning: isEmpty(nriDetails.date_of_leaving_india.value),
        },
      };
      nriDetails = {
        ...nriDetails,
        duration_of_stay_abroad_month: {
          ...nriDetails.duration_of_stay_abroad_month,
          warning: isEmpty(nriDetails.duration_of_stay_abroad_month.value),
        },
        duration_of_stay_abroad_year: {
          ...nriDetails.duration_of_stay_abroad_year,
          warning: isEmpty(nriDetails.duration_of_stay_abroad_year.value),
        },
      };

      if (nri_details.resident_status === "Foreign National") {
        nriDetails = {
          ...nriDetails,
          intended_duration_of_stay_month: {
            ...nriDetails.intended_duration_of_stay_month,
            warning: isEmpty(nriDetails.intended_duration_of_stay_month.value),
          },
          intended_duration_of_stay_year: {
            ...nriDetails.intended_duration_of_stay_year,
            warning: isEmpty(nriDetails.intended_duration_of_stay_year.value),
          },
        };
      }

      nri_details = { ...nri_details, nri_details: nriDetails };
    }

    if (nri_details.nri_travel_details.status) {
      var nri_travel_details = nri_details.nri_travel_details;

      nri_travel_details = {
        ...nri_travel_details,
        name_of_country: {
          ...nri_travel_details.name_of_country,
          warning: isEmpty(nri_travel_details.name_of_country.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        name_of_city: {
          ...nri_travel_details.name_of_city,
          warning: !minLength2(nri_travel_details.name_of_city.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        arrival_date: {
          ...nri_travel_details.arrival_date,
          warning: isEmpty(nri_travel_details.arrival_date.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        departure_date: {
          ...nri_travel_details.departure_date,
          warning: isEmpty(nri_travel_details.departure_date.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        visa_type: {
          ...nri_travel_details.visa_type,
          warning: !minLength2(nri_travel_details.visa_type.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        purpose_of_travel: {
          ...nri_travel_details.purpose_of_travel,
          warning: isEmpty(nri_travel_details.purpose_of_travel.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        type_residence: {
          ...nri_travel_details.type_residence,
          warning: isEmpty(nri_travel_details.type_residence.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        mode_of_travel: {
          ...nri_travel_details.mode_of_travel,
          warning: isEmpty(nri_travel_details.mode_of_travel.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        name_of_business: {
          ...nri_travel_details.name_of_business,
          warning: !minLength2(nri_travel_details.name_of_business.value),
        },
      };
      nri_travel_details = {
        ...nri_travel_details,
        safety_precaution: {
          ...nri_travel_details.safety_precaution,
          warning: !minLength2(nri_travel_details.safety_precaution.value),
        },
      };

      nri_details = { ...nri_details, nri_travel_details: nri_travel_details };
    }

    nri_details = {
      ...nri_details,
      phone_email: {
        ...nri_details.phone_email,
        mobile_number: {
          ...nri_details.phone_email.mobile_number,
          warning: isEmpty(nri_details.phone_email.mobile_number.value),
        },
        country_code: {
          ...nri_details.phone_email.country_code,
          warning: isEmpty(nri_details.phone_email.country_code.value),
        },
      },
    };

    nri_details = {
      ...nri_details,
      type_bank_account_held: {
        ...nri_details.type_bank_account_held,
        account_number: {
          ...nri_details.type_bank_account_held.account_number,
          warning: !minLength2(
            nri_details.type_bank_account_held.account_number.value
          ),
        },
      },
    };

    nri_details = {
      ...nri_details,
      aadhar_details: {
        ...nri_details.aadhar_details,
        doc_no: {
          ...nri_details.aadhar_details.doc_no,
          warning: isEmpty(nri_details.aadhar_details.doc_no.value),
        },
        option: {
          ...nri_details.aadhar_details.option,
          warning: isEmpty(nri_details.aadhar_details.option.value),
        },
      },
    };

    if (!nri_details.disclaimer_two) {
      toast.error("Please accept NRI Disclaimers");
      return;
    }

    setNRIDetail(nri_details);

    if (
      nri_details.tin_pan_issuing_country_2.warning === true ||
      nri_details.tin_pan_other_country_2.warning === true ||
      nri_details.tin_pan_issuing_country_3.warning === true ||
      nri_details.tin_pan_other_country_3.warning === true
    ) {
      hasError = true;
    }

    if (!nri_details.birth_country_india) {
      if (
        nri_details.country_of_birth.warning === true ||
        nri_details.place_of_birth.warning === true
      ) {
        hasError = true;
      }
    }

    if (
      nri_details.resident_status === "NRI" ||
      nri_details.resident_status === "PIO" ||
      nri_details.resident_status === "Foreign National"
    ) {
      var nriDetails = nri_details.nri_details;

      if (
        nriDetails.country_of_nationality.warning === true ||
        nriDetails.country_of_residence.warning === true ||
        nriDetails.passport_number.warning === true ||
        nriDetails.purpose_of_stay_abroad.warning === true ||
        nriDetails.employer_university_details.warning === true ||
        nriDetails.date_of_arriving_india.warning === true ||
        nriDetails.date_of_leaving_india.warning === true ||
        nriDetails.duration_of_stay_abroad_month.warning === true ||
        nriDetails.duration_of_stay_abroad_year.warning === true
      ) {
        hasError = true;
      }

      if (nri_details.resident_status === "Foreign National") {
        if (
          nriDetails.intended_duration_of_stay_month.warning === true ||
          nriDetails.intended_duration_of_stay_year.warning === true
        ) {
          hasError = true;
        }
      }
    }

    if (nri_details.nri_travel_details.status) {
      var nri_travel_details = nri_details.nri_travel_details;

      if (
        nri_travel_details.name_of_country.warning === true ||
        nri_travel_details.name_of_city.warning === true ||
        nri_travel_details.arrival_date.warning === true ||
        nri_travel_details.departure_date.warning === true ||
        nri_travel_details.visa_type.warning === true ||
        nri_travel_details.purpose_of_travel.warning === true ||
        nri_travel_details.type_residence.warning === true ||
        nri_travel_details.mode_of_travel.warning === true ||
        nri_travel_details.name_of_business.warning === true ||
        nri_travel_details.safety_precaution.warning === true
      ) {
        hasError = true;
      }
    }

    if (
      nri_details.phone_email.mobile_number.warning === true ||
      nri_details.phone_email.country_code.warning === true
    ) {
      hasError = true;
    }

    if (nri_details.type_bank_account_held.account_number.warning === true) {
      hasError = true;
    }

    if (
      nri_details.aadhar_details.doc_no.warning === true ||
      nri_details.aadhar_details.option.warning === true
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(TermFullJourneySlice.actions.setNRIDetails(nri_details));
      updateNRIDetailsData(nri_details);
    }
  };

  const updateNRIDetailsData = (nri_details: TNRIDetails) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_FULL_JOURNEY_ROUTES.NOMINEE_DETAILS);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    const data: NRIDetailsAPIDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.NRI_DETAILS,
      details: {
        passportNo: nri_details.nri_details.passport_number.value,
        dateOfArrivingIndia: FORMAT_DATE_DD_MM_YYYY(
          nri_details.nri_details.date_of_arriving_india.value
        ),
        dateOfLeavingIndia: FORMAT_DATE_DD_MM_YYYY(
          nri_details.nri_details.date_of_leaving_india.value
        ),
        durationOfStayInYear:
          nri_details.nri_details.duration_of_stay_abroad_year.value,
        durationOfStayInMonth:
          nri_details.nri_details.duration_of_stay_abroad_month.value,
        nameOfEmployer: nri_details.nri_travel_details.name_of_business.value,
        travelDetails: "",
        bankType: nri_details.type_bank_account_held.status,
        nreNroAccountNum:
          nri_details.type_bank_account_held.account_number.value,
        nriTINIssuingCountry: nri_details.tin_pan_issuing_country.value,
        nriTINIssuingCountry2: nri_details.tin_pan_other_country_2.value,
        nriTINIssuingCountry3: nri_details.tin_pan_other_country_3.value,
        currentresidence: nri_details.resident_status,
        tinNum: nri_details.tin_pan_other_country.value,
        tinNum2: nri_details.tin_pan_other_country_2.value,

        tinNum3: nri_details.tin_pan_other_country_3.value,

        nriBirthCountry: nri_details.country_of_birth.value,
        placeOfBirthNRI: nri_details.place_of_birth.value,

        countryOfResidence: nri_details.nri_details.country_of_residence.value,

        nriOtherResCountry: nri_details.country_of_birth.value,

        countryOfNationality:
          nri_details.nri_details.country_of_nationality.value,
        nriTaxResidentUS:
          nri_details.resident_only_india === true ? "Yes" : "No",

        purposeOfStay: nri_details.nri_details.purpose_of_stay_abroad.value,

        intentedDurationStayInYear:
          nri_details.nri_details.intended_duration_of_stay_year.value,

        intentedDurationStayInMonth:
          nri_details.nri_details.intended_duration_of_stay_month.value,

        travelInLast1Year:
          nri_details.nri_travel_details.status === true ? "Yes" : "No",
        nriTravelDetails: [
          {
            arrivalDate: FORMAT_DATE_DD_MM_YYYY(
              nri_details.nri_travel_details.arrival_date.value
            ),

            city: nri_details.nri_travel_details.name_of_city.value,

            departureDate: nri_details.nri_travel_details.departure_date.value,

            nameOfCountry: nri_details.nri_travel_details.name_of_country.value,
            purposeOfTravel:
              nri_details.nri_travel_details.purpose_of_travel.value,
            typeOfResidence:
              nri_details.nri_travel_details.type_residence.value,
            visaType: nri_details.nri_travel_details.visa_type.value,
          },
        ],
        modeOfTravel: nri_details.nri_travel_details.mode_of_travel.value,
        natureOfBusiness: nri_details.nri_travel_details.name_of_business.value,
        safetyPrecaution:
          nri_details.nri_travel_details.safety_precaution.value, //
      },
    };

    PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(onSuccess, onError, data);
  };
  return (
    <>
      {isMobile ? (
        <MNRIDetails
          loader={loader}
          continue_function={validate_form}
          updateMasterState={updateMasterState}
          NRIDetails={NRIDetail}
        />
      ) : (
        <NRIDetails
          loader={loader}
          continue_function={validate_form}
          updateMasterState={updateMasterState}
          NRIDetails={NRIDetail}
        />
      )}
    </>
  );
}

export default NRIDetailsContainer;
