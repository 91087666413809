import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "../../../Component/Footer/Footer.scss";
import CustomButton from "../../CustomButton/CustomButton";

const TermFooter = ({
  forward,
  disableButton,
  textName = "Continue",
  back_text = "Back",
  loader = false,
}: {
  forward?: Function;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
  back_text?: string;
}) => {
  const navigate = useNavigate();
  return (
    <Box className="proposalFooter">
      <Grid container spacing={3}>
        <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon /> {back_text}
          </Link>
          <div className="ctaBtn">
            <CustomButton
              text_name={textName}
              class_name="regularPrimaryBtn"
              onClickFunction={() => {
                if (forward) {
                  forward();
                }
              }}
              loading={loader}
              disabled={disableButton}
            />
            {/* This button will display only on preview page */}
            {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermFooter;
