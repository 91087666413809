import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { addYears, subDays } from "date-fns";
import * as React from "react";
import "../../Component/LeftSidebar/LeftSidebar.scss";
import { EMotorCompCode } from "../../Enum/EMotorCompCode";
import { useAppSelector } from "../../Store/hooks";
import { calculate_premium_for_car } from "../../SupportingFiles/CarPermiumCal";
import {
  CAL_NEW_NCB,
  FORMAT_DATE_DD_MM_YYYY,
  GET_CURRENT_DATE,
  addDaysToDate,
  addYearsToDate,
  calculateAgeInDays,
  formatAmountToCurrency,
  formatLicensePlate,
  formatNumberWithCurrencySymbol,
} from "../../SupportingFiles/HelpingFunction";

const CarLeftSidebar = () => {
  const {
    ADD_FORM,
    SELECTED_QUOTE_DATA,
    ADDON_STATUS,
    QUOTE_LOADER,
    ADDON_USER_VALUE,
    PLAN_TYPE,
  } = useAppSelector((state) => state.Car);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const [premium, setPremium] = React.useState<number>(0);

  React.useEffect(() => {
    const premium_details = SELECTED_QUOTE_DATA.quotationDetail;

    if (premium_details) {
      const premium_with_tax = calculate_premium_for_car(
        premium_details,
        ADDON_STATUS,
        ADDON_USER_VALUE.passengerCover.value,
        PLAN_TYPE,
        VERIFY_KYC_FORM.customer_type.value,
        ADD_FORM.reg_date.value
      );
      setPremium(premium_with_tax);
    }
  }, [SELECTED_QUOTE_DATA, ADDON_STATUS, VERIFY_KYC_FORM.customer_type.value]);

  const [policyPeriod, setPolicyPeriod] = React.useState("");

  React.useEffect(() => {
    if (PLAN_TYPE === "Bundle Policy") {
      const value = `${GET_CURRENT_DATE()} To ${FORMAT_DATE_DD_MM_YYYY(
        subDays(addYears(new Date(), 3), 1)
      )}`;
      setPolicyPeriod(value);
    } else {
      if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.ICICI ||
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.GO_DIGIT
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            2
          )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 1), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
        EMotorCompCode.BAJAJ
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            1
          )} To ${addYearsToDate(GET_CURRENT_DATE(), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
        EMotorCompCode.Kotak
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            3
          )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 2), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else if (
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
        EMotorCompCode.TATA
      ) {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          if (PLAN_TYPE === "Third Party") {
            const value = ` ${addDaysToDate(
              GET_CURRENT_DATE(),
              1
            )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 2), 1)}`;
            setPolicyPeriod(value);
          } else {
            const value = ` ${addDaysToDate(
              GET_CURRENT_DATE(),
              0
            )} To ${addYearsToDate(addDaysToDate(GET_CURRENT_DATE(), 2), 1)}`;
            setPolicyPeriod(value);
          }
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      } else {
        if (calculateAgeInDays(ADD_FORM.policy_expiry_date.value) > 0) {
          const value = ` ${addDaysToDate(
            GET_CURRENT_DATE(),
            1
          )} To ${addYearsToDate(GET_CURRENT_DATE(), 1)}`;
          setPolicyPeriod(value);
        } else {
          const value = `${addDaysToDate(
            ADD_FORM.policy_expiry_date.value,
            1
          )} To ${addYearsToDate(ADD_FORM.policy_expiry_date.value, 1)}`;
          setPolicyPeriod(value);
        }
      }
    }
  }, []);

  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"carIcon"}></Box>

      <h5 className="vehicle_number mb-4">
        {PLAN_TYPE === "Bundle Policy"
          ? ADD_FORM.rto.value
          : formatLicensePlate(ADD_FORM.reg_no.value)}
      </h5>

      <h5>{`${ADD_FORM.make_model.value}`}</h5>
      <h6 className="mb-6">{`${ADD_FORM.fuel_type.value}, ${ADD_FORM.variant_cc.value}`}</h6>

      <img
        alt=""
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.logo}
      />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_name}
      </h6>

      {PLAN_TYPE === "Third Party" ? null : (
        <Box>
          <h5>
            {formatAmountToCurrency(
              Number(SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails.IDV)
            )}
          </h5>
          <p className="mb-6">{"IDV"}</p>
        </Box>
      )}
      <h5>{policyPeriod}</h5>
      <p className="mb-8">Policy Period</p>
      {PLAN_TYPE === "Third Party" ? null : (
        <Box className="mb-6">
          <h5 className="ncb">
            {PLAN_TYPE === "Bundle Policy"
              ? "0"
              : CAL_NEW_NCB(
                  ADD_FORM.prev_ncb,
                  ADD_FORM.claimed,
                  ADD_FORM.previous_insurer.value,
                  ADD_FORM.policy_expiry_date.value
                )}
            %
          </h5>
          <p>New NCB</p>
        </Box>
      )}
      <h5 className="premium">
        {QUOTE_LOADER ? (
          <img src="../../images/loader.svg" alt="" height="12px" />
        ) : (
          <>{formatNumberWithCurrencySymbol(premium)}</>
        )}
      </h5>
      <p className="mb-8">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default CarLeftSidebar;
