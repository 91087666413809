import { useEffect } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import PolicyConfirm from "../../../../Page/Desktop/TermFullJourney/ICICI/PolicyConfirm/PolicyConfirm";
import MPolicyConfirm from "../../../../Page/Mobile/TermFullJourney/ICICI/PolicyConfirm/MPolicyConfirm";

function PolicyConfirmationPageContainer() {
  useEffect(() => {
    sessionStorage.clear();
  });
  const isMobile = useIsMobile();
  return <>{isMobile ? <MPolicyConfirm /> : <PolicyConfirm />}</>;
}

export default PolicyConfirmationPageContainer;
