import { Route, Routes } from "react-router";
import IffcoTokioCKYCContainer from "../../../../../Container/CKYC/IffcoTokio/IffcoTokioCKYCContainer";
import IffcoTokioManualKYCContainer from "../../../../../Container/CKYC/IffcoTokio/IffcoTokioManualKYCContainer";
import AddressDetailsContainer from "../../../../../Container/TW/IffcoTokio/AddressDetailsContainer";
import NomineeDetailsContainer from "../../../../../Container/TW/Common/NomineeDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/TW/Common/ReviewDetailsContainer";
import VehicleDetailsContainer from "../../../../../Container/TW/Common/VehicleDetailsContainer";
import OwnerDetailsContainer from "../../../../../Container/TW/IffcoTokio/OwnerDetailsContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { TW_ROUTES } from "../../../../Path/TWRoutes";
import TWProposalContainer from "../../TWProposalContainer";

export const IFFCO_TOKIO_ROUTES = (
  <Routes>
    <Route path={TW_ROUTES.PROPOSAL_PAGE} element={<TWProposalContainer />}>
      <Route path={CKYC_ROUTES.CKYC} element={<IffcoTokioCKYCContainer />} />
      <Route
        path={CKYC_ROUTES.MANUAL_CKYC}
        element={<IffcoTokioManualKYCContainer />}
      />
      <Route
        path={TW_ROUTES.OWNER_DETAILS}
        element={<OwnerDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.VEHICLE_DETAILS}
        element={<VehicleDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.PREVIEW_DEATILS}
        element={<ReviewDetailsContainer />}
      />
    </Route>
  </Routes>
);
