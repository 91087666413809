import React, { useEffect, useState } from "react";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import MedicalDetailNivaBupa from "../../../Page/Desktop/Health/NIVA_BUPA/medicalDetails/MedicalDetailNivaBupa";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { useNavigate } from "react-router-dom";
import { TNivaBupaMedicalQuestion } from "../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import MedicalDetail from "../../../Page/Desktop/Health/BAJAJ/MedicalDetails";
import { TBajajMedicalQuestion } from "../../../types/Health/HealthQuotation/TBajajMedicalQuestion";
import MMedicalDetails from "../../../Page/Mobile/Health/BAJAJ/MMedicalDetails";

const MedicalDetailContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_NIVA_BUPA,
    MEDICAL_QUESTION_DATA_BAJAJ,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state) => state.Health);
  const [change_status, setchange_status] = useState(false);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TBajajMedicalQuestion>(MEDICAL_QUESTION_DATA_BAJAJ);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_BAJAJ };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Has any of the persons to be insured suffer from/or investigated for any of the following? Disorder of the heart, or circulatory system, chest pain, high blood pressure, stroke, asthma any respiratory conditions, cancer tumor lump of any kind, diabetes, hepatitis, disorder of urinary tract or kidneys, blood disorder, any mental or psychiatric conditions, any disease of brain or nervous system, fits (epilepsy) slipped disc, backache, any congenital/ birth defects/ urinary diseases, AIDS or positive HIV, If yes, indicate in the table given below.If yes please provide details",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Hypertension",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {},
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {},
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Cholesterol Disorder",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {},
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {},
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "Cardiovascular diseases",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {},
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {},
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {},
                },
              },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Intoxication Declaration",
          description:
            "Select 'Yes' if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?",
          status: false,
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Does any of the members consume Alcohol?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Does any of the members consume smoking?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "Does any of the members consume tobacco?",
              description: "",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F1": {
                      id: "MQ1-SQ1-F1",
                      title: "Month",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Year",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title:
            "Do you or any of the family members to be covered have/had any health complaints/met with any accident in the past 4 years and prior to 4 years and have been taking treatment, regular medication (self/ prescribed)or planned for any treatment / surgery / hospitalization?",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title:
            "Have you or any of your immediate family members (father, mother, brother or sister) have/ had diabetes, hypertension,cancer, heart attack, or stroke and at What age? If yes, was it before age 60 years or after 60 years",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title:
            "Has any proposal for life, critical illness or health related insurance on your life or lives ever been postponed, declined or accepted on special terms? If yes, give details",
          description: "",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  console.log(medicalQuestionData, "medicalQuestionData");

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_BAJAJ);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_BAJAJ]);

  let flag = false;

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;

    // console.log('sss',flag)
    console.log(attrName, "attrName", value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false) {
        if (["MQ01", "MQ02"].includes(attrName[1])) {
          // Set all subquestions to false for MQ01 and MQ02
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question
          ).forEach((subQuestionKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              subQuestionKey
            ].status = false;
          });
        } else if (["MQ03", "MQ04", "MQ05"].includes(attrName[1])) {
          // Set selection status of all member data to false for MQ03, MQ04, MQ05
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.member_data
          ).forEach((key) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
              key
            ].selection_status = false;
          });
        }
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      setchange_status(flag);

      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
          ],
          status: value,
        },
      };
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };
      if (value === false) {
        // Set mainMember and mainMemberDataField to false and empty
        Object.keys(
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data
        ).forEach((memberDataKey) => {
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[memberDataKey].selection_status = false;
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[memberDataKey].field_data
          ).forEach((fieldDataKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[memberDataKey].field_data[fieldDataKey].value = {
              value: "",
              warning: false,
            };
          });
        });
        manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
          false;
        manipulatedMedicalQuestion[
          attrName[1]
        ].main_question.mainMemberDataField = "";
      }
      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMember") {
      console.log(
        "sdfgfghg",
        attrName,
        manipulatedMedicalQuestion[attrName[1]]
      );
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };

        if (value === false) {
          // Set mainMember and mainMemberDataField to false and empty
          manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ].member_data[attrName[3]].selection_status = false;
          Object.keys(
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[attrName[3]].field_data
          ).forEach((fieldDataKey) => {
            manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
              attrName[2]
            ].member_data[attrName[3]].field_data[fieldDataKey].value = {
              value: "",
              warning: false,
            };
          });
          manipulatedMedicalQuestion[attrName[1]].main_question.mainMember =
            false;
          manipulatedMedicalQuestion[
            attrName[1]
          ].main_question.mainMemberDataField = "";
        }

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (
      attrName[0] === "mainMemberDataField" &&
      attrName[1] === "MQ02"
    ) {
      manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
        attrName[4]
      ].member_data[attrName[2]].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    let data: TBajajMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status) {
        const memberData = mainQuestion.member_data;
        if (memberData) {
          Object.values(memberData).forEach((member) => {
            if (member.selection_status && member.field_data) {
              Object.values(member.field_data).forEach((field) => {
                if (field.value?.value === "") {
                  field.value = {
                    ...field.value,
                    warning: true,
                  };
                  hasError = true;
                }
              });
            }
          });
        }
      }
    });
    setMedicalQuestionData(data);
    console.log("hasError", hasError);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_BAJAJ(data));
      const onSuccess = (res: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
      console.log(
        data.MQ03.main_question.member_data?.adult_one,
        '  data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]'
      );
      const param = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: "G001",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medicalPreExistingDiseases:
          data.MQ01.main_question.status === true ? "Yes" : "NO", //Pre-Existing Diseases (Has any of the persons to be insured suffer from/or investigated for any of the following? Disorder of the heart, or circulatory system, chest pain, high blood pressure, stroke, asthma any respiratory conditions, cancer tumor lump of any kind, diabetes, hepatitis, disorder of urinary tract or kidneys, blood disorder, any mental or psychiatric conditions, any disease of brain or nervous system, fits (epilepsy) slipped disc, backache, any congenital/ birth defects/ urinary diseases, AIDS or positive HIV, If yes, indicate in the table given below.If yes please provide details)
        medicalEndocrineDiseases:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.status === true // Hypertension
            ? "Yes"
            : "NO",
        medicalEndocrine:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalEndocrine:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalEndocrine:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalEndocrine:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalEndocrine:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalEndocrine:
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        medicalProlongedDiseases:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.status === true //  Cholesterol Disorder
            ? "Yes"
            : "NO",
        medicalProlonged:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalProlonged:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalProlonged:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalProlonged:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalProlonged:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalProlonged:
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",
        medicalHIVDiseases:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.status === true // Cardiovascular diseases
            ? "Yes"
            : "NO",
        medicalHIV:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "No",
        spouse_medicalHIV:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "No",
        child1_medicalHIV:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "No",
        child2_medicalHIV:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "No",
        child3_medicalHIV:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "No",
        child4_medicalHIV:
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "No",

        personalHabitDetailsDiseases:
          data.MQ02.main_question.status === true ? "Yes" : "NO", //Intoxication Declaration(Select "Yes" if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?)

        medicalAlcoholDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "NO",
        spouse_medicalAlcoholDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "NO",
        child1_medicalAlcoholDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "NO",
        child2_medicalAlcoholDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "NO",
        child3_medicalAlcoholDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "NO",
        child4_medicalAlcoholDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "NO",
        alcohol_month: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        alcohol_year: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        spouse_alcohol_month: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_alcohol_year: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child1_alcohol_month: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child1_alcohol_year: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child2_alcohol_month: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child2_alcohol_year: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child3_alcohol_month: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child3_alcohol_year: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child4_alcohol_month: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child4_alcohol_year: `${data?.MQ02?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value}`,

        medicalSmokingDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.status === true // Does any of the members consume smoking?
            ? "Yes"
            : "NO",

        medicalSmoking:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "NO",
        spouse_medicalSmoking:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "NO",
        child1_medicalSmoking:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "NO",
        child2_medicalSmoking:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "NO",
        child3_medicalSmoking:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "NO",
        child4_medicalSmoking:
          data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "NO",

        smoking_month: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        spouse_smoking_month: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child1_smoking_month: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child1_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child2_smoking_month: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child2_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child3_smoking_month: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child3_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child4_smoking_month: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child4_smoking_year: `${data?.MQ02?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value}`,

        medicalTobaccoDiseases:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.status === true //Does any of the members consume tobacco?
            ? "Yes"
            : "NO",

        medicalTobacco:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one.selection_status === true
            ? "Yes"
            : "NO",
        spouse_medicalTobacco:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two.selection_status === true
            ? "Yes"
            : "NO",
        child1_medicalTobacco:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one.selection_status === true
            ? "Yes"
            : "NO",
        child2_medicalTobacco:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two.selection_status === true
            ? "Yes"
            : "NO",
        child3_medicalTobacco:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three.selection_status === true
            ? "Yes"
            : "NO",
        child4_medicalTobacco:
          data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four.selection_status === true
            ? "Yes"
            : "NO",

        tobacco_month: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        tobacco_year: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        spouse_tobacco_month: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        spouse_tobacco_year: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child1_tobacco_month: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child1_tobacco_year: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_one.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child2_tobacco_month: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child2_tobacco_year: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child3_tobacco_month: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child3_tobacco_year: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_three.field_data?.["MQ1-SQ1-F2"].value?.value}`,
        child4_tobacco_month: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F1"].value?.value}`,
        child4_tobacco_year: `${data?.MQ02?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_four.field_data?.["MQ1-SQ1-F2"].value?.value}`,

        medicalCardiacDiseases:
          data.MQ03.main_question.status === true ? "Yes" : "NO", //Do you or any of the family members to be covered have/had any health complaints/met with any accident in the past 4 years and prior to 4 years and have been taking treatment, regular medication (self/ prescribed)or planned for any treatment / surgery / hospitalization?
        medicalCardiac:
          data.MQ03.main_question.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        spouse_medicalCardiac:
          data.MQ03.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child1_medicalCardiac:
          data.MQ03.main_question.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        child2_medicalCardiac:
          data.MQ03.main_question.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child3_medicalCardiac:
          data.MQ03.main_question.member_data?.child_three.selection_status ===
            true
            ? "Yes"
            : "NO",
        child4_medicalCardiac:
          data.MQ03.main_question.member_data?.child_four.selection_status ===
            true
            ? "Yes"
            : "NO",
        medicalJointDiseases:
          data.MQ04.main_question.status === true ? "Yes" : "NO", //Have you or any of your immediate family members (father, mother, brother or sister) have/ had diabetes, hypertension,cancer, heart attack, or stroke and at What age? If yes, was it before age 60 years or after 60 years
        medicalJoint:
          data.MQ04.main_question.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        spouse_medicalJoint:
          data.MQ04.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child1_medicalJoint:
          data.MQ04.main_question.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        child2_medicalJoint:
          data.MQ04.main_question.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child3_medicalJoint:
          data.MQ04.main_question.member_data?.child_three.selection_status ===
            true
            ? "Yes"
            : "NO",
        child4_medicalJoint:
          data.MQ04.main_question.member_data?.child_four.selection_status ===
            true
            ? "Yes"
            : "NO",
        medicalKidneyDiseases:
          data.MQ05.main_question.status === true ? "Yes" : "NO", //Has any proposal for life, critical illness or health related insurance on your life or lives ever been postponed, declined or accepted on special terms? If yes, give details
        medicalKidney:
          data.MQ05.main_question.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        spouse_medicalKidney:
          data.MQ05.main_question.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child1_medicalKidney:
          data.MQ05.main_question.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "NO",
        child2_medicalKidney:
          data.MQ05.main_question.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "NO",
        child3_medicalKidney:
          data.MQ05.main_question.member_data?.child_three.selection_status ===
            true
            ? "Yes"
            : "NO",
        child4_medicalKidney:
          data.MQ05.main_question.member_data?.child_four.selection_status ===
            true
            ? "Yes"
            : "NO",
      };
      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    }
  };

  let data: TBajajMedicalQuestion = JSON.parse(
    JSON.stringify(medicalQuestionData)
  );

  return (
    <>
      {isMobile ? (
        <MMedicalDetails
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <MedicalDetail
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalDetailContainer;
