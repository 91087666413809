import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../../../../Store/hooks";
import { TCKYCDetails } from "../../../../../../types/TermFullJourney/TCKYCDetails";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

const MCKYCDetails = ({
  loader,
  continueFunction,
  updateMasterState,
  formData,
}: {
  loader: boolean;
  continueFunction: Function;
  updateMasterState: Function;
  formData: TCKYCDetails;
}) => {
  const { dropdownData } = useAppSelector((state) => state.TermFullJourney);

  return (
    <Box>
      <h5 className="sectionTitle">CKYC/eKYC Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="mb-4">Provide CKYC Details for Proposer</h6>
          <p className="textGrey">
            <span className="textBold">
              CKYC refers to Central KYC (Know Your Customer)
            </span>
            , an initiative of the Government of India. The aim of this
            initiative is to have a structure in place which allows investors to
            complete their KYC only once before interacting with various
            entities across the financial sector.
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="Age Proof"
            value={formData.age_proof.value}
            attrName={"age_proof"}
            value_update={updateMasterState}
            data={dropdownData.ageProf}
            warn_status={formData.age_proof.warning}
            error_message="Select age proof"
          />
        </Grid>
        {formData.age_proof.value === "Adhr" ? null : (
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Age Proof. ID no."}
              value={formData.age_proof_doc_no.value}
              attrName={"age_proof_doc_no"}
              value_update={updateMasterState}
              warn_status={formData.age_proof_doc_no.warning}
              error_message="Enter age prrof. ID no."
            />
          </Grid>
        )}
        {formData.age_proof.value === "DL" ||
          formData.age_proof.value === "PSPT" ? (
          <Grid xs={12}>
            <DatePicker
              class_name="inputField"
              title={"Age Proof Expiry Date"}
              value={formData.age_proof_exp_date.value}
              attrName={"age_proof_exp_date"}
              value_update={updateMasterState}
              error_message="Select age proof. expiry date."
              warn_status={formData.age_proof_exp_date.warning}
            />
          </Grid>
        ) : null}
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="Address Proof"
            value={formData.address_proof.value}
            attrName={"address_proof"}
            value_update={updateMasterState}
            data={dropdownData.addressProf}
            warn_status={formData.address_proof.warning}
            error_message="Select address proof"
          />
        </Grid>
        {formData.address_proof.value === "Adhr" ? null : (
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Address Proof. ID no."}
              value={formData.address_proof_doc_no.value}
              attrName={"address_proof_doc_no"}
              value_update={updateMasterState}
              warn_status={formData.address_proof_doc_no.warning}
              error_message="Enter address proof. ID no."
            />
          </Grid>
        )}
        {formData.address_proof.value === "DL" ||
          formData.address_proof.value === "PSPT" ? (
          <Grid xs={12}>
            <DatePicker
              class_name="inputField"
              title={"Address Proof Expiry Date"}
              value={formData.address_proof_exp_date.value}
              attrName={"address_proof_exp_date"}
              value_update={updateMasterState}
              error_message="Select address proof. expiry date."
              warn_status={formData.address_proof_exp_date.warning}
            />
          </Grid>
        ) : null}
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="Identity Proof"
            value={formData.identity_proof.value}
            attrName={"identity_proof"}
            value_update={updateMasterState}
            data={dropdownData.IDENTITY_PROOF_MASTER}
            warn_status={formData.identity_proof.warning}
            error_message="Select identity proof."
          />
        </Grid>
        {formData.identity_proof.value === "Adhr" ? null : (
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={"Identity Proof. ID no."}
              value={formData.identity_proof_doc_no.value}
              attrName={"identity_proof_doc_no"}
              value_update={updateMasterState}
              warn_status={formData.identity_proof_doc_no.warning}
              error_message="Enter identity prrof. ID no."
            />
          </Grid>
        )}
        {formData.identity_proof.value === "DL" ||
          formData.identity_proof.value === "PSPT" ? (
          <Grid xs={12}>
            <DatePicker
              class_name="inputField"
              title={"Identity Proof Expiry Date"}
              value={formData.identity_proof_exp_date.value}
              attrName={"identity_proof_exp_date"}
              value_update={updateMasterState}
              error_message="Select identity proof. expiry date."
              warn_status={formData.identity_proof_exp_date.warning}
            />
          </Grid>
        ) : null}
        <Grid container spacing={12}>
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="IT Proof"
              value={formData.it_proof.value}
              attrName={"it_proof"}
              value_update={updateMasterState}
              data={dropdownData.IT_PROOF_MASTER}
              warn_status={formData.it_proof.warning}
              error_message="Select IT Proof."
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer continueFunction={continueFunction} />
    </Box>
  );
};

export default MCKYCDetails;
