import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { THDFCCKYCDetails } from "../../../../types/TermFullJourney/TCKYCDetails";

const SET_HDFC_CKYC_DETAILS = (
  state: TTermFullJourneySlice,
  action: PayloadAction<THDFCCKYCDetails>
) => {
  const data: TTermFullJourneySlice = {
    ...state,
    HDFCCKYCDetails: { ...action.payload },
  };
  return data;
};

export const TERM_HDFC_CKYC_REDUCER = {
  SET_HDFC_CKYC_DETAILS,
};
