import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChildTwoDetails from "../../../../Page/Desktop/Health/CARE/ChildTwoDetails/ChildTwoDetails";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TOtherMemberDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";
import MChildTwoDetails from "../../../../Page/Mobile/Health/CARE/MChildTwoDetails/MChildTwoDetails";

export default function ChildTwoDetailsContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const [memberFields, setMemberFields] = useState<TOtherMemberDetails>(
    INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS
  );
  const { Health } = useAppSelector((state) => state);

  useEffect(() => {
    getRealtionData();
  }, []);

  const getRealtionData = () => {
    const onSuccess = (res: any) => {
      console.log("res..", res);
      let data: TDropdown[] = res?.results?.response?.map(
        (maritalData: any) => ({
          label: maritalData.title,
          value: maritalData.code,
        })
      );
      dispatch(
        HealthSlice.actions.HEALTH_DROPDOWNDATA({
          key: "CHILD_RELATIONSHIP",
          value: data,
        })
      );
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getRelationData(onSuccess, onError, param);
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    setMemberFields((prev) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "name" ? !validateFullName(value) : isEmpty(value),
      },
    }));
  };

  const SAVE_CHILD_DATA = (data: TOtherMemberDetails) => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };
    let param = {
      child2Gender: data.relationship.value === "daughter" ? "F" : "M",
      child2Name: data.name.value,
      child2DOB: data.dob.value,
      child2Weight: data.weight.value,
      child2HeightFeet: data.heightFeet.value,
      child2HeightInches: data.heightInches.value,
      section: "child2_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let formValue = { ...memberFields };
    formValue = {
      ...formValue,
      dob: { ...formValue.dob, warning: isEmpty(formValue.dob.value) },
      heightFeet: {
        ...formValue.heightFeet,
        warning: isEmpty(formValue.heightFeet.value),
      },
      heightInches: {
        ...formValue.heightInches,
        warning: isEmpty(formValue.heightInches.value),
      },
      name: {
        ...formValue.name,
        warning: !validateFullName(formValue.name.value),
      },
      relationship: {
        ...formValue.relationship,
        warning: isEmpty(formValue.relationship.value),
      },
      weight: { ...formValue.weight, warning: isEmpty(formValue.weight.value) },
    };
    setMemberFields({ ...formValue });

    if (
      !formValue.dob.warning &&
      !formValue.heightFeet.warning &&
      !formValue.heightInches.warning &&
      !formValue.name.warning &&
      !formValue.relationship.warning &&
      !formValue.weight.warning
    ) {
      const total_child = ADD_FORM.children;
      SAVE_CHILD_DATA(formValue);
      if (PAGE_STATUS) {
        navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}`);
      } else {
        if (total_child > 2) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_THREE_DETAILS}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}`
          );
        }
      }
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          INSURED_MEMBER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS,
            CHILD_TWO_DETAILS: { ...formValue },
          },
          PAGE_STATUS: false,
        })
      );
    }
  };
  return isMobile ? (
    <MChildTwoDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  ) : (
    <ChildTwoDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  );
}
