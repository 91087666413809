import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { DateFun, isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { TCareMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCareMedicalQuestion";
import MedicalDetailsHdfc from "../../../../Page/Desktop/Health/HDFC/MedicalDetails/MedicalDetailsHdfc";
import { TW_REDUCERS } from "../../../../Store/Slice_Reducer/TwoWheeler/TWReducer";

export default function MedicalDetailsContainerHdfc() {
  const isMobile = useIsMobile();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_HDFC,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
    medical_popup_mhs,
  } = useAppSelector((state) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_HDFC);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [change_status, setchange_status] = useState(false);
  const [modalstatus, setmodalstatus] = useState(medical_popup_mhs);
  console.log(medical_popup_mhs, "modalstatus", modalstatus);
  // const updateMasterState = () => {};

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_HDFC };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Do you have any pre-existing diseases or have been taking drugs/Medicines regularly during the last 1 year for any disease/illness (other than Hypertension / Diabetes/Hypothyroidism)?",
          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "Pre-Existing Diseases",
              description:
                "Do you have any pre-existing diseases or have been taking drugs/Medicines regularly during the last 1 year for any disease/illness (other than Hypertension / Diabetes/Hypothyroidism)?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "Smoking",
              description: "Does any of the members Smoke?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                },
              },
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_HDFC);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_HDFC]);
  let flag = true;
  console.log(change_status, "sss", flag);

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;

    // console.log('sss',flag)
    console.log(attrName, "attrName", value);
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      if (value === false) {
        // Update selection_status of members
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
          adult_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_one,
            selection_status: false,
          },
          adult_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_two,
            selection_status: false,
          },
          child_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_one,
            selection_status: false,
          },
          child_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_two,
            selection_status: false,
          },
          child_three: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_three,
            selection_status: false,
          },
          child_four: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_four,
            selection_status: false,
          },
        };
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      setchange_status(flag);

      // dispatch(HealthSlice.actions.MEDICAL_POPUP_MHS(false))

      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
            attrName[2]
          ],
          status: value,
        },
      };

      if (value === false) {
        // Check if the attribute name is neither "MQ15-SQ15" nor "MQ16-SQ16"

        Object.values(updatedSubQuestion[attrName[2]].member_data).forEach(
          (member: any) => {
            member.selection_status = false;
          }
        );
      }

      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "memberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
          attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        attrName[4] === "adult_one" ||
        attrName[4] === "adult_two" ||
        attrName[4] === "child_one" ||
        attrName[4] === "child_two" ||
        attrName[4] === "child_three" ||
        (attrName[4] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
        ].member_data[attrName[4]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
  };
  console.log("medicalQuestionData333", medicalQuestionData);
  const validateForm = () => {
    // Ensure medicalQuestionData is not null or undefined
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    // Deep copy the medicalQuestionData object to avoid state mutations
    let data: TCareMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    console.log("dddddd", data);
    let hasError = false;
    // debugger
    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.sub_question) {
        Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
          if (subQuestion && subQuestion.status) {
            Object.values(subQuestion.member_data).forEach(
              (memberdata: any) => {
                if (memberdata && memberdata.selection_status) {
                  <>{console.log(memberdata, "ewew")}</>;
                  // setmodalstatus( memberdata.selection_status)
                  hasError = true;
                  dispatch(
                    HealthSlice.actions.MEDICAL_POPUP_MHS(
                      memberdata.selection_status
                    )
                  );
                } else {
                }
              }
            );
          }
        });
      }
    });

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_HDFC(data));

      //api calling
      const onSuccess = (res: any) => {
        if (res.results.error === false) {
          let quoteData = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        console.log("err..", err);
      };
      console.log(
        data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
          ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value,
        "hhhhee"
      );

      let param: any = {
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
        medicalPreExistingDiseases: `${data.MQ01?.main_question.status}`,
        medicalOtherDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        spouse_medicalOtherDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        child1_medicalOtherDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ1-SQ1"].member_data?.child_one?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        child2_medicalOtherDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ1-SQ1"].member_data?.child_two?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        medicalSmokingDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.status}` ===
          "true"
            ? "Yes"
            : "No",
        medicalSmoking:
          `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_one?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        spouse_medicalSmoking:
          `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        child1_medicalSmoking:
          `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"].member_data?.child_one?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        child2_medicalSmoking:
          `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"].member_data?.child_two?.selection_status}` ===
          "true"
            ? "Yes"
            : "No",
        medical_info: "Yes",
      };
      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);

      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    }
  };

  return isMobile ? null : (
    <MedicalDetailsHdfc
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  );
}
