import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Loader from "../../../../../Component/Loader/Loader";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import { TRSAAddon } from "../../../../../types/Health/HealthQuotation/TAddonRSA";

const MAddonDetailsRSA = ({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TRSAAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) => {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);

  return (
    <>
      {isLoading && <Loader />}
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Consumable Items Coverage Opted"
            toggle_status={addonStatus?.consumableItemsCoverageOpted}
            value_update={updateMasterState}
            attrName={"consumableItemsCoverageOpted"}
          />

          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Hospital Cashbenefit Opted"
            toggle_status={addonStatus?.hospitalCashbenefitOpted}
            value_update={updateMasterState}
            attrName={"hospitalCashbenefitOpted"}
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="International Treatment Opted"
            toggle_status={addonStatus?.internationalTreatmentOpted}
            value_update={updateMasterState}
            attrName={"internationalTreatmentOpted"}
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="IsVoluntary Co-Payment Selected"
            toggle_status={addonStatus?.isVoluntaryCoPaymentSelected}
            value_update={updateMasterState}
            attrName={"isVoluntaryCoPaymentSelected"}
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Opt Top-Up Opted"
            toggle_status={addonStatus?.opttopupOpted}
            value_update={updateMasterState}
            attrName={"opttopupOpted"}
          />
          {SELECTED_QUOTE_DATA.productDetails.id === 99 ? (
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Supreme Plus Cover Selected"
              toggle_status={addonStatus?.supremePlusCoverSelected}
              value_update={updateMasterState}
              attrName={"supremePlusCoverSelected"}
            />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id === 100 ? (
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Elite Plus Cover Selected"
              toggle_status={addonStatus?.elitePlusCoverSelected}
              value_update={updateMasterState}
              attrName={"elitePlusCoverSelected"}
            />
          ) : null}

          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Top-Up Opted Count"
            value_update={updateMasterState}
            attrName={"TtopUpOptedCount"}
            toggle_status={addonStatus?.TtopUpOptedCount}
            sub_details={
              <Box maxWidth={"200px"}>
                <SelectDropdown
                  title="Select Top-Up Opted Count"
                  value={addonStatus.topUpOptedCount}
                  value_update={updateMasterState}
                  attrName={"topUpOptedCount"}
                  data={[
                    { value: "", label: "0" },
                    { value: "20000", label: "20000" },
                    { value: "30000", label: "30000" },
                    { value: "50000", label: "50000" },
                  ]}
                />
              </Box>
            }
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Deductible Amount"
            value_update={updateMasterState}
            attrName={"TdeductibleAmount"}
            toggle_status={addonStatus?.TdeductibleAmount}
            sub_details={
              <Box maxWidth={"200px"}>
                <SelectDropdown
                  title="Select Deductible Amount"
                  value={addonStatus.deductibleAmount}
                  value_update={updateMasterState}
                  attrName={"deductibleAmount"}
                  data={[
                    { value: "", label: "0" },
                    { value: "20000", label: "20000" },
                    { value: "30000", label: "30000" },
                    { value: "50000", label: "50000" },
                  ]}
                />
              </Box>
            }
          />
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Voluntary Co-Payment Percentage"
            value_update={updateMasterState}
            attrName={"TvoluntaryCoPaymentPercentage"}
            toggle_status={addonStatus?.TvoluntaryCoPaymentPercentage}
            sub_details={
              <Box maxWidth={"200px"}>
                <SelectDropdown
                  title="Select Voluntary CoPayment Percentage"
                  value={addonStatus.voluntaryCoPaymentPercentage}
                  value_update={updateMasterState}
                  attrName={"voluntaryCoPaymentPercentage"}
                  data={[
                    { value: "5", label: "5" },
                    { value: "10", label: "10" },
                    { value: "15", label: "15" },
                    { value: "20", label: "20" },
                  ]}
                />
              </Box>
            }
          />
        </Grid>
        <MFooter
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
};

export default MAddonDetailsRSA;
