import { Box, CircularProgress } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { SESSION_CONSTANTS } from "./Services/Constants/SessionConstants";
import { store } from "./Store/Store";
import { generateRandomNumberString } from "./SupportingFiles/HelpingFunction";
import { CommonURLs } from "./URLCollection/Common/CommonURLs";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import {
  FRONTEND_DOMAIN,
  NODE_DOMAIN,
  PHP_DOMAIN,
} from "./URLCollection/Domain";

Sentry.init({
  dsn: "https://980451ae1bbf9d9a83bcd5b1a4e1ef92@o4506864219521024.ingest.us.sentry.io/4506987932811264",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [FRONTEND_DOMAIN, PHP_DOMAIN, NODE_DOMAIN],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

async function generateAccessToken(callback: () => void): Promise<void> {
  const tokenEndpoint: string = CommonURLs.GENERATE_TOKEN;

  const username: string | undefined = process.env.REACT_APP_USERNAME;
  const password: string | undefined = process.env.REACT_APP_PASSWORD;
  try {
    const authHeader: string = `Basic ${btoa(`${username}:${password}`)}`;
    const session_id: string = generateRandomNumberString(10);
    const response: AxiosResponse = await axios.post(tokenEndpoint, null, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authHeader,
        UserSessionId: session_id,
      },
    });
    const accessToken: string = response.data.results.response;

    if (accessToken && session_id) {
      sessionStorage.setItem(SESSION_CONSTANTS.ACCESS_TOKEN, accessToken);
      sessionStorage.setItem(SESSION_CONSTANTS.SESSION_ID, session_id);

      callback();
    } else {
      throw new Error("Access token or session ID not retrieved properly");
    }
  } catch (error) {
    console.error("Error generating access token:", error);
  }
}

async function startApp(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  try {
    const token = sessionStorage.getItem(SESSION_CONSTANTS.ACCESS_TOKEN);
    const session_id = sessionStorage.getItem(SESSION_CONSTANTS.SESSION_ID);

    if (!token || !session_id) {
      setLoading(true); // Start loading

      await generateAccessToken(() => {
        setLoading(false); // Stop loading
        renderApp();
      });
    } else {
      renderApp();
    }
  } catch (error) {
    console.error("Error starting the application:", error);
  }
}

function AppLoader() {
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
    startApp(setLoading);
  }, []);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        height: "100vh",
        width: "100%",
      }}>
      <CircularProgress />
      <p>Please wait...</p>
    </Box>
  ) : null;
}

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ToastContainer theme="colored" />
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  reportWebVitals();
}

ReactDOM.render(<AppLoader />, document.getElementById("root"));
