import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../../../Component/Navbar/Navbar";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import RidersBenefitsContainer from "../../../../Container/Term/Bajaj/RidersBenefitsContainer";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TERM_ROUTES } from "../../../Path/TermRoutes";

const BAJAJ_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TERM_ROUTES.RIDERS_BENEFITS}
        element={<RidersBenefitsContainer />}
      />
    </Routes>
  );
};

const BajajRoutes = () => {
  const isMobile = useIsMobile();
  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              {BAJAJ_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              {BAJAJ_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BajajRoutes;
