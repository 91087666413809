import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import React, { useState } from "react";
interface Props {
  toggle_status: boolean | undefined;
  value_update: Function | undefined;
  attrName: any;
  disable?: boolean;
}

const ToggleSwitch: React.FC<Props> = ({
  toggle_status,
  value_update,
  attrName,
  disable = false,
}) => {
  return (
    <ToggleButtonGroup
      className="switch"
      disabled={disable}
      value={toggle_status}
      exclusive
      onChange={(e, value) => {
        if (value !== null) {
          value_update?.(attrName, value);
        }
      }}
    >
      <ToggleButton value={true}>Yes</ToggleButton>
      <ToggleButton value={false}>No</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleSwitch;
