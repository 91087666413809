import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../Store/hooks";
import { TOtherMemberDetails } from "../../../../../types/Health/ProposalDeatail/TProposalDetail";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import Footer from "../../../../../Component/Footer/Footer";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { subDays, subMonths } from "date-fns";

const ChildFourDetails = ({
  fieldsUpdateState,
  validateForm,
  memberFields,
}: {
  fieldsUpdateState: Function;
  validateForm: Function;
  memberFields: TOtherMemberDetails;
}) => {
  const navigate = useNavigate();
  const { PAGE_STATUS, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );

  const { HEIGHT_FEET, HEIGHT_INCH } = useAppSelector(
    (state) => state.CommonDropdownData
  );

  return (
    <Box>
      <h5 className="sectionTitle">
        4<sup>th</sup> Children
      </h5>
      <Grid container spacing={3}>
        <Grid xs={3}>
          <SelectDropdown
            class_name="inputField"
            title="Relationship with Proposer"
            value={memberFields.relationship.value}
            attrName={"relationship"}
            value_update={fieldsUpdateState}
            data={[
              { label: "Son", value: "son" },
              { label: "Daughter", value: "daughter" },
            ]}
            warn_status={memberFields.relationship.warning}
            error_message="Select Relationship"
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            validation_type="NAME"
            class_name="inputField"
            title={"Full Name"}
            value={memberFields.name.value}
            attrName={"name"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.name.warning}
            error_message="Enter Full Name"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={3}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={memberFields.dob.value}
            attrName={"dob"}
            value_update={fieldsUpdateState}
            error_message="Select DOB"
            warn_status={memberFields.dob.warning}
            date_validation_type={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.CARE
                ? "DAYS"
                : "MONTHS"
            }
            min_date={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN
                ? 9496
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.NIVA_BUPA
                ? 23 * 12
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.CARE
                ? 9129
                : 25 * 12
            }
            max_date={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN
                ? 91
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.GO_DIGIT
                ? 12
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.CARE
                ? 91
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.STAR
                ? 91
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.NIVA_BUPA
                ? 12
                : 3
            }
            default_date={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.HDFC ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN
                ? subDays(new Date(), 91)
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.NIVA_BUPA ||
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.GO_DIGIT
                ? subMonths(new Date(), 12)
                : subMonths(new Date(), 3)
            }
          />
        </Grid>
        <Grid xs={3} className="weightField">
          <span className="weightIcon"></span>
          <RKTextField
            class_name="inputField"
            validation_type="NUMBER"
            max_length={3}
            title={"Weight (Kg)"}
            value={memberFields.weight.value}
            attrName={"weight"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.weight.warning}
            error_message={"Enter Weight"}
          />
        </Grid>
        <Grid xs={5} className="heightField">
          <span className="heightIcon"></span>
          <SelectDropdown
            class_name="inputField mr-2"
            title="Feet"
            value={memberFields.heightFeet.value}
            attrName={"heightFeet"}
            error_message="Select Height Feet"
            value_update={fieldsUpdateState}
            data={HEIGHT_FEET}
            warn_status={memberFields.heightFeet.warning}
          />
          <SelectDropdown
            class_name="inputField"
            title="Inches"
            error_message="Select Height Inches"
            value={memberFields.heightInches.value}
            attrName={"heightInches"}
            value_update={fieldsUpdateState}
            data={HEIGHT_INCH}
            warn_status={memberFields.heightInches.warning}
          />
        </Grid>
      </Grid>
      <Footer
        attrName={"pageStatus"}
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        value={1}
        value_update={() => {}}
        forward={() => validateForm()}
        backward={() => navigate(-1)}
      />
    </Box>
  );
};

export default ChildFourDetails;
