import { Alert, AlertTitle, Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./PolicyConfirm.scss";
import { useAppSelector } from "../../../../../Store/hooks";
import Navbar from "../../../../../Component/Navbar/Navbar";
import {
  formatToCurrency,
  isEmpty,
} from "../../../../../SupportingFiles/HelpingFunction";

function PolicyConfirm() {
  const { proposerDetails, proposerPage } = useAppSelector(
    (state) => state.TermFullJourney
  );
  const { SELECTED_QUOTE_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Term
  );
  return (
    <Box className="paymentWrapper">
      {/* Navbar */}
      <Navbar />

      <Grid container spacing={3}>
        <Grid xs={12} className="my-4" textAlign="center">
          <img src="./images/policy-confirmation.svg" alt="" height="140px" />
        </Grid>
        <Grid xs={12} textAlign="center">
          <h3>Policy Confirmation</h3>
          <p className="mb-0">
            Great !! Congratulations on buying your investment.
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="mb-0 paymentDetail_p">
            Hi, {`${proposerDetails.full_name.value}`}
          </p>
          <p className="paymentDetail_p">
            {" "}
            {`${proposerDetails.gender.value}` === "M"
              ? "Male"
              : "Female"}, {`${ADD_FORM.age.value}`}
            Years
          </p>
        </Grid>
        <Grid xs={12}>
          <Box>
            <Grid container className="paymentPolicy_box">
              <Grid xs={6}>
                <p
                  style={{
                    textAlign: "left",
                  }}>{`${SELECTED_QUOTE_DATA.productDetails.product_name}`}</p>
              </Grid>
              <Grid xs={6}>
                <p style={{ textAlign: "right" }}>
                  Transaction ID :-{" "}
                  {!isEmpty(SELECTED_QUOTE_DATA.transaction_id)
                    ? SELECTED_QUOTE_DATA.transaction_id
                    : SELECTED_QUOTE_DATA.appno}
                </p>
              </Grid>
              <Grid xs={2.4} textAlign="left">
                <img
                  src={SELECTED_QUOTE_DATA.companyDetails.logo}
                  alt=""
                  style={{ width: "90px" }}
                />
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Sum Assured
                  <span>
                    {formatToCurrency(
                      Number(SELECTED_QUOTE_DATA.premiumDetails.sum_assured)
                    )}
                  </span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Policy Term
                  <span>{SELECTED_QUOTE_DATA.premiumDetails.term} Years</span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p>
                  Policy Paying Term
                  <span>
                    {SELECTED_QUOTE_DATA.premiumDetails.pay_term} Years
                  </span>
                </p>
              </Grid>
              <Grid xs={2.4}>
                <p style={{ textAlign: "right" }}>
                  Application No.
                  <span>{SELECTED_QUOTE_DATA.appno}</span>
                </p>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12}>
          <Alert severity="info">
            <AlertTitle>Upload Documents</AlertTitle>
            <Link
              target="_blank"
              sx={{ cursor: "pointer" }}
              href={proposerPage.PAYMENT_URL}>
              {proposerPage.PAYMENT_URL}
            </Link>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PolicyConfirm;
