import { NODE_DOMAIN, PHP_DOMAIN } from "../Domain";

export const TW_URLs = {
  GET_PROPOSAL_INFO: `${NODE_DOMAIN}/api/v1/premium/proposal-details`,
  GET_DETAILS_BY_QUOTE_NO: `${NODE_DOMAIN}/api/v1/premium/quotedetail`,
  GET_VEHICLE_NO_INFO: `${NODE_DOMAIN}/api/v1/premium/car-details?type=TW&CRegNo=`,
  GET_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}/api/previous-insurer-product-maps/get-prev-ins-lists.json`,
  UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-addon`,
  PREMIUM_RECALCULATION: `${PHP_DOMAIN}/api/tw-insurances/recalculate-premium.json`,
  UPDATE_PERPOSAL_RECORDS: `${PHP_DOMAIN}/api/tw-proposals/capture-record.json`,
  UPDATE_FULL_PERPOSAL_RECORDS: `${PHP_DOMAIN}/api/tw-proposals/add.json`,
  GET_CITY_STATE_BY_PINCODE_ONLY: `${PHP_DOMAIN}/api/state-city-maps/getDetailsByPincode.json`,
  saveQuotesForPickUpWhereYouLeft: `${PHP_DOMAIN}/api/lowest-premium-details/add.json`,
  TWO_WHEELER_PREVIOUS_INSURER_LIST: `${PHP_DOMAIN}/api/previous-insurer-product-maps/get-prev-ins-lists.json`,
  VEHICLE_ON_LOAN: `${PHP_DOMAIN}/api/financier-masters/get-list-auto.json`,
  PAYMENT_URL: `${PHP_DOMAIN}/api/tw-proposals/create-proposal.json`,
  BULK_UPDATE_ADDON_STATUS: `${NODE_DOMAIN}/api/v1/premium/update-bulk-addon`,
  GET_NOMINEE_RELATIONSHIP_LIST: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-lists.json`,
};
