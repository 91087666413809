import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";

import "./MedicalQuestion.scss";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useAppSelector } from "../../Store/hooks";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";

interface Props {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
}
const Medicaldeatailsoptimum: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
}) => {
  const { INSURED_MEMBER_DETAILS, ADD_FORM, KYC_DETAILS } = useAppSelector(
    (state) => state.Health
  );
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };
  const Liquor_Data: any = [];
  for (let i = 1; i <= 9; i++) {
    Liquor_Data.push({ value: i, label: ` ${i}` });
  }
  const Alcohal_Consumed_Data: any = [];
  for (let i = 1; i <= 7; i++) {
    Alcohal_Consumed_Data.push({ value: i, label: ` ${i}` });
  }
  const Wine_Data: any = [];
  for (let i = 1; i <= 6; i++) {
    Wine_Data.push({ value: i, label: ` ${i}` });
  }
  const dummyData: any = [];
  for (let i = 1; i <= 10; i++) {
    dummyData.push({ value: i, label: ` ${i}` });
  }
  const dummyDatasmoking: any = [];

  for (let i = 1; i <= 50; i++) {
    dummyDatasmoking.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  const Pouches_data: any = [];

  for (let i = 1; i <= 20; i++) {
    Pouches_data.push({
      value: i.toString(),
      label: `pouch ${i.toString()}`,
    });
  }
  return (
    <Box className={main_class}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            {sub_details}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
      </Grid>
      {select_disease}
      <Grid container spacing={2}>
        {data?.main_question?.member_data &&
          (data?.main_question?.id === "MQ01" ||
            data?.main_question?.id === "MQ02" ||
            data?.main_question?.id === "MQ03") &&
          data?.main_question?.status &&
          Object.values(data?.main_question?.member_data)?.map(
            (memberDataValue: any, memberIndex: number) => (
              <React.Fragment key={memberIndex}>
                {memberDataValue.name !== "" && (
                  <Grid xs={4}>
                    <MemberRadioButtonNew
                      class_name={
                        memberIndex === 0 || memberIndex === 1
                          ? "adult"
                          : "child"
                      }
                      checked={memberDataValue?.selection_status}
                      label_name={memberDataValue.name}
                      attrName={[
                        "mainMemberData",
                        data?.main_question?.id,
                        memberDataValue?.keyName,
                      ]}
                      value_update={updateMasterState}
                    />
                    {memberDataValue?.selection_status &&
                      memberDataValue.keyName && (
                        <>
                          {Object.values(memberDataValue.field_data).map(
                            (field_data_data: any) => {
                              let datePickerRendered = false; // Flag to track DatePicker rendering
                              console.log(memberDataValue, 'memberDataValue')
                              return (
                                <React.Fragment key={field_data_data.id}>
                                  {field_data_data.field_type === "DROPDOWN" ? (
                                    // Render your dropdown component here

                                    <SelectDropdown
                                      class_name="inputField mb-5"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      data={
                                        data?.main_question?.id === "MQ02"
                                          ? Pouches_data
                                          : dummyDatasmoking
                                      }
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {field_data_data.field_type ===
                                    "SERACHDROPDOWN" ? (
                                    <SearchDropdown
                                      class_name="inputField mb-5"
                                      title={field_data_data.title}
                                      attrName={[
                                        "mainMemberDataField",
                                        data?.main_question?.id,
                                        memberDataValue.keyName,
                                        field_data_data.id,
                                      ]}
                                      value={field_data_data?.value?.value}
                                      value_update={updateMasterState}
                                      warn_status={
                                        field_data_data?.value?.warning
                                      }
                                      data={field_data_data.id === "MQ1-SQ1-F1" ? Liquor_Data : field_data_data.id === "MQ1-SQ1-F3" ? Wine_Data : field_data_data.id === "MQ1-SQ1-F4" ? Alcohal_Consumed_Data : dummyData}
                                      error_message={"Enter description"}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      )}
                  </Grid>
                )}
              </React.Fragment>
            )
          )}
      </Grid>

    </Box>
  );
};

export default Medicaldeatailsoptimum;
