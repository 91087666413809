import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";

function BULK_UPDATE(
  state: TTermFullJourneySlice,
  action: PayloadAction<TTermFullJourneySlice>
) {
  const data: TTermFullJourneySlice = { ...state, ...action.payload };

  return data;
}

export const TERM_REDUCERS = {
  BULK_UPDATE,
};
