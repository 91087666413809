import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { useAppSelector } from "../../Store/hooks";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import {
  TMainQuestion,
  TSubQuestion,
} from "../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./MedicalQuestion.scss";

interface Props {
  value_update: Function;
  data: TMainQuestion;
  sub_details?: any;
}
const NivaBupaSenMedicalQuestion: React.FC<Props> = ({
  sub_details,
  value_update,
  data,
}) => {
  const { INSURED_MEMBER_DETAILS, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );

  return (
    <Box className={"medicalQuestion borderBottom"}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={"tobaco"}></span>
          <div>
            <h6>{isEmpty(data.title) ? data.description : data.title}</h6>
            {isEmpty(data.title) ? null : <p>{data.description}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={data.status}
            value_update={value_update}
            attrName={[data.id, "main_question", "status"]}
          />
        </Grid>
      </Grid>
      {sub_details}
      {data?.status && !data?.sub_question ? (
        <Grid container spacing={2}>
          <Grid xs={4}>
            <MemberRadioButtonNew
              class_name={"adult"}
              checked={data?.member_data?.adult_one.selection_status}
              label_name={INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value}
              attrName={[
                data?.id,
                "main_question",
                "adult_one",
                "selection_status",
              ]}
              value_update={value_update}
            />
          </Grid>
          {ADD_FORM.spouse_status ? (
            <Grid xs={4}>
              <MemberRadioButtonNew
                class_name={"adult"}
                checked={data?.member_data?.adult_two.selection_status}
                label_name={INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value}
                attrName={[
                  data?.id,
                  "main_question",
                  "adult_two",
                  "selection_status",
                ]}
                value_update={value_update}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {data?.status && data?.sub_question ? (
        <Collapse in={data?.status} className="medicalQuestion-SubSection">
          <Grid container spacing={3} className="pr-2 mb-3">
            {Object.values(data?.sub_question).map(
              (dataSubQuestion: TSubQuestion, indexSubQuestion) => (
                <React.Fragment key={indexSubQuestion}>
                  <Grid xs={8} md={9} className="medicalQuestionInner">
                    <span className={"tobaco"}></span>
                    <div>
                      <h6>
                        {isEmpty(dataSubQuestion.title)
                          ? dataSubQuestion.description
                          : dataSubQuestion.title}
                      </h6>
                      {isEmpty(dataSubQuestion.title) ? null : (
                        <p>{dataSubQuestion.description}</p>
                      )}
                    </div>
                  </Grid>
                  <Grid xs={4} md={3} textAlign="right" className="pt-4">
                    <ToggleSwitch
                      toggle_status={dataSubQuestion.status}
                      value_update={value_update}
                      attrName={[
                        data.id,
                        "sub_question",
                        dataSubQuestion.id,
                        "status",
                      ]}
                    />
                  </Grid>
                  {dataSubQuestion?.status ? (
                    <Grid container spacing={2}>
                      <Grid xs={4}>
                        <MemberRadioButtonNew
                          class_name={"adult"}
                          checked={
                            dataSubQuestion?.member_data?.adult_one
                              .selection_status
                          }
                          label_name={
                            INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value
                          }
                          attrName={[
                            data?.id,
                            "sub_question",
                            dataSubQuestion?.id,
                            "adult_one",
                            "selection_status",
                          ]}
                          value_update={value_update}
                        />
                        {dataSubQuestion?.member_data?.adult_one
                          .selection_status &&
                        dataSubQuestion?.member_data.adult_one.field_data
                          ? Object.values(
                              dataSubQuestion?.member_data.adult_one.field_data
                            ).map((data_input_field, index_input_field) => (
                              <SelectDropdown
                                class_name="inputField"
                                title={data_input_field?.title}
                                value={data_input_field?.value?.value}
                                attrName={[
                                  data?.id,
                                  "sub_question",
                                  dataSubQuestion?.id,
                                  data_input_field.id,
                                  "adult_one",
                                  "field_data",
                                ]}
                                value_update={value_update}
                                data={data_input_field.dropdown_data}
                                warn_status={data_input_field?.value?.warning}
                              />
                            ))
                          : null}
                      </Grid>
                      {ADD_FORM.spouse_status ? (
                        <Grid xs={4}>
                          <MemberRadioButtonNew
                            class_name={"adult"}
                            checked={
                              dataSubQuestion?.member_data?.adult_two
                                .selection_status
                            }
                            label_name={
                              INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name
                                .value
                            }
                            attrName={[
                              data?.id,
                              "sub_question",
                              dataSubQuestion?.id,
                              "adult_two",
                              "selection_status",
                            ]}
                            value_update={value_update}
                          />
                          {dataSubQuestion?.member_data?.adult_two
                            .selection_status &&
                          dataSubQuestion?.member_data.adult_two.field_data
                            ? Object.values(
                                dataSubQuestion?.member_data.adult_two
                                  .field_data
                              ).map((data_input_field, index_input_field) => (
                                <SelectDropdown
                                  class_name="inputField"
                                  title={data_input_field?.title}
                                  value={data_input_field?.value?.value}
                                  attrName={[
                                    data?.id,
                                    "sub_question",
                                    dataSubQuestion?.id,
                                    data_input_field.id,
                                    "adult_two",
                                    "field_data",
                                  ]}
                                  value_update={value_update}
                                  data={data_input_field.dropdown_data}
                                  warn_status={data_input_field?.value?.warning}
                                />
                              ))
                            : null}
                        </Grid>
                      ) : null}
                    </Grid>
                  ) : null}
                </React.Fragment>
              )
            )}
          </Grid>
        </Collapse>
      ) : null}
    </Box>
  );
};

export default NivaBupaSenMedicalQuestion;
