import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch, useAppSelector } from "../../../../../../Store/hooks";
import { TCareMedicalQuestion } from "../../../../../../types/Health/HealthQuotation/TCareMedicalQuestion";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../../../Router/Path/HealthRoutes";
import { ECompanyCode } from "../../../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../../../Store/Slice_Reducer/Health/HealthSlice";

function MedicalDetailPreviewBoxHdfc() {
  const {
    MEDICAL_QUESTION_DATA_HDFC,
    SELECTED_QUOTE_DATA,
    INSURED_MEMBER_DETAILS,
  } = useAppSelector((state) => state.Health);
  const navigate = useNavigate();

  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_HDFC);
  const dispatch = useAppDispatch();
  console.log("medicalQuestionData99", medicalQuestionData);
  // Initialize a variable to track if a main question with status true is found
  let found = false;

  Object.values(medicalQuestionData).forEach((main_data: any) => {
    // Check if main_data and main_question exist and are not null or undefined
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      // Iterate over each main question's sub-questions
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          // Check if sub_question_data exists and is not null or undefined
          if (sub_question_data && sub_question_data.status) {
            // Iterate over each sub-question's member data
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                // Check if member_data exists and is not null or undefined
                if (member_data && member_data.selection_status) {
                  // If a sub-question with status true and at least one member with selection_status true is found
                  found = true; // Set found to true
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });
  return (
    <Box className="previewBox mb-6 mx-3">
      <Grid container columnSpacing={2}>
        <Grid xs={8}>
          {found ? (
            <p className="previewBoxTitle">Insured Member's Medical Details</p>
          ) : (
            <p className="previewBoxTitle">No Medical History Declared</p>
          )}
        </Grid>
        <Grid xs={4} textAlign="right">
          <Link
            onClick={() => {
              dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}`
              );
            }}
            className="previewBoxEdit">
            <EditIcon /> Edit
          </Link>
        </Grid>
      </Grid>
      {SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.HDFC &&
      medicalQuestionData ? (
        <Grid xs={12}>
          {Object.values(medicalQuestionData).map((main_data: any) =>
            main_data && main_data.main_question ? (
              Object.values(main_data.main_question.sub_question).map(
                (sub_data: any) =>
                  sub_data
                    ? Object.values(sub_data.member_data).some(
                        (member_data: any) => member_data.selection_status
                      ) && (
                        <>
                          <React.Fragment key={sub_data.keyName}>
                            <h6 className="question">{sub_data.title}</h6>
                            <p className="">{sub_data.description}</p>
                            <div key={sub_data.id}></div>
                          </React.Fragment>
                          <ul className="member_list">
                            {Object.values(sub_data.member_data).map(
                              (memberData: any) =>
                                memberData.selection_status && (
                                  <li key={memberData.keyName}>
                                    <div
                                      className={`${memberData.image_class} illness`}></div>
                                    <p className="member_name">
                                      {memberData.name}
                                    </p>
                                  </li>
                                )
                            )}
                          </ul>
                        </>
                      )
                    : null
              )
            ) : (
              <Grid container columnSpacing={2}>
                <Grid xs={12}></Grid> {/* Add your content here */}
              </Grid>
            )
          )}
        </Grid>
      ) : null}
    </Box>
  );
}

export default MedicalDetailPreviewBoxHdfc;
