import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MedicalDetailNivaBupaSenior from "../../../Page/Desktop/Health/NIVA_BUPA/medicalDetails/MedicalDetailNivaBupaSenior";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { TNivaBupaMedicalQuestion } from "../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import { toast } from "react-toastify";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { NIVA_BUPA_SENIOR_MEDICAL_DTO } from "../../../DTO/HealthDTO/NivaBupaSenMedicalDTO";
import MMedicalDetailNivaBupaSenior from "../../../Page/Mobile/Health/NIVA_BUPA/medicalDetails/MMedicalDetailNivaBupaSenior";

const MedicalNivaBupaSenContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
  } = useAppSelector((state) => state.Health);
  const { Health } = useAppSelector((state) => state);

  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TNivaBupaMedicalQuestion>(MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR);

  const updateMasterState = (attrName: string[], value: any) => {
    const newData: TNivaBupaMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );



    const questionId = attrName[0];
    const mainOrSubQuestion = attrName[1];

    if (mainOrSubQuestion === "main_question") {
      const nestedProperty = attrName[2];
      const finalProperty = attrName[3];
      if (nestedProperty === "status") {
        newData[questionId].main_question.status = value;
      } else {
        const memberData = newData[questionId].main_question.member_data;
        if (memberData && nestedProperty in memberData) {
          const member = memberData[nestedProperty as keyof typeof memberData];
          if (finalProperty === "selection_status" && member) {
            member.selection_status = value;
          }
        }
      }
    } else if (mainOrSubQuestion === "sub_question") {
      // Get the sub-question ID
      const subQuestionId = attrName[2];
      const nestedProperty = attrName[3];
      const finalProperty = attrName[4];

      let subQuestion =
        newData[questionId]?.main_question?.sub_question?.[subQuestionId];
      if (subQuestion) {
        if (nestedProperty === "status") {
          subQuestion.status = value;
        } else if (
          nestedProperty === "adult_one" ||
          nestedProperty === "adult_two"
        ) {
          const memberData = subQuestion.member_data;

          if (memberData && nestedProperty in memberData) {
            const member =
              memberData[nestedProperty as keyof typeof memberData];
            if (finalProperty === "selection_status" && member) {
              member.selection_status = value;

              if (value === false && member.field_data) {
                Object.values(member.field_data).map(
                  (data_input_field, index_input_field) =>
                    (data_input_field.value = { value: "", warning: false })
                );
              }
            }
          }
        } else {
          let memberData = subQuestion.member_data;
          let question_id = attrName[3];
          if (memberData && finalProperty in memberData) {
            let member = memberData[finalProperty as keyof typeof memberData];
            let allFields = member.field_data;
            if (allFields) {
              let selectedFieldData = allFields[question_id];

              if (selectedFieldData) {
                selectedFieldData = {
                  ...selectedFieldData,
                  value: {
                    value: value,
                    warning: isEmpty(value),
                  },
                };

                allFields[question_id] = selectedFieldData;
                member.field_data = allFields;
                memberData[finalProperty as keyof typeof memberData] = member;
              }
            }
          }
        }
      }
    }  
    
    

    setMedicalQuestionData(newData);
  };

  const validateForm = () => {
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }

    let data: TNivaBupaMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.entries(data).forEach(([key, value], index) => {
      if (value.main_question.sub_question) {
        Object.entries(value.main_question.sub_question).forEach(
          ([key_sub_question, value_sub_question], index_sub_question) => {
            if (value_sub_question.member_data) {
              Object.entries(value_sub_question.member_data).forEach(
                ([key_member_data, value_member_data], index_member_data) => {
                  if (
                    value_member_data.field_data &&
                    value_member_data.selection_status
                  ) {
                    Object.entries(value_member_data.field_data).forEach(
                      (
                        [key_selected_field, value_selected_field],
                        index_selected_field
                      ) => {
                        value_selected_field.value = {
                          value: isEmpty(value_selected_field.value?.value)
                            ? ""
                            : `${value_selected_field.value?.value}`,
                          warning: isEmpty(value_selected_field.value?.value),
                        };
                        if (isEmpty(value_selected_field.value?.value)) {
                          hasError = true;
                        }
                      }
                    );
                  }
                }
              );
            }
          }
        );
      }
    });

    setMedicalQuestionData(data);
    if (!hasError) {
      const toastID = toast.loading("Please wait...");
      const onSuccess = (res: any) => {
        console.log("resNeva", res?.premiumDetails);

        toast.dismiss(toastID);
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData?.premiumDetails,
              finalPremium:
                res?.results?.response?.premiumDetails?.finalPremium,
            },
          };
          dispatch(
            HealthSlice.actions.BULK_UPLOAD({
              ...Health,
              QUOTE_LOADER: false,
              MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR: data,
              SELECTED_QUOTE_DATA: quoteData,
            })
          );

          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
          );
        } else {
          toast.error(`${res?.results?.message}`);
        }
      };
      const onError = (err: any) => {
        toast.dismiss(toastID);
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        console.log("err..", err);
      };
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

      let param: NIVA_BUPA_SENIOR_MEDICAL_DTO = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: "G023",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medicalHeartDiseases: data.MQ01.main_question.status ? "Yes" : "No", // (Heart disease like Heart attack, Heart failure, Ischemic heart disease or Coronary heart disease, Angina etc)
        medical_info: "Yes",
        medicalHeart: data.MQ01.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalHeart: data.MQ01.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalCancerDiseases: data.MQ02.main_question.status ? "Yes" : "No", // (Heart disease like Heart attack, Heart failure, Ischemic heart disease or Coronary heart disease, Angina etc)
        medicalCancer: data.MQ02.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalCancer: data.MQ02.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalKidneyDiseases: data.MQ03.main_question.status ? "Yes" : "No", //(. Major organ failure (Kidney, Liver, Heart, Lungs etc))
        medicalKidney: data.MQ03.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalKidney: data.MQ03.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalStrokeDiseases: data.MQ04.main_question.status ? "Yes" : "No", //(Stroke, Encephalopathy, Brain abscess, or any neurological disease)
        medicalStroke: data.MQ04.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalStroke: data.MQ04.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalLungDiseases: data.MQ05.main_question.status ? "Yes" : "No", //(Pulmonary fibrosis, collapse of lungs or Interstitial lung disease (ILD) )
        medicalLung: data.MQ05.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_lungDiseases: data.MQ05.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalLiverDiseases: data.MQ06.main_question.status ? "Yes" : "No", // (Hepatitis B or C, Chronic liver disease, Crohn’s disease, Ulcerative colitis)
        medicalLiver: data.MQ06.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalLiver: data.MQ06.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalanaemiaDiseases: data.MQ07.main_question.status ? "Yes" : "No", //(Any anaemia other than iron deficiency anaemia)
        medicalanaemiadeficiency: data.MQ07.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalanaemiadeficiency: data.MQ07.main_question.member_data
          ?.adult_two.selection_status
          ? "Yes"
          : "No",
        self_exact_hospitalized: data.MQ08.main_question.status ? "Yes" : "No", //(Ever been hospitalized for more than 5 days)
        exact_hospitalized: data.MQ08.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_exact_hospitalized: data.MQ08.main_question.member_data
          ?.adult_two.selection_status
          ? "Yes"
          : "No",
        self_exact_medication: data.MQ09.main_question.status ? "Yes" : "No", //(. Ever taken any medicines for more than 10 days continuously? Medication includes but not limited to inhalers, injections, oral drugs and external medical applications on body parts.)
        exact_medication: data.MQ09.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_exact_medication: data.MQ09.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        details_of_treatment_given: data.MQ10.main_question.status
          ? "Yes"
          : "No", //(Awaiting any treatment, surgical or medical that has been advised)
        treatment_given: data.MQ10.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_details_of_treatment_given: data.MQ10.main_question.member_data
          ?.adult_two.selection_status
          ? "Yes"
          : "No",
        medicaldiseaseSuffered: data.MQ11.main_question.status ? "Yes" : "No", // (Under any periodic / regular follow up for any disease suffered in past, whether cured or not? Follow up means periodic consultations, investigations etc)
        diseaseSuffered: data.MQ11.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_diseaseSuffered: data.MQ11.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalconsultations: data.MQ12.main_question.status ? "Yes" : "No", // (Has any consultations with doctor(s) or advised any tests for problems currently having or had in last 30 days?)
        diseaseconsultations: data.MQ12.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_consultations: data.MQ12.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalDiabetesDiseases: data.MQ13.main_question.status ? "Yes" : "No", //(Diabetes (high blood sugar), Pre-diabetes, High or low blood pressure, Chest Pain or any heart disease or Thyroid disorder, Asthma, Bronchitis)
        medicalDiabetes: data.MQ13.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalDiabetes: data.MQ13.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalgeneticCondition: data.MQ14.main_question.status ? "Yes" : "No", //(Parents have any hereditary or genetic condition? Please mention even if any of them is a carrier state i.e. did not have the disease but was a carrier)
        genetic_condition: data.MQ14.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_geneticCondition: data.MQ14.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalKidneyStone: data.MQ15.main_question.status ? "Yes" : "No", //(Any calculus (stone) disorder in any organ like Gall bladder, Kidneys, Urinary bladder, Ureter etc)
        Kidney_stone: data.MQ15.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_Kidney_stone: data.MQ15.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalTumorDiseases: data.MQ16.main_question.status ? "Yes" : "No", //(Tumor (Swelling)-benign or malignant, any external ulcer/growth/cyst/mass anywhere in the body?)
        TumorDiseases: data.MQ16.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_TumorDiseases: data.MQ16.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalHIVDiseases: data.MQ17.main_question.status ? "Yes" : "No", //(.HIV / AIDS, anaemia, thalassemia, haemophilia or any other blood related problem)
        HIVDiseases: data.MQ17.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_medicalHIVDiseases: data.MQ17.main_question.member_data
          ?.adult_two.selection_status
          ? "Yes"
          : "No",
        cMedicalMentalDiseases: data.MQ18.main_question.status ? "Yes" : "No", // (Psychiatric/Mental illnesses or sleep disorder?)
        cMedicalMental: data.MQ18.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_cMedicalMental: data.MQ18.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalanySymptoms: data.MQ19.main_question.status ? "Yes" : "No", //(Any health condition, disease, symptoms or information pertaining to health that is not captured above. If answer to this question is Yes for anyone, provide all medical documents)
        anySymptoms: data.MQ19.main_question.member_data?.adult_one
          .selection_status
          ? "Yes"
          : "No",
        spouse_anySymptoms: data.MQ19.main_question.member_data?.adult_two
          .selection_status
          ? "Yes"
          : "No",
        medicalpersonalHabitDetailsDiseases: data.MQ20.main_question.status
          ? "Yes"
          : "No", // (Smokes or consumes tobacco / gutkha /pan masala or alcohol If Yes, please answer the following:)
        medicalTobaccoDiseases: data?.MQ20?.main_question?.sub_question
          ?.MQ20_SQ01.status
          ? "Yes"
          : "No", //i. When did the applicant start smoking / consuming tobacco?
        medicalTobacco: data?.MQ20?.main_question?.sub_question?.MQ20_SQ01
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        consume_tobacco: `${data?.MQ20?.main_question?.sub_question?.MQ20_SQ01.member_data?.adult_one.field_data?.MQ20_SQ01_F01.value?.value}`,
        //i. When did the applicant start smoking / consuming tobacco?
        // a) School b) 10+2 c) College d) When started working e) Later
        spouse_medicalTobacco: data?.MQ20?.main_question?.sub_question
          ?.MQ20_SQ01.member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_consume_tobacco: `${data?.MQ20?.main_question?.sub_question?.MQ20_SQ01.member_data?.adult_two.field_data?.MQ20_SQ01_F01.value?.value}`,
        //i. When did the applicant start smoking / consuming tobacco?
        // a) School b) 10+2 c) College d) When started working e) Later
        medicalAlcoholDiseases: data?.MQ20?.main_question?.sub_question
          ?.MQ20_SQ02.status
          ? "Yes"
          : "No", // (How many years since the applicant has been consuming alcohol?)
        medicalAlcohol: data?.MQ20?.main_question?.sub_question?.MQ20_SQ02
          .member_data?.adult_one.selection_status
          ? "Yes"
          : "No",
        consume_alcohal: `${data?.MQ20?.main_question?.sub_question?.MQ20_SQ02.member_data?.adult_one.field_data?.MQ20_SQ02_F01.value?.value}`,
        spouse_medicalAlcohol: data?.MQ20?.main_question?.sub_question
          ?.MQ20_SQ02.member_data?.adult_two.selection_status
          ? "Yes"
          : "No",
        spouse_consume_alcohal: `${data?.MQ20?.main_question?.sub_question?.MQ20_SQ02.member_data?.adult_two.field_data?.MQ20_SQ02_F01.value?.value}`,
        alcoholQuantityAdult1: `${data?.MQ20?.main_question?.sub_question?.MQ20_SQ02.member_data?.adult_one.field_data?.MQ20_SQ02_F02.value?.value}`, //(How many days a week does the applicant consume alcohol? (1/2/3/4/5/6/7))
        alcoholQuantityAdult2: `${data?.MQ20?.main_question?.sub_question?.MQ20_SQ02.member_data?.adult_two.field_data?.MQ20_SQ02_F02.value?.value}`,
      };

      (Object.keys(param) as Array<keyof NIVA_BUPA_SENIOR_MEDICAL_DTO>).forEach(
        (key) => {
          if (param[key] === undefined || param[key] === "undefined") {
            param[key] = "";
          }
        }
      );

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
    }
  };

  return (
    <>
      {isMobile ? (
        <MMedicalDetailNivaBupaSenior
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      ) : (
        <MedicalDetailNivaBupaSenior
          medicalQuestionData={medicalQuestionData}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
};

export default MedicalNivaBupaSenContainer;
