import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { TW_URLs } from "../../URLCollection/TW/TWURLs";

const GET_PREVIOUS_INSURER_LIST = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void
) => {
  GetAPI.call(
    TW_URLs.GET_PREVIOUS_INSURER_LIST,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_VEHICLE_INFO = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  regNo: string
) => {
  PostAPI.call(
    TW_URLs.GET_VEHICLE_NO_INFO,
    { regNo: regNo },
    (res: any) => onSuccess(res.data),
    onError
  );
};

const VEHICLE_ON_LOAN = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  param: any
) => {
  PostAPI.call(
    TW_URLs.VEHICLE_ON_LOAN,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const PAYMENT = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  param: any
) => {
  PostAPI.call(
    TW_URLs.PAYMENT_URL,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TW_ADD_FORM_SERVICES = {
  GET_VEHICLE_INFO,
  GET_PREVIOUS_INSURER_LIST,
  VEHICLE_ON_LOAN,
  PAYMENT,
};
