import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../Component/CustomButton/CustomButton";
import { TW_ROUTES } from "../../../../Router/Path/TWRoutes";
import { TWSlice } from "../../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { formatNumberWithCurrencySymbol } from "../../../../SupportingFiles/HelpingFunction";
import { TWPremiumCal } from "../../../../SupportingFiles/TWHelper";
import { TTWPremiumDetails } from "../../../../types/TwoWheeler/TTWSingleQuoteDetail";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";

const PremRecalPopUp = ({
  policyRevisionPopup,
  setPolicyRevisionPopup,
  recalculatedPremium,
}: {
  policyRevisionPopup: boolean;
  setPolicyRevisionPopup: any;
  recalculatedPremium: TTWPremiumDetails;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADDON_STATUS, PAGE_STATUS, ADD_FORM_RESPONSE } =
    useAppSelector((state) => state.TW);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const [data, setData] = useState<{
    old_premium: number;
    old_idv: number;
    new_premium: number;
    new_idv: number;
  }>({
    old_premium: 0,
    old_idv: 0,
    new_premium: 0,
    new_idv: 0,
  });

  const ContinueFun = () => {
    if (SELECTED_QUOTE_DATA.quotationDetail) {
      dispatch(
        TWSlice.actions.SET_SELECTED_QUOTE_DATA({
          ...SELECTED_QUOTE_DATA,
          quotationDetail: {
            ...SELECTED_QUOTE_DATA.quotationDetail,
            premiumDetails: {
              ...SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails,
              ...recalculatedPremium,
            },
          },
        })
      );
    }
    if (PAGE_STATUS) {
      navigate(-1);
    } else {
      if (VERIFY_KYC_FORM.customer_type.value === "Organization") {
        navigate(`${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.ADDRESS_DETAILS}`);
      } else {
        navigate(`${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.NOMINEE_DETAILS}`);
      }
    }
  };

  useEffect(() => {
    if (recalculatedPremium && SELECTED_QUOTE_DATA.quotationDetail) {
      const new_premium = TWPremiumCal(
        SELECTED_QUOTE_DATA.quotationDetail,
        ADDON_STATUS,
        VERIFY_KYC_FORM.customer_type.value
      );
      const old_premium = TWPremiumCal(
        {
          ...SELECTED_QUOTE_DATA.quotationDetail,
          premiumDetails: recalculatedPremium,
        },
        ADDON_STATUS,
        VERIFY_KYC_FORM.customer_type.value
      );

      setData({
        new_idv: SELECTED_QUOTE_DATA.quotationDetail.premiumDetails.IDV,
        new_premium: new_premium,
        old_idv: recalculatedPremium.IDV,
        old_premium: old_premium,
      });
    }
  }, [recalculatedPremium, SELECTED_QUOTE_DATA]);

  return (
    <>
      {recalculatedPremium ? (
        <Modal open={policyRevisionPopup} className="modalWrapper">
          <Box className="modalInner lg-width">
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Link
                  className="close-button"
                  onClick={() => {
                    setPolicyRevisionPopup(false);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12}>
                <img alt="" src="/images/revision.svg" width="300px" />
              </Grid>
            </Grid>
            <Grid container spacing={2} textAlign="center">
              <Grid xs={12} className="mt-5">
                <h5>Policy Premium is Revised</h5>
              </Grid>

              <Grid xs={12}>
                {/* <p>Your new NCB is 0% and premium has changed</p> */}
                <Box marginBottom="16px">
                  {ADDON_STATUS.driver_cover.value && (
                    <div className="Section_li">
                      <div
                        className={
                          recalculatedPremium?.driverCover === 0 ? "no" : "yes"
                        }>
                        Driver Cover
                      </div>
                    </div>
                  )}
                  {ADDON_STATUS.zero_depreciation.value && (
                    <div className="Section_li">
                      <div
                        className={
                          recalculatedPremium?.depreciationCover === 0
                            ? "no"
                            : "yes"
                        }>
                        Zero Depreciation
                      </div>
                    </div>
                  )}
                  {ADDON_STATUS.pa_owner_cover.value && (
                    <div className="Section_li">
                      <div
                        className={
                          recalculatedPremium?.paOwnerDriver === 0
                            ? "no"
                            : "yes"
                        }>
                        Personal Accident Cover
                      </div>
                    </div>
                  )}
                  {ADDON_STATUS.rsa.value && (
                    <div className="Section_li">
                      <div
                        className={
                          recalculatedPremium?.roadSideAssistance === 0
                            ? "no"
                            : "yes"
                        }>
                        24x7 Roadside Assistance
                      </div>
                    </div>
                  )}
                </Box>
                <p className="premium">
                  Premium{" "}
                  {`${data.old_premium}` !== `${data.new_premium}` ? (
                    <span>
                      {formatNumberWithCurrencySymbol(data.new_premium)}
                    </span>
                  ) : null}
                  {formatNumberWithCurrencySymbol(data.old_premium)}
                </p>

                <h5 className="Idv_value">
                  IDV
                  <span>
                    {`${data.old_idv}` !== `${data.new_idv}` ? (
                      <>{formatNumberWithCurrencySymbol(data.old_idv)}</>
                    ) : null}
                  </span>
                  {formatNumberWithCurrencySymbol(data.new_idv)}
                </h5>
              </Grid>

              <Grid xs={12} style={{ textAlign: "center" }}>
                <CustomButton
                  text_name={"Continue"}
                  class_name="mediumPrimaryBtn"
                  onClickFunction={() => {
                    setPolicyRevisionPopup(true);
                    ContinueFun();
                  }}
                />
              </Grid>
              <Grid xs={12} style={{ textAlign: "center" }} className="ctaBtn">
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    let url = `${FRONTEND_DOMAIN}${TW_ROUTES.QUOTE}?type=TW&quote_no=${ADD_FORM_RESPONSE.quote_no}`;

                    // Check if the URL starts with "http:/" but not "http://"
                    if (
                      url.startsWith("http:/") &&
                      !url.startsWith("http://")
                    ) {
                      url = url.replace("http:/", "http://");
                    } else if (
                      !url.startsWith("http://") &&
                      !url.startsWith("https://")
                    ) {
                      url = "https://" + url;
                    }
                    window.location.href = url;
                  }}>
                  Go back to Quotes.
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default PremRecalPopUp;
