import { LARAVEL_DOMAIN, NODE_DOMAIN } from "../Domain";

export const TERM_URLS = {
  TERM_RIDERS_LARAVEL: `${LARAVEL_DOMAIN}/api/common/get-riders`,
  TERM_RIDERS_NODE: `${NODE_DOMAIN}/api/v1/term-plans/term-riders`,
  PREMIUM_TERM_DETAILS: `${LARAVEL_DOMAIN}/api/common/get-proposal`,
  PERPOSAL_TERM_DETAILS_LARAVEL: `${LARAVEL_DOMAIN}/api/common/get-proposal-journey`,
  PERPOSAL_TERM_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/get-proposal-journey`,
  PROPOSER_OCCUPATION_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/product-occupation-maps`,
  PROPOSER_QUALIFICATION_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/product-education-maps`,
  GET_REDIRECTION_URL_LARAVEL: `${LARAVEL_DOMAIN}/api/common/get-proposal`,
  GET_REDIRECTION_URL: `${NODE_DOMAIN}/api/v1/term-plans/get-proposal`,
  UPDATE_RIDERS_STATUS: `${NODE_DOMAIN}/api/v1/term-plans/term-riders`,
};

//  TERM_RIDERS_LARAVEL: `${LARAVEL_DOMAIN}/api/common/get-riders`,
//   PREMIUM_TERM_DETAILS: `${LARAVEL_DOMAIN}/api/common/get-proposal`,
// PERPOSAL_TERM_DETAILS: `${LARAVEL_DOMAIN}/api/common/get-proposal-journey`,
//   PERPOSAL_TERM_DETAILS: `${NODE_DOMAIN}/api/v1/term-plans/get-proposal-journey`,
//   PROPOSER_OCCUPATION_DETAILS: `${LARAVEL_DOMAIN}/api/common/product-occupation-maps`,
//   PROPOSER_QUALIFICATION_DETAILS: `${LARAVEL_DOMAIN}/api/common/product-education-maps`,
// GET_REDIRECTION_URL: `${LARAVEL_DOMAIN}/api/common/get-proposal`,
//   GET_REDIRECTION_URL: `${NODE_DOMAIN}/api/v1/term-plans/get-proposal`,
//   UPDATE_RIDERS_STATUS: `${NODE_DOMAIN}/api/v1/term-plans/term-riders`,
