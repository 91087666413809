import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../Store/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../SupportingFiles/HelpingFunction";

const HealthLeftSidebar = () => {
  const [totalChild, setTotalChild] = useState<number>(0);
  const { ADD_FORM, QUOTE_LOADER, SELECTED_QUOTE_DATA, POLICY_TENURE } =
    useAppSelector((state) => state.Health);
  const [quote_data, setquote_data] = useState<number>(0);
  useEffect(() => {
    setquote_data(SELECTED_QUOTE_DATA?.premiumDetails.finalPremium);
  }, [SELECTED_QUOTE_DATA]);
  useEffect(() => {
    const total_child: number = ADD_FORM.children;

    setTotalChild(total_child);
  }, [ADD_FORM]);
  console.log("ADD_FORM", ADD_FORM);
  console.log("SELECTED_QUOTE_DATA<<<<<<<>>>>>>>>>>>", SELECTED_QUOTE_DATA);
  return (
    <Grid xs className="proposalLeftSidebar">
      <Box className={"healthIcon"}></Box>
      <h6 className="mb-2">
        {ADD_FORM.spouse_status ? "2 Adults" : "1 Adult"}
        {totalChild === 0 ? null : `,`}
        <span className="p-1">
          {totalChild === 0
            ? null
            : totalChild === 1
            ? `${totalChild} Child`
            : `${totalChild} Children`}
        </span>
      </h6>
      <img
        className="insurerLogo"
        src={SELECTED_QUOTE_DATA?.CompanyDetails?.logo}
      />
      <h6 className="mb-6">
        {SELECTED_QUOTE_DATA.productDetails?.product_name}
      </h6>

      <h5>
        {formatToCurrency(
          Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured)
        )}
      </h5>
      <p className="mb-6">Sum Insured</p>
      {/* <p className="mb-8">{cover_type}</p> */}

      {/* {cover_value === undefined ? null : (
        <>
          <h5>
            <CurrencyRupeeIcon />
            {cover_value}
          </h5>
          <p className="mb-8">{cover_type_two}</p>
        </>
      )} */}
      <Box>
        <h5>
          {SELECTED_QUOTE_DATA.premiumDetails.term === 1
            ? "1 Year"
            : `${SELECTED_QUOTE_DATA.premiumDetails.term} Year's`}
        </h5>
        <p className="mb-6">Policy Tenure</p>
      </Box>

      {/* {payment_term === undefined ? null : (
        <>
          <h5>{payment_term}</h5>
          <p className="mb-8">{payment_term_label}</p>
        </>
      )} */}

      {/* <h5>{payment_mode}</h5> */}

      <h5 className="premium">
        {QUOTE_LOADER || quote_data === 0 ? (
          <img src="../images/loader.svg" alt="" height="12px" />
        ) : (
          <>{formatAmountToCurrency(Number(quote_data))}</>
        )}
        {}
      </h5>
      <p className="mb-6">Premium (Incl. GST)</p>
    </Grid>
  );
};

export default HealthLeftSidebar;
