import { PHP_DOMAIN } from "../Domain";

export const CKYC_URLS = {
  CKYC_DROPDOWN_MASTER: `${PHP_DOMAIN}/api/ckyc-ekyc-details/kyc-document-lists.json`,
  VERIFY_HDFC_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/hdfc-ergo.json`,
  VERIFY_FUTURE_GENERALI_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/future-generali.json`,
  VERIFY_RELIANCE_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/reliance.json`,
  VERIFY_KOTAK_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/kotak.json`,
  VERIFY_BAJAJ_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/bajaj.json`,
  MANUAL_BAJAJ_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/bajaj-upload-doc.json`,
  VERIFY_ICICI_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iciciLombard.json`,
  VERIFY_IFFCO_TOKIO_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco.json`,
  VERIFY_RSA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/royal-sund.json`,
  VERIFY_RSA_HP__CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/royalSundHealth.json`,
  VERIFY_TATA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/tata.json`,
  VERIFY_GO_DIGIT_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/go-digit.json`,
  HDFC_MANUAL_KYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/kyc-document-lists.json`,
  ICICI_MANUAL_KYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/icici-kyc-verify-manual.json`,
  IFFCO_TOKIO_MANUAL_KYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iffco-create-kyc.json`,
  GET_CKYC_DETAILS_FROM_HDFC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/get-kyc-detail-hdfc.json`,
  GET_CKYC_DETAILS_FROM_KOTAK: `${PHP_DOMAIN}/api/ckyc-ekyc-details/getKycDetailKotak.json`,
  GET_CKYC_DETAILS_FROM_RSA: `${PHP_DOMAIN}/api/ckyc-ekyc-details/getKycDetailRoyalSund.json`,
  VERIFY_CARE_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/religare.json`,
  VERIFY_ICICI_CKYC_HEALTH: `${PHP_DOMAIN}/api/ckyc-ekyc-details/iciciLombardHealth.json`,
  VERIFY_STAR_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/star.json`,
  VERIFY_ADITYA_BIRLA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/adityaBirla.json`,
  GET_CITY_BY_PINCODE: `${PHP_DOMAIN}/api/Stars/getStarCity.json`,
  GET_AREA_BY_CITY: `${PHP_DOMAIN}/api/Stars/getStarArea.json`,
  GET_FAMILY_RELATION: `${PHP_DOMAIN}/api/product-nominee-relationship-maps/get-adult-relationship-list.json`,
  VERIFY_STAR_MANUAL_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/starUploadDoc.json`,
  VERIFY_NIVA_BUPA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/maxbupa.json`,
  VERIFY_CIGNA_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/cigna.json`,
  VERIFY_CIGNA_MANUAL_CKYC: `${PHP_DOMAIN}/api/ckyc-ekyc-details/cigna-upload-doc.json`,
  VERIFY_BAJAJ_CKYC_HEALTH: `${PHP_DOMAIN}/api/CkycEkycDetails/bajajHealth.json`,
  GET_KYC_DETAIL_ADITYA_BIRLA: `${PHP_DOMAIN}/api/ckyc-ekyc-details/getKycDetailAdityaBirla.json`,
};
