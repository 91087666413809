import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

const MTermNavbar = () => {
  const navigate = useNavigate();

  return (
    <Box className="mProposalNavbar">
      <Grid container columnSpacing={0} rowSpacing={3}>
        <Grid xs={12}>
          {/* <Link ><img src="images/evervent-logo.svg" height="36px" alt="Brand Logo"/></Link> */}
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MTermNavbar;
