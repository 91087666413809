import { PostAPI } from "../../Axios/AxiosInstanceToken";
import { TERM_URLS } from "../../URLCollection/Term/TermURLs";

const RIDERS_BENEFITS_DETAIL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.TERM_RIDERS_LARAVEL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const RIDERS_BENEFITS_DETAIL_NODE = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.TERM_RIDERS_NODE,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_PREMIUM_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.PREMIUM_TERM_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_PROPOSER_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.PERPOSAL_TERM_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_PROPOSER_DETAILS_LARAVEL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.PERPOSAL_TERM_DETAILS_LARAVEL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const PROPOSER_OCCUPATION_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.PROPOSER_OCCUPATION_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const PROPOSER_QUALIFICATION_DETAILS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.PROPOSER_QUALIFICATION_DETAILS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_REDIRECTION_URL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.GET_REDIRECTION_URL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_REDIRECTION_URL_LARAVEL = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.GET_REDIRECTION_URL_LARAVEL,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const UPDATE_RIDERS_STATUS = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  dto: any
) => {
  PostAPI.call(
    TERM_URLS.UPDATE_RIDERS_STATUS,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TERM_SERVICES = {
  RIDERS_BENEFITS_DETAIL,
  UPDATE_PROPOSER_DETAILS,
  PROPOSER_OCCUPATION_DETAILS,
  PROPOSER_QUALIFICATION_DETAILS,
  GET_REDIRECTION_URL,
  UPDATE_RIDERS_STATUS,
  UPDATE_PREMIUM_DETAILS,
  UPDATE_PROPOSER_DETAILS_LARAVEL,
  GET_REDIRECTION_URL_LARAVEL,
  RIDERS_BENEFITS_DETAIL_NODE,
};
