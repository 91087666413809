import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Id, toast } from "react-toastify";
import RidersBenefits from "../../../Page/Desktop/Term/ShortPay/ICICI/RidersBenefits";
import MRidersBenefits from "../../../Page/Mobile/Term/ICICI/MRidersBenefits";
import { TERM_ROUTES } from "../../../Router/Path/TermRoutes";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { formatAmountToCurrency } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { DTOTermRiderStatus } from "../../../types/Term/DTOTermRidersStatus";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";

export type TICICIRidersStatus = {
  TF001: boolean;
  TF002: boolean;
  TF003: boolean;
  TF004: boolean;
};
const RidersBenefitsContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
    PROPOSER_DATA,
    QUOTE_FIELDS_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    LOADER,
  } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const navigate = useNavigate();
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf002, setLoaderTf002] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);

  let toastID: Id;
  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      // const results = data.results;
      const results = data.response;
      const error = data.error;
      const message = data.message;
      const response: TRidersBenefitsResponse = results;
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      if (!error) {
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...Term,
            RIDERS_BENEFITS: {
              ...Term.RIDERS_BENEFITS,
              riders_benefits_response: response,
            },
            SELECTED_QUOTE_DATA: {
              ...Term.SELECTED_QUOTE_DATA,
              premiumDetails: {
                ...Term.SELECTED_QUOTE_DATA.premiumDetails,
                pay_term: response.pay_term,
                term: response.term,
              },
            },
          })
        );
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      toast.dismiss(toastID);
      setLoaderTf001(false);
      setLoaderTf002(false);
      setLoaderTf003(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
      criticalIllnessSelected:
        QUOTE_FIELDS_DATA.payMode === "5"
          ? "No"
          : RIDERS_BENEFITS.local_value.tf001Status
          ? "Yes"
          : "No",
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Value,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      criticalIllnessValue:
        QUOTE_FIELDS_DATA.payMode === "5"
          ? ""
          : RIDERS_BENEFITS.local_value.tf001Value,
      // name: PROPOSER_DATA.full_name?.value,
      // mobile: ADD_FORM.mobile?.value,
      // pincode: ADD_FORM.pincode?.value,
      // company_code: SELECTED_QUOTE_DATA?.companyDetails?.company_code,
      // frequency: `${QUOTE_FIELDS_DATA?.payMode}`,
      // gender: ADD_FORM.gender?.value,
      // pay_term: `${SELECTED_QUOTE_DATA.premiumDetails?.pay_term}`,
      // product_code: SELECTED_QUOTE_DATA.productDetails?.product_code,
      // smoker: ADD_FORM.smoke,
      // term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
      // dob: ADD_FORM.age.value,

      // accidentalDisSelected: "No",
      // personalAccSelected: "No",
      // cancerCovSelected: "No",

      // accidentalDisValue: "",

      // adb: "No",
      // rop: "No",
      // wop: "No",
      // income_period: SELECTED_QUOTE_DATA.premiumDetails.income_period,
      // sum_assured: `${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`,
    };
    toast.dismiss(toastID);
    toastID = toast.loading("Please wait...");
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const continue_function = () => {
    navigate(TERM_ROUTES.BASIC_DETAILS);
  };

  useEffect(() => {
    const start_tf001 = 100000;
    const end_tf001 =
      parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`) > 10000000
        ? 10000000
        : parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`);

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 100000) {
      data_tf001.push({ value: `${i}`, label: formatAmountToCurrency(i) });
    }

    setTf001Data(data_tf001);

    const start_tf003 = 100000;
    const end_tf003 =
      parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`) > 20000000
        ? 20000000
        : parseInt(`${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`);

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 100000) {
      data_tf003.push({ value: `${i}`, label: formatAmountToCurrency(i) });
    }

    setTf003Data(data_tf003);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
    console.log("Umesh");
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf001Value,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [RIDERS_BENEFITS.local_value.tf001Status]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [RIDERS_BENEFITS.local_value.tf003Status]);

  return (
    <>
      {LOADER || loaderTf001 || loaderTf002 || loaderTf003 ? (
        <Box
          position={"fixed"}
          height={"100%"}
          width={"100%"}
          top="0"
          left="0"
          zIndex={911}
        />
      ) : null}
      {isMobile ? (
        <MRidersBenefits
          forward={continue_function}
          tf001Data={tf001Data}
          tf003Data={tf003Data}
          loaderTf001={loaderTf001}
          loaderTf003={loaderTf003}
        />
      ) : (
        <RidersBenefits
          loaderTf001={loaderTf001}
          forward={continue_function}
          tf003Data={tf003Data}
          tf001Data={tf001Data}
          loaderTf003={loaderTf003}
        />
      )}
    </>
  );
};

export default RidersBenefitsContainer;
