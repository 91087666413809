import { Box } from "@mui/material";
import React from "react";
import "./MemberRadioButton.scss";

interface Props {
  input_name: string | undefined;
  input_id: string | undefined;
  label_name: string | undefined;
  class_name: string | undefined;
  input_type: string;
  value_update: Function;
  attrName: any;
  checked: boolean;
  checkedValue?: string;
  value?: boolean;
}

const MemberRadioButton: React.FC<Props> = ({
  class_name,
  input_id,
  input_name,
  label_name,
  input_type,
  value_update,
  attrName,
  checked,
  checkedValue,
  value = false,
}) => {
  return (
    <Box className="insuredMembers">
      <input
        id={input_id}
        checked={value}
        name={input_name}
        type={input_type}
        onChange={(e: any) => {
          value_update(attrName, e.target.checked);
        }}
      />
      <label htmlFor={input_id} className={class_name}>
        {label_name}
      </label>
    </Box>
  );
};

export default MemberRadioButton;
