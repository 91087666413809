import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import { useAppSelector } from "../../../../Store/hooks";
import { TKOTAK_EDGE } from "../../../../types/Health/ProposalDeatail/TKotak/TKOTAK";
import Footer from "../../../../Component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import MFooter from "../../../../Component/MFooter/MFooter";

const MAddonDetailsKotak = ({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TKOTAK_EDGE;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) => {
  const navigate = useNavigate();
  const { ADD_FORM, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );
  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid xs={12}>
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Critical Illness Cover"
          toggle_status={addonStatus.critical_illness}
          value_update={updateMasterState}
          attrName={"critical_illness"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Compassionate Visit"
          toggle_status={addonStatus.compassionate_visit}
          value_update={updateMasterState}
          attrName={"compassionate_visit"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Air Ambulance Cover"
          toggle_status={addonStatus.air_ambulance}
          value_update={updateMasterState}
          attrName={"air_ambulance"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Personal Accident Cover"
          toggle_status={addonStatus.personal_accident}
          value_update={updateMasterState}
          attrName={"personal_accident"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Claim Protect"
          toggle_status={addonStatus.claim_protect}
          value_update={updateMasterState}
          attrName={"claim_protect"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Inflation Protect"
          toggle_status={addonStatus.inflation_proptect}
          value_update={updateMasterState}
          attrName={"inflation_proptect"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Super NCB"
          toggle_status={addonStatus.super_ncb}
          value_update={updateMasterState}
          attrName={"super_ncb"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Restoration Benefit Plus"
          toggle_status={addonStatus.restoration_benefit_plus}
          value_update={updateMasterState}
          attrName={"restoration_benefit_plus"}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Maternity Benefit"
          toggle_status={addonStatus.maternity_benefit}
          value_update={updateMasterState}
          attrName={"maternity_benefit"}
          disable={ADD_FORM.gender.value === "M" ? true : false}
          desc="For Female Only"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="New Born Baby Cover"
          toggle_status={addonStatus.new_born_baby_cover}
          value_update={updateMasterState}
          attrName={"new_born_baby_cover"}
          disable={ADD_FORM.gender.value === "M" ? true : false}
          desc="For Female Only"
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Vaccination Expenses"
          toggle_status={addonStatus.vaccination_expenses}
          value_update={updateMasterState}
          attrName={"vaccination_expenses"}
          disable={ADD_FORM.gender.value === "M" ? true : false}
          desc="For Female Only"
        />
        {SELECTED_QUOTE_DATA.productDetails.id === 2025 ? (
          <>
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Hospital Daily Cash"
              toggle_status={addonStatus.hospital_daily_cash}
              value_update={updateMasterState}
              attrName={"hospital_daily_cash"}
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Home Nursing Benefit"
              toggle_status={addonStatus.home_nursing_benefit}
              value_update={updateMasterState}
              attrName={"home_nursing_benefit"}
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Daily Cash for Accompanying an Insured Child"
              toggle_status={addonStatus.daily_cash}
              value_update={updateMasterState}
              attrName={"daily_cash"}
            />
            <AddonQuestion
              addon_amount=""
              main_class="addonQuestion borderBottom"
              icon_class="bonus"
              question_name="Convalescence Benefit"
              toggle_status={addonStatus.convalescence_benefit}
              value_update={updateMasterState}
              attrName={"convalescence_benefit"}
            />
            {Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <=
            500000 ? (
              <AddonQuestion
                addon_amount=""
                main_class="addonQuestion borderBottom"
                icon_class="bonus"
                question_name="Optional Cover"
                toggle_status={addonStatus.cap_on_room_rent}
                value_update={updateMasterState}
                attrName={"cap_on_room_rent"}
              />
            ) : null}
          </>
        ) : null}
      </Grid>
      <MFooter
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        attrName={"pageStatus"}
        forward={() => {
          validate();
        }}
        value={0}
        backward={() => {
          navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`);
        }}
      />
    </Box>
  );
};

export default MAddonDetailsKotak;
