import { Route, Routes } from "react-router";
import RelianceCKYCContainer from "../../../../../Container/CKYC/Reliance/RelianceCKYCContainer";
import AddressDetailsContainer from "../../../../../Container/Car/Common/AddressDetailsContainer";
import NomineeDetailsContainer from "../../../../../Container/Car/Common/NomineeDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/Car/Common/ReviewDetailsContainer";
import VehicleDetailsContainer from "../../../../../Container/Car/Common/VehicleDetailsContainer";
import OwnerDetailsContainer from "../../../../../Container/Car/Reliance/OwnerDetailsContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { CAR_ROUTES } from "../../../../Path/CarRoutes";
import CarProposalContainer from "../../CarProposalContainer";

export const Reliance_ROUTES = (
  <Routes>
    <Route path={CAR_ROUTES.PROPOSAL_PAGE} element={<CarProposalContainer />}>
      <Route path={CKYC_ROUTES.CKYC} element={<RelianceCKYCContainer />} />
      <Route
        path={CAR_ROUTES.OWNER_DETAILS}
        element={<OwnerDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.VEHICLE_DETAILS}
        element={<VehicleDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.PREVIEW_DEATILS}
        element={<ReviewDetailsContainer />}
      />
    </Route>
  </Routes>
);
