import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ProposerDetails from "../../../Page/Desktop/Term/ShortPay/HDFC/ProposerDetails";
import MProposerDetails from "../../../Page/Mobile/Term/HDFC/MProposerDetails";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TTermProposerData } from "../../../types/TTermSlice";

const BasicDetailsContainer = () => {
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const { SELECTED_QUOTE_DATA, ADD_FORM, PROPOSER_DATA } = useAppSelector(
    (state) => state.Term
  );
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const state = useAppSelector((state) => state);
  const [proposerDetails, setProposerDetails] = useState<TTermProposerData>(
    state.Term.PROPOSER_DATA
  );

  useEffect(() => {
    setProposerDetails((prev) => ({ ...PROPOSER_DATA, dob: ADD_FORM.age }));
    getOccupationList();

    setProposerDetails({
      ...state.Term.PROPOSER_DATA,
      full_name: { value: "", warning: false },
      mobile: { value: ADD_FORM.mobile.value, warning: false },
      email: { value: "", warning: false },
      gender: { value: ADD_FORM.gender.value, warning: false },
      dob: {
        value: ADD_FORM.age.value,
        warning: false,
      },
    });
  }, [SELECTED_QUOTE_DATA]);

  const getOccupationList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const occupationData: Array<TDropdown> = response.map((obj: any) => {
          return {
            value: obj.code,
            label: obj.title,
          };
        });

        dispatch(
          TermSlice.actions.SET_DROPDOWN_DATA({
            key: "OCCUPATION_DATA",
            value: occupationData,
          })
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };
    // PROPOSAL_PAGE_SERVICES.getOccupationList(onSuccess, onError, {
    //   product_code: selectedQuote.product_code,
    //   product_type_code: selectedQuote.product_type_code,
    //   buy_online_code: selectedQuote.buy_online_code,
    // });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "is_proposer_is_insured_member") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: value,
      }));
    } else if (attrName[0] === "full_name") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "mobile") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "emergency_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName[0] === "email") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateEmail(value),
        },
      }));
    } else if (attrName[0] === "aadhar_number") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateAadharNumber(value),
        },
      }));
    } else if (attrName[0] === "occupation") {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
      if (value === "BSEM") {
        attrName[1]((prev: any) => ({
          ...prev,
          ["org_name"]: {
            value: "Others",
            warning: false,
          },
        }));
      }
    } else {
      attrName[1]((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: attrName[2] === false ? false : isEmpty(value),
        },
      }));
    }
  };

  const validateForm = () => {
    let proposer_details_data: TTermProposerData = { ...proposerDetails };

    proposer_details_data = {
      ...proposer_details_data,
      full_name: {
        ...proposer_details_data.full_name,
        warning: !validateFullName(proposer_details_data.full_name.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      dob: {
        ...proposer_details_data.dob,
        warning: isEmpty(proposer_details_data?.dob?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      gender: {
        ...proposer_details_data.gender,
        warning: isEmpty(proposer_details_data?.gender?.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      mobile: {
        ...proposer_details_data.mobile,
        warning: !validateMobileNumber(proposer_details_data.mobile.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      email: {
        ...proposer_details_data.email,
        warning: !validateEmail(proposer_details_data.email.value),
      },
    };
    proposer_details_data = {
      ...proposer_details_data,
      occupation: {
        ...proposer_details_data.occupation,
        warning: isEmpty(proposer_details_data?.occupation?.value?.label),
      },
    };

    setProposerDetails(proposer_details_data);

    let hasError = false;

    if (
      proposer_details_data.full_name.warning ||
      proposer_details_data.dob.warning ||
      proposer_details_data.gender.warning ||
      proposer_details_data.mobile.warning ||
      proposer_details_data.email.warning ||
      proposer_details_data.occupation.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({
          ...Term,
          PROPOSER_DATA: { ...proposer_details_data },
        })
      );

      updateProposerDetailsData(proposer_details_data);
    }
    console.log("hasError", hasError);
  };

  const updateProposerDetailsData = (proposerDetails: TTermProposerData) => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        hdfcCreatePaymentLink();
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    // const data = {
    //   buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    //   quote_no: btoa(SELECTED_QUOTE_DATA.QuoteNo),
    //   section: PROPOSAL_PAGE_SECTION_STATUS_API.PROPOSAL_DETAILS,
    //   details: {
    //     proposer_relationship: proposerDetails.is_proposer_is_insured_member
    //       ? "Yes"
    //       : "No",
    //     aadhar_number: proposerDetails.aadhar_number.value,
    //     insured_aadhar: insuredMemberDetails.aadhar_number.value,
    //     insured_dob: FORMAT_YYYY_MM_DD(insuredMemberDetails.dob.value),
    //     insured_email: insuredMemberDetails.email.value,
    //     insured_gender: insuredMemberDetails.gender.value,
    //     insured_marital_status: insuredMemberDetails.marital_status.value.code,
    //     insured_mobile: insuredMemberDetails.mobile.value,
    //     insured_name: insuredMemberDetails.full_name.value,
    //     insured_occupation: insuredMemberDetails.occupation.value.code,
    //     premium: selectedQuote.premium,
    //     proposer_annual_income: proposerDetails.annual_income.value.code,
    //     proposer_dob: FORMAT_YYYY_MM_DD(proposerDetails.dob.value),
    //     proposer_email: proposerDetails.email.value,
    //     proposer_emergency_no: proposerDetails.emergency_number.value,
    //     proposer_gender: proposerDetails.gender.value,
    //     proposer_marital_status: proposerDetails.marital_status.value.code,
    //     proposer_mobile: proposerDetails.mobile.value,
    //     proposer_name: proposerDetails.full_name.value,
    //     proposer_occupation: proposerDetails.occupation.value.code,
    //     proposer_occDesc: proposerDetails.occupation_desc.value,
    //     proposer_education: proposerDetails.education.value.code,
    //   },
    // };
    // setLoader(true);
    // PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
    //   onSuccess,
    //   onError,
    //   data
    // );
  };

  const hdfcCreatePaymentLink = () => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        window.location.href = response.redirectionUrl;
        // dispatch(
        //   TermSlice.actions.updateQuoteData([
        //     {
        //       key: "appno",
        //       value: response.appno,
        //     },
        //   ])
        // );
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      toast.error("There are some technical error. Please contact to admin.");
      console.log(err);
    };

    // const data = {
    //   buy_online_code: selectedQuote.buy_online_code,
    //   product_id: `${selectedQuote.id}`,
    //   quote_no: selectedQuote.quote_no,
    //   age: `${selectedQuote.age}`,
    //   city: selectedQuote.city,
    //   company_code: selectedQuote.company_code,
    //   cover_pay_upto: selectedQuote.cover_pay_upto,
    //   cover_upto: selectedQuote.cover_upto,
    //   frequency: `${selectedQuote.frequency}`,
    //   gender: selectedQuote.gender,
    //   pay_term: `${selectedQuote.pay_term}`,
    //   policy_covers: selectedQuote.policy_covers,
    //   product_code: selectedQuote.product_code,
    //   product_desc: selectedQuote.product_desc,
    //   smoker: selectedQuote.smoker,
    //   state: selectedQuote.state,
    //   sum_assured: selectedQuote.sum_insured,
    //   term: `${selectedQuote.term}`,
    //   name: `${proposerDetails.full_name.value}`,
    //   annual_income: `${proposerDetails.annual_income.value.code}`,
    //   request_for: "RIDERREC",
    //   dob: `${selectedQuote.dob_date}-${selectedQuote.dob_month}-${selectedQuote.dob_year}`,
    //   email: `${proposerDetails.email.value}`,
    //   criticalIllnessSelected: RIDERS_BENEFITS.local_value.tf001Status
    //     ? "Yes"
    //     : "No",
    //   accidentalDisSelected: RIDERS_BENEFITS.local_value.tf002Status
    //     ? "Yes"
    //     : "No",
    //   personalAccSelected:
    //     selectedQuote.product_code === "LTH101"
    //       ? "No"
    //       : RIDERS_BENEFITS.local_value.tf003Status
    //       ? "Yes"
    //       : "No",
    //   cancerCovSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
    //   accidentalDeaSelected:
    //     selectedQuote.product_code === "LTH101"
    //       ? "No"
    //       : RIDERS_BENEFITS.local_value.tf005Status
    //       ? "Yes"
    //       : "No",
    //   criticalIllnessValue: RIDERS_BENEFITS.local_value.tf001Status
    //     ? RIDERS_BENEFITS.local_value.tf001Value
    //     : RIDERS_BENEFITS.local_value.tf004Value,
    //   accidentalDisValue: RIDERS_BENEFITS.local_value.tf002Value,
    //   accidentalDeathValue:
    //     selectedQuote.product_code === "LTH101"
    //       ? ""
    //       : RIDERS_BENEFITS.local_value.tf003Status
    //       ? RIDERS_BENEFITS.local_value.tf003Value
    //       : RIDERS_BENEFITS.local_value.tf005Value,
    //   adb: RIDERS_BENEFITS.local_value.BNF001Status ? "Yes" : "No",
    //   rop: RIDERS_BENEFITS.local_value.BNF002Status ? "Yes" : "No",
    //   wop: RIDERS_BENEFITS.local_value.BNF003Status ? "Yes" : "No",
    // };
    // PROPOSAL_PAGE_SERVICES.hdfcCreatePaymentLink(onSuccess, onError, data);
  };

  // const getInsuranceCompanyMaster = () => {
  //   const onSuccess = (data: any) => {
  //     const results = data.results;
  //     const error = results.error;
  //     const message = results.message;
  //     const response = results.response;

  //     if (error === false) {
  //       let data: TDropdown[] = response.map((obj: any) => {
  //         return {
  //           value: obj.code,
  //           label: obj.title,
  //         };
  //       });

  //       dispatch(TermSlice.actions.setInsuranceCompany(data));
  //     } else {
  //       console.log({ error: error, message: message });
  //     }
  //   };
  //   const onError = (err: any) => {
  //     console.log(err);
  //   };
  //   PROPOSAL_PAGE_SERVICES.getInsuranceCompanyMaster(
  //     onSuccess,
  //     onError,
  //     selectedQuote.company_code,
  //     selectedQuote.product_type_code
  //   );
  // };

  return (
    <>
      {isMobile ? (
        <MProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      ) : (
        <ProposerDetails
          proposerDetails={proposerDetails}
          setProposerDetails={setProposerDetails}
          updateMasterState={updateMasterState}
          validateForm={validateForm}
          loader={loader}
        />
      )}
    </>
  );
};

export default BasicDetailsContainer;
