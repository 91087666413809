import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TCKYCDetails } from "../../../../types/TermFullJourney/TCKYCDetails";

const SET_ICICI_CKYC_DETAILS = (
  state: TTermFullJourneySlice,
  action: PayloadAction<TCKYCDetails>
) => {
  const data: TTermFullJourneySlice = { ...state, ckycDetails: action.payload };
  return data;
};

export const TERM_CKYC_REDUCER = { SET_ICICI_CKYC_DETAILS };
