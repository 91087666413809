import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../Store/hooks";
import {
  calculateAgeInDays,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "../MedicalQuestion/MedicalQuestion.scss";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import { subDays } from "date-fns";
import CustomCheckbox from "../FieldTypes/CustomCheckbox/CustomCheckbox";
import BorderRadioButton from "../FieldTypes/BorderRadioButton/BorderRadioButton";

interface Props {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
  any_question_or_sub_question?: any;
  show_toggle_status?: boolean;
  addonStatus: any;
}
const NivaBupaAddonQuestion: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  dataSubQuestion,
  updateMasterState,
  any_question_or_sub_question,
  show_toggle_status = true,
  addonStatus,
}) => {
  const { MEDICAL_QUESTION_DATA_STAR, QUOTE_LOADER, SELECTED_QUOTE_DATA } =
    useAppSelector((state) => state.Health);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };
  const LifeOfManagement_data: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "MEDICAL";
    } else if (i === 2) {
      status = "SURGICAL";
    }
    LifeOfManagement_data.push({ value: status, label: `${status}` });
  }

  const CurrentStatus_data: any = [];

  for (let i = 1; i <= 3; i++) {
    let status;
    if (i === 1) {
      status = "CURED";
    } else if (i === 2) {
      status = "NOT TREATED";
    } else if (i === 3) {
      status = "ONGOING";
    }

    CurrentStatus_data.push({ value: status, label: `${status}` });
  }
  const Details_of_treatment: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "Yes";
    } else if (i === 2) {
      status = "NO";
    }

    Details_of_treatment.push({ value: status, label: `${status}` });
  }

  const checkQuation = Object.keys(MEDICAL_QUESTION_DATA_STAR).map(
    (data) => data
  );

  return (
    <Box className={main_class}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner mb-5">
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
            disabled={QUOTE_LOADER}
          />
        </Grid>
      </Grid>
      {any_question_or_sub_question}
      {data?.main_question.id === "MQ02" ? (
        <Grid container spacing={2}>
          {data?.main_question?.field_data && data?.main_question?.status && (
            <>
              <Grid container spacing={2} textAlign="left">
                <Grid xs={12}>
                  <CustomCheckbox
                    label={"BEST CONSULT"}
                    value_update={updateMasterState}
                    value={
                      data?.main_question?.field_data?.["MQ1-SQ1-F1"].value
                        ?.value
                    }
                    defaultChecked={false}
                    attrName={"best_consult"}
                  />
                  <CustomCheckbox
                    label={"BEST CARE"}
                    value_update={updateMasterState}
                    value={
                      data?.main_question?.field_data?.["MQ1-SQ1-F2"].value
                        ?.value
                    }
                    defaultChecked={false}
                    attrName={"best_care"}
                  />
                </Grid>
                <div style={{ marginBottom: "16px" }}>
                  {data?.main_question?.field_data?.["MQ1-SQ1-F2"].value
                    ?.value ? (
                    <Grid>
                      <BorderRadioButton
                        variant="default"
                        attrName="best_care_value"
                        count="2"
                        options={[
                          { label: "5000", value: "5000" },
                          { label: "10000", value: "10000" },
                          { label: "15000", value: "15000" },
                          { label: "20000", value: "20000" },
                        ]}
                        onChange={updateMasterState}
                        value={addonStatus.best_care_value}
                        defaultValue={"best_care_value"}
                      />
                    </Grid>
                  ) : null}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      ) : null}

      {data?.main_question.id === "MQ01" ? (
        <Grid container spacing={2}>
          {data?.main_question?.field_data && data?.main_question?.status && (
            <Grid xs={8} lg={4} className="pl-14">
              <SelectDropdown
                class_name="inputField mb-5"
                title={"HVB Value"}
                attrName={"dropdown"}
                value={
                  data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.value
                }
                value_update={updateMasterState}
                warn_status={
                  data?.main_question?.field_data?.["MQ1-SQ1-F1"].value?.warning
                }
                data={
                  `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                    "500000"
                    ? [{ value: "1000", label: "1000" }]
                    : `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                      "1000000" ||
                      `${SELECTED_QUOTE_DATA.premiumDetails.sum_insured}` ===
                      "1500000"
                      ? [{ value: "2000", label: "2000" }]
                      : Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >=
                        1500000
                        ? [{ value: "4000", label: "4000" }]
                        : []
                }
                error_message={"Enter description"}
              />
            </Grid>
          )}
        </Grid>
      ) : null}
    </Box>
  );
};

export default NivaBupaAddonQuestion;
