import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ETermCompanyCode } from "../../../../../Enum/ETermCompanyCode";
import NomineeDetails from "../../../../../Page/Desktop/TermFullJourney/ICICI/Proposal/NomineeDetails/NomineeDetails";
import MNomineeDetails from "../../../../../Page/Mobile/TermFullJourney/ICICI/Proposal/NomineeDetails/MNomineeDetails";
import { TERM_FULL_JOURNEY_ROUTES } from "../../../../../Router/Path/TermFullJourneyRoutes";
import { NomineeDetailsAPIDTO } from "../../../../../Services/DTO/TermFullJourney/NomineeDetailsAPIDTO";
import { PROPOSAL_PAGE_SERVICES } from "../../../../../Services/TermFullJourneyServices/ProposalPageServices";
import { TermFullJourneySlice } from "../../../../../Store/Slice_Reducer/TermFullJourney/TermFullJourneySlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
  validatePincode,
} from "../../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../../types/TermFullJourney/TDropdown";
import { TNomineeDetails } from "../../../../../types/TermFullJourney/TNomineeDetails";
import { PROPOSAL_PAGE_SECTION_STATUS_API } from "../../../../../types/TermFullJourney/TProposerDetails";

function NomineeDetailsContainer() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);
  const proposerPage = useAppSelector(
    (state) => state.TermFullJourney.proposerPage
  );
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Term
  );
  const nominee_details = useAppSelector(
    (state) => state.TermFullJourney.nomineeDetails
  );

  const [nomineeDetails, setNomineeDetails] =
    useState<TNomineeDetails>(nominee_details);

  useEffect(() => {
    getNomineeRelationshipList();
  }, []);

  const getNomineeRelationshipList = () => {
    const onSuccess = (data: any) => {
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response = results.response;

      if (error === false) {
        const relationshipData: Array<TDropdown> = response.map((obj: any) => {
          return {
            key: obj.code,
            value: obj.title,
          };
        });

        dispatch(
          TermFullJourneySlice.actions.setNomineeRelationshipData(
            relationshipData
          )
        );
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      console.log(err);
    };
    PROPOSAL_PAGE_SERVICES.getNomineeRelationshipList(onSuccess, onError, {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: "TP",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "full_name") {
      setNomineeDetails((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning: !validateFullName(value),
        },
      }));
    } else if (attrName[0] === "add_beneficiary") {
      setNomineeDetails((prev: any) => {
        const updatedBeneficiary = [...prev.beneficiary];

        updatedBeneficiary.push({
          date_of_birth: { value: "", warning: false },
          full_name: { value: "", warning: false },
          relation_to_proposer: { value: "", warning: false },
          share: { value: "", warning: false },
        });

        return {
          ...prev,
          beneficiary: updatedBeneficiary,
        };
      });
    } else if (attrName[0] === "remove_beneficiary") {
      setNomineeDetails((prev: any) => {
        const updatedBeneficiary = [...prev.beneficiary];

        updatedBeneficiary.splice(attrName[1], 1);

        return {
          ...prev,
          beneficiary: updatedBeneficiary,
        };
      });
    } else if (attrName[0] === "beneficiary") {
      if (attrName[0] === "full_name") {
        setNomineeDetails((prev: any) => {
          const updatedBeneficiary = [...prev.beneficiary];

          updatedBeneficiary[attrName[2]] = {
            ...updatedBeneficiary[attrName[2]],
            [attrName[1]]: {
              value: value,
              warning: !validateFullName(value),
            },
          };

          return {
            ...prev,
            beneficiary: updatedBeneficiary,
          };
        });
      } else {
        setNomineeDetails((prev) => {
          const updatedBeneficiary = [...prev.beneficiary];

          updatedBeneficiary[attrName[2]] = {
            ...updatedBeneficiary[attrName[2]],
            [attrName[1]]: {
              value: value,
              warning: `${value}`.length > 0 ? false : true,
            },
          };

          return {
            ...prev,
            beneficiary: updatedBeneficiary,
          };
        });
      }
    } else if (attrName === "is_beneficiary") {
      setNomineeDetails((prev: any) => ({
        ...prev,
        is_beneficiary: value,
      }));
    } else if (attrName[0] === "trustee_details") {
      setNomineeDetails((prev: any) => ({
        ...prev,
        trustee_details: {
          ...prev.trustee_details,
          [attrName[1]]: { value: value, warning: !(`${value}`.length > 0) },
        },
      }));
    } else {
      setNomineeDetails((prev: any) => ({
        ...prev,
        [attrName[0]]: {
          value: value,
          warning:
            attrName[2] === false ? false : !(value.toString().length > 0),
        },
      }));
    }
  };

  const validateForm = () => {
    let nominee_details: TNomineeDetails = { ...nomineeDetails };

    if (!nominee_details.is_beneficiary) {
      nominee_details = {
        ...nominee_details,
        full_name: {
          ...nominee_details.full_name,
          warning: !validateFullName(`${nominee_details.full_name.value}`),
        },
      };
      nominee_details = {
        ...nominee_details,
        dob: {
          ...nominee_details.dob,
          warning: nominee_details?.dob?.value
            ? nominee_details?.dob?.value.toString().length <= 0
            : true,
        },
      };

      nominee_details = {
        ...nominee_details,
        relationship: {
          ...nominee_details.relationship,
          warning: nominee_details?.relationship?.value
            ? nominee_details?.relationship?.value.toString().length <= 0
            : true,
        },
      };
    } else {
      //Beneficery Details
      const updatedBeneficiaries = nominee_details.beneficiary.map(
        (beneficiary) => {
          const { full_name, date_of_birth, relation_to_proposer, share } =
            beneficiary;

          return {
            full_name: {
              ...full_name,
              warning: !validateFullName(`${full_name.value}`),
            },
            date_of_birth: {
              ...date_of_birth,
              warning:
                date_of_birth?.value === null ||
                date_of_birth?.value?.toString()?.length <= 0,
            },
            relation_to_proposer: {
              ...relation_to_proposer,
              warning:
                relation_to_proposer?.value === null ||
                relation_to_proposer?.value?.toString()?.length <= 0,
            },
            share: {
              ...share,
              warning:
                share?.value === null || share?.value?.toString()?.length <= 0,
            },
          };
        }
      );

      //Trustee details
      let trustee_data = nomineeDetails?.trustee_details;
      trustee_data = {
        ...trustee_data,
        name: {
          ...trustee_data.name,
          warning:
            trustee_data?.name?.value === null ||
            trustee_data?.name?.value?.toString().length <= 0,
        },
      };
      trustee_data = {
        ...trustee_data,
        dob: {
          ...trustee_data.dob,
          warning:
            trustee_data?.dob?.value === null ||
            trustee_data?.dob?.value?.toString().length <= 0,
        },
      };
      trustee_data = {
        ...trustee_data,
        trusteeType: {
          ...trustee_data.trusteeType,
          warning:
            trustee_data?.trusteeType?.value === null ||
            trustee_data?.trusteeType?.value?.toString().length <= 0,
        },
      };
      trustee_data = {
        ...trustee_data,
        address: {
          ...trustee_data.address,
          warning:
            trustee_data?.address?.value === null ||
            trustee_data?.address?.value?.toString().length <= 0,
        },
      };
      trustee_data = {
        ...trustee_data,
        city: {
          ...trustee_data.city,
          warning:
            trustee_data?.city?.value === null ||
            trustee_data?.city?.value?.toString().length <= 0,
        },
      };
      trustee_data = {
        ...trustee_data,
        pincode: {
          ...trustee_data.pincode,
          warning:
            trustee_data?.name?.value === null ||
            !validatePincode(trustee_data?.pincode?.value),
        },
      };
      trustee_data = {
        ...trustee_data,
        state: {
          ...trustee_data.state,
          warning:
            trustee_data?.state?.value === null ||
            trustee_data?.state?.value?.toString().length <= 0,
        },
      };
      trustee_data = {
        ...trustee_data,
        panNo: {
          ...trustee_data.panNo,
          warning:
            trustee_data?.panNo?.value === null ||
            !validatePAN(`${trustee_data?.panNo?.value}`),
        },
      };
      trustee_data = {
        ...trustee_data,
        mobileNo: {
          ...trustee_data.mobileNo,
          warning:
            trustee_data?.mobileNo?.value === null ||
            !validateMobileNumber(trustee_data?.mobileNo?.value),
        },
      };
      trustee_data = {
        ...trustee_data,
        email: {
          ...trustee_data.email,
          warning:
            trustee_data?.email?.value === null ||
            !validateEmail(`${trustee_data?.email?.value}`),
        },
      };

      nominee_details = {
        ...nomineeDetails,
        trustee_details: trustee_data,
        beneficiary: updatedBeneficiaries,
      };
    }

    setNomineeDetails(nominee_details);

    let hasError = false;
    if (!nominee_details.is_beneficiary) {
      if (
        nominee_details.full_name.warning === true ||
        nominee_details.dob.warning === true ||
        nominee_details.gender.warning === true ||
        nominee_details.relationship.warning === true
      ) {
        hasError = true;
      }
    } else {
      const hasWarning = (details: any): boolean => {
        return Object.values(details).some(
          (property: any) => property?.warning === true
        );
      };

      const hasBeneficiaryWarning: boolean = nominee_details.beneficiary.some(
        (beneficiary) => hasWarning(beneficiary)
      );
      const hasTrusteeWarning: boolean = hasWarning(
        nomineeDetails.trustee_details
      );

      if (hasBeneficiaryWarning || hasTrusteeWarning) {
        hasError = true;
      }
    }
    let share = 0;
    for (const element of nominee_details.beneficiary) {
      share = share + parseInt(`${element.share.value}`);
    }

    if (share > 100) {
      toast.error("Total share % exceeds 100%.");
    }
    if (!hasError) {
      dispatch(TermFullJourneySlice.actions.setNomineeDetails(nominee_details));

      updateNomineeDetailsData(nominee_details);
    }
  };

  const updateNomineeDetailsData = (nominee_details: TNomineeDetails) => {
    const onSuccess = (data: any) => {
      setLoader(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;

      console.log(error);
      if (error === false) {
        if (proposerPage.update_status) {
          navigate(-1);
          dispatch(TermFullJourneySlice.actions.updateUpdateStatus(false));
        } else {
          navigate(TERM_FULL_JOURNEY_ROUTES.ADDRESS_DETAILS);
        }
      } else {
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    const beneficiary_details = nominee_details.beneficiary.map(
      (beneficiary) => {
        const { full_name, date_of_birth, relation_to_proposer, share } =
          beneficiary;

        const firstName = `${full_name.value}`.split(" ")[0];
        const lastName = `${full_name.value}`.split(" ")[1] || "";
        return {
          frstNm: firstName,
          lstNm: lastName,
          relationship: relation_to_proposer.value || "",
          dob: FORMAT_DATE_DD_MM_YYYY(date_of_birth.value) || "",
          shareOfBenefit: share.value || "",
        };
      }
    );
    const trustee_details = nominee_details.trustee_details;
    const MWPA_STATUS = nominee_details.is_beneficiary;
    const data: NomineeDetailsAPIDTO = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: PROPOSAL_PAGE_SECTION_STATUS_API.NOMINEE_DETAILS,
      details: !MWPA_STATUS
        ? {
            nominee_dob: FORMAT_DATE_DD_MM_YYYY(nominee_details.dob.value),
            nominee_gender: nomineeDetails.gender.value,
            nominee_name: nominee_details.full_name.value,
            nominee_relationship: nominee_details.relationship.value,
          }
        : {
            beneficiary: beneficiary_details,
            trustee: {
              address: trustee_details.address.value,
              city: trustee_details.city.value,
              dob: FORMAT_DATE_DD_MM_YYYY(trustee_details.dob.value),
              email: trustee_details.email.value,
              mobileNo: trustee_details.mobileNo.value,
              name: trustee_details.name.value,
              panNo: `${trustee_details.panNo.value}`.toUpperCase(),
              pincode: trustee_details.pincode.value,
              salutation: "",
              state: trustee_details.state.value,
              trusteeType: trustee_details.trusteeType.value,
            },
          },
    };
    setLoader(true);
    if (
      SELECTED_QUOTE_DATA.companyDetails.company_code === ETermCompanyCode.HDFC
    ) {
      PROPOSAL_PAGE_SERVICES.updateProposalPageHDFCDetails(
        onSuccess,
        onError,
        data
      );
    } else {
      PROPOSAL_PAGE_SERVICES.updateProposalPageDetails(
        onSuccess,
        onError,
        data
      );
    }
  };

  return (
    <>
      {isMobile ? (
        <MNomineeDetails
          nomineeDetails={nomineeDetails}
          setNomineeDetails={setNomineeDetails}
          continueFunction={validateForm}
          updateMasterState={updateMasterState}
        />
      ) : (
        <>
          <NomineeDetails
            nomineeDetails={nomineeDetails}
            setNomineeDetails={setNomineeDetails}
            continueFunction={validateForm}
            updateMasterState={updateMasterState}
          />
        </>
      )}
    </>
  );
}

export default NomineeDetailsContainer;
