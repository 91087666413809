import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Id, toast } from "react-toastify";
import RidersBenefits from "../../../Page/Desktop/Term/ShortPay/Bajaj/RidersBenefits";
import MRidersBenefits from "../../../Page/Mobile/Term/Bajaj/MRidersBenefits";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { formatAmountToCurrency } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { DTOTermRiderStatus } from "../../../types/Term/DTOTermRidersStatus";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";

const RidersBenefitsContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
    PROPOSER_DATA,
    QUOTE_FIELDS_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    LOADER,
    redirection_url,
  } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);

  let toastID: Id;
  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      toast.dismiss(toastID);
      setLoaderTf003(false);
      const results = data.results;
      const error = results.error;
      const message = results.message;
      const response: TRidersBenefitsResponse = results.response;
      if (!error) {
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...Term,
            RIDERS_BENEFITS: {
              ...Term.RIDERS_BENEFITS,
              riders_benefits_response: response,
            },
            SELECTED_QUOTE_DATA: {
              ...Term.SELECTED_QUOTE_DATA,
              premiumDetails: {
                ...Term.SELECTED_QUOTE_DATA.premiumDetails,
                pay_term: response.pay_term,
                term: response.term,
              },
            },
            LOADER: false,
            redirection_url: results?.response?.redirection_url,
          })
        );
      } else {
        toast.error("There are some technical error. Please contact to admin.");
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      toast.dismiss(toastID);
      setLoaderTf003(false);
      toast.error("There are some technical error. Please contact to admin.");
    };

    const data: DTOTermRiderStatus = {
      name: PROPOSER_DATA.full_name?.value,
      mobile: ADD_FORM.mobile?.value,
      pincode: ADD_FORM.pincode?.value,
      product_id: `${SELECTED_QUOTE_DATA.productDetails?.id}`,
      quote_no: ADD_FORM_RESPONSE?.quote_no,
      company_code: SELECTED_QUOTE_DATA?.companyDetails?.company_code,
      frequency: `${QUOTE_FIELDS_DATA?.payMode}`,
      gender: ADD_FORM.gender?.value,
      pay_term: `${SELECTED_QUOTE_DATA.premiumDetails?.pay_term}`,
      product_code: SELECTED_QUOTE_DATA.productDetails?.product_code,
      smoker: ADD_FORM.smoke,
      sum_assured: `${SELECTED_QUOTE_DATA.premiumDetails.sum_assured}`,
      term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
      dob: ADD_FORM.age.value,
      criticalIllnessSelected: "No",
      accidentalDisSelected: "No",
      personalAccSelected: "No",
      cancerCovSelected: "No",
      accidentalDeathSelected: "Yes",
      criticalIllnessValue: "",
      accidentalDisValue: "",
      accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Value,
      adb: "No",
      rop: "No",
      wop: "No",
      income_period: SELECTED_QUOTE_DATA.premiumDetails.income_period,
    };
    toast.dismiss(toastID);
    toastID = toast.loading("Please wait...");

    setLoaderTf003(true);
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const continue_function = () => {
    window.location.href = redirection_url;
  };

  useEffect(() => {
    let data_tf003: TDropdown[] = [];
    for (let i = 10; i <= 100; i += 10) {
      data_tf003.push({
        value: `${(SELECTED_QUOTE_DATA.premiumDetails.sum_assured / 100) * i}`,
        label: formatAmountToCurrency(
          Number(
            `${(SELECTED_QUOTE_DATA.premiumDetails.sum_assured / 100) * i}`
          )
        ),
      });
    }

    setTf003Data(data_tf003);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [RIDERS_BENEFITS.local_value]);

  return (
    <>
      {LOADER || loaderTf003 ? (
        <Box
          position={"fixed"}
          height={"100%"}
          width={"100%"}
          top="0"
          left="0"
          zIndex={911}
        />
      ) : null}
      {isMobile ? (
        <MRidersBenefits
          loaderTf003={loaderTf003}
          forward={continue_function}
          tf003Data={tf003Data}
        />
      ) : (
        <RidersBenefits
          loaderTf003={loaderTf003}
          forward={continue_function}
          tf003Data={tf003Data}
        />
      )}
    </>
  );
};

export default RidersBenefitsContainer;
