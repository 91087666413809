import { TCarAddonStatus } from "../types/Car/TCarAddonStatus";
import { TCarSingleQuoteDetail } from "../types/Car/TCarSingleQuoteDetail";
import {
  calculateAge,
  calculateAgeInDays,
  calculate_passenger_cover,
  parse_value,
} from "./HelpingFunction";

export type TBajajPlanName =
  | "Drive Assure Prime"
  | "Drive Assure Economy"
  | "Drive Assure Economy Plus";

export const DETERMINE_PLAN_TYPE_BAJAJ = (
  ADDON_STATUS: TCarAddonStatus,
  reg_date: string
): TBajajPlanName => {
  let plan_name: TBajajPlanName = "Drive Assure Prime";
  console.log("calculateAge(reg_date)", calculateAge(reg_date));
  if (
    (ADDON_STATUS.depreciation_cover.value ||
      ADDON_STATUS.engine_cover.value) &&
    calculateAgeInDays(reg_date) <= 1822
  ) {
    plan_name = "Drive Assure Economy";
  }

  if (
    (ADDON_STATUS.depreciation_cover.value ||
      ADDON_STATUS.engine_cover.value) &&
    (ADDON_STATUS.personalBelonging.value ||
      ADDON_STATUS.key_replacement.value) &&
    calculateAgeInDays(reg_date) <= 1822
  ) {
    plan_name = "Drive Assure Economy Plus";
  }

  return plan_name;
};

export function calculate_premium_for_car_bajaj(
  quote_details: TCarSingleQuoteDetail | undefined,
  addon_status: TCarAddonStatus,
  passenger_cover_user_value: string,
  PLAN_TYPE: string,
  reg_date: string
) {
  let premium: number = 0;

  if (quote_details === undefined) return 0;

  const deductibleDiscount = parse_value(
    quote_details.premiumDetails.deductibleDiscount
  );
  const cngKitOD = parse_value(quote_details.premiumDetails.cngKitOD);
  const cngKitTP = parse_value(quote_details.premiumDetails.cngKitTP);
  const basicOD = parse_value(quote_details.premiumDetails.basicOD);
  const basicTP = parse_value(quote_details.premiumDetails.basicTP);
  const ncbDiscount = parse_value(quote_details.premiumDetails.ncbDiscount);
  const paOwnerDriver = parse_value(quote_details.premiumDetails.paOwnerDriver);
  const driverCover = parse_value(quote_details.premiumDetails.driverCover);
  const accessoryCoverCharge = parse_value(
    quote_details.premiumDetails.accessoryCoverCharge
  );
  const keyReplacementCover = parse_value(
    quote_details.premiumDetails.keyReplacementCover
  );
  const zerodepAssurePlusPremiume = parse_value(
    quote_details.premiumDetails.zerodepAssurePlusPremiume
  );
  const zerodepAssurePremiume = parse_value(
    quote_details.premiumDetails.zerodepAssurePremiume
  );

  premium =
    basicOD + basicTP - ncbDiscount - deductibleDiscount + accessoryCoverCharge;
  // Define addon packages based on addon_status
  let selectedPackage = DETERMINE_PLAN_TYPE_BAJAJ(addon_status, reg_date);

  // Calculate premium based on selected package
  switch (selectedPackage) {
    case "Drive Assure Prime":
      premium = premium + keyReplacementCover;
      break;
    case "Drive Assure Economy":
      premium = premium + zerodepAssurePremiume;
      break;
    case "Drive Assure Economy Plus":
      premium = premium + zerodepAssurePlusPremiume;
      break;
    default:
  }

  // Add addon premiums
  if (addon_status.pa_owner_cover.value) {
    premium = premium + paOwnerDriver;
  }
  if (addon_status.driver_cover.value) {
    premium = premium + driverCover;
  }
  if (addon_status.passenger_cover.value) {
    premium =
      premium +
      calculate_passenger_cover(
        Number(`${passenger_cover_user_value}`),
        quote_details,
        PLAN_TYPE
      );
  }
  if (addon_status.cng_kit.value) {
    premium = premium + cngKitOD + cngKitTP;
  }

  // Calculate tax amount and final premium
  let tax_amount = (premium / 100) * 18;
  let final_premium = premium + tax_amount;

  return final_premium;
}
