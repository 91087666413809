import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../../Component/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import TermFooter from "../../../../../Component/Term/TermFooter/TermFooter";
import RecalculatedPremiumPopup from "../../../../../Container/Term/MaxLife/RecalculatedPremiumPopup";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  isEmpty,
  validateAadharNumber,
} from "../../../../../SupportingFiles/HelpingFunction";
import { TTermProposerData } from "../../../../../types/TTermSlice";
import { TRidersBenefitsResponse } from "../../../../../types/Term/TRidersBenifits";

const ProposerDetails = ({
  updateMasterState,
  proposerDetails,
  setProposerDetails,
  validateForm,
  loader,
  open,
  setOpen,
  recRiders,
  setRecRiders,
}: {
  updateMasterState: Function;
  proposerDetails: TTermProposerData;
  setProposerDetails: Function;
  validateForm: Function;
  loader: boolean;
  open: boolean;
  setOpen: Function;
  recRiders: TRidersBenefitsResponse;
  setRecRiders: Function;
}) => {
  const { SELECTED_QUOTE_DATA, QUOTE_FIELDS_DATA } = useAppSelector(
    (state) => state.Term
  );
  const dropdownData = useAppSelector((state) => state.Term.DROPDOWN_DATA);

  return (
    <Box>
      <RecalculatedPremiumPopup
        open={open}
        setOpen={setOpen}
        recRiders={recRiders}
        setProposerDetails={setProposerDetails}
      />
      <h5 className="sectionTitle">Proposer Details</h5>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            value={proposerDetails?.full_name?.value}
            attrName={"full_name"}
            validation_type="NAME"
            value_update={updateMasterState}
            warn_status={proposerDetails?.full_name?.warning}
            error_message="Enter full name"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={proposerDetails?.dob?.value}
            attrName={"dob"}
            value_update={updateMasterState}
            error_message="Select DOB"
            warn_status={proposerDetails?.dob?.warning}
            min_date={60}
            disabled={true}
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Gender"
            value={proposerDetails?.gender?.value}
            attrName={"gender"}
            value_update={updateMasterState}
            data={dropdownData.GENDER}
            warn_status={proposerDetails?.gender?.warning}
            error_message="Select gender"
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Mobile"}
            value={proposerDetails?.mobile?.value}
            attrName={"mobile"}
            value_update={updateMasterState}
            warn_status={proposerDetails?.mobile?.warning}
            error_message="Enter mobile no."
            validation_type="NUMBER"
            max_length={10}
            disabled={true}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={proposerDetails?.email?.value}
            attrName={"email"}
            value_update={updateMasterState}
            warn_status={proposerDetails?.email?.warning}
            error_message={
              isEmpty(proposerDetails?.email?.value)
                ? "Enter E-mail"
                : "Enter Valid E-mail"
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Aadhar"}
            value={proposerDetails?.aadhar_number?.value}
            attrName={"aadhar_number"}
            value_update={updateMasterState}
            warn_status={proposerDetails.aadhar_number?.warning}
            error_message={
              !validateAadharNumber(proposerDetails?.aadhar_number?.value)
                ? "Enter Valid Aadhar no."
                : ""
            }
            validation_type="NUMBER"
            max_length={12}
          />
        </Grid>
        <Grid xs={6}>
          <RKTextField
            class_name="inputField"
            title={"Emergency Phone"}
            value={proposerDetails?.emergency_number?.value}
            attrName={"emergency_number"}
            value_update={updateMasterState}
            warn_status={proposerDetails?.emergency_number?.warning}
            error_message={
              !isEmpty(proposerDetails?.emergency_number?.value)
                ? "Enter Valid emergency no."
                : "Enter emergency Number"
            }
            validation_type="NUMBER"
            max_length={10}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Education"
            value={proposerDetails?.education?.value}
            attrName={"education"}
            value_update={updateMasterState}
            data={dropdownData?.EDUCATION_MASTER}
            warn_status={proposerDetails?.education?.warning}
            error_message="Select education"
          />
        </Grid>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Occupation"
            value={proposerDetails?.occupation?.value}
            attrName={"occupation"}
            value_update={updateMasterState}
            data={dropdownData?.OCCUPATION_DATA}
            warn_status={proposerDetails?.occupation?.warning}
            error_message="Select occupation"
          />
        </Grid>
        <Grid xs={4}>
          <RKTextField
            disabled={
              isEmpty(proposerDetails?.education?.value) ||
              isEmpty(proposerDetails?.occupation?.value)
            }
            class_name="inputField"
            title="Annual Income"
            value={proposerDetails?.annual_income?.value}
            attrName={"annual_income"}
            value_update={updateMasterState}
            warn_status={proposerDetails?.annual_income?.warning}
            error_message={
              isEmpty(proposerDetails?.annual_income?.value)
                ? "Enter annual income"
                : SELECTED_QUOTE_DATA.productDetails.product_code === "LTM052"
                ? "Minimum annual income should be 10 lakh."
                : "Enter valid annual income"
            }
            validation_type="NUMBER"
            max_length={8}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <SearchDropdown
            class_name="inputField"
            title="Sum Assured"
            value={
              proposerDetails.sum_assured.value !== ""
                ? proposerDetails.sum_assured.value
                : QUOTE_FIELDS_DATA.sumAssured
            }
            attrName={"sum_assured"}
            value_update={updateMasterState}
            data={dropdownData?.MAX_SUM_ASSURED}
            warn_status={proposerDetails?.sum_assured?.warning}
            error_message="Select sum assured"
          />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <FormControlLabel
          className="proposalCheckBoxWrapper"
          control={
            <Checkbox
              disabled={SELECTED_QUOTE_DATA?.productDetails?.frequency === "5"}
              value={proposerDetails?.diabetic}
              defaultChecked={proposerDetails?.diabetic}
              onChange={(e, v) => updateMasterState("diabetic", v)}
            />
          }
          label="Are you suffering from diabetes?"
        />
      </Grid>
      {proposerDetails?.occupation?.value === "01" ? (
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class=""
            question_name="Are you paying for policy?"
            toggle_status={proposerDetails?.housewifePayerStatus}
            value_update={updateMasterState}
            attrName={"housewifePayerStatus"}
          />
        </Grid>
      ) : null}

      {proposerDetails?.occupation?.value === "01" &&
      proposerDetails?.housewifePayerStatus ? (
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField"
            title="Relationship"
            value={proposerDetails?.housewifePayerRelation?.value}
            attrName={"housewifePayerRelation"}
            value_update={updateMasterState}
            data={[
              { label: "Spouce", value: "01" },
              { label: "Parents", value: "02" },
            ]}
            warn_status={proposerDetails?.housewifePayerRelation?.warning}
            error_message="Select relationship with payer"
          />
        </Grid>
      ) : null}
      <TermFooter forward={validateForm} loader={loader} />
    </Box>
  );
};

export default ProposerDetails;
