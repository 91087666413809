import { Routes, Route } from "react-router-dom";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import GoDigitCKYCContainer from "../../../../../Container/CKYC/GoDigit/GoDigitCKYCContainer";
import HealthProposalContainer from "../../HealthProposalContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import MedicalDetailsContainerComp from "../../../../../Container/Health/GODIGIT/MedicalDetailsContainerGoDigit/MedicalDetailsContainerComp";
import AddonDetailsContainer from "../../../../../Container/Health/GODIGIT/AddonDetailsContainer";

const GODIGIT_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<GoDigitCKYCContainer />} />
        <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainer />}
          />
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainerComp />}
        />
      </Route>
    </Routes>
  );
};

export default GODIGIT_ROUTES;
 


