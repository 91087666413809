import React, { useState } from "react";
import { Box, Button, Collapse, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MMedicalDetailQuestion.scss";
import { COLORS } from "../../SupportingFiles/colors";
import SwitchToggle from "../FieldTypes/SwitchToggle/SwitchToggle";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";

const MMedicalDetailQuestion = ({
  main_Question,
  mainQues_info,
  innerQuestiondata,
  data,
  updateMasterState,
  value_update,
  attrName,
  toggle_button_status,
}: {
  main_Question: string;
  mainQues_info: string;
  innerQuestiondata: any;
  data?: any;
  updateMasterState?: any;
  value_update?: Function;
  attrName?: any;
  toggle_button_status?: boolean;
}) => {
  const [checkState, setCheckState] = useState(false);
  const handleToggleMainQuestion = (e: any) => {
    setCheckState(e.target.checked);
  };

  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };

  return (
    <Box className="medicalQuestion_m">
      <Grid
        container
        className="row"
        columnSpacing={3}
        alignItems={"flex-start"}
        margin={"0"}
      >
        <Grid xs={8}>
          <h6 className="mb-1">{main_Question}</h6>
          <p
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              color: COLORS.lightgrey,
              marginLeft: "22px",
            }}
          >
            {mainQues_info}
          </p>
        </Grid>
        <Grid xs={4} textAlign={"right"}>
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
        <Grid xs={12} marginTop={"12px"}>
          {innerQuestiondata}
        </Grid>
        <Grid xs={12} marginTop={"12px"}>
          <Box marginLeft={"24px"} className="toggleDetailBox">
            <Grid container columnSpacing={2} rowSpacing={2}>
              {data?.main_question?.member_data &&
                (data?.main_question?.id === "MQ02" ||
                  data?.main_question?.id === "MQ03" ||
                  data?.main_question?.id === "MQ04" ||
                  data?.main_question?.id === "MQ05") &&
                data?.main_question?.status &&
                Object.values(data?.main_question?.member_data)?.map(
                  (memberDataValue: any, memberIndex: number) => (
                    <Grid xs={6}>
                      <React.Fragment key={memberIndex}>
                        {memberDataValue.name !== "" && (
                          <MemberRadioButtonNew
                            class_name={
                              memberIndex === 0 || memberIndex === 1
                                ? "adult"
                                : "child"
                            }
                            checked={memberDataValue?.selection_status}
                            label_name={memberDataValue.name}
                            attrName={[
                              "mainMemberData",
                              data?.main_question?.id,
                              memberDataValue?.keyName,
                            ]}
                            value_update={updateMasterState}
                          />
                        )}
                      </React.Fragment>
                    </Grid>
                  )
                )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} alignItems={"flex-start"} margin={"0"}>
        <Grid xs={12} textAlign={"right"}>
          <hr />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MMedicalDetailQuestion;
