export enum HEALTH_ROUTE {
  QOUTE_PAGE = "/quote/health-quote",
  PROPOSAL_PAGE = "/health-insurance",
  KYC_DETAILS = "health-kyc-details",
  ADDON_DETAILS = "health-addon-details",
  ADULT_ONE_DETAILS = "health-adult-one-details",
  INSURED_MEMBER = "health-adult-one-details_members",
  NOMINEE_DETAILS = "health-noninee-details",
  ADULT_TWO_DETAILS = "health-adult-two-details",
  CHILD_ONE_DETAILS = "health-child-one-details",
  CHILD_TWO_DETAILS = "health-child-two-details",
  CHILD_THREE_DETAILS = "health-child-three-details",
  CHILD_FOUR_DETAILS = "health-child-four-details",
  ADDRESS_DETAILS = "health-address-details",
  MEDICAL_DETAILS = "health-medical-details",
  PREVIEW_DETAILS = "health-preview-details",
  PAYMENT_CIS = "cis-pdf",
}
