import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RidersBenefits from "../../../Page/Desktop/Term/ShortPay/MaxLife/RidersBenefits";
import MRidersBenefits from "../../../Page/Mobile/Term/MaxLife/MRidersBenefits";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  formatAmountToCurrency,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";
import { CLIENTS } from "../../../URLCollection/Clients";

function RidersBenefitsContainer() {
  const isMobile = useIsMobile();
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [tf001Data, setTf001Data] = useState<TDropdown[]>([]);
  const [tf003Data, setTf003Data] = useState<TDropdown[]>([]);
  const {
    SELECTED_QUOTE_DATA,
    PROPOSER_DATA,
    RIDERS_BENEFITS,
    QUOTE_FIELDS_DATA,
    ADD_FORM_RESPONSE,
    ADD_FORM,
    LOADER,
  } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const [loaderTf001, setLoaderTf001] = useState<boolean>(false);
  const [loaderTf003, setLoaderTf003] = useState<boolean>(false);
  const [loaderTf004, setLoaderTf004] = useState<boolean>(false);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [RIDERS_BENEFITS.local_value]);

  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    setLoader(true);
    if (window.location.hostname === CLIENTS.MOTILAL) {
      const onSuccess = (data: any) => {
        setLoader(false);
        setLoaderTf001(false);
        setLoaderTf003(false);
        const results = data?.results;
        const error = results?.error;
        const message = results?.message;
        const response: TRidersBenefitsResponse = results?.response;

        if (!error) {
          dispatch(
            TermSlice.actions.BULK_UPDATE({
              ...Term,
              LOADER: false,
              RIDERS_BENEFITS: {
                ...Term.RIDERS_BENEFITS,
                riders_benefits_response: response,
              },
            })
          );
        } else {
          toast.error(
            "There are some technical error. Please contact to admin."
          );
          console.log({ error: error, message: message });
        }
      };
      const onError = (err: any) => {
        setLoader(false);
        setLoaderTf001(false);
        setLoaderTf003(false);
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        toast.error("There are some technical error. Please contact to admin.");
      };

      const data = {
        name: PROPOSER_DATA.full_name.value,
        mobile: ADD_FORM.mobile.value,
        pincode: ADD_FORM.pincode.value,
        product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
        frequency: `${QUOTE_FIELDS_DATA.payMode}`,
        gender: ADD_FORM.gender.value,
        pay_term: `${SELECTED_QUOTE_DATA.premiumDetails.pay_term}`,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        smoker: ADD_FORM.smoke,
        sum_assured: QUOTE_FIELDS_DATA.sumAssured,
        term: `${SELECTED_QUOTE_DATA.premiumDetails.term}`,
        dob: PROPOSER_DATA.dob.value,
        criticalIllnessSelected:
          QUOTE_FIELDS_DATA.payMode === "5"
            ? "No"
            : RIDERS_BENEFITS.local_value.tf001Status
            ? "Yes"
            : "No",
        accidentalDisSelected: "No",
        personalAccSelected: "No",
        cancerCovSelected: "No",
        accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
        criticalIllnessValue:
          QUOTE_FIELDS_DATA.payMode === "5"
            ? ""
            : RIDERS_BENEFITS.local_value.tf001Value,
        accidentalDisValue: "",
        accidentalDeathValue: RIDERS_BENEFITS.local_value.tf003Value,
        adb: "No",
        rop: "No",
        wop: "No",
        annual_income: ADD_FORM.annualIncome.value,
        wopSelected: RIDERS_BENEFITS.local_value.tf004Status ? "Yes" : "No",
        occupation: PROPOSER_DATA.occupation.value,
      };
      TERM_SERVICES.RIDERS_BENEFITS_DETAIL(onSuccess, onError, data);
    } else {
      const onSuccess = (data: any) => {
        setLoader(false);
        setLoaderTf001(false);
        setLoaderTf003(false);
        const results = data;
        const error = results?.error;
        const message = results?.message;
        const response: TRidersBenefitsResponse =
          results?.response.premiumDetails;

        if (!error) {
          dispatch(
            TermSlice.actions.BULK_UPDATE({
              ...Term,
              LOADER: false,
              RIDERS_BENEFITS: {
                ...Term.RIDERS_BENEFITS,
                riders_benefits_response: response,
              },
            })
          );
        } else {
          toast.error(
            "There are some technical error. Please contact to admin."
          );
          console.log({ error: error, message: message });
        }
      };
      const onError = (err: any) => {
        setLoader(false);
        setLoaderTf001(false);
        setLoaderTf003(false);
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        toast.error("There are some technical error. Please contact to admin.");
      };

      const data = {
        product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
        quote_no: ADD_FORM_RESPONSE.quote_no,
        criticalIllnessSelected:
          Term.QUOTE_FIELDS_DATA.payMode === "5"
            ? "No"
            : Term.RIDERS_BENEFITS.local_value.tf001Status
            ? "Yes"
            : "No",
        accidentalDeathSelected: Term.RIDERS_BENEFITS.local_value.tf003Status
          ? "Yes"
          : "No",
        criticalIllnessValue:
          Term.QUOTE_FIELDS_DATA.payMode === "5"
            ? ""
            : Term.RIDERS_BENEFITS.local_value.tf001Value,
        accidentalDeathValue: Term.RIDERS_BENEFITS.local_value.tf003Value,
        wopSelected: Term.RIDERS_BENEFITS.local_value.tf004Status
          ? "Yes"
          : "No",
        occupation: PROPOSER_DATA?.occupation?.value,
        sum_assured: PROPOSER_DATA.sum_assured?.value,
      };
      TERM_SERVICES.RIDERS_BENEFITS_DETAIL_NODE(onSuccess, onError, data);
    }
  };

  useEffect(() => {
    const start_tf001 = 500000;
    let end_tf001 =
      parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2 < 5000000
        ? parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2
        : 5000000;
    const age = calculateAge(ADD_FORM.age.value);
    if (age >= 18 && age <= 35) {
      end_tf001 =
        parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2 < 3000000
          ? parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2
          : 3000000;
    } else if (age >= 36 && age <= 45) {
      end_tf001 =
        parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2 < 2000000
          ? parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2
          : 2000000;
    } else if (age >= 46 && age <= 50) {
      end_tf001 =
        parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2 < 1000000
          ? parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2
          : 1000000;
    } else if (age >= 51 && age <= 55) {
      end_tf001 =
        parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2 < 500000
          ? parseInt(QUOTE_FIELDS_DATA.sumAssured) / 2
          : 500000;
    }

    const annual_income = PROPOSER_DATA.annual_income.value;
    end_tf001 = Math.min(end_tf001, annual_income * 5);

    let data_tf001: TDropdown[] = [];
    for (let i = start_tf001; i <= end_tf001; i += 100000) {
      data_tf001.push({ value: `${i}`, label: formatAmountToCurrency(i) });
    }

    setTf001Data(data_tf001);

    const start_tf003 = 500000;
    const end_tf003 =
      parseInt(QUOTE_FIELDS_DATA.sumAssured) < 10000000
        ? parseInt(QUOTE_FIELDS_DATA.sumAssured)
        : 10000000;

    let data_tf003: TDropdown[] = [];
    for (let i = start_tf003; i <= end_tf003; i += 100000) {
      data_tf003.push({ value: `${i}`, label: formatAmountToCurrency(i) });
    }

    setTf003Data(data_tf003);
  }, [RIDERS_BENEFITS]);

  useEffect(() => {
    setLoaderTf001(true);
  }, [
    RIDERS_BENEFITS.local_value.tf001Status,
    RIDERS_BENEFITS.local_value.tf001Value,
  ]);

  useEffect(() => {
    setLoaderTf003(true);
  }, [
    RIDERS_BENEFITS.local_value.tf003Status,
    RIDERS_BENEFITS.local_value.tf003Value,
  ]);

  useEffect(() => {
    setLoaderTf004(true);
  }, [
    RIDERS_BENEFITS.local_value.tf004Status,
    RIDERS_BENEFITS.local_value.tf004Value,
  ]);
  const [showWOP, setShowWOP] = useState<boolean>(false);

  useEffect(() => {
    const premium = RIDERS_BENEFITS.riders_benefits_response.finalPremium;
    const pay_term = parseInt(SELECTED_QUOTE_DATA.premiumDetails.pay_term);
    if (
      (pay_term === 5 && premium < 1100000) ||
      (pay_term === 6 && premium < 1000000) ||
      (pay_term === 7 && premium < 850000) ||
      (pay_term === 8 && premium < 800000) ||
      (pay_term === 9 && premium < 700000) ||
      (pay_term === 10 && premium < 650000) ||
      (pay_term === 11 && premium < 600000) ||
      (pay_term >= 12 && pay_term <= 15 && premium < 500000) ||
      (pay_term >= 16 && pay_term <= 24 && premium < 400000) ||
      (pay_term >= 25 && pay_term <= 36 && premium < 350000) ||
      (pay_term >= 37 && pay_term <= 52 && premium < 300000) ||
      pay_term > 52
    ) {
      setShowWOP(true);
    }

    // if (pay_term !== term) {
    //   setShowWOP(false);
    // }
  }, [RIDERS_BENEFITS.riders_benefits_response.finalPremium]);

  const GET_REDIRECTION_URL = () => {
    if (
      RIDERS_BENEFITS?.riders_benefits_response.riders.TF001?.premium >
      RIDERS_BENEFITS?.riders_benefits_response.premiumAmount * 1.18
    ) {
      toast.error(
        "Your additional coverage brings a higher premium than your basic plan."
      );
      return;
    }

    setLoader(true);

    if (window.location.hostname === CLIENTS.MOTILAL) {
      const onSuccess = (res: any) => {
        setLoader(false);
        const results = res.results;
        const error = results.error;
        if (!error) {
          const response = results.response;
          const redirectionUrl = response.redirectionUrl;

          if (!isEmpty(redirectionUrl)) {
            window.location.href = redirectionUrl;
          } else {
            toast.error("Redirestion URL Missing");
          }
        }
      };
      const onError = () => {
        setLoader(false);
      };
      const params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        criticalIllnessSumAssured: RIDERS_BENEFITS.local_value.tf001Status
          ? RIDERS_BENEFITS.local_value.tf001Value
          : "",
        accDeathSumAssured: RIDERS_BENEFITS.local_value.tf003Status
          ? RIDERS_BENEFITS.local_value.tf003Value
          : "",
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        product_id: SELECTED_QUOTE_DATA.productDetails.id,
      };
      TERM_SERVICES.GET_REDIRECTION_URL_LARAVEL(onSuccess, onError, params);
    } else {
      const onSuccess = (res: any) => {
        setLoader(false);
        const results = res;
        const error = results.error;
        if (!error) {
          const response = results.response;
          const redirectionUrl = results.redirectionUrl;

          if (!isEmpty(redirectionUrl)) {
            window.location.href = redirectionUrl;
          } else {
            toast.error("Redirestion URL Missing");
          }
        }
      };
      const onError = () => {
        setLoader(false);
      };
      const params = {
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      };
      TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
    }
  };

  return (
    <>
      {LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MRidersBenefits
          loaderTf001={loaderTf001}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          tf001Data={tf001Data}
          tf003Data={tf003Data}
          showWOP={showWOP}
          loader={loader}
          GET_REDIRECTION_URL={GET_REDIRECTION_URL}
        />
      ) : (
        <RidersBenefits
          loaderTf001={loaderTf001}
          loaderTf003={loaderTf003}
          loaderTf004={loaderTf004}
          tf001Data={tf001Data}
          tf003Data={tf003Data}
          showWOP={showWOP}
          loader={loader}
          GET_REDIRECTION_URL={GET_REDIRECTION_URL}
        />
      )}
    </>
  );
}

export default RidersBenefitsContainer;
