import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NivaBupaAddonQuestion from "../../../../../Component/AddonQuestion/NivaBupaAddonQuestion";
import CustomCheckbox from "../../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import Footer from "../../../../../Component/Footer/Footer";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import { TNivaBupaAddonQuestion } from "../../../../../types/Health/HealthQuotation/TNivaBupaAddonQuestion";
import { EProductId } from "../../../../../Services/Enum/EHome";

const AddonDetailsNIVABUPA = ({
  addonQuestionData,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
  addonStatus,
}: {
  addonQuestionData: TNivaBupaAddonQuestion;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
  addonStatus: any;
}) => {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, ADD_FORM, QUOTE_LOADER } = useAppSelector(
    (state) => state.Health
  );
  const [openAcuteCare, setOpenAcuteCare] = useState<boolean>(false);
  useEffect(() => {
    if (addonQuestionData.MQ02.main_question.status) {
      setOpenAcuteCare(true);
    }
  }, [addonQuestionData.MQ02.main_question.status]);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid xs={12}>
        <>
          {Object.values(addonQuestionData).map((data, indexMainQuestion) => (
            <>
              {SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.NIVA_BUPA_Senior_First_Platinum_IND ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
              SELECTED_QUOTE_DATA.productDetails.id ===
                EProductId.NIVA_BUPA_Senior_First_Platinum_Floater ? (
                data?.main_question?.id === "MQ03" ? (
                  <div key={indexMainQuestion}>
                    <NivaBupaAddonQuestion
                      key={indexMainQuestion}
                      main_class="medicalQuestion borderBottom"
                      icon_class={data?.main_question?.icon_class}
                      question_name={data?.main_question?.title}
                      sub_details={data?.main_question?.description}
                      input_id_first={data?.main_question?.id}
                      input_id_second={data?.main_question?.id}
                      input_name="diagnosed"
                      toggle_button_status={data?.main_question?.status}
                      attrName={["question", data?.main_question?.id]}
                      value_update={updateMasterState}
                      data={data}
                      updateMasterState={updateMasterState}
                      addonStatus={addonStatus}
                      any_question_or_sub_question={
                        <Collapse
                          in={data?.main_question?.status}
                          className="medicalQuestion-SubSection"
                        >
                          <Grid container spacing={3} className="pr-2 mb-3">
                            {data?.main_question?.member_data && (
                              <Grid xs={12}>
                                {Object.values(
                                  data.main_question.member_data
                                ).map((memberDataValue, indexMemberSection) =>
                                  memberDataValue.name !== "" ? (
                                    <Grid
                                      xs={6}
                                      lg={4}
                                      key={indexMemberSection}
                                    >
                                      <Box>
                                        <MemberRadioButtonNew
                                          class_name={
                                            indexMemberSection === 0 ||
                                            indexMemberSection === 1
                                              ? "adult"
                                              : "child"
                                          }
                                          checked={
                                            memberDataValue?.selection_status
                                          }
                                          label_name={memberDataValue.name}
                                          attrName={[
                                            "mainMemberData",
                                            data.main_question.id,
                                            memberDataValue.keyName,
                                          ]}
                                          value_update={updateMasterState}
                                        />
                                      </Box>
                                      <Box className="mt-3">
                                        {memberDataValue?.selection_status &&
                                          memberDataValue.keyName &&
                                          memberDataValue?.field_data &&
                                          Object.entries(
                                            memberDataValue?.field_data
                                          ).map(
                                            (
                                              [
                                                data_sub_question_key,
                                                data_sub_question_value,
                                              ],
                                              index_sub_question_fields: any
                                            ) =>
                                              memberDataValue.selection_status ===
                                              true ? (
                                                <>
                                                  {data_sub_question_value.field_type ===
                                                  "CHECKBOX" ? (
                                                    <CustomCheckbox
                                                      label={
                                                        data_sub_question_value.title
                                                      }
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      value={
                                                        data_sub_question_value
                                                          .value
                                                          ?.value as boolean
                                                      }
                                                      defaultChecked={false}
                                                      attrName={[
                                                        "main_question",
                                                        data.main_question.id,
                                                        memberDataValue.keyName,
                                                        data_sub_question_key,
                                                        "checkbox",
                                                      ]}
                                                    />
                                                  ) : null}
                                                </>
                                              ) : null
                                          )}
                                      </Box>
                                    </Grid>
                                  ) : null
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Collapse>
                      }
                    />
                  </div>
                ) : null
              ) : (
                <div key={indexMainQuestion}>
                  <NivaBupaAddonQuestion
                    key={indexMainQuestion}
                    main_class="medicalQuestion borderBottom"
                    icon_class={data?.main_question?.icon_class}
                    question_name={data?.main_question?.title}
                    sub_details={data?.main_question?.description}
                    input_id_first={data?.main_question?.id}
                    input_id_second={data?.main_question?.id}
                    input_name="diagnosed"
                    toggle_button_status={data?.main_question?.status}
                    attrName={["question", data?.main_question?.id]}
                    value_update={updateMasterState}
                    data={data}
                    updateMasterState={updateMasterState}
                    addonStatus={addonStatus}
                    any_question_or_sub_question={
                      <Collapse
                        in={data?.main_question?.status}
                        className="medicalQuestion-SubSection"
                      >
                        <Grid container spacing={3} className="pr-2 mb-3">
                          {data?.main_question?.member_data && (
                            <>
                              {Object.values(
                                data.main_question.member_data
                              ).map((memberDataValue, indexMemberSection) =>
                                memberDataValue.name !== "" ? (
                                  <Grid xs={6} lg={4} key={indexMemberSection}>
                                    <Box>
                                      <MemberRadioButtonNew
                                        class_name={
                                          indexMemberSection === 0 ||
                                          indexMemberSection === 1
                                            ? "adult"
                                            : "child"
                                        }
                                        checked={
                                          memberDataValue?.selection_status
                                        }
                                        label_name={memberDataValue.name}
                                        attrName={[
                                          "mainMemberData",
                                          data.main_question.id,
                                          memberDataValue.keyName,
                                        ]}
                                        value_update={updateMasterState}
                                      />
                                    </Box>
                                    <Box className="mt-3" textAlign={"center"}>
                      
                                        {memberDataValue?.selection_status &&
                                          memberDataValue.keyName &&
                                          memberDataValue?.field_data &&
                                          Object.entries(
                                            memberDataValue?.field_data
                                          ).map(
                                            (
                                              [
                                                data_sub_question_key,
                                                data_sub_question_value,
                                              ],
                                              index_sub_question_fields: any
                                            ) =>
                                              memberDataValue.selection_status ===
                                              true ? (
                                                <>
                                                  {data_sub_question_value.field_type ===
                                                  "CHECKBOX" ? (
                                                    <CustomCheckbox
                                                      label={
                                                        data_sub_question_value.title
                                                      }
                                                      value_update={
                                                        updateMasterState
                                                      }
                                                      value={
                                                        data_sub_question_value
                                                          .value
                                                          ?.value as boolean
                                                      }
                                                      defaultChecked={false}
                                                      attrName={[
                                                        "main_question",
                                                        data.main_question.id,
                                                        memberDataValue.keyName,
                                                        data_sub_question_key,
                                                        "checkbox",
                                                      ]}
                                                    />
                                                  ) : null}
                                                </>
                                              ) : null
                                          )}
                            
                                    </Box>
                                  </Grid>
                                ) : null
                              )}
                            </>
                          )}
                        </Grid>
                      </Collapse>
                    }
                  />
                </div>
              )}
            </>
          ))}
        </>
      </Grid>

      <Footer
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        attrName={"pageStatus"}
        forward={() => {
          validate();
        }}
        value={0}
        backward={() => {
          navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`);
        }}
        disableButton={QUOTE_LOADER}
      />
    </Box>
  );
};

export default AddonDetailsNIVABUPA;
