import { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { useNavigate } from "react-router-dom";
import { TAddressDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";
import {
  isEmpty,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { CAR_SERVICES } from "../../../../Services/Car/CarServices";
import AddressDetails from "../../../../Page/Desktop/Health/CARE/AddressDetails/AddressDetails";
import MAddressDetails from "../../../../Page/Mobile/Health/CARE/MAddressDetails/MAddressDetails";
import { ECompanyCode } from "../../../../Services/Enum/EHome";

export default function AddressDetailsContainer() {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    ADDRESS_DETAILS,
    PAGE_STATUS,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Health);
  // Permanent Address
  const [permanentAddress, setPermanentAddress] =
    useState<TAddressDetails>(ADDRESS_DETAILS);

  useEffect(() => {
    if (validatePincode(permanentAddress.pincode.value)) {
      GetCityStateByPin("PERMANENT");
    }
  }, [permanentAddress.pincode.value]);

  useEffect(() => {
    setPermanentAddress(ADDRESS_DETAILS);
  }, [ADDRESS_DETAILS]);

  useEffect(() => {
    if (validatePincode(permanentAddress.mailingPinCode.value)) {
      GetCityStateByPin("MAILING");
    }
  }, [permanentAddress.mailingPinCode.value]);
  const ADD_API_DATA = () => {
    const onSuccess = (res: any) => {
      if (res.results.error === false) {
        let quoteData = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        // Perform hard refresh on success
        // (window.location.reload as any)(true);
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    // dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    };

    // setTimeout(() => {
    //   HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
    // }, 500); // 2000 milliseconds = 2 seconds
  };
  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code !== ECompanyCode.GO_DIGIT
    ) {
      if (permanentAddress.isMailingAddressSame === true) {
        let formValue = { ...ADDRESS_DETAILS };
        formValue = {
          ...formValue,
          mailingPinCode: {
            ...formValue.pincode,
            warning: !validatePincode(formValue.pincode.value),
          },
          mailingAddressLineOne: {
            ...formValue.addressLineOne,
            warning: isEmpty(formValue.addressLineOne.value),
          },
          mailingAddressLineTwo: {
            ...formValue.addressLineTwo,
            warning: isEmpty(formValue.addressLineTwo.value),
          },
        };
        setPermanentAddress({ ...formValue });
      }
    }
  }, [ADDRESS_DETAILS]);

  const updateMasterState = (attrName: any, value: any) => {
    setPermanentAddress((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode" ? !validatePincode(value) : isEmpty(value),
      },
    }));
  };

  const handleClick = () => {
    setPermanentAddress((prev: any) => ({
      ...prev,
      isMailingAddressSame: !prev.isMailingAddressSame,
    }));
  };

  const validateForm = () => {
    let formValue = { ...permanentAddress };
    formValue = {
      ...formValue,
      pincode: {
        ...formValue.pincode,
        warning:
        !validatePincode(formValue.pincode.value),
      },
      addressLineOne: {
        ...formValue.addressLineOne,
        warning: isEmpty(formValue.addressLineOne.value),
      },
      addressLineTwo: {
        ...formValue.addressLineTwo,
        warning: isEmpty(formValue.addressLineTwo.value),
      },
      mailingPinCode: {
        ...formValue.mailingPinCode,
        warning: !validatePincode(formValue.mailingPinCode.value),
      },
      mailingAddressLineOne: {
        ...formValue.mailingAddressLineOne,
        warning: isEmpty(formValue.mailingAddressLineOne.value),
      },
      mailingAddressLineTwo: {
        ...formValue.mailingAddressLineTwo,
        warning: isEmpty(formValue.mailingAddressLineTwo.value),
      },
    };
    setPermanentAddress({ ...formValue });
    const onSuccess = (res: any) => {
      console.log("onSuccess");
      ADD_API_DATA();
      const results = res.results;
      const error = results.error;
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };
    let param = {
      addressPPincode: formValue.pincode.value,
      addressPHouse: formValue.addressLineOne.value,
      addressPArea: formValue.addressLineTwo.value,
      addressPCity: formValue.city,
      addressPState: formValue.state,
      addressMailingSame: formValue.isMailingAddressSame ? "Yes" : "No",
      addressMPincode: formValue.mailingPinCode.value,
      addressMHouse: formValue.mailingAddressLineOne.value,
      addressMArea: formValue.mailingAddressLineTwo.value,
      addressMCity: formValue.mailingCity,
      addressMState: formValue.mailingState,
      section: "address_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      finalPremium: `${SELECTED_QUOTE_DATA.premiumDetails.finalPremium}`,
    };

    if (
      !formValue.pincode.warning &&
      !formValue.addressLineOne.warning &&
      !formValue.addressLineTwo.warning
    ) {
      let shouldNavigate = false;
      if (!permanentAddress.isMailingAddressSame) {
        if (
          !formValue.mailingAddressLineOne.warning &&
          !formValue.mailingAddressLineTwo.warning &&
          !formValue.mailingPinCode.warning
        ) {
          shouldNavigate = true;
        }
      } else {
        shouldNavigate = true;
      }

      if (shouldNavigate) {
        HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(
          onSuccess,
          onError,
          param
        );
        if (PAGE_STATUS === true) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}`
          );
        }
        dispatch(HealthSlice.actions.SAVE_ADDRESS_DETAILS({ ...formValue }));
      }
    }
  };

  const GetCityStateByPin = (key: "PERMANENT" | "MAILING") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (error === false) {
        const response = results.response;

        if (key === "PERMANENT") {
          setPermanentAddress((prev: any) => ({
            ...prev,
            city: response.city,
            state: response.state,
          }));
        } else {
          setPermanentAddress((prev: any) => ({
            ...prev,
            mailingCity: response.city,
            mailingState: response.state,
          }));
        }
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };
    let param = {
      pincode:
        key === "PERMANENT"
          ? permanentAddress.pincode.value
          : permanentAddress.mailingPinCode.value,
    };

    CAR_SERVICES.GetCityStateByPin(onSuccess, onError, param);
  };
  return isMobile ? (
    <MAddressDetails
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      handleClick={handleClick}
      permanentAddress={permanentAddress}
    />
  ) : (
    // null
    <AddressDetails
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      handleClick={handleClick}
      permanentAddress={permanentAddress}
    />
  );
}
