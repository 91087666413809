export enum CAR_ROUTES {
  QUOTE = "/quote/car-insurance/car-quote",
  PROPOSAL_PAGE = "/car-insurance",
  OWNER_DETAILS = "owner-details",
  VEHICLE_DETAILS = "vehicle-details",
  NOMINEE_DETAILS = "nominee-details",
  ADDRESS_DETAILS = "address-details",
  PREVIEW_DEATILS = "preview-details",
  INSPECTION_CONFIRMATION = "/inspection-confirmation",
}
