import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import { COLORS } from "../../../SupportingFiles/colors";
import { formatAmountToCurrency } from "../../../SupportingFiles/HelpingFunction";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./MTermRiderQuestion.scss";

interface Props {
  addon_amount?: string;
  main_class?: string;
  icon_class?: string;
  question_name: string;
  sub_details?: any;
  input_id?: string;
  input_name?: string;
  input_type?: "TOGGLE" | "DROPDOWN";
  field_label?: string;
  field_value?: string;
  field_attrName?: any;
  field_value_update?: Function;
  field_dropdown_data?: Array<any>;
  field_warn_status?: boolean;
  value_update: Function;
  attrName?: string;
  toggle_status: boolean | undefined;
  policy_term?: string;
  desc?: any;
  loader?: boolean;
}
const MTermRiderQuestion: React.FC<Props> = ({
  addon_amount,
  main_class = "",
  icon_class = "",
  question_name,
  sub_details,
  input_id,
  input_name,
  value_update,
  attrName,
  toggle_status,
  policy_term,
  desc,
  loader,
}) => {
  useEffect(() => {
    console.log("Bajaj", loader);
  }, [loader]);
  return (
    <Box className={main_class}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={8} className="mAddonQuestionInner mt-2">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            <p>
              {" "}
              <b>{policy_term}</b>{" "}
            </p>
            <p>{desc}</p>
          </div>
        </Grid>
        <Grid xs={4} textAlign="right" className="pt-4">
          {/* {toggle_status ? (
            loader ? (
              <img src="../images/loader.svg" height="10px" alt="Brand Logo" />
            ) : (
              <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
                {formatAmountToCurrency(parseInt(`${addon_amount}`)) !==
                  "₹ NaN" &&
                toggle_status &&
                !loader ? (
                  <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
                    {formatAmountToCurrency(parseInt(`${addon_amount}`))}
                  </h5>
                ) : null}
              </h5>
            )
          ) : null} */}
          <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
            {formatAmountToCurrency(parseInt(`${addon_amount}`)) !== "₹ NaN" &&
            toggle_status &&
            !loader ? (
              <h5 style={{ color: COLORS.primary, marginBottom: "4px" }}>
                {formatAmountToCurrency(parseInt(`${addon_amount}`))}
              </h5>
            ) : null}
          </h5>
          <ToggleSwitch
            toggle_status={toggle_status}
            value_update={value_update}
            attrName={attrName}
          />
        </Grid>
        {toggle_status ? sub_details : null}
      </Grid>
    </Box>
  );
};

export default MTermRiderQuestion;
