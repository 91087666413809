import { TCignaMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCignaMedicalQuestion";

export const CIGNA_QUESTION_INITIAL_VALUE = (): TCignaMedicalQuestion => {
  return {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "Cancer",
        description:
          "Has any of the applicants ever been diagnosed with or suspected to have Cancer or Rheumatoid Arthritis or Ulcerative Colitis or Crohn's disease or Chronic Liver Disease, Hepatitis B, Cirrhosis or Chronic Kidney Disease or Kidney failure or Epilepsy or Fits or Stroke or Paralysis or Parkinsonism or Alzheimer's or Multiple sclerosis or Brain Tumor or Cerebral Palsy or Heart Failure or Heart Attack or Angina or Coronary Artery Disease or Ischemic Heart Disease or Chronic Bronchitis or Intestitial Lung Diseases or Pneumoconiosis or Emphysema.?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        icon_class: "tobaco",
        title: "Hospitalised",
        description:
          "Has any of the applicant ever been diagnosed with or suspected to have Cancer or Rheumatoid Arthritis or Ulcerative Colitis or Crohn's disease or Chronic Liver Disease, Hepatitis B, Cirrhosis or Chronic Kidney Disease or Kidney failure or Epilepsy or Fits or Stroke or Paralysis or Parkinsonism or Alzheimer's or Multiple sclerosis or Brain Tumor or Cerebral Palsy or Heart Failure or Heart Attack or Angina or Coronary Artery Disease or Ischemic Heart Disease or Chronic Bronchitis or Intestitial Lung Diseases or Pneumoconiosis or Emphysema.?",
        status: false,
        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "Diabetes Mellitus?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "Hypertension?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "High Cholesterol?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ4-SQ4": {
            id: "MQ4-SQ4",
            icon_class: "",
            status: false,
            title: "Thyroid disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ5-SQ5": {
            id: "MQ5-SQ5",
            icon_class: "",
            status: false,
            title: "Heart and Lung disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ6-SQ6": {
            id: "MQ6-SQ6",
            icon_class: "",
            status: false,
            title: "Digestive system disorders (Stomach and related organs)?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ7-SQ7": {
            id: "MQ7-SQ7",
            icon_class: "",
            status: false,
            title: "Brain, nerve and Psychiatric (Mental) disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ8-SQ8": {
            id: "MQ8-SQ8",
            icon_class: "",
            status: false,
            title: "Other Endocrine (Hormonal) disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ9-SQ9": {
            id: "MQ9-SQ9",
            icon_class: "",
            status: false,
            title: "Bone, joints and muscle disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ10-SQ10": {
            id: "MQ10-SQ10",
            icon_class: "",
            status: false,
            title: "Ear, nose, eye and throat disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ11-SQ11": {
            id: "MQ11-SQ11",
            icon_class: "",
            status: false,
            title: "Genito-urinary and Gynaecological disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ12-SQ12": {
            id: "MQ12-SQ12",
            icon_class: "",
            status: false,
            title: "Blood and related disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ13-SQ13": {
            id: "MQ13-SQ13",
            icon_class: "",
            status: false,
            title: "Skin disorders?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ14-SQ14": {
            id: "MQ14-SQ14",
            icon_class: "",
            status: false,
            title: "Any other condition / illness / disorder / surgery?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Treatment taken",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Complication/Recurrences",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Diagnosis year",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Last Consultation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Histo-Pathology Examination Report",
                    field_type: "DROPDOWN_SURGICAL",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        icon_class: "cancer",
        title: "Pre-Existing Diseases",
        description:
          "Has any of the applicant recommended to undergo or has undergone any pathologic or radiologic tests for any illness other than the ones listed above and routine or annual health check-up?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
      },
    },
    MQ04: {
      main_question: {
        id: "MQ04",
        icon_class: "tobaco",
        title: "Good Health",
        description:
          "Are you currently in good health and not undergoing any treatment or medication for any illness/medical condition (Physical, Psychiatric, Mental illness /disorders, Sleep disorders)?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
      },
    },
    MQ05: {
      main_question: {
        id: "MQ05",
        icon_class: "tobaco",
        title: "Intoxication Declaration",
        description:
          "Select 'Yes' if any insured member Smokes, consumes Tobacco in any form, or drinks Alcohol?",
        status: false,
        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "Does any of the members consume Alcohol?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Alcohal",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Alcohal",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Alcohal",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Alcohal",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Alcohal",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Alcohal",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "Does any of the members consume smoking?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Smoking",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Smoking",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Smoking",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Smoking",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Smoking",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Smoking",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "Does any of the members consume tobacco?",
            description: "",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Tobacco",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Tobacco",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Tobacco",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Tobacco",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Tobacco",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Tobacco",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
        },
      },
    },
    MQ06: {
      main_question: {
        id: "MQ06",
        icon_class: "tobaco",
        title: "Critical Illness",
        description:
          "Have any first degree relatives (i.e. parents, brothers, sisters or children) of any of the applicants (who are not themselves applicants for this insurance policy) had cancer, motor neurone disease or any other hereditary disorders?",
        status: false,
      },
    },
    MQ07: {
      main_question: {
        id: "MQ07",
        icon_class: "tobaco",
        title: "Terminal illness(Personal Accident)",
        description:
          "Does any proposed to be insured suffer from any terminal illness, seizure disorders or any disease/deformity affecting or restricting mobility, sight, hearing or speech?",
        status: false,
      },
    },
    MQ08: {
      main_question: {
        id: "MQ08",
        icon_class: "tobaco",
        title: "Hazardous Activities(Personal Accident)",
        description:
          "Does any proposed to be insured's occupation or nature of duties require them to be a part of armed forces, expose them to hazardous substances/chemicals or hazardous activities?",
        status: false,
      },
    },
  };
};
