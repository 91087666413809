import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CKYC_ROUTES } from "./Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "./Router/Path/HealthRoutes";
import { TW_ROUTES } from "./Router/Path/TWRoutes";
import CarRoutes from "./Router/Routes/CarRoutes/CarRoutes";
import CommonRoutes from "./Router/Routes/CommonRoutes/CommonRoutes";
import HealthRoutes from "./Router/Routes/Health/HealthRoutes";
import TWRoutes from "./Router/Routes/TWRoutes/TWRoutes";
import TermRoutes from "./Router/Routes/TermRoutes/TermRoutes";
import { CAR_ADD_FORM_SERVICES } from "./Services/Car/CarAddFormServices";
import { CAR_SERVICES } from "./Services/Car/CarServices";
import { CKYCSlice } from "./Store/Slice_Reducer/CKYC/CKYCSlice";
import { CarSlice } from "./Store/Slice_Reducer/Car/CarSlice";
import { HealthSlice } from "./Store/Slice_Reducer/Health/HealthSlice";
import { TermSlice } from "./Store/Slice_Reducer/Term/TermSlice";
import { TWSlice } from "./Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch } from "./Store/hooks";
import {
  DETERMINE_MOTOR_PLAN_TYPE,
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "./SupportingFiles/HelpingFunction";
import changeFaviconAndTitle from "./SupportingFiles/faviconUtils";
import { CLIENTS } from "./URLCollection/Clients";
import { TAddonsValueType } from "./types/Car/TAddonsValueType";
import { TCarAddonStatus } from "./types/Car/TCarAddonStatus";
import { TCarProduct } from "./types/Car/TCarProduct";
import { TCarSingleQuoteDetail } from "./types/Car/TCarSingleQuoteDetail";
import { TAddressDetails } from "./types/Common/TAddressDetails";
import { THealthQuote } from "./types/Health/HealthQuotation/THealthQuote";
import { THealthAddForm } from "./types/Health/THealthSlice";
import {
  TCarAddForm,
  TCarAddFormResponse,
  TCarDiscount,
  TCarOwnerDetails,
  TCarSlice,
  TCarVehicleDetails,
  TClaimStatus,
  TNCB,
  TNomineeDetails,
} from "./types/TCarSlice";
import {
  TOwnerDetails,
  TTWAddFormResponse,
  TTWForm,
  TTWSlice,
  TVehicleDetails,
} from "./types/TTWSlice";
import { TSelectedQuote } from "./types/TTermSlice";
import { TTermAddForm } from "./types/Term/TTermAddForm";
import { TTWAddonStatus } from "./types/TwoWheeler/TTWAddonStatus";
import { TTWProduct } from "./types/TwoWheeler/TTWProduct";
import { TTWSingleQuoteDetail } from "./types/TwoWheeler/TTWSingleQuoteDetail";

function App() {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      // Prevent the default install prompt
      event.preventDefault();
      console.log("Install prompt disabled");
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Cleanup the event listener
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const quote_no = params.get("quote_no");
    const product_id = params.get("product_id");
    const companyCode = params.get("companyCode");

    changeFaviconAndTitle();

    if (type && quote_no && companyCode && product_id) {
      const prams = {
        type: type,
        quote_no: quote_no,
        companyCode: companyCode,
        product_id: product_id,
      };
      if (type !== "HP") {
        GET_PREVIEW_DATA(prams);
      }
      return;
    }

    if (type && quote_no && product_id) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({ ...TermSlice.getInitialState() })
      );
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
        })
      );
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...HealthSlice.getInitialState(),
        })
      );
      dispatch(TWSlice.actions.BULK_UPDATE(TWSlice.getInitialState()));
      dispatch(CarSlice.actions.BULK_UPDATE(CarSlice.getInitialState()));

      const prams = {
        type: type,
        quote_no: quote_no,
        product_id: product_id,
      };

      setTimeout(() => {
        GET_PROPOSAL_INFO(prams);
      }, 1000);
    }
  }, []);

  const SET_HEALTH_DATA = (response: any) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const redirected: boolean = params.get("redirected") === "true";

    if (
      window.location.pathname !==
        `/proposal${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}` &&
      window.location.pathname !==
        `/proposal${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}`
    ) {
      return;
    }
    const form_data = response.healthResponse;
    const more_details = form_data.more_details;
    const premium_details = response.premiumDetails;
    const company_details = response.companyDetails;
    const product_details = response.productDetails;

    let modify_more_details: { [key: string]: any } = {};

    more_details.forEach((detail: any) => {
      Object.keys(detail).forEach((key) => {
        modify_more_details[key] = detail[key];
      });
    });

    let sonCount = 0;
    let daughterCount = 0;

    more_details.forEach((detail: any) => {
      Object.values(detail).forEach((value) => {
        if (value === "son") {
          sonCount++;
        } else if (value === "daughter") {
          daughterCount++;
        }
      });
    });

    const manipulated_data_add_form: THealthAddForm = {
      spouse_relationship: {
        value: modify_more_details?.adult2Relation,
        warning: false,
      },
      age: {
        value: `${modify_more_details?.adult1Age}`,
        warning: false,
      },
      children: form_data.child,
      gender: { value: modify_more_details?.adult1Gender, warning: false },
      pincode: { value: `${form_data.pincode}`, warning: false },
      whom_to_insure: "One Adult",
      mobile: { value: form_data.mobile, warning: false },
      name: { value: "", warning: false },
      son_count: { value: sonCount, warning: false },
      daughter_count: { value: daughterCount, warning: false },
      self_dob: {
        value: `${modify_more_details?.adult1Age}`,
        warning: false,
      },
      spouse_dob: {
        value: `${modify_more_details?.adult2Age}`,
        warning: false,
      },
      son_status: sonCount > 0,
      daughter_status: daughterCount > 0,
      self_status: true,
      spouse_status: `${form_data.adult}` === "2",
      child_one_dob: { value: modify_more_details?.childage_1, warning: false },
      child_two_dob: { value: modify_more_details?.childage_2, warning: false },
      child_three_dob: {
        value: modify_more_details?.childage_3,
        warning: false,
      },
      child_four_dob: {
        value: modify_more_details?.childage_4,
        warning: false,
      },
    };

    const manipulated_selected_quote_data: THealthQuote = {
      CashlessHospitalLists: [],
      CompanyDetails: company_details,
      loading: false,
      premiumDetails: {
        ...premium_details,
        finalPremium: premium_details.premium,
      },
      productDetails: product_details,
      SpecialFeatureLists: [],
      sumInsured: form_data.sum_insured,
    };

    let health_slice = {
      ...HealthSlice.getInitialState(),
      ADD_FORM: {
        ...manipulated_data_add_form,
        children: form_data.child,
      },
      ADD_FORM_RESPONSE: {
        ...HealthSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: form_data.quote_no,
        sum_insured: parseInt(form_data.sum_insured),
        utm_medium: form_data?.utm_medium,
        utm_source: form_data?.utm_source,
      },
      SELECTED_QUOTE_DATA: {
        ...HealthSlice.getInitialState().SELECTED_QUOTE_DATA,
        ...manipulated_selected_quote_data,
      },
    };

    if (redirected) {
      health_slice = { ...health_slice, REDIRECTED: redirected };
    }

    dispatch(CKYCSlice.actions.BULK_UPDATE({ ...CKYCSlice.getInitialState() }));
    dispatch(HealthSlice.actions.BULK_UPLOAD({ ...health_slice }));
  };

  const SET_TERM_DATA = (response: any) => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const redirected: boolean = params.get("redirected") === "true";

    const form_data = response.lifeResponse;
    const premium_details = response.premiumDetails;
    const manipulated_data_add_form: TTermAddForm = {
      annualIncome: { value: form_data.annual_income, warning: false },
      smoke: premium_details.smoker,
      gender: { value: premium_details.gender, warning: false },
      age: {
        value: `${
          premium_details.dob_date.length > 1
            ? premium_details.dob_date
            : `0${premium_details.dob_date}`
        }-${
          premium_details.dob_month.length > 1
            ? premium_details.dob_month
            : `0${premium_details.dob_month}`
        }-${premium_details.dob_year}`,
        warning: false,
      },
      pincode: { value: form_data.pincode, warning: false },
      name: { value: form_data.name, warning: false },
      mobile: { value: form_data.mobile, warning: false },
    };

    const manipulated_selected_quote_data: TSelectedQuote = {
      appno: "",
      documentURL: "",
      transaction_id: "",
      premiumDetails: response.premiumDetails,
      companyDetails: response.companyDetails,
      SpecialFeatureLists: [],
      productDetailLists: null,
      buyOnlineLink: {
        method: "",
        buy_online_link: "",
        redirection_type: "",
      },
      productDetails: {
        ...response.productDetails,
        frequency: premium_details.frequency,
      },
      sumAssured: "",
      QuoteNo: "",
    };

    let term_slice = {
      ...TermSlice.getInitialState(),
      ADD_FORM: {
        ...TermSlice.getInitialState().ADD_FORM,
        ...manipulated_data_add_form,
      },
      ADD_FORM_RESPONSE: {
        ...TermSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: form_data.quote_no,
        utm_medium: form_data?.lifeResponse?.utm_medium,
        utm_source: form_data?.lifeResponse?.utm_source,
      },
      SELECTED_QUOTE_DATA: {
        ...TermSlice.getInitialState().SELECTED_QUOTE_DATA,
        ...manipulated_selected_quote_data,
      },
      PROPOSER_DATA: {
        ...TermSlice.getInitialState().PROPOSER_DATA,
        full_name: { value: form_data.name, warning: false },
        mobile: { value: form_data.mobile, warning: false },
        dob: {
          value: `${
            premium_details.dob_date.length > 1
              ? premium_details.dob_date
              : `0${premium_details.dob_date}`
          }-${
            premium_details.dob_month.length > 1
              ? premium_details.dob_month
              : `0${premium_details.dob_month}`
          }-${premium_details.dob_year}`,
          warning: false,
        },
        gender: {
          value: premium_details.gender,
          warning: false,
        },
        pincode: { value: form_data.pincode, warning: false },
        sum_assured: { value: form_data.sum_assured, warning: false },
        annual_income: { value: form_data.annual_income, warning: false },
      },
      QUOTE_FIELDS_DATA: {
        ...TermSlice.getInitialState().QUOTE_FIELDS_DATA,
        sumAssured: premium_details.sum_assured,
        payMode: premium_details.frequency,
      },
      RIDERS_BENEFITS: {
        ...TermSlice.getInitialState().RIDERS_BENEFITS,
        riders_benefits_response: {
          ...TermSlice.getInitialState().RIDERS_BENEFITS
            .riders_benefits_response,
          finalPremium: premium_details.premium,
        },
      },
    };

    if (redirected) {
      term_slice = { ...term_slice, REDIRECTED: redirected };
    }

    dispatch(TermSlice.actions.BULK_UPDATE({ ...term_slice }));
  };

  const SET_CAR_DATA = (response: any) => {
    console.log("apprs", response);

    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const redirected: boolean = params.get("redirected") === "true";

    // if (
    //   window.location.pathname !==
    //   `/proposal${CAR_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}`
    // ) {
    //   dispatch(
    //     CKYCSlice.actions.BULK_UPDATE({ ...CKYCSlice.getInitialState() })
    //   );
    // }
    // if (
    //   window.location.pathname !==
    //     `/proposal${CAR_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}` &&
    //   window.location.pathname !==
    //     `/proposal${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.OWNER_DETAILS}`
    // ) {
    //   return;
    // }
    try {
      const form_data = response.insurance;
      const manipulated_data_add_form: TCarAddForm = {
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data.business_type,
        claimed: form_data.claim_made as TClaimStatus,
        make_model: {
          value: form_data.make_model,
          warning: false,
        },
        pincode: {
          value: form_data.pincode,
          warning: false,
        },
        fuel_type: {
          value: form_data.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data.previous_ncb as TNCB,
        previous_insurer: { value: form_data.previous_insurer, warning: false },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
          warning: false,
        },
        reg_no: { value: form_data.registration_no, warning: false },
        variant_cc: {
          value: form_data.variant_cc,
          warning: false,
        },
        rto: { value: form_data.rto_code, warning: false },
        new_ncb: { value: form_data.new_ncb, warning: false },
        policy_expiry_date: {
          value:
            form_data.business_type === "New"
              ? ""
              : FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_owner_details: TCarOwnerDetails = {
        ...CarSlice.getInitialState().OWNER_DETAILS,
        mobile: { value: form_data.mobile, warning: false },
      };

      const manipulated_addon_status: TCarAddonStatus = {
        depreciation_cover: {
          label: "Zero Depreciation",
          value: form_data.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data.pa_owner_driver === 1,
        },
        personalBelonging: {
          label: "Personal Belonging",
          value: form_data?.personal_belonging === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data.driver_cover === 1,
        },
        consumable_cover: {
          label: "Consumable Cover",
          value: form_data.consumables_cover === 1,
        },
        engine_cover: {
          label: "Engine Cover",
          value: form_data.engine_gear_box_protection === 1,
        },
        invoice_cover: {
          label: "Invoice Cover",
          value: form_data.invoice_cover === 1,
        },
        key_replacement: {
          label: "Key Replacement",
          value: form_data.key_replacement_cover === 1,
        },
        ncb_cover: {
          label: "NCB Cover",
          value: form_data.no_claim_bonus_same_slab === 1,
        },
        tyre_cover: {
          label: "Tyre Cover",
          value: form_data.tyre_secure_cover === 1,
        },
        passenger_cover: {
          label: "Passenger Cover (₹)",
          value: form_data.passernger_cover > 0,
        },
        electrical: {
          label: "Electrical (₹)",
          value: form_data.electric_accessory > 0,
        },
        non_electrical: {
          label: "Non-Electrical (₹)",
          value: form_data.non_electric_accessory > 0,
        },
        cng_kit: { label: "CNG Kit (₹)", value: form_data.cng_kit > 0 },
      };

      const manipulated_data_discount: TCarDiscount = {
        anti_theft_device: "No",
        deductible: "0",
        vehicle_owned: response.vehicle_owned,
      };

      const manipulated_selected_quote_data: TCarSingleQuoteDetail = {
        cashlessGarageLists: [],
        CompanyDetails: response.companyDetails,
        productDetails: response.productDetails,
        premiumDetails: response.jsonData,
      };

      const manipulated_ADDON_USER_VALUE: TAddonsValueType = {
        cngKitValue: { value: form_data.cng_kit, warning: false },
        electricalAcessories: {
          value: form_data.electric_accessory,
          warning: false,
        },
        idv: { value: form_data.idv, warning: false },
        nonElectricalAcessories: {
          value: form_data.non_electric_accessory,
          warning: false,
        },
        passengerCover: { value: form_data.passernger_cover, warning: false },
      };

      const set_data_in_redux = (engine_no: string, chassis_no: string) => {
        setLoader(false);

        let car_slice: TCarSlice = {
          ...CarSlice.getInitialState(),
          ADD_FORM: {
            ...CarSlice.getInitialState().ADD_FORM,
            ...manipulated_data_add_form,
          },
          ADD_FORM_RESPONSE: {
            ...CarSlice.getInitialState().ADD_FORM_RESPONSE,
            quote_no: response.insurance.quote_no,
            utm_medium: response?.utmDetails?.utm_medium,
            utm_source: response?.utmDetails?.utm_source,
          },
          DISCOUNT_FORM: manipulated_data_discount,
          SELECTED_QUOTE_DATA: {
            ...CarSlice.getInitialState().SELECTED_QUOTE_DATA,
            quotationDetail: manipulated_selected_quote_data,
          },
          OWNER_DETAILS: {
            ...manipulated_owner_details,
          },
          VEHICLE_DETAILS: {
            ...CarSlice.getInitialState().VEHICLE_DETAILS,
            chasis_no: { value: engine_no, warning: false },
            engine_no: { value: chassis_no, warning: false },
            reg_date: {
              value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
              warning: false,
            },
            mfg_year: {
              value: FORMAT_DATE_DD_MM_YYYY(form_data.manufacture_date),
              warning: false,
            },
            policy_exp_date: {
              value: FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
              warning: false,
            },
            prev_insurer: {
              value: form_data.previous_insurer,
              warning: false,
            },
            tp_insurer: {
              value: form_data.tp_previous_insurer,
              warning: false,
            },
          },
          ADDON_STATUS: {
            ...CarSlice.getInitialState().ADDON_STATUS,
            ...manipulated_addon_status,
          },
          ADDON_USER_VALUE: {
            ...CarSlice.getInitialState().ADDON_USER_VALUE,
            ...manipulated_ADDON_USER_VALUE,
          },
          PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
            manipulated_selected_quote_data.productDetails.product_code,
            response.insurance.business_type
          ),
          QUOTE_LOADER: false,
        };

        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            CKYC_DETAILS: { ...response?.CKYC_DETAILS?.data },
            VERIFY_KYC_FORM: {
              ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
              customer_type: { value: form_data.vehicle_owned, warning: false },
            },
          })
        );

        if (redirected) {
          car_slice = { ...car_slice, REDIRECTED: redirected };
        }
        dispatch(CarSlice.actions.BULK_UPDATE({ ...car_slice }));
      };

      const onSuccess = (res: any) => {
        const response_fast_lane = res.response;
        const chasis_no = response_fast_lane.engine_no;
        const engine_no = response_fast_lane.chasis_no;

        set_data_in_redux(engine_no, chasis_no);
      };
      const onError = () => {
        set_data_in_redux("", "");
      };

      if (
        window.location.hostname !== CLIENTS.JIO &&
        window.location.hostname !== CLIENTS.AMAZE
      ) {
        set_data_in_redux("", "");
      } else {
        if (manipulated_data_add_form.business_type.toLowerCase() !== "new") {
          CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
            onSuccess,
            onError,
            manipulated_data_add_form.reg_no.value,
            "CAR"
          );
        } else {
          set_data_in_redux("", "");
        }
      }
    } catch (error) {
      toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_CAR_PREVIEW_DATA = (response: any) => {
    try {
      const form_data = response?.insurance;
      const manipulated_data_add_form: TCarAddForm = {
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data?.business_type,
        claimed: form_data?.claim_made as TClaimStatus,
        make_model: {
          value: form_data?.make_model,
          warning: false,
        },
        pincode: {
          value: form_data?.pincode,
          warning: false,
        },
        fuel_type: {
          value: form_data?.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data?.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data?.previous_ncb as TNCB,
        previous_insurer: {
          value: form_data?.previous_insurer,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.registration_date),
          warning: false,
        },
        reg_no: { value: form_data?.registration_no, warning: false },
        variant_cc: {
          value: form_data?.variant_cc,
          warning: false,
        },
        rto: { value: form_data?.rto_code, warning: false },
        new_ncb: { value: form_data?.new_ncb, warning: false },
        policy_expiry_date: {
          value:
            form_data.business_type === "New"
              ? ""
              : FORMAT_DATE_DD_MM_YYYY(form_data?.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_data_add_form_response: TCarAddFormResponse = {
        ...CarSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: response?.insurance?.quote_no,
      };

      const manipulated_selected_quote_data: TCarProduct = {
        ...CarSlice.getInitialState().SELECTED_QUOTE_DATA,
        quotationDetail: {
          cashlessGarageLists: [],
          CompanyDetails: response?.companyDetails,
          productDetails: response?.productDetails,
          premiumDetails: response?.jsonData,
        },
      };

      const manipulated_data_discount: TCarDiscount = {
        anti_theft_device: "No",
        deductible: "0",
        vehicle_owned: response?.vehicle_owned,
      };

      const manipulated_addon_status: TCarAddonStatus = {
        depreciation_cover: {
          label: "Zero Depreciation",
          value: form_data?.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data?.pa_owner_driver === 1,
        },
        personalBelonging: {
          label: "Personal Belonging",
          value: form_data?.personal_belonging === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data?.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data?.driver_cover === 1,
        },
        consumable_cover: {
          label: "Consumable Cover",
          value: form_data?.consumables_cover === 1,
        },
        engine_cover: {
          label: "Engine Cover",
          value: form_data?.engine_gear_box_protection === 1,
        },
        invoice_cover: {
          label: "Invoice Cover",
          value: form_data?.invoice_cover === 1,
        },
        key_replacement: {
          label: "Key Replacement",
          value: form_data?.key_replacement_cover === 1,
        },
        ncb_cover: {
          label: "NCB Cover",
          value: form_data?.no_claim_bonus_same_slab === 1,
        },
        tyre_cover: {
          label: "Tyre Cover",
          value: form_data?.tyre_secure_cover === 1,
        },
        passenger_cover: {
          label: "Passenger Cover (₹)",
          value: form_data?.passernger_cover > 0,
        },
        electrical: {
          label: "Electrical (₹)",
          value: form_data?.electric_accessory > 0,
        },
        non_electrical: {
          label: "Non-Electrical (₹)",
          value: form_data?.non_electric_accessory > 0,
        },
        cng_kit: { label: "CNG Kit (₹)", value: form_data?.cng_kit > 0 },
      };

      const manipulated_ADDON_USER_VALUE: TAddonsValueType = {
        cngKitValue: { value: form_data?.cng_kit, warning: false },
        electricalAcessories: {
          value: form_data?.electric_accessory,
          warning: false,
        },
        idv: { value: form_data?.idv, warning: false },
        nonElectricalAcessories: {
          value: form_data?.non_electric_accessory,
          warning: false,
        },
        passengerCover: { value: form_data?.passernger_cover, warning: false },
      };

      const proposal_data = response?.praposal;
      const manipulated_owner_details: TCarOwnerDetails = {
        ...CarSlice.getInitialState().OWNER_DETAILS,
        name: { value: proposal_data?.customer_name, warning: false },
        dob: { value: proposal_data?.customer_dob, warning: false },
        email: { value: proposal_data?.customer_email, warning: false },
        gender: { value: proposal_data?.customer_gender, warning: false },
        gst: { value: proposal_data?.customer_gst_no, warning: false },
        maritalStatus: {
          value: proposal_data?.customer_marital,
          warning: false,
        },
        mobile: { value: proposal_data?.customer_mobile, warning: false },
      };

      const vehicle_data = response?.VEHICLE_DETAILS;
      const manipulated_vehicle_details: TCarVehicleDetails = {
        ...CarSlice.getInitialState().VEHICLE_DETAILS,
        bank_name: { value: vehicle_data?.bank_name, warning: false },
        chasis_no: { value: vehicle_data?.chasis_no, warning: false },
        city: { value: vehicle_data?.city, warning: false },
        engine_no: { value: vehicle_data?.engine_no, warning: false },
        is_vehicle_on_loan:
          vehicle_data?.is_vehicle_on_loan === "No" ? false : true,
        mfg_year: { value: vehicle_data?.mfg_year, warning: false },
        pan_no: { value: vehicle_data?.pan_no, warning: false },
        policy_exp_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.policy_exp_date),
          warning: false,
        },
        prev_insurer: {
          value: response.insurance.previous_insurer,
          warning: false,
        },
        prev_policy_no: {
          value: proposal_data?.existing_policy_no,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.reg_date),
          warning: false,
        },
        tp_insurer: {
          value: proposal_data?.tp_existing_policy_no,
          warning: false,
        },
        tp_policy: { value: proposal_data?.tp_policy, warning: false },
        tp_policy_start: {
          value: vehicle_data?.tp_policy_start,
          warning: false,
        },
      };

      const manipulated_nominee_details: TNomineeDetails = {
        ...CarSlice.getInitialState().NOMINEE_DETAILS,
        fullName: { value: proposal_data?.nominee_name, warning: false },
        dob: {
          value: FORMAT_DATE_DD_MM_YYYY(proposal_data?.nominee_dob),
          warning: false,
        },
        relationship: {
          value: proposal_data?.nominee_relationship,
          warning: false,
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...CarSlice.getInitialState().ADDRESS_DETAILS,
        pincode: {
          value: !isEmpty(response?.CKYC_DETAILS?.data?.correspondencePincode)
            ? response?.CKYC_DETAILS?.data?.correspondencePincode
            : isEmpty(form_data.pincode)
            ? proposal_data?.address_p_pincode
            : form_data.pincode,
          warning: false,
        },
        city: proposal_data?.address_p_city,
        state: proposal_data?.address_p_state,
        addressLineOne: {
          value: proposal_data?.address_p_house,
          warning: false,
        },
        addressLineTwo: {
          value: proposal_data?.address_p_area,
          warning: false,
        },
        isMailingAddressSame: proposal_data?.address_mailing_same === "Yes",
        mailingPinCode: {
          value: proposal_data?.address_m_pincode,
          warning: false,
        },
        mailingCity: proposal_data?.address_m_city,
        mailingState: proposal_data?.address_m_state,
        mailingAddressLineOne: {
          value: proposal_data?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: proposal_data?.address_m_area,
          warning: false,
        },
      };

      const set_data_in_redux = () => {
        setLoader(false);
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            CKYC_DETAILS: {
              ...response?.CKYC_DETAILS,
              VERIFY_KYC_FORM: {
                ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
                customer_type: {
                  value: form_data.vehicle_owned,
                  warning: false,
                },
              },
            },
          })
        );
        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...CarSlice.getInitialState(),
            ADD_FORM: {
              ...manipulated_data_add_form,
            },
            ADD_FORM_RESPONSE: {
              ...manipulated_data_add_form_response,
            },
            DISCOUNT_FORM: manipulated_data_discount,
            SELECTED_QUOTE_DATA: {
              ...manipulated_selected_quote_data,
            },
            OWNER_DETAILS: {
              ...manipulated_owner_details,
            },
            VEHICLE_DETAILS: {
              ...manipulated_vehicle_details,
            },
            NOMINEE_DETAILS: { ...manipulated_nominee_details },
            ADDRESS_DETAILS: { ...manipulated_address_details },
            ADDON_STATUS: {
              ...manipulated_addon_status,
            },
            ADDON_USER_VALUE: {
              ...manipulated_ADDON_USER_VALUE,
            },
            PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
              `${manipulated_selected_quote_data?.quotationDetail?.productDetails.product_code}`,
              response?.insurance?.business_type
            ),
            QUOTE_LOADER: false,
            CKYC_DETAILS: { ...response?.CKYC_DETAILS },
          })
        );
      };

      set_data_in_redux();
    } catch (error) {
      toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_TW_PREVIEW_DATA = (response: any) => {
    try {
      const form_data = response?.insurance;
      const manipulated_data_add_form: TTWForm = {
        pincode: { value: response?.insurance?.pincode, warning: false },
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: { value: "", warning: false },
        business_type: form_data?.business_type,
        claimed: form_data?.claim_made as TClaimStatus,
        make_model: {
          value: form_data?.make_model,
          warning: false,
        },
        fuel_type: {
          value: form_data?.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: { value: form_data?.mobile, warning: false },
        model: { value: "", warning: false },
        prev_ncb: form_data?.previous_ncb as TNCB,
        previous_insurer: {
          value: form_data?.previous_insurer,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.registration_date),
          warning: false,
        },
        reg_no: { value: form_data?.registration_no, warning: false },
        variant_cc: {
          value: form_data?.variant_cc,
          warning: false,
        },
        rto: { value: form_data?.rto_code, warning: false },
        policy_expiry_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_data_add_form_response: TTWAddFormResponse = {
        ...TWSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: response?.insurance?.quote_no,
      };

      const manipulated_selected_quote_data: TTWProduct = {
        ...TWSlice.getInitialState().SELECTED_QUOTE_DATA,
        quotationDetail: {
          cashlessGarageLists: [],
          CompanyDetails: response?.companyDetails,
          productDetails: response?.productDetails,
          premiumDetails: response?.jsonData,
        },
      };

      const manipulated_addon_status: TTWAddonStatus = {
        zero_depreciation: {
          label: "Zero Depreciation",
          value: form_data?.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data?.pa_owner_driver === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data?.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data?.driver_cover === 1,
        },
      };

      const proposal_data = response?.praposal;
      const manipulated_owner_details: TOwnerDetails = {
        ...TWSlice.getInitialState().OWNER_DETAILS,
        name: { value: proposal_data?.customer_name, warning: false },
        dob: {
          value: FORMAT_DATE_DD_MM_YYYY(proposal_data?.customer_dob),
          warning: false,
        },
        email: { value: proposal_data?.customer_email, warning: false },
        gender: { value: proposal_data?.customer_gender, warning: false },
        GST: { value: proposal_data?.customer_gst_no, warning: false },
        maritalStatus: {
          value: proposal_data?.customer_marital,
          warning: false,
        },
        mobile: { value: proposal_data?.customer_mobile, warning: false },
      };

      const vehicle_data = response?.VEHICLE_DETAILS;
      const manipulated_vehicle_details: TVehicleDetails = {
        ...TWSlice.getInitialState().VEHICLE_DETAILS,
        bank_name: { value: vehicle_data?.bank_name, warning: false },
        chasis_no: { value: vehicle_data?.chasis_no, warning: false },
        city: { value: vehicle_data?.city, warning: false },
        engine_no: { value: vehicle_data?.engine_no, warning: false },
        is_vehicle_on_loan:
          vehicle_data?.is_vehicle_on_loan === "No" ? false : true,
        mfg_year: { value: vehicle_data?.mfg_year, warning: false },
        PAN: { value: vehicle_data?.pan_no, warning: false },
        policy_exp_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.policy_exp_date),
          warning: false,
        },
        prev_insurer: {
          value: response.insurance.previous_insurer,
          warning: false,
        },
        prev_policy_no: {
          value: proposal_data?.existing_policy_no,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.reg_date),
          warning: false,
        },
        tp_insurer: {
          value: proposal_data?.tp_existing_policy_no,
          warning: false,
        },
        tp_policy: { value: proposal_data?.tp_policy, warning: false },
        tp_policy_start: {
          value: vehicle_data?.tp_policy_start,
          warning: false,
        },
      };

      const manipulated_nominee_details: TNomineeDetails = {
        ...TWSlice.getInitialState().NOMINEE_DETAILS,
        fullName: { value: proposal_data?.nominee_name, warning: false },
        dob: {
          value: FORMAT_DATE_DD_MM_YYYY(proposal_data?.nominee_dob),
          warning: false,
        },
        relationship: {
          value: proposal_data?.nominee_relationship,
          warning: false,
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...TWSlice.getInitialState().ADDRESS_DETAILS,
        pincode: { value: proposal_data?.address_p_pincode, warning: false },
        city: proposal_data?.address_p_city,
        state: proposal_data?.address_p_state,
        addressLineOne: {
          value: proposal_data?.address_p_house,
          warning: false,
        },
        addressLineTwo: {
          value: proposal_data?.address_p_area,
          warning: false,
        },
        isMailingAddressSame: proposal_data?.address_mailing_same === "Yes",
        mailingPinCode: {
          value: proposal_data?.address_m_pincode,
          warning: false,
        },
        mailingCity: proposal_data?.address_m_city,
        mailingState: proposal_data?.address_m_state,
        mailingAddressLineOne: {
          value: proposal_data?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: proposal_data?.address_m_area,
          warning: false,
        },
      };

      const set_data_in_redux = () => {
        setLoader(false);
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYCSlice.getInitialState(),
            VERIFY_KYC_FORM: {
              ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
              customer_type: {
                value: form_data.vehicle_owned,
                warning: false,
              },
            },
            CKYC_DETAILS: {
              ...response?.CKYC_DETAILS,
            },
          })
        );
        dispatch(
          TWSlice.actions.BULK_UPDATE({
            ...TWSlice.getInitialState(),
            ADD_FORM: {
              ...manipulated_data_add_form,
            },
            IDV_VALUE: form_data?.idv,
            ADD_FORM_RESPONSE: {
              ...manipulated_data_add_form_response,
            },
            SELECTED_QUOTE_DATA: {
              ...manipulated_selected_quote_data,
            },
            OWNER_DETAILS: {
              ...manipulated_owner_details,
            },
            VEHICLE_DETAILS: {
              ...manipulated_vehicle_details,
            },
            NOMINEE_DETAILS: { ...manipulated_nominee_details },
            ADDRESS_DETAILS: { ...manipulated_address_details },
            ADDON_STATUS: {
              ...manipulated_addon_status,
            },
            PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
              `${manipulated_selected_quote_data?.quotationDetail?.productDetails.product_code}`,
              response?.insurance?.business_type
            ),
            QUOTE_LOADER: false,
            CKYC_DETAILS: { ...response?.CKYC_DETAILS },
          })
        );
      };

      set_data_in_redux();
    } catch (error) {
      toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  const SET_TW_DATA = (response: any) => {
    console.log("rere", response);

    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const redirected: boolean = params.get("redirected") === "true";

    if (
      window.location.pathname !==
      `/proposal${TW_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}`
    ) {
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
        })
      );
    }
    // if (
    //   window.location.pathname !==
    //     `/proposal${TW_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}` &&
    //   window.location.pathname !==
    //     `/proposal${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.OWNER_DETAILS}`
    // ) {
    //   return;
    // }

    const form_data = response.insurance;
    const manipulated_data_add_form: TTWForm = {
      pincode: { value: response?.insurance?.pincode, warning: false },
      utm_medium_d: response?.utmDetails?.utm_medium_d,
      invoice_date: { value: "", warning: false },
      previous_policy_type: { value: "SAOD", warning: false },
      name: { value: "", warning: false },
      business_type: form_data.business_type,
      claimed: form_data.claim_made as TClaimStatus,
      make_model: {
        value: form_data.make_model,
        warning: false,
      },
      fuel_type: {
        value: form_data.fuel_type,
        warning: false,
      },
      make: { value: "", warning: false },
      mobile: { value: form_data.mobile, warning: false },
      model: { value: "", warning: false },
      prev_ncb: form_data.previous_ncb as TNCB,
      previous_insurer: { value: form_data.previous_insurer, warning: false },
      reg_date: {
        value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
        warning: false,
      },
      reg_no: { value: form_data.registration_no, warning: false },
      variant_cc: {
        value: form_data.variant_cc,
        warning: false,
      },
      rto: { value: form_data.rto_code, warning: false },
      policy_expiry_date: {
        value: FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
        warning: false,
      },
    };

    const manipulated_addon_status: TTWAddonStatus = {
      zero_depreciation: {
        label: "Zero Depreciation",
        value: form_data.depreciation_cover === 1,
      },
      pa_owner_cover: {
        label: "Personal Accident Cover",
        value: form_data.pa_owner_driver === 1,
      },
      rsa: {
        label: "24x7 Roadside Assistance",
        value: form_data.road_side_assistance === 1,
      },
      driver_cover: {
        label: "Driver Cover",
        value: form_data.driver_cover === 1,
      },
    };

    const manipulated_selected_quote_data: TTWSingleQuoteDetail = {
      cashlessGarageLists: [],
      CompanyDetails: response.companyDetails,
      productDetails: response.productDetails,
      premiumDetails: response.jsonData,
    };

    const set_data_in_redux = (engine_no: string, chassis_no: string) => {
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
          VERIFY_KYC_FORM: {
            ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
            customer_type: { value: form_data.vehicle_owned, warning: false },
          },
        })
      );
      let tw_slice: TTWSlice = {
        ...TWSlice.getInitialState(),
        QUOTE_LOADER: false,
        ADD_FORM: {
          ...TWSlice.getInitialState().ADD_FORM,
          ...manipulated_data_add_form,
        },
        ADD_FORM_RESPONSE: {
          ...TWSlice.getInitialState().ADD_FORM_RESPONSE,
          quote_no: response.insurance.quote_no,
          utm_medium: response?.utmDetails?.utm_medium,
          utm_source: response?.utmDetails?.utm_source,
        },
        SELECTED_QUOTE_DATA: {
          ...TWSlice.getInitialState().SELECTED_QUOTE_DATA,
          quotationDetail: manipulated_selected_quote_data,
        },
        ADDON_STATUS: {
          ...TWSlice.getInitialState().ADDON_STATUS,
          ...manipulated_addon_status,
        },
        IDV_VALUE: { value: form_data.idv, warning: false },
        OWNER_DETAILS: {
          ...TWSlice.getInitialState().OWNER_DETAILS,
          mobile: { value: form_data.mobile, warning: false },
        },
        VEHICLE_DETAILS: {
          ...TWSlice.getInitialState().VEHICLE_DETAILS,
          reg_date: {
            value: FORMAT_DATE_DD_MM_YYYY(form_data.registration_date),
            warning: false,
          },
          mfg_year: {
            value: FORMAT_DATE_DD_MM_YYYY(form_data.manufacture_date),
            warning: false,
          },
          policy_exp_date: {
            value: FORMAT_DATE_DD_MM_YYYY(form_data.policy_expiry_date),
            warning: false,
          },
          prev_insurer: {
            value: form_data.previous_insurer,
            warning: false,
          },
          tp_insurer: {
            value: form_data.tp_previous_insurer,
            warning: false,
          },
          engine_no: {
            value: engine_no,
            warning: false,
          },
          chasis_no: {
            value: chassis_no,
            warning: false,
          },
        },
        PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
          manipulated_selected_quote_data.productDetails.product_code,
          response.insurance.business_type
        ),
      };
      if (redirected) {
        tw_slice = { ...tw_slice, REDIRECTED: redirected };
      }
      dispatch(TWSlice.actions.BULK_UPDATE({ ...tw_slice }));
    };
    const onSuccess = (res: any) => {
      setLoader(false);

      const response_fast_lane = res.response;
      const chasis_no = response_fast_lane.chasis_no;
      const engine_no = response_fast_lane.engine_no;

      set_data_in_redux(engine_no, chasis_no);
    };
    const onError = () => {
      set_data_in_redux("", "");
      setLoader(false);
    };

    if (
      window.location.hostname !== CLIENTS.JIO &&
      window.location.hostname !== CLIENTS.AMAZE
    ) {
      set_data_in_redux("", "");
    } else {
      if (manipulated_data_add_form.business_type.toLowerCase() !== "new") {
        CAR_ADD_FORM_SERVICES.GET_VEHICLE_NO_INFO(
          onSuccess,
          onError,
          manipulated_data_add_form.reg_no.value,
          "TW"
        );
      } else {
        set_data_in_redux("", "");
      }
    }
  };

  const GET_PROPOSAL_INFO = (params: {
    type: string;
    quote_no: string;
    product_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;

      if (!error) {
        const response = results.response;
        switch (params.type) {
          case "TW":
            setLoader(false);
            SET_TW_DATA(response);
            break;
          case "CAR":
            SET_CAR_DATA(response);
            break;
          case "TM":
            setLoader(false);
            SET_TERM_DATA(response);
            break;
          case "HP":
            setLoader(false);
            SET_HEALTH_DATA(response);
            break;

          default:
            break;
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);

    CAR_SERVICES.GET_PROPOSAL_INFO(onError, onSuccess, params);
  };

  const GET_PREVIEW_DATA = (params: {
    type: string;
    quote_no: string;
    companyCode: string;
    product_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;

      if (!error) {
        const response = results.response;
        switch (params.type) {
          case "TW":
            SET_TW_PREVIEW_DATA(response);
            break;
          case "CAR":
            SET_CAR_PREVIEW_DATA(response);
            break;

          default:
            break;
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);

    CAR_SERVICES.GET_PREVIEW_DATA(onError, onSuccess, params);
  };

  return (
    <>
      {loader ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            height: "100vh",
            width: "100%",
          }}>
          <CircularProgress />
          <p>Please wait...</p>
        </Box>
      ) : (
        <BrowserRouter basename="/proposal">
          <CommonRoutes />
          <CarRoutes />
          <TWRoutes />
          <TermRoutes />
          <HealthRoutes />
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
