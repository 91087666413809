import { Route, Routes } from "react-router";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { TW_ROUTES } from "../../../../Path/TWRoutes";
import TWProposalContainer from "../../TWProposalContainer";
import OwnerDetailsContainer from "../../../../../Container/TW/Acko/OwnerDetailsContainer";
import VehicleDetailsContainer from "../../../../../Container/TW/Common/VehicleDetailsContainer";
import NomineeDetailsContainer from "../../../../../Container/TW/Common/NomineeDetailsContainer";
import AddressDetailsContainer from "../../../../../Container/TW/Acko/AddressDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/TW/Common/ReviewDetailsContainer";
import RSACKYCContainer from "../../../../../Container/CKYC/RSA/RSACKYCContainer";

export const ACKO_ROUTES = (
  <Routes>
    <Route path={TW_ROUTES.PROPOSAL_PAGE} element={<TWProposalContainer />}>
      <Route path={CKYC_ROUTES.CKYC} element={<RSACKYCContainer />} />
      <Route
        path={TW_ROUTES.OWNER_DETAILS}
        element={<OwnerDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.VEHICLE_DETAILS}
        element={<VehicleDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route
        path={TW_ROUTES.PREVIEW_DEATILS}
        element={<ReviewDetailsContainer />}
      />
    </Route>
  </Routes>
);
