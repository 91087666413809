import { Box, Grid, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { TCareMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCareMedicalQuestion";

const MMedicalDetailPreviewBoxOptimaSecure = () => {
  const { MEDICAL_QUESTION_DATA_OPTIMA_SECURE, isDeduction_value } =
    useAppSelector((state) => state.Health);
  const navigate = useNavigate();

  const [medicalQuestionData, setMedicalQuestionData] =
    React.useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_OPTIMA_SECURE);
  const dispatch = useAppDispatch();
  // Initialize a variable to track if a main question with status true is found
  let found = false;

  Object.values(medicalQuestionData).forEach((main_data: any) => {
    // Check if main_data and main_question exist and are not null or undefined
    if (
      main_data &&
      main_data.main_question &&
      main_data.main_question.sub_question
    ) {
      // Iterate over each main question's sub-questions
      Object.values(main_data.main_question.sub_question).forEach(
        (sub_question_data: any) => {
          // Check if sub_question_data exists and is not null or undefined
          if (sub_question_data && sub_question_data.status) {
            // Iterate over each sub-question's member data
            Object.values(sub_question_data.member_data).forEach(
              (member_data: any) => {
                // Check if member_data exists and is not null or undefined
                if (member_data && member_data.selection_status) {
                  // If a sub-question with status true and at least one member with selection_status true is found
                  found = true; // Set found to true
                  return; // Break out of the loop
                }
              }
            );
          }
        }
      );
    }
  });
  return (
    <>
      <Box className="previewBox mb-6 mx-3">
        <Grid container columnSpacing={2}>
          <Grid xs={8}>
            {found ? (
              <p className="previewBoxTitle">
                Insured Member's Medical Details
              </p>
            ) : (
              <p className="previewBoxTitle">No Medical History Declared</p>
            )}
          </Grid>
          <Grid xs={4} textAlign="right">
            <Link
              onClick={() => {
                dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
                navigate(
                  `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}`
                );
              }}
              className="previewBoxEdit">
              Edit
            </Link>
          </Grid>
        </Grid>
        {medicalQuestionData ? (
          <Grid xs={12}>
            {Object.values(medicalQuestionData).map((main_data) => {
              if (
                main_data.main_question.id === "MQ01" &&
                main_data.main_question.sub_question
              ) {
                return Object.values(main_data.main_question.sub_question).map(
                  (sub_question_data) => {
                    const hasSelectedMember =
                      sub_question_data.member_data &&
                      Object.values(sub_question_data.member_data).some(
                        (memberData) => memberData.selection_status
                      );

                    return (
                      hasSelectedMember && (
                        <div key={sub_question_data.id}>
                          <React.Fragment>
                            <h6 className="question">
                              {sub_question_data.description}
                            </h6>
                          </React.Fragment>
                          <ul className="member_list">
                            {sub_question_data.member_data &&
                              Object.values(sub_question_data.member_data).map(
                                (memberData) =>
                                  memberData.selection_status && (
                                    <li key={memberData.keyName}>
                                      <div
                                        className={`${memberData.image_class} illness`}></div>
                                      <p className="member_name">
                                        {memberData.name}
                                      </p>
                                      {memberData.field_data &&
                                        Object.values(
                                          memberData.field_data
                                        ).map((fieldData: any) => (
                                          <p className="member_name">
                                            {fieldData.title} :{" "}
                                            {fieldData.value.value}
                                          </p>
                                        ))}
                                    </li>
                                  )
                              )}
                          </ul>
                        </div>
                      )
                    );
                  }
                );
              } else {
                return null; // Return null for other cases where the main question ID doesn't match or sub_question doesn't exist
              }
            })}
            {Object.values(medicalQuestionData).map((main_data: any) =>
              main_data && main_data.main_question ? (
                main_data.main_question ? (
                  Object.values(main_data.main_question.member_data).some(
                    (member_data: any) => member_data.selection_status
                  ) && (
                    <>
                      <React.Fragment key={main_data.main_question.keyName}>
                        <h6 className="question">
                          {/* {main_data.main_question.title} */}
                          {main_data.main_question.description}
                        </h6>
                        <p className=""></p>
                        <div key={main_data.main_question.id}></div>
                      </React.Fragment>
                      <ul className="member_list">
                        {Object.values(main_data.main_question.member_data).map(
                          (memberData: any) =>
                            memberData.selection_status && (
                              <li
                                key={memberData.keyName}
                                style={{ marginBottom: "12px" }}>
                                <div
                                  className={`${memberData.image_class} illness`}></div>
                                <p className="member_name">{memberData.name}</p>
                                {memberData.field_data &&
                                  Object.values(memberData.field_data).map(
                                    (fieldData: any) => (
                                      <p className="member_name">
                                        {fieldData.title} :{" "}
                                        {fieldData.value.value}
                                      </p>
                                    )
                                  )}
                              </li>
                            )
                        )}
                      </ul>
                    </>
                  )
                ) : null
              ) : (
                <Grid container columnSpacing={2}>
                  <Grid xs={12}></Grid> {/* Add your content here */}
                </Grid>
              )
            )}
          </Grid>
        ) : null}
      </Box>
    </>
  );
};

export default MMedicalDetailPreviewBoxOptimaSecure;
