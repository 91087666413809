import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../../Component/AddonQuestion/AddonQuestion";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import Loader from "../../../../../Component/Loader/Loader";
import MFooter from "../../../../../Component/MFooter/MFooter";
import { HEALTH_ROUTE } from "../../../../../Router/Path/HealthRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import { TGOLDEN_SHIELDICICIAddon } from "../../../../../types/Health/ProposalDeatail/TICICAddon/TICICAddon";
const MAddonDetailGoldenShieldICICI = ({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TGOLDEN_SHIELDICICIAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) => {
  const navigate = useNavigate();
  const { SELECTED_QUOTE_DATA, QUOTE_LOADER } = useAppSelector(
    (state) => state.Health
  );

  return (
    <Box>
      {isLoading && <Loader />}
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid xs={12}>
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Claim Protector"
          toggle_status={addonStatus.Claim_protector}
          value_update={updateMasterState}
          attrName={"Claim_protector"}
          desc="Get benefit for the non-payable items in case of claim to ensure no out-pocket expenses."
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Modification of Base Co-Payment"
          toggle_status={addonStatus.Modification_base_co_payment}
          value_update={updateMasterState}
          attrName={"Modification_base_co_payment"}
          desc="Co-payment is the percentage of the claim that the insured agrees to pay from his/her pocket irrespective of the claim amount."
          sub_details={
            <Grid xs={12}>
              <SelectDropdown
                class_name="inputField"
                title="Voluntary Co-Payment Percentage"
                value={addonStatus?.VoluntaryCopaymentPercentage}
                attrName={"VoluntaryCopaymentPercentage"}
                value_update={updateMasterState}
                data={[
                  {
                    value: 40,
                    label: 40,
                  },
                  {
                    value: 30,
                    label: 30,
                  },
                  {
                    value: 20,
                    label: 20,
                  },
                ]}
                warn_status={false}
                error_message="Select Percentage"
              />
            </Grid>
          }
        />
        {Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 500000 ? (
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Voluntary Deductible"
            toggle_status={addonStatus.Voluntary_deduction}
            value_update={updateMasterState}
            attrName={"Voluntary_deduction"}
            desc="Co-payment is the percentage of the claim that the insured agrees to pay from his/her pocket irrespective of the claim amount."
            sub_details={
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Voluntary Co-Payment Percentage"
                  value={addonStatus?.VoluntaryCopaymentPercentage}
                  attrName={"VoluntaryCopaymentPercentage"}
                  value_update={updateMasterState}
                  data={[
                    {
                      value: 40,
                      label: 40,
                    },
                    {
                      value: 30,
                      label: 30,
                    },
                    {
                      value: 20,
                      label: 20,
                    },
                  ]}
                  warn_status={false}
                  error_message="Select Percentage"
                />
              </Grid>
            }
          />
        ) : null}
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Care Management Program"
          toggle_status={addonStatus.Care_management_program}
          value_update={updateMasterState}
          attrName={"Care_management_program"}
          disable={true}
          showAsterisk={true}
        />
        <AddonQuestion
          addon_amount=""
          main_class="addonQuestion borderBottom"
          icon_class="bonus"
          question_name="Care Management Plus Program"
          toggle_status={addonStatus.Care_management_plus_program}
          value_update={updateMasterState}
          attrName={"Care_management_plus_program"}
        />
      </Grid>
      <MFooter
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        attrName={"pageStatus"}
        forward={() => {
          validate();
        }}
        value={0}
        backward={() => {
          navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`);
        }}
        loader={QUOTE_LOADER}
      />
    </Box>
  );
};

export default MAddonDetailGoldenShieldICICI;
