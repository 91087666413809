import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import "../FieldTypes.scss";

interface Props {
  attrName: any;
  title: string;
  value: string;
  value_update: Function;
  data: Array<any>;
  error_message: string;
  warn_status: boolean;
  class_name: string;
  disabled?: boolean;
}

const SearchDropdown: React.FC<Props> = ({
  attrName,
  title,
  value,
  data = [],
  class_name,
  error_message,
  warn_status,
  value_update,
  disabled = false,
}) => {
  return (
    <div className={class_name}>
      <Autocomplete
        id={title}
        disablePortal
        options={data}
        renderInput={(params) => <TextField {...params} label={title} />}
        value={data.find((option) => option.value === value) || null} // Find option by value
        isOptionEqualToValue={(option, value) => option.value === value}
        onChange={(event, new_team) => {
          value_update(attrName, new_team ? new_team.value : null);
        }}
      />
      {warn_status ? <span className="error">{error_message}</span> : null}
    </div>
  );
};

export default SearchDropdown;
