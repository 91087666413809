import { TMedicalQuestionIffcoTokio } from "../../../../types/Health/HealthQuotation/TMedicalQuestionv3";

export const IFFCO_TOKIO_MEDICAL_QUESTION_INITIAL_VALUE =
  (): TMedicalQuestionIffcoTokio => {
    return {
      question: [
        {
          main_question: {
            code: "PQ01",
            icon_class: "tobaco",
            title: "Pre-Existing Diseases",
            description:
              "Select Yes if any member(s) to be insured have any Pre-Existing diseases other than Diabetes,High Blood Pressure,Asthma,Cholesterol?",
            status: false,
            member_data: [],
          },
          sub_question: [
            {
              code: "PQ08-SQ01",
              icon_class: "tobaco",
              title: "High or low blood pressure",
              status: false,
              member_data: [
                {
                  keyName: "adult_one",
                  name: "",
                  image_class: "adult",
                  memberId: "MO7",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "adult_two",
                  name: "",
                  image_class: "adult",
                  memberId: "MO8",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_four",
                  name: "",
                  image_class: "child",
                  memberId: "MO9",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_one",
                  name: "",
                  image_class: "child",
                  memberId: "MO10",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_three",
                  name: "",
                  image_class: "child",
                  memberId: "MO11",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_two",
                  name: "",
                  image_class: "child",
                  memberId: "MO12",
                  selection_status: false,
                  field_data: [],
                },
              ],
            },
            {
              code: "PQ08-SQ01",
              icon_class: "tobaco",
              title: "Diabetes",
              status: false,
              member_data: [
                {
                  keyName: "adult_one",
                  name: "",
                  image_class: "adult",
                  memberId: "MO7",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "adult_two",
                  name: "",
                  image_class: "adult",
                  memberId: "MO8",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_four",
                  name: "",
                  image_class: "child",
                  memberId: "MO9",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_one",
                  name: "",
                  image_class: "child",
                  memberId: "MO10",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_three",
                  name: "",
                  image_class: "child",
                  memberId: "MO11",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_two",
                  name: "",
                  image_class: "child",
                  memberId: "MO12",
                  selection_status: false,
                  field_data: [],
                },
              ],
            },
            {
              code: "PQ08-SQ01",
              icon_class: "tobaco",
              title: "Thyroid disorder or any other endocrine disorder ?",
              status: false,
              member_data: [
                {
                  keyName: "adult_one",
                  name: "",
                  image_class: "adult",
                  memberId: "MO7",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "adult_two",
                  name: "",
                  image_class: "adult",
                  memberId: "MO8",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_four",
                  name: "",
                  image_class: "child",
                  memberId: "MO9",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_one",
                  name: "",
                  image_class: "child",
                  memberId: "MO10",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_three",
                  name: "",
                  image_class: "child",
                  memberId: "MO11",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_two",
                  name: "",
                  image_class: "child",
                  memberId: "MO12",
                  selection_status: false,
                  field_data: [],
                },
              ],
            },
            {
              code: "PQ08-SQ01",
              icon_class: "tobaco",
              title: "Do you depend on insulin ?",
              status: false,
              member_data: [
                {
                  keyName: "adult_one",
                  name: "",
                  image_class: "adult",
                  memberId: "MO7",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "adult_two",
                  name: "",
                  image_class: "adult",
                  memberId: "MO8",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_four",
                  name: "",
                  image_class: "child",
                  memberId: "MO9",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_one",
                  name: "",
                  image_class: "child",
                  memberId: "MO10",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_three",
                  name: "",
                  image_class: "child",
                  memberId: "MO11",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_two",
                  name: "",
                  image_class: "child",
                  memberId: "MO12",
                  selection_status: false,
                  field_data: [],
                },
              ],
            },
            {
              code: "PQ08-SQ01",
              icon_class: "tobaco",
              title:
                "Any other existing disease or additional facts which effect the proposed insurance & should be closed to insurer ?",
              status: false,
              member_data: [
                {
                  keyName: "adult_one",
                  name: "",
                  image_class: "adult",
                  memberId: "MO7",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "adult_two",
                  name: "",
                  image_class: "adult",
                  memberId: "MO8",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_four",
                  name: "",
                  image_class: "child",
                  memberId: "MO9",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_one",
                  name: "",
                  image_class: "child",
                  memberId: "MO10",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_three",
                  name: "",
                  image_class: "child",
                  memberId: "MO11",
                  selection_status: false,
                  field_data: [],
                },
                {
                  keyName: "child_two",
                  name: "",
                  image_class: "child",
                  memberId: "MO12",
                  selection_status: false,
                  field_data: [],
                },
              ],
            },
          ],
        },
        {
          main_question: {
            code: "PQ08",
            icon_class: "hospitalized",
            title: "Smoking",
            status: false,
            description: "Does any of the members Smoke?",

            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Cigarettes per day",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Cigarettes per day",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "child",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Cigarettes per day",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "child",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Cigarettes per day",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "child",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Cigarettes per day",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "child",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Cigarettes per day",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          sub_question: [],
        },
        {
          main_question: {
            code: "PQ08",
            icon_class: "hospitalized",
            title: "Tobacco",
            status: false,
            description: "Do you Chew Pan Masala? (per week)",

            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Pouches per week",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Pouches per week",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "child",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Pouches per week",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "child",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Pouches per week",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "child",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Pouches per week",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "child",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Pouches per week",
                    value: "",
                    warning: false,
                    field_type: "dropdown",
                    dropdown_data: [
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          sub_question: [],
        },
        {
          main_question: {
            code: "PQ08",
            icon_class: "alcohol",
            title: "Alcohol",
            status: false,
            description: "Do you drink Alcohol (per week)?",

            member_data: [
              {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                memberId: "MO7",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Liquor (ml)",
                    value: "",
                    warning: false,
                    field_type: "textField",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                memberId: "MO8",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Liquor (ml)",
                    value: "",
                    warning: false,
                    field_type: "textField",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_four",
                name: "",
                image_class: "child",
                memberId: "MO9",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Liquor (ml)",
                    value: "",
                    warning: false,
                    field_type: "textField",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_one",
                name: "",
                image_class: "child",
                memberId: "MO10",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Liquor (ml)",
                    value: "",
                    warning: false,
                    field_type: "textField",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_three",
                name: "",
                image_class: "child",
                memberId: "MO11",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Liquor (ml)",
                    value: "",
                    warning: false,
                    field_type: "textField",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
              {
                keyName: "child_two",
                name: "",
                image_class: "child",
                memberId: "MO12",
                selection_status: false,
                field_data: [
                  {
                    code: "PQ08-SQ01-1",
                    title: "Liquor (ml)",
                    value: "",
                    warning: false,
                    field_type: "textField",
                    dropdown_data: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                    numbers_only: false,
                  },
                ],
              },
            ],
          },
          sub_question: [],
        },
      ],
    };
  };
