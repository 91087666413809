import React from "react";
import { Route, Routes } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { useAppSelector } from "../../../../../Store/hooks";
import { EProductId } from "../../../../../Services/Enum/EHome";
import BajajCKYCContainer from "../../../../../Container/CKYC/Bajaj/BajajCKYCContainer";
import BajajManualKYCContainer from "../../../../../Container/CKYC/Bajaj/BajajManualKYCContainer";
import AddonDetailsContainer from "../../../../../Container/Health/BAJAJ/AddonDetailsContainer";
import MedicalDetailContainer from "../../../../../Container/Health/BAJAJ/MedicalDetailContainer";

const BAJAJ_ROUTES = () => {
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.productDetails
  );
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}>
        <Route path={CKYC_ROUTES.CKYC} element={<BajajCKYCContainer />} />
        <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<BajajManualKYCContainer />}
        />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsContainer />}
        />
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailContainer />}
        />
      </Route>
    </Routes>
  );
};

export default BAJAJ_ROUTES;

