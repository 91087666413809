import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./Footer.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { TWSlice } from "../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import CustomButton from "../CustomButton/CustomButton";
import { CAR_ROUTES } from "../../Router/Path/CarRoutes";
const Footer = ({
  forward,
  value,
  disableButton,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) => {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Box className="proposalFooter">
      <Grid container spacing={3}>
        <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
              dispatch(TWSlice.actions.Home_loader(true));
            }}
          >
            <ArrowBackIcon /> {value === 0 ? " Back to Quotes" : "Back"}
          </Link>

          <CustomButton
            text_name={
              textName !== "Continue"
                ? textName
                : path ===
                  `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.PREVIEW_DEATILS}`
                ? "Make Payment"
                : textName
            }
            class_name="regularPrimaryBtn"
            onClickFunction={() => {
              if (forward) {
                forward();
              } else {
                value_update && value_update(attrName, value + 1);
              }
            }}
            loading={loader}
            disabled={disableButton}
          />

          {/* This button will display only on preview page */}
          {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
