import PhotoIcon from "@mui/icons-material/Photo";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import "./MJourneyRequirements.scss";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";
const MJourneyRequirements = ({
  continueFunction,
}: {
  continueFunction: Function;
}) => {
  const navigate = useNavigate();

  const docList = [
    {
      class_name: "ageDoc",
      title_name: "Age Document",
    },
    {
      class_name: "idDoc",
      title_name: "Identity Proof",
    },
    {
      class_name: "incomeDoc",
      title_name: "Income Proof",
    },
    {
      class_name: "permanentAddressDoc",
      title_name: "Permanent Address Proof",
    },
    {
      class_name: "addressDoc",
      title_name: "Address Proof",
    },
  ];

  return (
    <Box className="documentsWrapperMobile">
      <h5 className="sectionTitle">Journey Requirements</h5>
      <Grid container spacing={3}>
        <Grid xs={12} textAlign="center">
          <img src="./images/document-img.svg" height="140px" />
          <h5>Before you start</h5>
          <p>
            Please read the instructions below for Quick and Hassle free
            processing
          </p>
        </Grid>
        <Grid xs={12}>
          <h6>
            <PhotoIcon /> Documents you might need.
          </h6>
          <ul className="documentsList">
            {docList.map((data, index) => (
              <li>
                <p className={data.class_name}>{data.title_name}</p>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid xs={12}>
          <h6>
            <PlaylistAddCheckIcon /> Notes:
          </h6>
          <ul className="documentsNotes">
            <li>Maximum size should be 5 MB.</li>
            <li>Supported Formats are JPEG, PDF, TIF and PNG.</li>
            <li>
              If you have Proofs in any other language apart from English,
              Share/UPload in attested English version along with your
              orginal proofs.
            </li>
            <li>
              If certain information is present on the back side of the
              document, then upload back side image as well.
            </li>
          </ul>
        </Grid>
      </Grid>
      <Footer continueFunction={continueFunction} backText="Back" />
    </Box>
  );
};

export default MJourneyRequirements;
