import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../../Component/Footer/Footer.scss";
import { useNavigate } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { useAppDispatch } from "../../../../Store/hooks";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import CustomButton from "../../../../Component/CustomButton/CustomButton";

const HealthFooter = ({
  forward,
  value,
  disableButton,
  backward,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) => {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <Box className="proposalFooter">
      <Grid container spacing={3}>
        <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">
          <Link
            className="backStep"
            onClick={() => {
              navigate(-1);
              dispatch(HealthSlice.actions.PREVIEW_LOADER(true));
            }}
          >
            <ArrowBackIcon /> {value === 0 ? " Back to Quotes" : "Back"}
          </Link>

          {/* <Button
              className="greenMdBtn"
              onClick={() => {
                value_update(attrName, value + 1);
              }}
            >
              {value === 7 ? " Make Payment" : "Continue"}
            </Button> */}
          <CustomButton
            text_name={
              path ===
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}`
                ? "Make Payment"
                : textName
            }
            class_name="regularPrimaryBtn"
            onClickFunction={() => {
              forward && forward();
              value_update && value_update(attrName, value + 1);
            }}
            loading={loader}
            disabled={disableButton}
          />
          {/* This button will display only on preview page */}
          {/* <Buttons class_name='regularPrimaryBtn ml-4' text_name='Test Link'/> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HealthFooter;
