import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MFooter.scss";
import { useAppSelector } from "../../Store/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { CAR_ROUTES } from "../../Router/Path/CarRoutes";

const MFooter = ({
  forward,
  value,
  disableButton,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) => {
  const { PAGE_STATUS } = useAppSelector((state) => state.Car);
  const path = window.location.pathname;
  const buttonText = PAGE_STATUS === true ? "Update" : "Continue";
  return (
    <Box className="mProposalFooter">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <CustomButton
            text_name={
              textName !== "Continue"
                ? textName
                : path ===
                  `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.PREVIEW_DEATILS}`
                ? "Make Payment"
                : textName
            }
            class_name="regularPrimaryBtn"
            onClickFunction={() => {
              forward && forward();
              value_update && value_update(attrName, value + 1);
            }}
            loading={loader}
            disabled={disableButton}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MFooter;
