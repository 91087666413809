import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TW_ROUTES } from "../../Router/Path/TWRoutes";
import { useAppSelector } from "../../Store/hooks";
import CustomButton from "../CustomButton/CustomButton";

function TMFooter({
  forward,
  value,
  disableButton,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) {
  const { PAGE_STATUS } = useAppSelector((state) => state.TW);
  const path = window.location.pathname;

  return (
    <Box className="mProposalFooter">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <CustomButton
            disabled={disableButton}
            text_name={
              PAGE_STATUS
                ? "Update"
                : textName !== "Continue"
                ? textName
                : path ===
                  `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}`
                ? "Make Payment"
                : textName
            }
            class_name="regularPrimaryBtn"
            onClickFunction={() => {
              forward && forward();
              value_update && value_update(attrName, value + 1);
            }}
            loading={loader}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TMFooter;
