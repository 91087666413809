import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { toast } from "react-toastify";
import AddressDetailBox from "../../../../../../Component/Mobile/TermFullJourney/PreviewDetails/AddressDetailsBox/AddressDetailsBox";
import PreviewDetailBox from "../../../../../../Component/Mobile/TermFullJourney/PreviewDetails/PreviewDetailBox/PreviewDetailBox";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";
import VerifyOTPContainer from "../../../../../../Container/TermFullJourney/ICICI/ProposalPage/ProposerDetailsContainer/VerifyOTPContainer/VerifyOTPContainer";
import { ICICI_PROPOSAL_SERVICES } from "../../../../../../Services/TermFullJourneyServices/ICICIProposalServices";
import { useAppSelector } from "../../../../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { TAddressDetails } from "../../../../../../types/TermFullJourney/TAddressDetails";

const MPreviewDetails = ({
  loader,
  PreviewDetails,
  permanentAddressDetails,
  mailingAddressDetails,
  address_details,
  continueFunction,
}: {
  loader: boolean;
  PreviewDetails: Array<any>;
  permanentAddressDetails: any;
  mailingAddressDetails: any;
  address_details: TAddressDetails;
  continueFunction: Function;
}) => {
  const [openOTP, setOpenOTP] = useState<boolean>(false);
  const { PROPOSER_DATA, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Term
  );

  const SEND_OTP = () => {
    const onSuccess = (data: any) => {
      toast.success("OTP has been sent successfully");
      setOpenOTP(true);
    };
    const onError = (err: any) => {
      toast.error("Limit exceeded");
    };

    ICICI_PROPOSAL_SERVICES.SEND_OTP(onSuccess, onError, {
      mobile: `${PROPOSER_DATA.mobile.value}`,
      name: `${PROPOSER_DATA.full_name.value}`,
    });
  };
  return (
    <Box className="proposalPreviewMobile">
      <VerifyOTPContainer
        open={openOTP}
        setOpen={setOpenOTP}
        continue_function={continueFunction}
      />
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Box className="previewDetailBox">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <img src={SELECTED_QUOTE_DATA.companyDetails.logo} alt="logo" />
            <p className="mt-3">
              {SELECTED_QUOTE_DATA.productDetails.product_name}
            </p>
            <p>
              Policy Tenure:{" "}
              <span>{`${SELECTED_QUOTE_DATA.premiumDetails.term} Years`}</span>
            </p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="premium">
              Premium:{" "}
              <span>
                {formatAmountToCurrency(
                  SELECTED_QUOTE_DATA.premiumDetails.finalPremium
                )}
              </span>
            </p>
            <p>
              Sum Assured:{" "}
              <span>
                {formatToCurrency(
                  SELECTED_QUOTE_DATA.premiumDetails.sum_assured
                )}
              </span>
            </p>
            <p>
              Payment Mode:{" "}
              <span>
                {SELECTED_QUOTE_DATA?.productDetails.frequency === "12"
                  ? "Monthly"
                  : SELECTED_QUOTE_DATA?.productDetails.frequency === "2"
                  ? "Half-Yearly"
                  : SELECTED_QUOTE_DATA?.productDetails.frequency === "4"
                  ? "Quarterly"
                  : SELECTED_QUOTE_DATA?.productDetails.frequency === "1"
                  ? "Yearly"
                  : "Single Pay"}
              </span>
            </p>
          </Grid>
        </Grid>
      </Box>
      <Masonry columns={1} spacing={2} className="px-0 mx-0">
        {PreviewDetails.map((data, index) => (
          <PreviewDetailBox
            editFunction={data.editFunction}
            title={data.title}
            data={data.detailView}
          />
        ))}
        <AddressDetailBox
          editFunction={permanentAddressDetails.editFunction}
          permanentAddress={permanentAddressDetails.detailView}
          mailingAddress={mailingAddressDetails.detailView}
          permanent_mailing_address_same={
            address_details.is_mailing_and_permanent_address_same
          }
        />
      </Masonry>
      <Footer
        loader={loader}
        continueFunction={() => {
          SEND_OTP();
        }}
      />
    </Box>
  );
};
export default MPreviewDetails;
