import { EMotorCompCode } from "../Enum/EMotorCompCode";
import { TCKYC } from "../types/CKYC/TCKYC";
import { TCarSlice } from "../types/TCarSlice";
import { cal_gst, calculate_premium_for_car } from "./CarPermiumCal";

export const CAR_CREATE_PROPOSAL_REQUEST = (
  CarSlice: TCarSlice,
  CKYCSlice: TCKYC,
  ADD_FORM_RESPONSE: any
) => {
  const param = {
    transactionId:
      CarSlice.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
        .pTransactionId,
    proposal_no: CarSlice.policyId,
    utm_medium: ADD_FORM_RESPONSE?.utm_medium,
    utm_source: ADD_FORM_RESPONSE?.utm_source,
    UniqueRequestID:
      CarSlice.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
        .UniqueRequestID,
    kycPTransactionId: CKYCSlice.CKYC_DETAILS.transactionId,
    formsixty:
      CarSlice.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails
        .company_code === EMotorCompCode.RSA
        ? CKYCSlice.VERIFY_KYC_FORM.form_sixety.value
        : "",
    quote_no: CarSlice.ADD_FORM_RESPONSE.quote_no,
    product_code:
      CarSlice.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_code,
    company_code:
      CarSlice.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code,
    prev_depth_cover: "",
    registrationCharge: CarSlice.ADD_FORM_RESPONSE.registration_no,
    firstYearInsurancePremium: "",
    customer_name: CarSlice.OWNER_DETAILS.name.value,
    customer_mobile: CarSlice.OWNER_DETAILS.mobile.value,
    customer_email: CarSlice.OWNER_DETAILS.email.value,
    customer_gender: CarSlice.OWNER_DETAILS.gender.value,
    customer_dob: CarSlice.OWNER_DETAILS.dob.value,
    customer_marital: CarSlice.OWNER_DETAILS.maritalStatus.value,
    customer_gst_no: "",
    chassis_number: CarSlice.VEHICLE_DETAILS.chasis_no.value,
    engine_number: CarSlice.VEHICLE_DETAILS.engine_no.value,
    existing_policy_no: CarSlice.VEHICLE_DETAILS.prev_policy_no.value,
    tp_existing_policy_no: "",
    existing_premium_amount: "",
    pan_number: "",
    pre_ins_address: "",
    is_financed: CarSlice.VEHICLE_DETAILS.is_vehicle_on_loan ? "Yes" : "No",
    financier_name: CarSlice.VEHICLE_DETAILS.bank_name.value,
    financier_city: CarSlice.VEHICLE_DETAILS.city.value,
    nominee_relationship: CarSlice.NOMINEE_DETAILS.relationship.value,
    nominee_name: CarSlice.NOMINEE_DETAILS.fullName.value,
    nominee_dob: CarSlice.NOMINEE_DETAILS.dob.value,
    nominee_gender: "",
    address_p_house: CarSlice.ADDRESS_DETAILS.addressLineOne.value,
    address_p_area: CarSlice.ADDRESS_DETAILS.addressLineTwo.value,
    address_p_pincode: CarSlice.ADDRESS_DETAILS.pincode.value,
    address_p_district: "",
    address_p_city: CarSlice.ADDRESS_DETAILS.city,
    address_p_state: CarSlice.ADDRESS_DETAILS.state,
    address_mailing_same: CarSlice.ADDRESS_DETAILS.isMailingAddressSame
      ? "Yes"
      : "No",
    address_m_house: CarSlice.ADDRESS_DETAILS.mailingAddressLineOne.value,
    address_m_area: CarSlice.ADDRESS_DETAILS.mailingAddressLineTwo.value,
    address_m_pincode: CarSlice.ADDRESS_DETAILS.mailingPinCode.value,
    address_m_district: "",
    address_m_city: CarSlice.ADDRESS_DETAILS.mailingCity,
    address_m_state: CarSlice.ADDRESS_DETAILS.mailingState,
    address_i_house: "",
    address_i_area: "",
    address_i_pincode: "",
    address_i_district: "",
    address_i_city: "",
    address_i_state: "",
    previous_policy_type: "",
    aadhar_number: "",
    inspection_mode: "",
    finalPremium: `${Math.round(
      calculate_premium_for_car(
        CarSlice.SELECTED_QUOTE_DATA.quotationDetail,
        CarSlice.ADDON_STATUS,
        CarSlice.ADDON_USER_VALUE.passengerCover.value,
        CarSlice.PLAN_TYPE,
        CKYCSlice.VERIFY_KYC_FORM.customer_type.value,
        CarSlice.ADD_FORM.reg_date.value
      )
    )}`,
    taxAmount: `${Math.round(
      cal_gst(
        calculate_premium_for_car(
          CarSlice.SELECTED_QUOTE_DATA.quotationDetail,
          CarSlice.ADDON_STATUS,
          CarSlice.ADDON_USER_VALUE.passengerCover.value,
          CarSlice.PLAN_TYPE,
          CKYCSlice.VERIFY_KYC_FORM.customer_type.value,
          CarSlice.ADD_FORM.reg_date.value
        )
      )
    )}`,
    inspectionMode: "",
    kycDetails: CKYCSlice.CKYC_DETAILS,
    create_propoal_response: CarSlice.create_proposal_response,
  };

  return param;
};
