import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TPolicyDetails } from "../../../../types/TermFullJourney/TPolicyDetails";

function setPolicyDetails(
  state: TTermFullJourneySlice,
  action: PayloadAction<TPolicyDetails>
) {
  const data: TTermFullJourneySlice = {
    ...state,
    policyDetails: action.payload,
  };
  return data;
}

export const TERM_POLICY_DETAILS_REDUCERS = {
  setPolicyDetails,
};
