import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppSelector } from "../../../Store/hooks";
import CustomButton from "../../CustomButton/CustomButton";

const MTermFooter = ({
  disableButton,
  forward = () => {},
  loader,
  value,
}: {
  disableButton?: any;
  forward?: Function;
  loader?: boolean;
  value?: any;
}) => {
  const { PAGE_STATUS } = useAppSelector((state) => state.Car);
  const path = window.location.pathname;
  const buttonText = PAGE_STATUS === true ? "Update" : "Continue";
  return (
    <Box className="mProposalFooter">
      <Grid container spacing={3}>
        <Grid xs={12} className="footerInner" alignItems="center">
          <CustomButton
            text_name={buttonText}
            class_name="regularPrimaryBtn"
            onClickFunction={() => {
              if (!loader) {
                forward();
              }
            }}
            loading={loader}
            disabled={disableButton}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MTermFooter;
