import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TNomineeDetails } from "../../../../types/TermFullJourney/TNomineeDetails";

function setNomineeDetails(
  state: TTermFullJourneySlice,
  action: PayloadAction<TNomineeDetails>
) {
  const data: TTermFullJourneySlice = {
    ...state,
    nomineeDetails: action.payload,
  };
  return data;
}

export const TERM_NOMINEE_DETAILS_REDUCERS = {
  setNomineeDetails,
};
