export const TERM_FULL_JOURNEY_ROUTES = {
  PROPOSER_DETAILS: "/term-insurance/fj/proposer-details",
  RIDERS_BENEFITS: "/term-insurance/fj/riders-benefits",
  COFIRMATION_PAGE: "/term-insurance/fj/confirmation-page",
  JOURNEY_REQUIREMENT: "/term-insurance/fj/journey-requirement",
  NRI_DETAILS: "/term-insurance/fj/nri-details",
  NOMINEE_DETAILS: "/term-insurance/fj/nominee-details",
  ADDRESS_DETAILS: "/term-insurance/fj/address-details",
  CKYC: "/term-insurance/fj/ckyc-details",
  MEDICAL_PAGE: "/term-insurance/fj/medical-details",
  PREVIEW_PAGE: "/term-insurance/fj/preview-details",
  UPLOAD_DOCUMENT: "/term-insurance/fj/upload-document",
  POLICY_CONFIRMATION: "/term-insurance/fj/policy-confirmation",
};
