import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TOTPDetails } from "../../../../types/TermFullJourney/TOTPDetails";

function setOTPData(
  state: TTermFullJourneySlice,
  action: PayloadAction<TOTPDetails>
) {
  const data: TTermFullJourneySlice = { ...state, OTP_DETAILS: action.payload };

  return data;
}

export const TERM_OTP_DETAILS_REDUCERS = {
  setOTPData,
};
