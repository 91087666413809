import { Route, Routes } from "react-router-dom";
import ADITYABIRLACKYCContainer from "../../../../../Container/CKYC/Aditya_Birla/ADITYABIRLACKYCContainer";
import AddonDetailsContainer from "../../../../../Container/Health/ADITYA_BIRLA/AddonDetailsContainer/AddonDetailsContainer";
import MedicalDetailsContainer from "../../../../../Container/Health/ADITYA_BIRLA/MedicalDetailsContainer/MedicalDetailsContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

const ADITYA_BIRLA_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}>
        <Route path={CKYC_ROUTES.CKYC} element={<ADITYABIRLACKYCContainer />} />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsContainer />}
        />

        {/* {SELECTED_QUOTE_DATA.productDetails.id === ECompanyCode.ADITY_BIRLA ?  */}
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainer />}
        />
        {/* :
       (<></>) 
       } */}
      </Route>
    </Routes>
  );
};

export default ADITYA_BIRLA_ROUTES;
