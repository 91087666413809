import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/system";
import { useAppSelector } from "../../../../../../Store/hooks";
import ToggleSwitch from "../../../../../../Component/Term/ToggleSwitch/ToggleSwitch";
import SelectDropdown from "../../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import CustomCheckbox from "../../../../../../Component/FieldTypes/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../../../Component/FieldTypes/DatePicker/DatePicker";
import DurationPicker from "../../../../../../Component/FieldTypes/DurationPicker/DurationPicker";
import MobileField from "../../../../../../Component/FieldTypes/MobileField/MobileField";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";
import { TNRIDetails } from "../../../../../../types/TermFullJourney/TNRIDetails";

function MNRIDetails({
  updateMasterState,
  NRIDetails,
  continue_function,
  loader,
}: {
  updateMasterState: Function;
  NRIDetails: TNRIDetails;
  continue_function: Function;
  loader: boolean;
}) {
  const dropdownData = useAppSelector((state) => state.Term.DROPDOWN_DATA);
  return (
    <Box>
      <h5 className="sectionTitle">NRI Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6 className="mr-3">My FATCA and CRS details</h6>
        </Grid>
        <Grid xs={12} display={"flex"} justifyContent={"space-between"}>
          <h6 className="mr-3">I/We are a tax resident of ONLY India.</h6>
          <ToggleSwitch
            attrName="resident_only_india"
            toggle_status={false}
            value_update={updateMasterState}
          />
        </Grid>
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="TIN/PAN Issuing Country"
            value={NRIDetails.tin_pan_issuing_country.value}
            attrName={"tin_pan_issuing_country"}
            value_update={updateMasterState}
            data={dropdownData.country}
            warn_status={NRIDetails.tin_pan_issuing_country.warning}
            error_message="Enter TIN/PAN issuing country"
            disabled
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"TIN/PAN"}
            value={NRIDetails.tin_pan_other_country.value}
            attrName={"tin_pan_other_country"}
            value_update={updateMasterState}
            warn_status={NRIDetails.tin_pan_other_country.warning}
            error_message="Enter TIN/PAN"
            disabled
          />
        </Grid>
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="TIN/PAN Issuing Country"
            value={NRIDetails.tin_pan_issuing_country_2.value}
            attrName={"tin_pan_issuing_country_2"}
            value_update={updateMasterState}
            data={dropdownData.country}
            warn_status={NRIDetails.tin_pan_issuing_country_2.warning}
            error_message="Enter TIN/PAN issuing country"
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"TIN/PAN"}
            value={NRIDetails.tin_pan_other_country_2.value}
            attrName={"tin_pan_other_country_2"}
            value_update={updateMasterState}
            warn_status={NRIDetails.tin_pan_other_country_2.warning}
            error_message="Enter TIN/PAN"
          />
        </Grid>
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="TIN/PAN Issuing Country"
            value={NRIDetails.tin_pan_issuing_country_3.value}
            attrName={"tin_pan_issuing_country_3"}
            value_update={updateMasterState}
            data={dropdownData.country}
            warn_status={NRIDetails.tin_pan_issuing_country_3.warning}
            error_message="Enter TIN/PAN issuing country"
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"TIN/PAN"}
            value={NRIDetails.tin_pan_other_country_3.value}
            attrName={"tin_pan_other_country_3"}
            value_update={updateMasterState}
            warn_status={NRIDetails.tin_pan_other_country_3.warning}
            error_message="Enter TIN/PAN"
          />
        </Grid>
        <Grid xs={12} display={"flex"} justifyContent={"space-between"}>
          <h6 className="mr-3">My birth country is India</h6>
          <ToggleSwitch
            attrName="birth_country_india"
            toggle_status={NRIDetails.birth_country_india}
            value_update={updateMasterState}
          />
        </Grid>
        {NRIDetails.birth_country_india === false ? (
          <Grid xs={12}>
            <SelectDropdown
              class_name="inputField"
              title="Country of Birth"
              value={NRIDetails.country_of_birth.value}
              attrName={"country_of_birth"}
              value_update={updateMasterState}
              data={dropdownData.country}
              warn_status={NRIDetails.country_of_birth.warning}
              error_message="Select country of birth"
            />
          </Grid>
        ) : null}
        {NRIDetails.birth_country_india === false ? (
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title="Place of Birth"
              value={NRIDetails.place_of_birth.value}
              attrName={"place_of_birth"}
              value_update={updateMasterState}
              warn_status={NRIDetails.place_of_birth.warning}
              error_message="Select place of birth"
            />
          </Grid>
        ) : null}
        <Grid xs={12}>
          <CustomCheckbox
            label="This information is required to be collected as per The Income Tax Act, 1961 to comply with FATCA and CRS regulations. We will continue to report the above information 'As is' till we receive any change request from you"
            attrName={"FATCA_CRS_STATUS"}
            value={NRIDetails.FATCA_CRS_STATUS}
            value_update={updateMasterState}
          />
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <h6>Nationality and Resident Status</h6>
            <RadioGroup
              value={NRIDetails.nationality_status}
              onChange={(e) => {
                updateMasterState("nationality_status", e.target.value);
              }}
              style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                value="Indian"
                control={<Radio />}
                label="Indian"
              />
              <FormControlLabel
                value="Non indian"
                control={<Radio />}
                label="Non Indian"
              />
            </RadioGroup>
            <RadioGroup
              value={NRIDetails.resident_status}
              onChange={(e) => {
                updateMasterState("resident_status", e.target.value);
              }}
              style={{ display: "flex", flexDirection: "row" }}>
              {NRIDetails.nationality_status === "Indian" ? (
                <>
                  <FormControlLabel
                    value="Resident Indian"
                    control={<Radio />}
                    label="Resident Indian"
                  />
                  <FormControlLabel
                    value="NRI"
                    control={<Radio />}
                    label="NRI"
                  />
                </>
              ) : (
                <>
                  <FormControlLabel
                    value="PIO"
                    control={<Radio />}
                    label="PIO/OCI"
                  />
                  <FormControlLabel
                    value="Foreign National"
                    control={<Radio />}
                    label="Foreign National"
                  />
                </>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        {/* <------------NRI Details------------> */}
        {NRIDetails.resident_status !== "Resident Indian" ? (
          <Grid xs={12}>
            <h6 className="mr-3">NRI Details</h6>
          </Grid>
        ) : null}
        {NRIDetails.resident_status !== "Resident Indian" ? (
          <Grid xs={12}>
            <Grid container>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Country of Nationality"
                  value={NRIDetails.nri_details.country_of_nationality.value}
                  attrName={["nri_details", "country_of_nationality"]}
                  value_update={updateMasterState}
                  data={dropdownData.country}
                  warn_status={
                    NRIDetails.nri_details.country_of_nationality.warning
                  }
                  error_message="Select country of nationality"
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Country of Residence"
                  value={NRIDetails.nri_details.country_of_residence.value}
                  attrName={["nri_details", "country_of_residence"]}
                  value_update={updateMasterState}
                  data={dropdownData.country}
                  warn_status={
                    NRIDetails.nri_details.country_of_residence.warning
                  }
                  error_message="Select country of residence"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Passport Number"}
                  value={NRIDetails.nri_details.passport_number.value}
                  attrName={["nri_details", "passport_number"]}
                  value_update={updateMasterState}
                  warn_status={NRIDetails.nri_details.passport_number.warning}
                  error_message="Enter passport number"
                  max_length={15}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Purpose of Your Stay Abroad"}
                  value={NRIDetails.nri_details.purpose_of_stay_abroad.value}
                  attrName={["nri_details", "purpose_of_stay_abroad"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_details.purpose_of_stay_abroad.warning
                  }
                  error_message="Enter purpose of stay abroad"
                  max_length={30}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Employer/University Details"}
                  value={
                    NRIDetails.nri_details.employer_university_details.value
                  }
                  attrName={["nri_details", "employer_university_details"]}
                  value_update={updateMasterState}
                  warn_status={NRIDetails.tin_pan_other_country_3.warning}
                  error_message="Enter Employer/University Details"
                  max_length={30}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
              <Grid xs={12}>
                <DatePicker
                  title={"Date of Arriving in India"}
                  value={NRIDetails.nri_details.date_of_arriving_india.value}
                  attrName={["nri_details", "date_of_arriving_india"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_details.date_of_arriving_india.warning
                  }
                  error_message="Select arrival date"
                />
              </Grid>
              <Grid xs={12}>
                <DatePicker
                  title={"Date of leaving india after current visit"}
                  value={NRIDetails.nri_details.date_of_leaving_india.value}
                  attrName={["nri_details", "date_of_leaving_india"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_details.date_of_leaving_india.warning
                  }
                  error_message="Select leaving date"
                />
              </Grid>
              <Grid xs={12}>
                <DurationPicker
                  attrNameMonth={[
                    "nri_details",
                    "duration_of_stay_abroad_month",
                  ]}
                  attrNameYear={["nri_details", "duration_of_stay_abroad_year"]}
                  title={"Duration of stay abroad"}
                  valueMonth={
                    NRIDetails.nri_details.duration_of_stay_abroad_month.value
                  }
                  valueUpadte={updateMasterState}
                  valueYear={
                    NRIDetails.nri_details.duration_of_stay_abroad_year.value
                  }
                  warning_month={
                    NRIDetails.nri_details.duration_of_stay_abroad_month.warning
                  }
                  warning_year={
                    NRIDetails.nri_details.duration_of_stay_abroad_year.warning
                  }
                />
              </Grid>
              {NRIDetails.resident_status === "Foreign National" ? (
                <Grid xs={12}>
                  <DurationPicker
                    attrNameMonth={[
                      "nri_details",
                      "intended_duration_of_stay_month",
                    ]}
                    attrNameYear={[
                      "nri_details",
                      "intended_duration_of_stay_year",
                    ]}
                    title={"Intended Duration of stay"}
                    valueMonth={
                      NRIDetails.nri_details.intended_duration_of_stay_month
                        .value
                    }
                    valueUpadte={updateMasterState}
                    valueYear={
                      NRIDetails.nri_details.intended_duration_of_stay_year
                        .value
                    }
                    warning_month={
                      NRIDetails.nri_details.intended_duration_of_stay_month
                        .warning
                    }
                    warning_year={
                      NRIDetails.nri_details.intended_duration_of_stay_year
                        .warning
                    }
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        {/* <---------NRI Travel Details-------------> */}
        <Grid xs={12}>
          <h6 className="mr-3">NRI Travel Details</h6>
        </Grid>
        <Grid xs={12} display={"flex"} justifyContent={"space-between"}>
          <h6 className="mr-3">Have you travelled abroad in last 1 year</h6>
          <ToggleSwitch
            attrName={["nri_travel_details", "status"]}
            toggle_status={NRIDetails.nri_travel_details.status}
            value_update={updateMasterState}
          />
        </Grid>
        {NRIDetails.nri_travel_details.status === true ? (
          <Grid xs={12}>
            <Grid container>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title={"Name of country"}
                  value={NRIDetails.nri_travel_details.name_of_country.value}
                  attrName={["nri_travel_details", "name_of_country"]}
                  value_update={updateMasterState}
                  data={dropdownData.country}
                  warn_status={
                    NRIDetails.nri_travel_details.name_of_country.warning
                  }
                  error_message="Enter name of country"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Name of city"}
                  value={NRIDetails.nri_travel_details.name_of_city.value}
                  attrName={["nri_travel_details", "name_of_city"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_travel_details.name_of_city.warning
                  }
                  error_message="Enter name of city"
                  max_length={30}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
              <Grid xs={12}>
                <DatePicker
                  title={"Arrival Date"}
                  value={NRIDetails.nri_travel_details.arrival_date.value}
                  attrName={["nri_travel_details", "arrival_date"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_travel_details.arrival_date.warning
                  }
                  error_message="Select arrival date"
                />
              </Grid>
              <Grid xs={12}>
                <DatePicker
                  title={"Departure Date"}
                  value={NRIDetails.nri_travel_details.departure_date.value}
                  attrName={["nri_travel_details", "departure_date"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_travel_details.departure_date.warning
                  }
                  error_message="Select departure date"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title={"Visa Type"}
                  value={NRIDetails.nri_travel_details.visa_type.value}
                  attrName={["nri_travel_details", "visa_type"]}
                  value_update={updateMasterState}
                  warn_status={NRIDetails.nri_travel_details.visa_type.warning}
                  error_message="Enter Visa type"
                  max_length={30}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Purpose of Travel"
                  value={NRIDetails.nri_travel_details.purpose_of_travel.value}
                  attrName={["nri_travel_details", "purpose_of_travel"]}
                  value_update={updateMasterState}
                  data={dropdownData.purposeTravel}
                  warn_status={
                    NRIDetails.nri_travel_details.purpose_of_travel.warning
                  }
                  error_message="Select purposse of travel"
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Type of residence"
                  value={NRIDetails.nri_travel_details.type_residence.value}
                  attrName={["nri_travel_details", "type_residence"]}
                  value_update={updateMasterState}
                  data={dropdownData.typeResidence}
                  warn_status={
                    NRIDetails.nri_travel_details.type_residence.warning
                  }
                  error_message="Select type of residence"
                />
              </Grid>
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Mode of Travel within the travelled country"
                  value={NRIDetails.nri_travel_details.mode_of_travel.value}
                  attrName={["nri_travel_details", "mode_of_travel"]}
                  value_update={updateMasterState}
                  data={dropdownData.modeTravel}
                  warn_status={
                    NRIDetails.nri_travel_details.mode_of_travel.warning
                  }
                  error_message="Select mode of travel"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Nature of business/employment"
                  value={NRIDetails.nri_travel_details.name_of_business.value}
                  attrName={["nri_travel_details", "name_of_business"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_travel_details.name_of_business.warning
                  }
                  error_message="Enter Nature of business/employment"
                  max_length={30}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
              <Grid xs={12}>
                <RKTextField
                  class_name="inputField"
                  title="Safety precaution by employer if applicable"
                  value={NRIDetails.nri_travel_details.safety_precaution.value}
                  attrName={["nri_travel_details", "safety_precaution"]}
                  value_update={updateMasterState}
                  warn_status={
                    NRIDetails.nri_travel_details.safety_precaution.warning
                  }
                  error_message="Enter safety precaution by employer if applicable"
                  max_length={60}
                  validation_type="ALPHANUMERIC"
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {/* <--------- Phone and email ------------> */}
        <Grid xs={12}>
          <h6 className="mr-3">Phone and Email</h6>
        </Grid>
        <Grid xs={12}>
          <MobileField
            attrNameCode={["phone_email", "country_code"]}
            attrNameMobile={["phone_email", "mobile_number"]}
            valueCode={NRIDetails.phone_email.country_code.value}
            valueMobile={NRIDetails.phone_email.mobile_number.value}
            updateMasterState={updateMasterState}
            warning={
              NRIDetails.phone_email.mobile_number.warning ||
              NRIDetails.phone_email.mobile_number.warning
            }
            error_msg="Enter mobile no./Select Country code"
          />
        </Grid>
        {/* <----------Type of bank account held------------> */}
        <Grid xs={12}>
          <h6 className="mr-3">Type of Bank Account Held</h6>
        </Grid>
        <Grid xs={12}>
          <RadioGroup
            value={NRIDetails.type_bank_account_held.status}
            onChange={(e) => {
              updateMasterState(
                ["type_bank_account_held", "status"],
                e.target.value
              );
            }}
            style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel value="NRE" control={<Radio />} label="NRE" />
            <FormControlLabel value="NRO" control={<Radio />} label="NRO" />
          </RadioGroup>
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title="NRE/NRO Account Number"
            value={NRIDetails.type_bank_account_held.account_number.value}
            attrName={["type_bank_account_held", "account_number"]}
            value_update={updateMasterState}
            warn_status={
              NRIDetails.type_bank_account_held.account_number.warning
            }
            error_message="Enter safety precaution by employer if applicable"
            max_length={21}
            validation_type="ALPHANUMERIC"
          />
        </Grid>
        {/* <--------- Phone and email ------------> */}
        <Grid xs={12}>
          <h6 className="mr-3">Aadhar Details</h6>
        </Grid>
        <Grid xs={12}>
          <SelectDropdown
            class_name="inputField"
            title="Aadhar Options"
            value={NRIDetails.aadhar_details.option.value}
            attrName={["aadhar_details", "option"]}
            value_update={updateMasterState}
            data={dropdownData.AADHAR_OPTIONS_DATA}
            warn_status={NRIDetails.aadhar_details.option.warning}
            error_message="Select aadhar option"
          />
        </Grid>
        {NRIDetails.aadhar_details.option.value ? (
          <Grid xs={12}>
            <RKTextField
              class_name="inputField"
              title={
                NRIDetails.aadhar_details.option.value === "aadhar_no"
                  ? "Aadhar No"
                  : "Virtual ID"
              }
              value={NRIDetails.aadhar_details.doc_no.value}
              attrName={["aadhar_details", "doc_no"]}
              value_update={updateMasterState}
              warn_status={NRIDetails.aadhar_details.doc_no.warning}
              error_message={`Enter ${NRIDetails.aadhar_details.option.value === "aadhar_no"
                ? "aadhar no."
                : "virtual ID"
                }`}
              validation_type="NUMBER"
            />
          </Grid>
        ) : null}
        <Grid xs={12}>
          <Grid container>
            <Grid xs={12}>
              <h6 className="mr-3">NRI Disclaimers</h6>
            </Grid>
            <Grid xs={12}>
              <p className="mr-3">
                1. These applications shall be processed and underwritten in
                India and any contract emanating there from shall be subject to
                Indian jurisdiction. The contract/policy shall be solely
                governed and construed in accordance with the laws of India
                without any reference to the conflict of laws principles.
                Further, any dispute arising out of the contract/policy shall be
                subject to the exclusive jurisdiction of the court of Mumbai.
              </p>
            </Grid>
            <Grid xs={12}>
              <p className="mr-3">
                2. This document/application does not constitute the
                distribution of any information or the making of offer or
                solicitation by anyone in any jurisdiction in which such
                distribution or offer is not authorised or to any person to whom
                it is unlawful to distribute such a document or make such an
                offer or solicitation.
              </p>
            </Grid>
            <Grid xs={12}>
              <CustomCheckbox
                label="I hereby declare and confirm that I am a resident of country details provided in the application form for taxation purposes for the current financial year 2023-2024 and here-onwards. In case of any further changes in my residential status, I shall inform the same to the Company with the relevant documents."
                attrName={"disclaimer_two"}
                value={NRIDetails.disclaimer_two}
                value_update={updateMasterState}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer continueFunction={continue_function} backText="Back" />
    </Box>
  );
}

export default MNRIDetails;
