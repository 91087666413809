import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../Store/hooks";
import { calculateAgeInDays } from "../../../../../SupportingFiles/HelpingFunction";
import MedicalQuestion from "../../../../../Component/MedicalQuestion/MedicalQuestion";
import MemberRadioButtonNew from "../../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../Component/Footer/Footer";
import MedicalQuestionHdfc from "../../../../../Component/MedicalQuestion/MedicalQuestionhdfc";
import HealthMedDetailsNotProceedPopup from "../../../../../Component/HealthMedDetailsNotProceedPopup/HealthMedDetailsNotProceedPopup";

function MedicalDetailsHdfc({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,

}: {
  medicalQuestionData: any;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;

}) {
  const { INSURED_MEMBER_DETAILS ,medical_popup_mhs} = useAppSelector((state) => state.Health);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);

  const [openProceedPopup, setOpenProceedPopup] = useState(false);
  const [modalstatus , setmodalstatus] = useState(medical_popup_mhs)
  console.log(medical_popup_mhs, "jjj3", openProceedPopup);

  useEffect(()=>{
    if(medical_popup_mhs){
 setOpenProceedPopup(true)
    }
   
  },[medical_popup_mhs])
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
     <HealthMedDetailsNotProceedPopup
          open={openProceedPopup}
          setOpen={setOpenProceedPopup}
        /> 
     
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => (
            <div key={indexMainQuestion}>
              <Grid container spacing={3} className="pr-2 mb-3">
                {data?.main_question?.sub_question && (
                  <Grid xs={12}>
                    {/* <h5>High or low blood pressure</h5> */}
                    {data?.main_question?.sub_question &&
                      Object.values(data?.main_question?.sub_question)?.map(
                        (dataSubQuestion: any, indexSubQuestion: number) => (
                          <>
                            <MedicalQuestionHdfc
                              key={indexSubQuestion}
                              main_class="medicalQuestion borderBottom"
                              icon_class={"tobaco"}
                              question_name={dataSubQuestion?.title}
                              sub_details={
                                <p>{dataSubQuestion?.description}</p>
                              }
                              input_id_first={dataSubQuestion?.id}
                              input_id_second={dataSubQuestion?.id}
                              input_name="blood pressure"
                              toggle_button_status={dataSubQuestion?.status}
                              attrName={[
                                "subQuestion",
                                data?.main_question?.id,
                                dataSubQuestion?.id,
                              ]}
                              value_update={updateMasterState}
                              data={data}
                              dataSubQuestion={dataSubQuestion}
                              updateMasterState={updateMasterState}
                              select_disease={
                                <Collapse
                                  in={dataSubQuestion?.status}
                                  className="medicalQuestion-SubSection"
                                >
                                  <Grid container spacing={2}>
                                    {Object.values(
                                      dataSubQuestion?.member_data
                                    )?.map(
                                      (
                                        memberDataValue: any,
                                        memberIndex: any
                                      ) => (
                                        <Grid xs={6} md={3} lg={4}>
                                          <>
                                            {console.log(
                                              memberDataValue,
                                              "ccccc"
                                            )}
                                          </>
                                          <>
                                            {memberDataValue.name !== "" ? (
                                              <form>
                                                {" "}
                                                <MemberRadioButtonNew
                                                  class_name={
                                                    memberIndex === 0 ||
                                                    memberIndex === 1
                                                      ? "adult"
                                                      : "child"
                                                  }
                                                  checked={
                                                    memberDataValue?.selection_status
                                                  }
                                                  label_name={
                                                    memberDataValue.name
                                                  }
                                                  attrName={[
                                                    "memberData",
                                                    data.main_question.id,
                                                    dataSubQuestion.id,
                                                    memberDataValue.keyName,
                                                  ]}
                                                  value_update={
                                                    updateMasterState
                                                  }
                                                />
                                              </form>
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        </Grid>
                                      )
                                    )}
                                  </Grid>
                                </Collapse>
                              }
                            />
                          </>
                        )
                      )}
                  </Grid>
                )}
              </Grid>
            </div>
          )
        )}
      </>
      <Footer
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
}

export default MedicalDetailsHdfc;
