import { Box, Modal, CircularProgress, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import CustomButton from "../CustomButton/CustomButton";
import { COLORS } from "../../SupportingFiles/colors";
import { HealthSlice } from "../../Store/Slice_Reducer/Health/HealthSlice";

function HealthMedDetailsNotProceedPopup({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) {
  const dispatch = useAppDispatch();
  return (
    <Modal open={open} className="modalWrapper">
      <Box className="modalContent  kycPopups">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                dispatch(HealthSlice.actions.MEDICAL_POPUP_MHS(false))
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={"50px"}>
          <Grid xs={12} textAlign={"center"}>
            <img src="/images/warning.svg" alt="" width={"300px"} />
          </Grid>
          <Grid xs={12} textAlign={"center"}>
            <h3>SORRY!!</h3>
            <p
              style={{
                textAlign: "center",
                marginBottom: "24px",
                fontSize: "16px",
              }}
            >
              if you or any insured member have any pre-existing disease, this
              insurer doesn't cover. Kindly chat with our online representative
              or call us at
              <a
                href="tel:1800-313-3279"
                style={{ color: COLORS.primary, fontWeight: 700 }}
              >
                {" "}
                1800-313-3279
              </a>{" "}
              for more information.
            </p>

            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid
            xs={12}
            className=""
            style={{ color: COLORS.primary, fontWeight: 700, cursor:"pointer" }}
          >
            <Link>or Checkout with other Insurer</Link>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default HealthMedDetailsNotProceedPopup;
