import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
import { CKYCSlice } from "./Slice_Reducer/CKYC/CKYCSlice";
import { CarSlice } from "./Slice_Reducer/Car/CarSlice";
import { CommonDropdownSlice } from "./Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "./Slice_Reducer/Health/HealthSlice";
import { PaymentGatewaySlice } from "./Slice_Reducer/PaymentGateway/PaymentGatewaySlice";
import { TermSlice } from "./Slice_Reducer/Term/TermSlice";
import { TWSlice } from "./Slice_Reducer/TwoWheeler/TWSlice";
import { TermFullJourneySlice } from "./Slice_Reducer/TermFullJourney/TermFullJourneySlice";

const rootReducer = combineReducers({
  CommonDropdownData: CommonDropdownSlice.reducer,
  TW: TWSlice.reducer,
  Car: CarSlice.reducer,
  Term: TermSlice.reducer,
  TermFullJourney: TermFullJourneySlice.reducer,
  Health: HealthSlice.reducer,
  CKYC: CKYCSlice.reducer,
  PAYMENT_GATEWAY: PaymentGatewaySlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "proposal root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
