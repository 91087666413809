export const Yes_Or_No = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

export const Consume_alcohal = [
  { label: "Occasional or Social", value: "AL1C" },
  { label: "Once in a week", value: "AL1B" },
  { label: "Daily", value: "AL1A" },
];

export const Asthma_diagnosed = [
  { label: "Upto 10yrs", value: "AS1A" },
  { label: "More than 10yrs", value: "AS1B" },
];

export const Asthma_Current_medication = [
  { label: "None", value: "AS2C" },
  { label: "Non Steroids", value: "AS2B" },
  { label: "Steroids", value: "AS2A" },
];

export const asthma_suffered = [
  { label: "Less than 2", value: "A32A" },
  { label: "More than 5", value: "AS3c" },
  { label: "2 to 5", value: "AS3B" },
];

export const diabetes_type = [
  { label: "Insulin dependant", value: "DB1A" },
  { label: "Non Insulin dependant", value: "DB1B" },
];

export const diabetes_medication = [
  { label: "Insulin", value: "DB2A" },
  { label: "Oral medication", value: "DB2B" },
];

export const diabetes_fbs = [
  { label: "Abnormal", value: "DB10B" },
  { label: "Within normal limits", value: "DB10A" },
  { label: "Not listed", value: "DB10C" },
];

export const diabetes_diagnosed = [
  { label: "More than 10yrs", value: "DB3B" },
  { label: "Upto 10yrs", value: "DB3A" },
];

export const diabetes_last_blood = [
  { label: "Not done in last 6 months", value: "DB4C" },
  { label: "Less than 3 months", value: "DB4A" },
  { label: "More than 3months", value: "DB4B" },
];

export const diabetes_hbA1c_value = [
  { label: "upto 7.5%", value: "DB9A" },
  { label: "More than 7.5%", value: "DB9B" },
];

export const tobacco_consume = [
  { label: "Chewable", value: "TB1B" },
  { label: "Smoke", value: "TB1A" },
  { label: "Both", value: "TB1C" },
];

export const thyroid_dysfunction = [
  { label: "Hyperthyroidism", value: "TH1A" },
  { label: "Hypothyroidism", value: "TH1B" },
  { label: "Not sure", value: "TH1C" },
];

export const thyroid_medication = [
  { label: "Thyroxine", value: "TH2B" },
  { label: "Eltroxine", value: "TH2A" },
  { label: "Not listed", value: "TH2C" },
];

export const thyroid_diagnosed = [
  { label: "10yrs or more", value: "TH3C" },
  { label: "Less than 5yrs", value: "TH3A" },
  { label: "5 to 9yrs", value: "TH3B" },
];

export const hypertension_diagnosed = [
  { label: "Upto 10yrs", value: "HP1A" },
  { label: "More than 10yrs", value: "HP1B" },
];
export const hypertension_tablets = [
  { label: "More than two", value: "HP2B" },
  { label: "Two or less", value: "HP2A" },
];

export const hypertension_systolic = [
  { label: "More than 130 to 149 mm of Hg", value: "HP3B" },
  { label: "130 mm of Hg or less", value: "HP3A" },
  { label: "150 mm of Hg or more", value: "HP3C" },
];

export const hypertension_diastolic = [
  { label: "90 mm of Hg or less", value: "HP4A" },
  { label: "90 to 95 mm of Hg", value: "HP4B" },
  { label: "More than 95 mm of Hg", value: "HP4C" },
];

export const hyperlipidemia_diagnosed = [
  { label: "5 to 9yrs", value: "DL3B" },
  { label: "Less than 5yrs", value: "Dl3A" },
  { label: "10yrs or more", value: "DL3C" },
];

export const viral_fever_miltiple = [
  { label: "Diabetes", value: "SPR1" },
  { label: "COPD", value: "SPR12" },
  { label: "Alzhemeirs disease", value: "SPR30" },
  { label: "Systemic Lupus Erythmatous", value: "SPR18" },
  { label: "Kidney failure", value: "SPR19" },
  { label: "Thyroid problem", value: "SPR5" },
  { label: "Kidney Stone", value: "SPR50" },
  { label: "Cancer", value: "SPR3" },
  { label: "Myasthenia gravis", value: "SPR39" },
  { label: "High Cholesterol", value: "SPR22" },
  { label: "Epilepsy", value: "SPR15" },
  { label: "Muscular dystrophy", value: "SPR24" },
  { label: "Anemia", value: "SPR42" },
  { label: "Rheumatoid Arthritis", value: "SPR17" },
  { label: "Asthma", value: "SPR7" },
  { label: "Liver disease", value: "SPR20" },
  { label: "Neurofibromatosis", value: "SPR37" },
  { label: "Mental retardation", value: "SPR41" },
  { label: "Crohns disease", value: "SPR26" },
  { label: "Multiple sclerosis", value: "SPR6" },
  { label: "Ankylosing Spondylitis", value: "SPR47" },
  { label: "Parkinsons disease", value: "SPR29" },
  { label: "Hypertension", value: "SPR2" },
  { label: "Rheumatic fever", value: "SPR46" },
  { label: "Ulcerative collitis", value: "SPR32" },
  { label: "Gynea disorder", value: "SPR49" },
  { label: "Heart disease", value: "SPR4" },
  { label: "HIV", value: "SPR28" },
  { label: "Downs syndrome", value: "SPR31" },
  { label: "Gout", value: "SPR23" },
  { label: "Accident involving fractures", value: "SPR35" },
  { label: "Hernia or hydrocele", value: "SPR9" },
  { label: "Physical disability or amputation", value: "SPR33" },
  { label: "Hepatitis", value: "SPR27" },
  { label: "Congenital defect", value: "SPR40" },
  { label: "Not listed", value: "SPR44" },
  { label: "Gastritis", value: "SPR14" },
  { label: "ENT related", value: "SPR48" },
  { label: "Psychiatric illness", value: "SPR25" },
  { label: "Anal fissure", value: "SPR10" },
  { label: "Blood disorder", value: "SPR43" },
  { label: "Blindness", value: "SPR34" },
  { label: "Deafness", value: "SPR36" },
  { label: "Thalessemia", value: "SPR38" },
  { label: "Neuropathy or Neurological disorder", value: "SPR11" },
  { label: "Arthritis", value: "SPR8" },
  { label: "Gall bladder disease", value: "SPR21" },
  { label: "Cataract", value: "SPR13" },
  { label: "Dengue", value: "SPR45" },
  { label: "Migraine", value: "SPR16" },
];

export const ForShowOnPreviewPage = [
  // Yes or No
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },

  // Consume alcohol
  { label: "Occasional or Social", value: "AL1C" },
  { label: "Once in a week", value: "AL1B" },
  { label: "Daily", value: "AL1A" },

  // Asthma diagnosed
  { label: "Upto 10yrs", value: "AS1A" },
  { label: "More than 10yrs", value: "AS1B" },

  // Asthma Current medication
  { label: "None", value: "AS2C" },
  { label: "Non Steroids", value: "AS2B" },
  { label: "Steroids", value: "AS2A" },

  // Asthma suffered
  { label: "Less than 2", value: "A32A" },
  { label: "More than 5", value: "AS3c" },
  { label: "2 to 5", value: "AS3B" },

  // Diabetes type
  { label: "Insulin dependant", value: "DB1A" },
  { label: "Non Insulin dependant", value: "DB1B" },

  // Diabetes medication
  { label: "Insulin", value: "DB2A" },
  { label: "Oral medication", value: "DB2B" },

  // Diabetes FBS
  { label: "Abnormal", value: "DB10B" },
  { label: "Within normal limits", value: "DB10A" },
  { label: "Not listed", value: "DB10C" },

  // Diabetes diagnosed
  { label: "More than 10yrs", value: "DB3B" },
  { label: "Upto 10yrs", value: "DB3A" },

  // Diabetes last blood
  { label: "Not done in last 6 months", value: "DB4C" },
  { label: "Less than 3 months", value: "DB4A" },
  { label: "More than 3months", value: "DB4B" },

  // Diabetes HbA1c value
  { label: "upto 7.5%", value: "DB9A" },
  { label: "More than 7.5%", value: "DB9B" },

  // Tobacco consume
  { label: "Chewable", value: "TB1B" },
  { label: "Smoke", value: "TB1A" },
  { label: "Both", value: "TB1C" },

  // Thyroid dysfunction
  { label: "Hyperthyroidism", value: "TH1A" },
  { label: "Hypothyroidism", value: "TH1B" },
  { label: "Not sure", value: "TH1C" },

  // Thyroid medication
  { label: "Thyroxine", value: "TH2B" },
  { label: "Eltroxine", value: "TH2A" },
  { label: "Not listed", value: "TH2C" },

  // Thyroid diagnosed
  { label: "10yrs or more", value: "TH3C" },
  { label: "Less than 5yrs", value: "TH3A" },
  { label: "5 to 9yrs", value: "TH3B" },

  // Hypertension diagnosed
  { label: "Upto 10yrs", value: "HP1A" },
  { label: "More than 10yrs", value: "HP1B" },

  // Hypertension tablets
  { label: "More than two", value: "HP2B" },
  { label: "Two or less", value: "HP2A" },

  // Hypertension systolic
  { label: "More than 130 to 149 mm of Hg", value: "HP3B" },
  { label: "130 mm of Hg or less", value: "HP3A" },
  { label: "150 mm of Hg or more", value: "HP3C" },

  // Hypertension diastolic
  { label: "90 mm of Hg or less", value: "HP4A" },
  { label: "90 to 95 mm of Hg", value: "HP4B" },
  { label: "More than 95 mm of Hg", value: "HP4C" },

  // Hyperlipidemia diagnosed
  { label: "5 to 9yrs", value: "DL3B" },
  { label: "Less than 5yrs", value: "Dl3A" },
  { label: "10yrs or more", value: "DL3C" },
];
