import { Route, Routes } from "react-router-dom";
import CareCKYCContainer from "../../../../../Container/CKYC/CARE/CareCKYCContainer";
import AddonDetailsContainer from "../../../../../Container/Health/CARE/AddonDetailsContainer/AddonDetailsContainer";
import AddressDetailsContainer from "../../../../../Container/Health/CARE/AddressDetailsContainer/AddressDetailsContainer";
import AdultOneDetailsContainer from "../../../../../Container/Health/CARE/AdultOneDetailsContainer/AdultOneDetailsContainer";
import MemberDetailContainer from "../../../../../Container/Health/CARE/AdultTwoDetailsContainer/AdultTwoDetailsContainer";
import ChildFourDetailsContainer from "../../../../../Container/Health/CARE/ChildFourDetailsContainer/ChildFourDetailsContainer";
import ChildOneDetailsContainer from "../../../../../Container/Health/CARE/ChildOneDetailsContainer/ChildOneDetailsContainer";
import ChildThreeDetailsContainer from "../../../../../Container/Health/CARE/ChildThreeDetailsContainer/ChildThreeDetailsContainer";
import ChildTwoDetailsContainer from "../../../../../Container/Health/CARE/ChildTwoDetailsContainer/ChildTwoDetailsContainer";
import InsuredMemberContainer from "../../../../../Container/Health/CARE/InsuredMemberContainer/InsuredMemberContainer";
import MedicalDetailsContainer from "../../../../../Container/Health/CARE/MedicalDetailsContainer/MedicalDetailsContainer";
import PreviewDetailsContainer from "../../../../../Container/Health/CARE/PreviewDetailsContainer/PreviewDetailsContainer";
import MedicalDetailsContainerHdfc from "../../../../../Container/Health/HDFC/MedicalDetailsContainerHdfc/MedicalDetailsContainerHdfc";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../Store/hooks";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import AddonDetailsContainerICIC from "../../../../../Container/Health/ICIC/AddonDetailsContainerICIC/AddonDetailsContainerICIC";
import MedicalDetailsContainerICIC from "../../../../../Container/Health/ICIC/AddonDetailsContainerICIC/MedicalDetailsContainerICIC/MedicalDetailsContainerICIC";
import ICICICKYCContainer from "../../../../../Container/CKYC/ICICI/ICICICKYCContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import ICICIManualKYCContainer from "../../../../../Container/CKYC/ICICI/ICICIManualKYCContainer";
import AddonDetailsContainerGoldenShieldIcici from "../../../../../Container/Health/ICIC/AddonDetailsContainerICIC/AddonDetailsContainerGoldenShieldIcici";

export const ICICI_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const company_code = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code
  );

  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<ICICICKYCContainer />} />
        <Route
          path={CKYC_ROUTES.MANUAL_CKYC}
          element={<ICICIManualKYCContainer />}
        />
        {company_code === ECompanyCode.ICIC &&
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.ICICI_GOLDEN_PLAN ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerGoldenShieldIcici />}
          />
        ) : (
          <></>
        )}
        {company_code === ECompanyCode.ICIC &&
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.ICICI_HEALTH_ADVANTAGE ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerICIC />}
          />
        ) : (
          <></>
        )}
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailsContainerICIC />}
        />
      </Route>
    </Routes>
  );
};
