import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { CAR_URLS } from "../../URLCollection/Car/CarURLs";

const GET_PREVIOUS_INSURER_LIST = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    CAR_URLS.GET_PREVIOUS_INSURER_LIST,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const VEHICLE_ON_LOAN = (
  onSuccess: (data: any) => void,
  onError: (data: any) => void,
  param: any
) => {
  PostAPI.call(
    CAR_URLS.VEHICLE_ON_LOAN,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const GENERATE_OTP = (onSuccess: Function, onError: Function, param: any) => {
  PostAPI.call(
    CAR_URLS.GENERATE_OTP,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const VERIFY_OTP = (onSuccess: Function, onError: Function, param: any) => {
  PostAPI.call(
    CAR_URLS.VERIFY_OTP,
    param,
    (res: any) => onSuccess(res.data),
    onError
  );
};
const GET_VEHICLE_NO_INFO = (
  onSuccess: Function,
  onError: Function,
  reg_no: string | Date | null,
  type: "CAR" | "TW"
) => {
  PostAPI.call(
    `${CAR_URLS.GET_VEHICLE_NO_INFO}`,
    {
      type: type,
      rc_no: reg_no,
    },
    (res: any) => onSuccess(res.data),
    onError
  );
};
export const CAR_ADD_FORM_SERVICES = {
  GET_VEHICLE_NO_INFO,
  GET_PREVIOUS_INSURER_LIST,
  VEHICLE_ON_LOAN,
  GENERATE_OTP,
  VERIFY_OTP,
};
