import { createSlice } from "@reduxjs/toolkit";
import { THealthSlice } from "../../../types/Health/THealthSlice";
import { HEALTH_REDUCERS } from "./HealthReducer";
import { formatNumberToLakhOrCrores } from "../../../SupportingFiles/HelpingFunction";
import { IFFCO_TOKIO_MEDICAL_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/IffcoTokio";
import { GO_DIGIT_MEDICAL_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/GoDigit";
import { FUTURE_GENERALI_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/FutureGenerali";
import { STAR_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Star";
import { STAR_RED_CARPET_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Star";
import { STAR_FHO_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/StarFHO";
import { CARE_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Care";
import { HDFC_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/HDFC";
import { ICICI_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/ICICI";
import { OPTIMA_RESTORE_INITIAL_VALUE } from "./MedicalInitialValue/OptimaRestore";
import { OPTIMA_SECURE_INITIAL_VALUE } from "./MedicalInitialValue/OptimaSecure";
import { NIVA_BUPA_INITIAL_VALUE } from "./MedicalInitialValue/NivaBupa";
import { NIVA_BUPA_SENIOR_INITIAL_VALUE } from "./MedicalInitialValue/NivaBupaSenior";
import { KOTAK_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Kotak";
import { BAJAJ_MEDICAL_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Bajaj";
import { CIGNA_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/Cigna";
import { RSA_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/RSA";
import { CARE_HEART_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/CareHeart";
import { ADITYA_BIRLA_MEDICAL_QUESTION_INITIAL_VALUE } from "./MedicalInitialValue/ADITYA_BIRLA";

const initialState: THealthSlice = {
  REDIRECTED: false,
  QUOTE_LOADER: false,
  DROPDOWN_DATA: {
    TERM: [
      { label: "1 Year", value: "1" },
      { label: "2 Years", value: "2" },
      { label: "3 Years", value: "3" },
    ],
    SORT_BY: [
      { label: "Low-High", value: "Low-High" },
      { label: "High-Low", value: "High-Low" },
    ],
    AGE: [],
    SUM_INSURED: [
      { value: "1 Lakh", label: "100000" },
      { value: "2 Lakh", label: "200000" },
      { value: "4 Lakh", label: "400000" },
      { value: "5 Lakh", label: "500000" },
      { value: "6 Lakh", label: "600000" },
      { value: "7 Lakh", label: "700000" },
      { value: "8 Lakh", label: "800000" },
      { value: "9 Lakh", label: "900000" },
      { value: "10 Lakh", label: "1000000" },
      { value: "12 Lakh", label: "1200000" },
      { value: "15 Lakh", label: "1500000" },
      { value: "16 Lakh", label: "1600000" },
      { value: "17 Lakh", label: "1700000" },
      { value: "18 Lakh", label: "1800000" },
      { value: "19 Lakh", label: "1900000" },
      { value: "20 Lakh", label: "2000000" },
      { value: "25 Lakh", label: "2500000" },
      { value: "30 Lakh", label: "3000000" },
      { value: "40 Lakh", label: "4000000" },
      { value: "50 Lakh", label: "5000000" },
      { value: "60 Lakh", label: "6000000" },
      { value: "75 Lakh", label: "7500000" },
      { value: "1 Crore", label: "10000000" },
      { value: "1.5 Crore", label: "15000000" },
      { value: "2 Crore", label: "20000000" },
      { value: "3 Crore", label: "30000000" },
      { value: "6 Crore", label: "60000000" },
    ],
    OCCUPATION: [],
    MARTIAL: [],
    ADULT1_RELATIONSHIP: [],
    ADULT2_RELATIONSHIP: [
      { label: "Father", value: "Father" },
      { label: "Mother", value: "Mother" },
      { label: "Spouse", value: "Spouse" },
    ],
    CHILD_RELATIONSHIP: [],
    NOMINEE_RELATIONSHIP: [],
    GLOBALCOVERAGE: [
      { value: "Not Applicable", label: "Not Applicable" },
      { value: "WW Coverage", label: "WW Coverage" },
      { value: "WW Excl. US and Canada", label: "WW Excl. US and Canada" },
    ],
    CRITICAL_SUMINSURED: [],
    DEDUCTION_DATA: [
      { value: "25000", label: "25000" },
      { value: "50000", label: "50000" },
      { value: "100000", label: "100000" },
      { value: "200000", label: "200000" },
      { value: "300000", label: "300000" },
      { value: "500000", label: "500000" },
    ],
  },
  isDeduction_value: "0",
  past_health_insurance_value: "",
  ADDON_DETAILS: {
    NCBSuper: true,
    field_34: false,
    SmartSelect: false,
    AirAmbulanceCover: true,
    CareShield: true,
    GlobalCoverage: { value: "", warning: false },
    field_43: false,
    field_AHC: true,
    field_IC: false,
    field_OPD: false,
    field_OPD_data: { value: "5000", warning: false },
    field_PED_tenure: { value: "1", warning: false },
    field_WB: true,
    field_CPW: false,
    field_DK: false,
    field_DUE: false,
    recharge: false,
    critical_illness: false,
    ipaRiderCheck: false,
    unlimitedRiderCheck: false,
    ipaSa: { value: "1000", warning: false },
    hdcSa: { value: "1000", warning: false },
    ipa: false,
    IsUnlimitedRestore: false,
    optima_well: false,
    overseas_travel: false,
    health_hospital_cash_benefit: false,
    health_hospital_cash_benefit_sum_insured: "",
    critical_suminsured: { value: "1000", warning: false },
    Iscritical_Illness: false,
    Iscritical_Illness_sum_insured: "100000",
    Insured_CriticalIllnessAddonCover_PlanType: "",
    isGlobalRider: false,
    field_CS: false,
    field_HomeCare: false,
    field_UAR: true,
    field_COPAY: false,
  },
  ADDON_DETAILS_ICIC: {
    Critical_Illness: false,
    Maternity: false,
    New_Born: false,
    Vaccination: false,
    Domestic_Air_Ambulance: false,
    personal_accident: false,
    SI_Protector: false,
    Claim_Protector: false,
    Compassionate_Visit: false,
    Nursing_at_Home: false,
    BeFit_A: false,
    BeFit_B: false,
    BeFit_C: false,
    BeFit_D: false,
    BeFit_E: false,
    BeFit_F: false,
    BeFit_G: false,
    BeFit_H: false,
  },
  // ADDON_DETAILS_ADITYA_BIRLA: {
  //   second_medical_opinion_for_listed_major_illness: false,
  //   compassionate_visit: false,
  //   durable_equipment_cover: false,
  //   cancer_booster: false,
  //   reduction_in_specific_disease_waiting_period: false,
  //   reduction_in_pre_existing_disease_waiting_period: false,
  //   room_rent_type_options: false,
  //   per_claim_deductible: false,
  //   in_patient_hospitalization: false,
  //   road_ambulance_cover_per_hospitalization: false,
  //   modern_procedures_treatments: false,
  //   hiv_aids_and_std_over: false,
  //   mental_illness_hospitalization: false,
  //   obesity_treatment: false,
  //   pre_hospitalization_expenses: false,
  //   post_hospitalization_expenses: false,
  //   domiciliary_hospitalization: false,
  //   home_health_care: false,
  //   ayush_treatment: false,
  //   organ_donor_expenses: false,
  //   super_reload: false,
  //   health_assessmentTM: false,
  //   healthReturnsTM: false,
  //   claim_protect_non_medical: false,
  //   preferred_provider_network: false,
  //   critical_illness_cover: false,
  //   personal_accident_cover: false,
  //   Super_credit_increases_irrespective_of_claim: false,
  //   chronic_care: false,
  //   chronic_management_program: false,
  //   chronic_care_restriction: false,
  //   annual_screening_package_for_cancer_diagnosed_patients: false,
  //   annual_health_check_up: false,
  //   vaccine_cover: false,
  //   tele_OPD_consultation: false,
  //   opd_add_on: false,
  // },
  ADDON_DETAILS_ADITYA_BIRLA: {
    cancer_booster: false,
    reduction_in_pre_existing_disease_waiting_period: false,
    reduction_ped_waiting_period: "3TO1", //  DropDown => 3TO1 = 3 Years to 1 Year , 3TO2 = 3 Years to 2 Years
    room_rent_type_options: false,
    room_rent_type: "SH", //  DropDown => SH = Shared Accomodation , YSY = Single Private Room
    critical_illness_cover: false,
    critical_illness_cover_SI: "1000000", // Dropdown of SI
    personal_accident_cover: false,
    personal_accident_cover_SI: "2000000", // Dropdown of SI
    chronic_care: false, // For VYTL Plan Only
    chronic_management_program: false,
    durable_equipment_cover: false,
    natureOfDuty: "",
    NonChronic: false,
    Diabetes: false,
    Asthama: false,
    Hypertension: false,
    Hyperlipidaemia: false,
    PTCA: false,
    COPD: false,
    HighBMI: false,
  },
  ADDON_DETAILS_GOLDEN_SHIELD_ICICI: {
    Claim_protector: false,
    Modification_base_co_payment: false,
    Voluntary_deduction: false,
    Care_management_program: true,
    Care_management_plus_program: false,
    VoluntaryCopaymentPercentage: 20,
  },
  ADDON_DETAILS_GODDIGIT: {
    consumables_cover: false,
  },
  ADDON_DETAILS_BAJAJ: {
    Addonnme: false,
    Addonroomwaiver: false,
  },
  ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE: {
    critical_illness: false,
    outpatient: true,
    cumulative: false,
    healthAddSumInsured: "20000",
    non_medical: false,
    personal_accident: false,
  },
  ADDON_DETAILS_CIGNA_PRIME_ACTIVE: {
    non_medical: false,
    health_ckeck: false,
    waiver: false,
    world_accident: false,
    wellness: true,
  },
  ADDON_DETAILS_CIGNA_PRIME_PROTECT: {
    critical_illness: false,
    cumulative: false,
    reduction: false,
    healthAddSumInsured: "10000",
    non_medical: false,
    personal_accident: false,
  },
  ADDON_DETAILS_CIGNA_LIFETIME_INDIA: {
    critical_illness: false,
    cumulative: true, // mandatory
    wwhospitalized: true, // mandatory
    wwh_suminsured: "2500000",
    maternity_expenses: false,
    infertility: false,
    Oocyte: false,
    health_360_advance: false,
    health_360_opd: false,
    health_360_shield: false,
    health_plus: false,
    woman_plus: false,
  },
  ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL: {
    critical_illness: false,
    cumulative: false,
    wwhospitalized: false,
    maternity_expenses: false,
    infertility: false,
    Oocyte: false,
    health_360_advance: false,
    health_360_opd: false,
    health_360_shield: false,
    global: true, // mandatory
    global_plus: false,
    coverage_type: "",
    coverage_type_toggle: false,
    coverage_illness: "",
    coverage_illness_toggle: false,
    wwh_suminsured: "",
    global_suminsured: "2500000",
  },
  ADD_FORM: {
    spouse_relationship: { value: "", warning: false },
    age: { value: "", warning: false },
    children: 0,
    gender: { value: "M", warning: false },
    pincode: { value: "", warning: false },
    whom_to_insure: "One Adult",
    mobile: { value: "", warning: false },
    name: { value: "", warning: false },
    son_count: { value: "0", warning: false },
    daughter_count: { value: "0", warning: false },
    self_dob: { value: "", warning: false },
    spouse_dob: { value: "", warning: false },
    son_status: false,
    daughter_status: false,
    self_status: true,
    spouse_status: false,
    child_one_dob: { value: "", warning: false },
    child_two_dob: { value: "", warning: false },
    child_three_dob: { value: "", warning: false },
    child_four_dob: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    sum_insured: 0,
    quote_no: "",
    module: "",
    insurance_type: "",
    term: 0,
    cover: "",
    city: "",
    state: "",
    child: 0,
    adult: 0,
    mobile: 0,
    pincode: "",
    sort_by: "Low-High",
    utm_medium: "",
  },
  KYC_DETAILS: {
    propFullName: { value: "", warning: false },
    ProposerDob: { value: "", warning: false },
    verificationDoc: { value: "", warning: false },
    docID: { value: "", warning: false },
    cusdtomertype: { value: "", warning: false },
    gender: { value: "", warning: false },
    addressDoc: { value: "", warning: false },
    verificationDocFile: { value: "", warning: false },
    addressDocFile: { value: "", warning: false },
    pincode_zone: "",
  },
  ALL_QUOTES_DATA: [],
  POLICY_TENURE: 1,
  SELECTED_QUOTE_DATA: {
    CashlessHospitalLists: [],
    sumInsured: "",
    productDetails: {
      brochure: "",
      buy_online_code: "",
      company_product_map: { company_code: "" },
      discount_calc_action: "",
      health_form_validation: "",
      id: 0,
      is_online: "",
      pre_existing_cover: "",
      prem_calc_action: "",
      product_code: "",
      product_type_code: "",
      product_desc: "",
      product_name: "",
      proposal_method: "",
      rate_calc_method: "",
      rating: "",
      room_cover: "",
    },
    premiumDetails: {
      basePremium: 0,
      finalPremium: 0,
      quoteId: "",
      serviceTax: 0,
      sum_insured: 500000,
      term: 1,
      transactionid: "",
      insurance_type: "",
    },
    SpecialFeatureLists: [
      { code: "", description: "", show_desc: 0, title: "" },
    ],
    CompanyDetails: {
      claim_ratio: "",
      company_code: "",
      logo: "",
      name: "",
      short_desc: "",
    },
    loading: true,
  },
  INSURED_MEMBER_DETAILS: {
    NOMINEE__DETAILS: {
      full_name: { value: "", warning: false },
      dob: { value: "", warning: false },
      relationship: { value: "", warning: false },
      insured_member: true,
      prosper_details: true,
      localstate: false,
    },
    PROPOSER_DETAILS: {
      IS_PROPOSER_INSURED: true,
      name: { value: "", warning: false },
      occupation: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      proposer_name: { value: "", warning: false },
      relationship: { value: "", warning: false },
      pan_card: { value: "", warning: false },
      annual_income: { value: "", warning: false },
      qualification: { value: "", warning: false },
      member_income: { value: "", warning: false },
      grainful_income: { value: "", warning: false },
    },
    ADULT_ONE_DETAILS: {
      name: { value: "", warning: false },
      occupation: { value: "", warning: false },
      dob: { value: "", warning: false },
      mobile: { value: "", warning: false },
      emergencyPhone: { value: "", warning: false },
      email: { value: "", warning: false },
      aadhar: { value: "", warning: false },
      gender: { value: "", warning: false },
      maritalStatus: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      proposer_name: { value: "", warning: false },
      relationship: { value: "", warning: false },
      pan_card: { value: "", warning: false },
      annual_income: { value: "", warning: false },
      qualification: { value: "", warning: false },
      member_income: { value: "", warning: false },
      grainful_income: { value: "lakhs", warning: false },
    },
    ADULT_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
      occupation: { value: "", warning: false },
      qualification: { value: "", warning: false },
    },
    CHILD_ONE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_TWO_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_THREE_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
    CHILD_FOUR_DETAILS: {
      name: { value: "", warning: false },
      dob: { value: null, warning: false },
      relationship: { value: "", warning: false },
      weight: { value: "", warning: false },
      heightFeet: { value: "", warning: false },
      heightInches: { value: "", warning: false },
    },
  },
  PAYMENT_DATA: {
    proposal_no: "",
    finalPremium: 0,
    quote_no: "",
    PartnerId: "",
    transactionNumber: "",
    applicationNumber: 0,
    transactionID: "",
    SubscriptionID: "",
    paymentRequest: {
      actionURL: "",
      actionMethod: "",
      Chksum: "",
      Trnsno: "",
      Amt: 0,
      Appid: 0,
      Subid: "",
      Surl: "",
      Furl: "",
      Src: "",
    },
  },
  CIS_DATA: {
    document_url: "",
    ProposalNumber: "",
    TransactionID: "",
  },
  NOMINEE_RELATIONSHIP_STATUS: "OTHER",
  ADDRESS_DETAILS: {
    pincode: { value: "", warning: false },
    city: "",
    state: "",
    addressLineOne: { value: "", warning: false },
    addressLineTwo: { value: "", warning: false },
    isMailingAddressSame: true,
    mailingPinCode: { value: "", warning: false },
    mailingCity: "",
    mailingState: "",
    mailingAddressLineOne: { value: "", warning: false },
    mailingAddressLineTwo: { value: "", warning: false },
  },
  MEDICAL_QUESTION_DATA_IIFCO: IFFCO_TOKIO_MEDICAL_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_GO_DIGIT: GO_DIGIT_MEDICAL_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_BAJAJ: BAJAJ_MEDICAL_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_ADITYA_BIRLA:
    ADITYA_BIRLA_MEDICAL_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_FUTURE_GENERALI:
    FUTURE_GENERALI_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_STAR: STAR_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_STAR_RED_CARPET:
    STAR_RED_CARPET_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_STAR_FHO: STAR_FHO_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_CARE: CARE_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_HDFC: HDFC_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_ICIC: ICICI_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_OPTIMA_RESTORE: OPTIMA_RESTORE_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_OPTIMA_SECURE: OPTIMA_SECURE_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_CARE_HEART: CARE_HEART_QUESTION_INITIAL_VALUE(),
  PAGE_STATUS: false,
  Preview_Loader: false,
  Form_dob_model: false,
  Form_member_model: false,
  medical_popup_mhs: false,
  ADDON_QUESTION_NIVA_BUPA: {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "Hospital Cash",
        description:
          "Get cash benefit on per day basis of hospitalization (up to 30 days) if hospitalised for more than 48 hours",
        status: false,
        field_data: {
          "MQ1-SQ1-F1": {
            id: "MQ1-SQ1-F1",
            title: "HVB Value",
            field_type: "DROPDOWN",
            value: { value: "0", warning: false },
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        icon_class: "tobaco",
        title: "Acute Care Opted",
        description:
          "Unlimited tele-consultations with Doctors (General Medical Practitioners, Specialists & Super Specialists). Also, covers pharmacy and diagnostics up to limits.",
        status: false,
        field_data: {
          "MQ1-SQ1-F1": {
            id: "MQ1-SQ1-F1",
            title: "BEST CONSULT",
            field_type: "CHECKBOX",
            value: { value: false, warning: false },
          },
          "MQ1-SQ1-F2": {
            id: "MQ1-SQ1-F2",
            title: "BEST CARE",
            field_type: "CHECKBOX",
            value: { value: false, warning: false },
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        icon_class: "tobaco",
        title: "Safeguard Opted",
        description:
          "Get access to additional benefits like- annual increase in coverage based on inflation rate, coverage for non-payable items and no impact on booster benefit.",
        status: false,
      },
    },
    MQ04: {
      main_question: {
        id: "MQ04",
        icon_class: "tobaco",
        title: "Safeguard+ Opted",
        description:
          "Get access to additional benefits like - annual increase in coverage based on inflation rate, Coverage for non-payable items (as per annexure 1), and protection of Booster+ benefit from small claims up to INR 1 Lakh",
        status: false,
      },
    },
    MQ05: {
      main_question: {
        id: "MQ05",
        icon_class: "tobaco",
        title: "Personal Accident Cover",
        description:
          "Get additional protection from Accidental Death, Permanent Total Disability and Permanent Partial Disability. Coverage Equal to 5 times of Base Sum Insured, Maximum up to INR 1 Crore.",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
      },
    },
    MQ06: {
      main_question: {
        id: "MQ06",
        icon_class: "tobaco",
        title: "Disease Management",
        description:
          "Get day 1 coverage for Diabetes and Hypertension. Along with up to 20% renewal discount. (*Variant under Smart Health+ Add On.)",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Gold",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Platinum",
                field_type: "CHECKBOX",
                value: { value: false, warning: false },
              },
            },
          },
        },
      },
    },
  },
  MEDICAL_QUESTION_DATA_NIVA_BUPA: NIVA_BUPA_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_NIVA_BUPA_SENIOR: NIVA_BUPA_SENIOR_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_KOTAK: KOTAK_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_RSA: RSA_QUESTION_INITIAL_VALUE(),
  MEDICAL_QUESTION_DATA_CIGNA: CIGNA_QUESTION_INITIAL_VALUE(),
  ADDON_DETAILS_NIVA_BUPA: {
    Hospital_Cash: false,
    Acute_Care_Opted: false,
    Safeguard_Opted: false,
    Safeguard_Plus_Opted: false,
    Personal_Accident_Cover: false,
    Disease_Management: false,
    best_consult: false,
    best_care: false,
    best_care_value: "5000",
    platinum_adult_one: false,
    gold_adult_one: false,
    platinum_adult_two: false,
    gold_adult_two: false,
  },
  ADDON_DETAILS_RSA: {
    hospitalCashbenefitOpted: false,
    isVoluntaryCoPaymentSelected: false,
    opttopupOpted: false,
    internationalTreatmentOpted: false,
    supremePlusCoverSelected: false,
    elitePlusCoverSelected: false,
    consumableItemsCoverageOpted: false,
    TdeductibleAmount: false,
    TtopUpOptedCount: false,
    TvoluntaryCoPaymentPercentage: false,
    voluntaryCoPaymentPercentage: "",
    deductibleAmount: "",
    topUpOptedCount: "",
  },
  ADDON_DETAILS_KOTAK: {
    critical_illness: false,
    compassionate_visit: false,
    air_ambulance: false,
    personal_accident: false,
    claim_protect: false,
    inflation_proptect: false,
    super_ncb: false,
    restoration_benefit_plus: false,
    maternity_benefit: false,
    new_born_baby_cover: false,
    vaccination_expenses: false,
    hospital_daily_cash: false,
    home_nursing_benefit: false,
    daily_cash: false,
    convalescence_benefit: false,
    cap_on_room_rent: false,
  },
};

let ageData = [];
for (let i = 18; i <= 110; i++) {
  ageData.push({
    value: `${i}`,
    label: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

let SUM_INSURED_DATA = [];
for (
  let i = 100000;
  i <= 15000000;
  i += i < 2000000 ? 100000 : i < 5000000 ? 500000 : 1000000
) {
  SUM_INSURED_DATA.push({
    value: `${i}`,
    label: `${formatNumberToLakhOrCrores(i)}`,
  });
}

SUM_INSURED_DATA.push(
  { value: "10000000", label: formatNumberToLakhOrCrores(10000000) },
  { value: "15000000", label: formatNumberToLakhOrCrores(15000000) },
  { value: "20000000", label: formatNumberToLakhOrCrores(20000000) },
  { value: "30000000", label: formatNumberToLakhOrCrores(30000000) },
  { value: "60000000", label: formatNumberToLakhOrCrores(60000000) }
);
initialState.DROPDOWN_DATA.SUM_INSURED = SUM_INSURED_DATA;

let criticalSum_data = [];
for (let incomeValue = 100000; incomeValue <= 20000000; incomeValue += 50000) {
  let label;

  if (incomeValue === 20000000) {
    label = "2 Crore";
  } else {
    label = `${incomeValue / 100000} Lakh`;
  }

  criticalSum_data.push({ label: label, value: `${incomeValue}` });
}
initialState.DROPDOWN_DATA.CRITICAL_SUMINSURED = criticalSum_data;

export const HealthSlice = createSlice({
  name: "Health",
  initialState,
  reducers: HEALTH_REDUCERS,
});

export default HealthSlice.reducer;
