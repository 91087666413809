import InspectionConf from "../../Page/Desktop/Common/InspectionConf/InspectionConf";
import MInspectionConf from "../../Page/Mobile/Common/MInspectionConf/MInspectionConf";
import useIsMobile from "../../SupportingFiles/MobileProvider";

export type TPaymentResponse = {
  policy_no: string;
  policy_status: string;
  document_url: string;
  payment_status: string;
  customer_name: string;
};

function InspectionConfContainer() {
  const isMobile = useIsMobile();

  return <>{isMobile ? <MInspectionConf /> : <InspectionConf />}</>;
}

export default InspectionConfContainer;
