import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import MFooter from "../../../../Component/MFooter/MFooter";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { TGoDigitAddon } from "../../../../types/Health/TGoDigitAddon";

function MAddonDetails({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TGoDigitAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Consumables Cover"
            toggle_status={addonStatus.consumables_cover}
            value_update={updateMasterState}
            attrName={"consumables_cover"}
          />
        </Grid>
        <MFooter
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
}

export default MAddonDetails;
