import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import { TCareAddon } from "../../../../types/Health/TCareAddon";
import { useAppSelector } from "../../../../Store/hooks";
import Loader from "../../../../Component/LoaderblackBg/Loader";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import Footer from "../../../../Component/Footer/Footer";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { COLORS } from "../../../../SupportingFiles/colors";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import { TBAJAJAddon } from "../../../../types/Health/ProposalDeatail/TBAJAJAddon/TBAJAJAddon";

function AddonDetails({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TBAJAJAddon;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();
  const { QUOTE_LOADER, DROPDOWN_DATA, ADD_FORM } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Non Medical Expense"
            toggle_status={addonStatus.Addonnme}
            value_update={updateMasterState}
            attrName={"Addonnme"}
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Room waiver addon"
            toggle_status={addonStatus.Addonroomwaiver}
            value_update={updateMasterState}
            attrName={"Addonroomwaiver"}
          />
        </Grid>
        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
}

export default AddonDetails;
