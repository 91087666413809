import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import "./AddonQuestion.scss";
import { TFieldData } from "../../types/Common/TFieldData";
import { useAppSelector } from "../../Store/hooks";
import HealthToggleSwitch from "../HealthToggleSwitch/HealthToggleSwitch";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";

interface Props {
  addon_amount?: string;
  main_class?: string;
  icon_class?: string;
  question_name: string;
  sub_details?: any;
  value_update: Function;
  attrName?: string;
  toggle_status: boolean | TFieldData;
  value?: boolean;
  addOnData?: any;
  desc?: string;
  policy_term?: string;
  criticalIllnessSelected?: boolean;
  loader?: boolean;
  addonStatus?: any;
  disable?: boolean;
  showAsterisk?: boolean;
}
const AddonQuestion: React.FC<Props> = ({
  addon_amount,
  main_class = "",
  icon_class = "",
  question_name,
  sub_details,
  value_update,
  attrName,
  toggle_status,
  desc,
  policy_term,
  addonStatus,
  loader,
  disable = false,
  showAsterisk,
}) => {
  const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);

  return (
    <Box className={main_class}>
      <Grid container spacing={2} alignItems="center">
        <Grid xs={8} lg={9} className="addonQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{question_name}</h6>
            <p>
              {" "}
              <b>{policy_term}</b>{" "}
            </p>

            <p>
              {desc}{" "}
              {showAsterisk ? <span style={{ color: "red" }}>*</span> : null}
            </p>
          </div>
        </Grid>
        <Grid xs={4} lg={3} textAlign="right" className="pt-4">
          {attrName !== "GlobalCoverage" ? (
            <HealthToggleSwitch
              disable={disable}
              toggle_status={
                typeof toggle_status === "boolean" ? toggle_status : false
              }
              value_update={value_update}
              attrName={attrName}
            />
          ) : (
            <SelectDropdown
              class_name="inputField"
              title="Select  Global Coverage"
              value={addonStatus?.GlobalCoverage?.value}
              attrName={attrName}
              value_update={value_update}
              data={DROPDOWN_DATA.GLOBALCOVERAGE}
              warn_status={addonStatus?.GlobalCoverage?.warning}
              error_message="Select Gender"
            />
          )}
        </Grid>
        <Grid xs={8} lg={9} paddingLeft="56px">
          {toggle_status ? sub_details : null}
        </Grid>
        {/* {toggle_status ? sub_details : null} */}
      </Grid>
    </Box>
  );
};

export default AddonQuestion;
