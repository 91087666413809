import { Box, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Unstable_Grid2";
import "./AddressDetails.scss";
import { TAddressDetails } from "../../../../../../types/TermFullJourney/TAddressDetails";
import RKTextField from "../../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../../Component/Mobile/TermFullJourney/ProposalForm/Common/Footer/Footer";

const AddressDetails = ({
  addressDetails,
  setAddressDetails,
  updateMasterState,
  continueFunction,
  loader,
}: {
  addressDetails: TAddressDetails;
  setAddressDetails: Function;
  updateMasterState: Function;
  continueFunction: Function;
  loader: boolean;
}) => {
  return (
    <Box className="proposalAddress">
      <h5 className="sectionTitle">Address Details</h5>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={addressDetails.permanent_address.pincode.value}
            attrName={["pincode", "permanent_address", setAddressDetails, true]}
            value_update={updateMasterState}
            warn_status={addressDetails.permanent_address.pincode.warning}
            validation_type="NUMBER"
            max_length={6}
            error_message="Enter pincode"
            disabled
          />
        </Grid>
        {addressDetails.permanent_address.city.toString().length > 0 ? (
          <Grid xs={8}>
            {`${addressDetails.permanent_address.city}, ${addressDetails.permanent_address.state}`}
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6} md={5}>
          <RKTextField
            class_name="inputField"
            title={"H.No. / Building"}
            value={addressDetails.permanent_address.house_no_building.value}
            attrName={[
              "house_no_building",
              "permanent_address",
              setAddressDetails,
              true,
            ]}
            value_update={updateMasterState}
            warn_status={
              addressDetails.permanent_address.house_no_building.warning
            }
            error_message="Enter H.No. / Building"
            max_length={30}
          />
        </Grid>
        <Grid xs={6} md={5}>
          <RKTextField
            class_name="inputField"
            title={"Area / Town / Locality"}
            value={addressDetails.permanent_address.area_town_locality.value}
            attrName={[
              "area_town_locality",
              "permanent_address",
              setAddressDetails,
              true,
            ]}
            value_update={updateMasterState}
            warn_status={
              addressDetails.permanent_address.area_town_locality.warning
            }
            error_message="Enter Area / Town / Locality"
            max_length={30}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                defaultChecked={
                  addressDetails.is_mailing_and_permanent_address_same
                }
                onClick={() => {
                  updateMasterState(
                    [
                      "is_mailing_and_permanent_address_same",
                      setAddressDetails,
                    ],
                    !addressDetails.is_mailing_and_permanent_address_same
                  );
                }}
              />
            }
            label="Mailing / Correspondence address is same as permanent address."
          />
        </Grid>
      </Grid>

      {/* Mailing Address */}
      {!addressDetails.is_mailing_and_permanent_address_same ? (
        <Box>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                value={addressDetails.mailing_address.pincode.value}
                attrName={[
                  "pincode",
                  "mailing_address",
                  setAddressDetails,
                  true,
                ]}
                value_update={updateMasterState}
                warn_status={addressDetails.mailing_address.pincode.warning}
                validation_type="NUMBER"
                max_length={6}
                error_message="Enter pincode"
              />
            </Grid>
            {addressDetails.mailing_address.city.toString().length > 0 ? (
              <Grid xs={8}>
                {`${addressDetails.mailing_address.city}, ${addressDetails.mailing_address.state}`}
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={6} md={5}>
              <RKTextField
                class_name="inputField"
                title={"H.No. / Building"}
                value={addressDetails.mailing_address.house_no_building.value}
                attrName={[
                  "house_no_building",
                  "mailing_address",
                  setAddressDetails,
                  true,
                ]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.mailing_address.house_no_building.warning
                }
                error_message="Enter H.no. / Building"
                max_length={30}
              />
            </Grid>
            <Grid xs={6} md={5}>
              <RKTextField
                class_name="inputField"
                title={"Area / Town / Locality"}
                value={addressDetails.mailing_address.area_town_locality.value}
                attrName={[
                  "area_town_locality",
                  "mailing_address",
                  setAddressDetails,
                  true,
                ]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.mailing_address.area_town_locality.warning
                }
                error_message="Enter Area / Town / Locality"
                max_length={30}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <Footer
        continueFunction={continueFunction}
        backText="Back to Quotes"
        loader={loader}
      />
    </Box>
  );
};

export default AddressDetails;
