import { Route, Routes } from "react-router-dom";
import CareCKYCContainer from "../../../../../Container/CKYC/CARE/CareCKYCContainer";
import AddonDetailsContainer from "../../../../../Container/Health/CARE/AddonDetailsContainer/AddonDetailsContainer";
import MedicalDetailsContainer from "../../../../../Container/Health/CARE/MedicalDetailsContainer/MedicalDetailsContainer";
import { useAppSelector } from "../../../../../Store/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import MedicalDetailsCareHeartContainer from "../../../../../Container/Health/CARE/MedicalDetailsContainer/MedicalDetailsCareHeartContainer";

export const CARE_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<CareCKYCContainer />} />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsContainer />}
        />
        {SELECTED_QUOTE_DATA.productDetails.id === 1551 ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsCareHeartContainer />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainer />}
          />
        )}
      </Route>
    </Routes>
  );
};
