import React from "react";
import { Route, Routes } from "react-router-dom";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import NIVABUPACKYCContainer from "../../../../../Container/CKYC/Niva_bupa/NIVABUPACKYCContainer";
import AddonDetailsCOntainerNIVABUPA from "../../../../../Container/Health/NIVA_BUPA/AddonDetailsCOntainerNIVABUPA";
import MedicalDetailContainerNivaBupa from "../../../../../Container/Health/NIVA_BUPA/MedicalDetailContainerNivaBupa";
import { useAppSelector } from "../../../../../Store/hooks";
import { EProductId } from "../../../../../Services/Enum/EHome";
import MedicalNivaBupaSenContainer from "../../../../../Container/Health/NIVA_BUPA/MedicalNivaBupaSenContainer";

const NIVABIPA_ROUTES = () => {
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.productDetails
  );
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<NIVABUPACKYCContainer />} />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsCOntainerNIVABUPA />}
        />
        {id === EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
        id === EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
        id === EProductId.NIVA_BUPA_Senior_First_Platinum_Floater ||
        id === EProductId.NIVA_BUPA_Senior_First_Platinum_IND ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalNivaBupaSenContainer />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailContainerNivaBupa />}
          />
        )}
      </Route>
    </Routes>
  );
};

export default NIVABIPA_ROUTES;
