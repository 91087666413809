import React from "react";
import { Routes, Route } from "react-router-dom";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import KotakCKYCContainer from "../../../../../Container/CKYC/Kotak/KotakCKYCContainer";
import { useAppSelector } from "../../../../../Store/hooks";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import AddonContainerKotak from "../../../../../Container/Health/Kotak/AddonContainerKotak/AddonContainerKotak";
import MedicalDetailContainer from "../../../../../Container/Health/Kotak/MedicalDetailContainer/MedicalDetailContainer";

const KOTAK_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const company_code = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code
  );
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<KotakCKYCContainer />} />
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonContainerKotak />}
        />
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailContainer />}
        />
      </Route>
    </Routes>
  );
};

export default KOTAK_ROUTES;
