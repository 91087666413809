import { Route, Routes } from "react-router-dom";
import CareCKYCContainer from "../../../../../Container/CKYC/CARE/CareCKYCContainer";
import AddonDetailsContainer from "../../../../../Container/Health/CARE/AddonDetailsContainer/AddonDetailsContainer";
import AddressDetailsContainer from "../../../../../Container/Health/CARE/AddressDetailsContainer/AddressDetailsContainer";
import AdultOneDetailsContainer from "../../../../../Container/Health/CARE/AdultOneDetailsContainer/AdultOneDetailsContainer";
import MemberDetailContainer from "../../../../../Container/Health/CARE/AdultTwoDetailsContainer/AdultTwoDetailsContainer";
import ChildFourDetailsContainer from "../../../../../Container/Health/CARE/ChildFourDetailsContainer/ChildFourDetailsContainer";
import ChildOneDetailsContainer from "../../../../../Container/Health/CARE/ChildOneDetailsContainer/ChildOneDetailsContainer";
import ChildThreeDetailsContainer from "../../../../../Container/Health/CARE/ChildThreeDetailsContainer/ChildThreeDetailsContainer";
import ChildTwoDetailsContainer from "../../../../../Container/Health/CARE/ChildTwoDetailsContainer/ChildTwoDetailsContainer";
import InsuredMemberContainer from "../../../../../Container/Health/CARE/InsuredMemberContainer/InsuredMemberContainer";
import MedicalDetailsContainer from "../../../../../Container/Health/CARE/MedicalDetailsContainer/MedicalDetailsContainer";
import PreviewDetailsContainer from "../../../../../Container/Health/CARE/PreviewDetailsContainer/PreviewDetailsContainer";
import MedicalDetailsContainerHdfc from "../../../../../Container/Health/HDFC/MedicalDetailsContainerHdfc/MedicalDetailsContainerHdfc";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../Store/hooks";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";
import AddonDetailsContainerICIC from "../../../../../Container/Health/ICIC/AddonDetailsContainerICIC/AddonDetailsContainerICIC";
import MedicalDetailsContainerICIC from "../../../../../Container/Health/ICIC/AddonDetailsContainerICIC/MedicalDetailsContainerICIC/MedicalDetailsContainerICIC";
import ICICICKYCContainer from "../../../../../Container/CKYC/ICICI/ICICICKYCContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import HDFCCKYCContainer from "../../../../../Container/CKYC/HDFC/HDFCCKYCContainer";
import MedicalDetailsContainerOptimarestore from "../../../../../Container/Health/HDFC/MedicalDetailsContainerOptimarestore";
import AddonDetailsContainerOptimaRestore from "../../../../../Container/Health/HDFC/AddonDetailsContainerOptimaRestore";
import MedicalDetailsContainerOptimaSecure from "../../../../../Container/Health/HDFC/MedicalDetailsContainerOptimaSecure";
import AddonDetailsContainerOptimaSecure from "../../../../../Container/Health/HDFC/AddonDetailsContainerOptimaSecure";

export const HDFC_ROUTES = () => {
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const company_code = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code
  );
  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        <Route path={CKYC_ROUTES.CKYC} element={<HDFCCKYCContainer />} />
        {(company_code === ECompanyCode.HDFC_OPTIMA_RESTORE &&
          SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_RESTORE) ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerOptimaRestore />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SECURE ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SECURE_Global ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SECURE_Plan ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerOptimaSecure />}
          />
        ) : (
          <></>
        )}
        {company_code === ECompanyCode.HDFC ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerHdfc />}
          />
        ) : (
          <></>
        )}
        {(company_code === ECompanyCode.HDFC_OPTIMA_RESTORE &&
          SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_RESTORE) ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerOptimarestore />}
          />
        ) : (
          <></>
        )}
        {SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SECURE ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SECURE_Global ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SECURE_Plan ||
        SELECTED_QUOTE_DATA.productDetails.id ===
          EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerOptimaSecure />}
          />
        ) : (
          <></>
        )}
      </Route>
    </Routes>
  );
};
