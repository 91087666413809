import { Route, Routes } from "react-router";
import RSACKYCContainer from "../../../../../Container/CKYC/RSA/RSACKYCContainer";
import AddressDetailsContainer from "../../../../../Container/Car/Bajaj/AddressDetailsContainer";
import NomineeDetailsContainer from "../../../../../Container/Car/Common/NomineeDetailsContainer";
import ReviewDetailsContainer from "../../../../../Container/Car/Common/ReviewDetailsContainer";
import VehicleDetailsContainer from "../../../../../Container/Car/Common/VehicleDetailsContainer";
import OwnerDetailsContainer from "../../../../../Container/Car/Bajaj/OwnerDetailsContainer";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { CAR_ROUTES } from "../../../../Path/CarRoutes";
import CarProposalContainer from "../../CarProposalContainer";
import BajajCKYCContainer from "../../../../../Container/CKYC/Bajaj/BajajCKYCContainer";
import BajajManualKYC from "../../../../../Page/Desktop/CKYC/Bajaj/BajajManualKYC";
import BajajManualKYCContainer from "../../../../../Container/CKYC/Bajaj/BajajManualKYCContainer";

export const BAJAJ_ROUTES = (
  <Routes>
    <Route path={CAR_ROUTES.PROPOSAL_PAGE} element={<CarProposalContainer />}>
      <Route path={CKYC_ROUTES.CKYC} element={<BajajCKYCContainer />} />
      <Route
        path={CKYC_ROUTES.MANUAL_CKYC}
        element={<BajajManualKYCContainer />}
      />
      <Route
        path={CAR_ROUTES.OWNER_DETAILS}
        element={<OwnerDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.VEHICLE_DETAILS}
        element={<VehicleDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.NOMINEE_DETAILS}
        element={<NomineeDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.ADDRESS_DETAILS}
        element={<AddressDetailsContainer />}
      />
      <Route
        path={CAR_ROUTES.PREVIEW_DEATILS}
        element={<ReviewDetailsContainer />}
      />
    </Route>
  </Routes>
);
