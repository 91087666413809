import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { COLORS } from "../../../SupportingFiles/colors";

const CustomCheckbox = ({
  label,
  defaultChecked = false,
  value,
  value_update,
  attrName,
  disabled,
  selectedValue,
  variant,
}: {
  label: React.ReactNode;
  defaultChecked?: boolean;
  value?: boolean;
  attrName?: any;
  value_update?: Function;
  disabled?: boolean;
  selectedValue?: string;
  variant?: "default" | "large";
}) => {
  const checkedLabelColor = "#141414";
  const uncheckedLabelColor = "#595959";
  return (
    <div style={{ width: "100%" }}>
      <FormControlLabel
        name={attrName}
        sx={{
          ".MuiCheckbox-root": {
            padding: "7px 8px 7px 0px",
          },
          ".MuiTypography-root": {
            lineHeight: "14px",
          },
          width: "auto",
          marginLeft: "0px",
          marginRight: "0px",
          ".MuiButtonBase-root": {
            position: "relative",
            ".MuiSvgIcon-root": {
              display: "none",
            },
            "&:before": {
              content: '""',
              border: "1px solid",
              borderColor: COLORS.lightgrey,
              height: "18px",
              width: "18px",
              borderRadius: "4px",
            },
            "&.Mui-checked": {
              "&:before": {
                content: '""',
                backgroundColor: COLORS.blue,
                border: "1px solid",
                borderColor: COLORS.blue,
                height: "18px",
                width: "18px",
                borderRadius: "4px",
              },
              "&:after": {
                position: "absolute",
                top: "13px",
                left: "6px",
                content: '""',
                borderBottom: "1px solid",
                borderLeft: "1px solid",
                borderTop: "none",
                borderRight: "none",
                borderColor: COLORS.white,
                transform: "rotate(-45deg)",
                height: "4px",
                width: "8px",
              },
            },
          },
        }}
        control={
          <Checkbox
            id={`${attrName}`}
            disabled={disabled}
            defaultChecked={defaultChecked}
            checked={Boolean(value)}
            onChange={(e) => {
              value_update && value_update(attrName, e.target.checked);
            }}
          />
        }
        label={
          <span
            style={{
              color: value ? checkedLabelColor : uncheckedLabelColor,
              position: "relative",
              // top: "1px",
              lineHeight: "20px",
              fontSize: variant === "default" ? "14px" : "14px",
              fontFamily: "JioType",
            }}
          >
            {label}
          </span>
        }
      />
    </div>
  );
};

export default CustomCheckbox;
