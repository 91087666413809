import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";
import AddonQuestion from "../../../../Component/AddonQuestion/AddonQuestion";
import Footer from "../../../../Component/Footer/Footer";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { TADDON_DETAILS_CIGNA_LIFETIME_INDIA } from "../../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";

function AddonDetailsLifeTime({
  addonStatus,
  updateMasterState,
  validate,
  PAGE_STATUS,
  isLoading,
}: {
  addonStatus: TADDON_DETAILS_CIGNA_LIFETIME_INDIA;
  PAGE_STATUS: boolean;
  updateMasterState: Function;
  validate: Function;
  isLoading: any;
}) {
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <h5 className="sectionTitle">Addon Details</h5>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Critical Illness"
            toggle_status={addonStatus.critical_illness}
            value_update={updateMasterState}
            attrName={"critical_illness"}
            desc="Critical illness give a lump sum amount equal to Sum Insured in case of first diagnosis of the covered critical illnesses. It protect the insured against financial loss in the event of a terminal illness."
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Cumulative Bonus"
            toggle_status={addonStatus.cumulative}
            value_update={updateMasterState}
            attrName={"cumulative"}
            desc=""
            disable={true}
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Worldwide Medical Emergency Hospitalization"
            toggle_status={addonStatus.wwhospitalized}
            value_update={updateMasterState}
            attrName={"wwhospitalized"}
            desc=""
            disable={true}
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Maternity Expenses"
            toggle_status={addonStatus.maternity_expenses}
            value_update={updateMasterState}
            attrName={"maternity_expenses"}
            desc=""
          />
        </Grid>
        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="infertility"
            toggle_status={addonStatus.infertility}
            value_update={updateMasterState}
            attrName={"infertility"}
            desc=""
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Oocyte Donor Cover"
            toggle_status={addonStatus.Oocyte}
            value_update={updateMasterState}
            attrName={"Oocyte"}
            desc=""
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health 360 Advance"
            toggle_status={addonStatus.health_360_advance}
            value_update={updateMasterState}
            attrName={"health_360_advance"}
            desc=""
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health 360 Opd"
            toggle_status={addonStatus.health_360_opd}
            value_update={updateMasterState}
            attrName={"health_360_opd"}
            desc=""
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health 360 Shield"
            toggle_status={addonStatus.health_360_shield}
            value_update={updateMasterState}
            attrName={"health_360_shield"}
            desc=""
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Health+"
            toggle_status={addonStatus.health_plus}
            value_update={updateMasterState}
            attrName={"health_plus"}
            desc=""
          />
        </Grid>

        <Grid xs={12}>
          <AddonQuestion
            addon_amount=""
            main_class="addonQuestion borderBottom"
            icon_class="bonus"
            question_name="Woman+"
            toggle_status={addonStatus.woman_plus}
            value_update={updateMasterState}
            attrName={"woman_plus"}
            desc=""
          />
        </Grid>

        <Footer
          textName={PAGE_STATUS === true ? "Update" : "Continue"}
          attrName={"pageStatus"}
          forward={() => {
            validate();
          }}
          value={0}
          backward={() => {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.QOUTE_PAGE}`
            );
          }}
        />
      </Box>
    </>
  );
}

export default AddonDetailsLifeTime;
