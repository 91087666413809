import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../../../Component/Navbar/Navbar";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import ProposerDetailsContainer from "../../../../Container/Term/MaxLife/ProposerDetailsContainer";
import RidersBenefitsContainer from "../../../../Container/Term/MaxLife/RidersBenefitsContainer";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TERM_ROUTES } from "../../../Path/TermRoutes";
import "./../../../../SCSS/CommonSCSS/MProposalForms.scss";
import { useAppSelector } from "../../../../Store/hooks";
import RiderBenefitSTEPContainer from "../../../../Container/Term/MaxLife/RiderBenefitSTEPContainer";

function MaxLifeRoutes() {
  const isMobile = useIsMobile();

  const MAX_LIFE_ROUTES = () => {
    const { SELECTED_QUOTE_DATA } = useAppSelector((state: any) => state.Term);
    return (
      <Routes>
        {SELECTED_QUOTE_DATA.productDetails.product_code === "LTM052" ? (
          <Route
            path={TERM_ROUTES.RIDERS_BENEFITS}
            element={<RiderBenefitSTEPContainer />}
          />
        ) : (
          <Route
            path={TERM_ROUTES.RIDERS_BENEFITS}
            element={<RidersBenefitsContainer />}
          />
        )}
        <Route
          path={TERM_ROUTES.BASIC_DETAILS}
          element={<ProposerDetailsContainer />}
        />
      </Routes>
    );
  };

  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MTermNavbar />
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              {MAX_LIFE_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              {MAX_LIFE_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default MaxLifeRoutes;
