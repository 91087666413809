import React, { useState } from "react";
import { Box, Button, Collapse, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./MMedicalDetailQuestion.scss";
import { COLORS } from "../../SupportingFiles/colors";
import SwitchToggle from "../FieldTypes/SwitchToggle/SwitchToggle";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useAppSelector } from "../../Store/hooks";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import { calculateAgeInDays, isEmpty } from "../../SupportingFiles/HelpingFunction";
import DatePicker from "../FieldTypes/DatePicker/DatePicker";
import { subDays } from "date-fns";
const MNivaBupaMedicalQuestion = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  input_id_first,
  input_id_second,
  input_name,
  toggle_button_status,
  attrName,
  value_update,
  data,
  updateMasterState,
  select_disease,
  dataSubQuestion,
}: {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
  show_toggle_status?: boolean;
}) => {
  const [checkState, setCheckState] = useState(false);
  const handleToggleMainQuestion = (e: any) => {
    setCheckState(e.target.checked);
  };

  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };

  const { INSURED_MEMBER_DETAILS, MEDICAL_QUESTION_DATA_NIVA_BUPA } =
    useAppSelector((state) => state.Health);

  // const update_status = (attrName: any, value: any) => {
  //   value_update?.(attrName, value);
  // };
  const LifeOfManagement_data: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "MEDICAL";
    } else if (i === 2) {
      status = "SURGICAL";
    }
    LifeOfManagement_data.push({ value: status, label: `${status}` });
  }

  const CurrentStatus_data: any = [];

  for (let i = 1; i <= 3; i++) {
    let status;
    if (i === 1) {
      status = "CURED";
    } else if (i === 2) {
      status = "NOT TREATED";
    } else if (i === 3) {
      status = "ONGOING";
    }

    CurrentStatus_data.push({ value: status, label: `${status}` });
  }
  const Details_of_treatment: any = [];

  for (let i = 1; i <= 2; i++) {
    let status;
    if (i === 1) {
      status = "Yes";
    } else if (i === 2) {
      status = "NO";
    }

    Details_of_treatment.push({ value: status, label: `${status}` });
  }

  console.log("bfabfbafhbsdf", data);

  return (
    <Box className="medicalQuestion_m">
      <Grid
        container
        className="row"
        columnSpacing={3}
        alignItems={"flex-start"}
        margin={"0"}
      >
        <Grid xs={9}>
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={3} textAlign={"right"}>
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
        <Grid xs={12} marginTop={"12px"}>
          {select_disease}
        </Grid>
        <Grid xs={12} marginTop={"12px"}>
          {data?.main_question?.field_data && data?.main_question?.status && (
            <>
              {" "}
              <RKTextField
                class_name=" inputField mb-5"
                title={"Account Number"}
                attrName={[
                  "mainMemberDataField",
                  data?.main_question?.id,
                  data?.main_question?.field_data.id,
                  "MQ1-SQ1-F1",
                ]}
                value={data?.main_question?.field_data?.value?.value}
                value_update={updateMasterState}
                warn_status={
                  data?.main_question?.field_data["MQ1-SQ1-F1"].value?.warning
                }
                error_message={"Enter description"}
                validation_type="NUMBER"
                max_length={13}
              />
              <SelectDropdown
                class_name=" inputField mb-5"
                title={"Repository Name"}
                attrName={[
                  "mainMemberDataField",
                  data?.main_question?.id,
                  data?.main_question?.field_data.keyName,
                  "MQ1-SQ1-F2",
                  data?.main_question?.field_data.id,
                ]}
                value={
                  data?.main_question?.field_data["MQ1-SQ1-F2"].value?.value
                }
                value_update={updateMasterState}
                warn_status={data?.main_question?.field_data?.value?.warning}
                data={[
                  {
                    label: "M/s NSDL Database Management Limited",
                    value: "NSDL",
                  },
                  {
                    label: "M/s Central Insurance Repository Limited",
                    value: "CIRL",
                  },
                  {
                    label: "M/s Karvy Insurance Repository Limited",
                    value: "Karvy",
                  },
                  {
                    label: "M/s CAMS Repository Service Limited",
                    value: "CAMS",
                  },
                ]}
                error_message={"Enter description"}
              />
            </>
          )}
          <Box marginLeft={"24px"} className="toggleDetailBox">
            {/*  */}
            <Grid container columnSpacing={2} rowSpacing={2}>
              {data?.main_question?.member_data &&
                data?.main_question?.status &&
                Object.values(data?.main_question?.member_data)?.map(
                  (memberDataValue: any, memberIndex: number) => (
                    <React.Fragment key={memberIndex}>
                      {memberDataValue.name !== "" && (
                        <Grid xs={6}>
                          <MemberRadioButtonNew
                            class_name={`mb-3 ${memberIndex === 0 || memberIndex === 1
                              ? "adult"
                              : "child"
                              }`}
                            checked={memberDataValue?.selection_status}
                            label_name={memberDataValue.name}
                            attrName={[
                              "mainMemberData",
                              data?.main_question?.id,
                              memberDataValue?.keyName,
                            ]}
                            value_update={updateMasterState}
                          />
                          {memberDataValue?.selection_status &&
                            memberDataValue.keyName && (
                              <>
                                {memberDataValue?.field_data &&
                                  Object?.values(
                                    memberDataValue?.field_data
                                  )?.map((field_data_data: any) => {
                                    let datePickerRendered = false; // Flag to track DatePicker rendering
                                    return (
                                      <React.Fragment key={field_data_data.id}>
                                        {field_data_data.field_type ===
                                          "DROPDOWN" ? (
                                          <SelectDropdown
                                            class_name=" inputField mb-5"
                                            title={field_data_data.title}
                                            attrName={[
                                              "mainMemberDataField",
                                              data?.main_question?.id,
                                              memberDataValue.keyName,
                                              field_data_data.id,
                                            ]}
                                            value={
                                              field_data_data?.value?.value
                                            }
                                            value_update={updateMasterState}
                                            warn_status={
                                              field_data_data?.value?.warning
                                            }
                                            data={
                                              field_data_data?.title ===
                                                "CurrentStatus"
                                                ? CurrentStatus_data
                                                : field_data_data?.title ===
                                                  "IsMedicalQuestionOpted"
                                                  ? Details_of_treatment
                                                  : LifeOfManagement_data
                                            }
                                            error_message={
                                              "Enter description"
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {/* {field_data_data.field_type ===
                                        "TEXTFIELD" ? (
                                          <RKTextField
                                            class_name=" inputField mb-5"
                                            title={field_data_data.title}
                                            attrName={[
                                              "mainMemberDataField",
                                              data?.main_question?.id,
                                              memberDataValue.keyName,
                                              field_data_data.id,
                                            ]}
                                            value={
                                              field_data_data?.value?.value
                                            }
                                            value_update={updateMasterState}
                                            warn_status={
                                              field_data_data?.value?.warning
                                            }
                                            error_message={
                                              "Enter description"
                                            }
                                          />
                                        ) : (
                                          ""
                                        )} */}
                                        {field_data_data.title === "Systolic" ||
                                          field_data_data.title === "Diastolic" ||
                                          field_data_data.title === "HbA1c" ||
                                          field_data_data.title === "Dosage" ? (
                                          <RKTextField
                                            class_name=" inputField mb-5"
                                            title={field_data_data.title}
                                            attrName={[
                                              "mainMemberDataField",
                                              data?.main_question?.id,
                                              memberDataValue.keyName,
                                              field_data_data.id,
                                            ]}
                                            value={
                                              field_data_data?.value?.value
                                            }
                                            value_update={updateMasterState}
                                            warn_status={
                                              field_data_data?.value?.warning
                                            }
                                            error_message={
                                              "Enter description"
                                            }
                                            validation_type="NUMBER"
                                            max_length={10}
                                          />
                                        ) : field_data_data.field_type ===
                                          "TEXTFIELD" ? (
                                          <RKTextField
                                            class_name=" inputField mb-5"
                                            title={field_data_data.title}
                                            attrName={[
                                              "mainMemberDataField",
                                              data?.main_question?.id,
                                              memberDataValue.keyName,
                                              field_data_data.id,
                                            ]}
                                            value={
                                              field_data_data?.value?.value
                                            }
                                            value_update={updateMasterState}
                                            warn_status={
                                              field_data_data?.value?.warning
                                            }
                                            error_message={
                                              "Enter description"
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {field_data_data.field_type ===
                                          "DATEPICKER" &&
                                          !datePickerRendered && (
                                            <DatePicker
                                              class_name="inputField mb-5"
                                              title={field_data_data?.title}
                                              value={
                                                field_data_data?.value?.value
                                              }
                                              attrName={[
                                                "mainMemberDataField",
                                                data?.main_question?.id,
                                                memberDataValue.keyName,
                                                field_data_data.id,
                                              ]}
                                              value_update={updateMasterState}
                                              min_date={
                                                memberDataValue.keyName ===
                                                  "adult_one"
                                                  ? INSURED_MEMBER_DETAILS
                                                    .ADULT_ONE_DETAILS.dob
                                                    .value
                                                    ? calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                    )
                                                    : calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                    )
                                                  : memberDataValue.keyName ===
                                                    "adult_two"
                                                    ? calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                    )
                                                    : memberDataValue.keyName ===
                                                      "child_one"
                                                      ? calculateAgeInDays(
                                                        `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                      )
                                                      : memberDataValue.keyName ===
                                                        "child_two"
                                                        ? calculateAgeInDays(
                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                        )
                                                        : memberDataValue.keyName ===
                                                          "child_three"
                                                          ? calculateAgeInDays(
                                                            `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                          )
                                                          : memberDataValue.keyName ===
                                                            "child_four"
                                                            ? calculateAgeInDays(
                                                              `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                            )
                                                            : 25
                                              }
                                              max_date={0}
                                              default_date={subDays(
                                                new Date(),
                                                memberDataValue.keyName ===
                                                  "adult_one"
                                                  ? INSURED_MEMBER_DETAILS
                                                    .ADULT_ONE_DETAILS.dob
                                                    .value
                                                    ? calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                    )
                                                    : calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value}`
                                                    )
                                                  : memberDataValue.keyName ===
                                                    "adult_two"
                                                    ? calculateAgeInDays(
                                                      `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value}`
                                                    )
                                                    : memberDataValue.keyName ===
                                                      "child_one"
                                                      ? calculateAgeInDays(
                                                        `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.dob.value}`
                                                      )
                                                      : memberDataValue.keyName ===
                                                        "child_two"
                                                        ? calculateAgeInDays(
                                                          `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob.value}`
                                                        )
                                                        : memberDataValue.keyName ===
                                                          "child_three"
                                                          ? calculateAgeInDays(
                                                            `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob.value}`
                                                          )
                                                          : memberDataValue.keyName ===
                                                            "child_four"
                                                            ? calculateAgeInDays(
                                                              `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob.value}`
                                                            )
                                                            : 25
                                              )}
                                              warn_status={
                                                field_data_data?.value?.warning
                                              }
                                              date_validation_type="DAYS"
                                              error_message={
                                                "Select existing since"
                                              }
                                            />
                                          )}
                                      </React.Fragment>
                                    );
                                  })}
                              </>
                            )}
                        </Grid>
                      )}
                    </React.Fragment>
                  )
                )}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} alignItems={"flex-start"} margin={"0"}>
        <Grid xs={12} textAlign={"right"}>
          <hr />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MNivaBupaMedicalQuestion;
