import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { TADDON_DETAILS_CIGNA_LIFETIME_GLOBAL } from "../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";
import AddonDetailsLifeTimeGlobal from "../../../Page/Desktop/Health/CIGNA/AddonDetailsLifeTimeGlobal";
import { toast } from "react-toastify";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import { Box } from "@mui/material";
import MAddonDetailsLifeTimeGlobal from "../../../Page/Mobile/Health/CIGNA/MAddonDetailsLifeTimeGlobal";

export default function AddonDetailsContainerLifeTimeGlobal() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const { Health } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addonStatus, setAddonStatus] =
    useState<TADDON_DETAILS_CIGNA_LIFETIME_GLOBAL>(
      ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL
    );
  const [loader, setLoader] = useState<boolean>(false);
  console.log(addonStatus, "addonStatus");

  // useEffect(() => {
  //   updatePreiumApi();
  //   dispatch(
  //     HealthSlice.actions.ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL(addonStatus)
  //   );
  // }, [addonStatus]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "critical_illness") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (
      attrName === "maternity_expenses" &&
      (ADD_FORM.gender.value === "F" ||
        ADD_FORM.spouse_relationship.value === "Spouse")
    ) {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (
      attrName === "infertility" &&
      addonStatus.maternity_expenses === false
    ) {
      toast.error(
        "Infertility Rider will be allowed only when the Maternity cover rider is opted"
      );
    } else if (
      attrName === "infertility" &&
      addonStatus.maternity_expenses === true
    ) {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "Oocyte") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "health_360_advance") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "health_360_opd") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "health_360_shield") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "global_plus") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "wwhospitalized") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "wwh_suminsured") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    } else if (attrName === "global_suminsured") {
      setAddonStatus((prev) => ({
        ...prev,
        [attrName]: value,
      }));
    }
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      gender: ADD_FORM.gender.value,
      addon: {
        critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
        cumulative: addonStatus.cumulative === true ? "Yes" : "No",
        wwhospitalized: addonStatus.wwhospitalized === true ? "Yes" : "No",
        maternity_expenses:
          addonStatus.maternity_expenses === true ? "Yes" : "No",
        infertility: addonStatus.infertility === true ? "Yes" : "No",
        Oocyte: addonStatus.Oocyte === true ? "Yes" : "No",
        health_360_advance:
          addonStatus.health_360_advance === true ? "Yes" : "No",
        health_360_opd: addonStatus.health_360_opd === true ? "Yes" : "No",
        health_360_shield:
          addonStatus.health_360_shield === true ? "Yes" : "No",
        global: addonStatus.global === true ? "Yes" : "No",
        global_plus: addonStatus.global_plus === true ? "Yes" : "No",
        wwh_suminsured: addonStatus.wwh_suminsured,
        global_suminsured: addonStatus.global_suminsured,
      },
    };
    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonCignaLifeTimeGlobal(
      onSuccess,
      onError,
      param
    );
  };

  const validate = () => {
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "add_ons_details",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
      cumulative: addonStatus.cumulative === true ? "Yes" : "No",
      wwhospitalized: addonStatus.wwhospitalized === true ? "Yes" : "No",
      maternity_expenses:
        addonStatus.maternity_expenses === true ? "Yes" : "No",
      infertility: addonStatus.infertility === true ? "Yes" : "No",
      Oocyte: addonStatus.Oocyte === true ? "Yes" : "No",
      health_360_advance:
        addonStatus.health_360_advance === true ? "Yes" : "No",
      health_360_opd: addonStatus.health_360_opd === true ? "Yes" : "No",
      health_360_shield: addonStatus.health_360_shield === true ? "Yes" : "No",
      global: addonStatus.global === true ? "Yes" : "No",
      global_plus: addonStatus.global_plus === true ? "Yes" : "No",
    };
    if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
        // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
      );
    }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  return (
    <>
      {" "}
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}{" "}
      {isMobile ? <MAddonDetailsLifeTimeGlobal
        isLoading={loader}
        addonStatus={addonStatus}
        updateMasterState={updateMasterState}
        validate={validate}
        PAGE_STATUS={PAGE_STATUS}
      /> : (
        <AddonDetailsLifeTimeGlobal
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
}
