import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CustomButton from "../../../Component/CustomButton/CustomButton";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  formatAmountToCurrency,
  formatToCurrency,
} from "../../../SupportingFiles/HelpingFunction";
import { TTermProposerData } from "../../../types/TTermSlice";
import { TRidersBenefitsResponse } from "../../../types/Term/TRidersBenifits";

function RecalculatedPremiumPopup({
  open,
  setOpen,
  recRiders,
  setProposerDetails,
}: {
  open: boolean;
  setOpen: Function;
  recRiders: TRidersBenefitsResponse;
  setProposerDetails: Function;
}) {
  const {
    PROPOSER_DATA,
    QUOTE_FIELDS_DATA,
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Term);
  const { Term } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  return (
    <Modal open={open} className="modalWrapper">
      <Box className="modalInner lg-width">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Link
              className="close-button"
              onClick={() => {
                setProposerDetails({
                  ...Term.PROPOSER_DATA,
                  sum_assured: { value: "", warning: false },
                });
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12} className="mt-5">
            <h5>
              We regret to inform that we have to revise your offer based on
              profile.
            </h5>
            <h5>Please review and confirm</h5>
          </Grid>
          <Grid xs={12} className="tableView">
            <ul>
              <li>Products</li>
              <li>Initial Selection</li>
              <li>Revised Offer</li>
            </ul>
            <ul>
              <li>Policy Tenure</li>
              <li>{SELECTED_QUOTE_DATA?.premiumDetails?.term} Years</li>
              <li>{SELECTED_QUOTE_DATA?.premiumDetails?.term} Years</li>
            </ul>
            <ul>
              <li>Policy Pay Tenure</li>
              <li>{SELECTED_QUOTE_DATA?.premiumDetails?.pay_term} Years</li>
              <li>{SELECTED_QUOTE_DATA?.premiumDetails?.pay_term} Years</li>
            </ul>
            <ul>
              <li>Sum Assured</li>
              <li>{formatToCurrency(Number(QUOTE_FIELDS_DATA.sumAssured))}</li>
              <li>
                {formatToCurrency(Number(PROPOSER_DATA.sum_assured.value))}
              </li>
            </ul>
            <ul>
              <li>Total Premium</li>
              <li>
                {" "}
                {formatAmountToCurrency(
                  RIDERS_BENEFITS?.riders_benefits_response?.finalPremium
                )}
              </li>
              <li>
                {formatAmountToCurrency(recRiders?.finalPremium)}{" "}
                <span style={{ display: "block", fontSize: "10px" }}>
                  12% Discount for 1st Yr
                </span>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} className="mt-4">
            <CustomButton
              text_name={"Accept Premium"}
              class_name="mediumPrimaryBtn"
              onClickFunction={() => {
                dispatch(
                  TermSlice.actions.BULK_UPDATE({
                    ...Term,
                    RIDERS_BENEFITS: {
                      ...Term.RIDERS_BENEFITS,
                      riders_benefits_response: { ...recRiders },
                    },
                    QUOTE_FIELDS_DATA: {
                      ...Term.QUOTE_FIELDS_DATA,
                      sumAssured: Term.PROPOSER_DATA.sum_assured.value,
                    },
                  })
                );
                setOpen(false);
              }}
            />
            <CustomButton
              text_name={"Reselect"}
              class_name="mediumSecondaryBtn mt-4"
              onClickFunction={() => {
                setProposerDetails((prev: TTermProposerData) => ({
                  ...prev,
                  sum_assured: { value: "", warning: false },
                }));
                setOpen(false);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default RecalculatedPremiumPopup;
