import { Box } from "@mui/material";
import NivaBupaSenMedicalQuestion from "../../../../../Component/MedicalQuestion/NivaBupaSenMedicalQuestion";
import { TNivaBupaMedicalQuestion } from "../../../../../types/Health/HealthQuotation/TNivaBupaMedicalQuestion";
import HealthFooter from "../../HealthFooter/HealthFooter";

const MedicalDetailNivaBupaSenior = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
}: {
  medicalQuestionData: TNivaBupaMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
}) => {
  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      {Object.values(medicalQuestionData).map(
        (data, indexMainQuestion: number) => (
          <div key={indexMainQuestion}>
            <NivaBupaSenMedicalQuestion
              value_update={updateMasterState}
              data={data.main_question}
            />
          </div>
        )
      )}
      <HealthFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MedicalDetailNivaBupaSenior;
