import { PayloadAction } from "@reduxjs/toolkit";
import { TTermFullJourneySlice } from "../../../../types/TTermFullJourneySlice";
import { TTermMedicalQuestionDetails } from "../../../../types/TermFullJourney/TTermMedicalQuestionDetails";

function SET_ICICI_MEDICAL_QUESTION(
  state: TTermFullJourneySlice,
  action: PayloadAction<TTermMedicalQuestionDetails>
) {
  const data: TTermFullJourneySlice = {
    ...state,
    TermICICIMedicalQuestion: { ...action.payload },
  };
  return data;
}

export const TERM_ICICI_MEDICAL_QUESTION_REDUCERS = {
  SET_ICICI_MEDICAL_QUESTION,
};
